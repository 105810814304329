
.waiting_room_my_video_blank{
  font-size: 15px;
  letter-spacing: 0;
  line-height: 22px;
  .btn-link{
      color: #FF9F00;
  }
    h1{
        color: #FFFFFF;
        font-size: 36px;
        letter-spacing: 0;
        line-height: 43px;
    }
}