@import "../../../scss/utils/variables";


.goolge_tv_test_drive{
    &__main{
        &__screen{
           background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;
            min-height: 100vh;
        }
        
    }
    
    &__inner{
        &__screen{
            overflow: auto;
            max-width: 966px;
            width: 100%;
            margin-top: 19px;
            margin-bottom: 85px !important;
        }
    }
   
}
.topBanner{
    font-size: 24px;
    line-height: 21px;
    color: #FFFFFF;
    background: #FF9F00;
    height: 81px;
    font-weight: 500;
}

.videoRoomBox{
    background: linear-gradient(314.86deg, rgba(0, 0, 0, 0.85) 0%, rgba(199, 199, 199, 0.85) 178.2%);
    color: $white;
    font-size: 2.8rem;
    transition: all 0.2s;
    opacity: 0.7;
    &__operatory_room{
        background: linear-gradient(135deg, rgba(54, 108, 128, 0.93) 0%, rgba(15, 79, 149, 0.93) 100%);
        backdrop-filter: blur(19.028px);
    }
    &__innerBox{
        max-width: 540px;
    }
    &:hover{
        width: 120%!important;
        opacity: 1;
    }
    h2{
        font-size: 2.8rem;
        color: $white;
        font-weight: 400;
    }
    h1{
        font-size: 6rem;
        font-weight: 400;
        color: $white;
    }
    
  
}

@media (max-width:1366px){
    .goolge_tv_test_drive{
        &__main{
            &__screen{
              zoom: 0.8;
              padding-top: 7rem;
            }
            
        }
    }
}