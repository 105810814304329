
.operatory-room .PrivateTabIndicator-colorPrimary-2{
    background-color: transparent;
}
.opretory_video_box{
    width: 200px;
}

.videolist__main__header__header-title{
    color: white;
    display: block;
}
.operatory-room         { padding-top: 70px; }
.opratory-dropbox-bg    { background: rgba(255,255,255,0.05); color: #fff; }
.dropbox-title p        { font-size: 36px; line-height: 43px; font-weight: 500;  }
.dropbox-sub-title      {font-size: 15px; line-height: 22px; color: #fff; opacity: 0.6; }
.opratory-title         { color: #fff;  }
.opratory-title .practice_name { font-size: 20px; line-height: 30px; }
.opratory-left-title    { font-size: 20px; line-height: 26px; }

.MuiBox-root    { max-width: 1630px; margin: 0px auto; }
.videoSelected__main__centerCol{ padding-left: 30px; padding-right: 15px; }
.videoSelected__main__centerCol .border-4 { border: none; }
.videoSelected__main__card{ background: transparent !important; border: 3px solid transparent; transition: 0.5s;
    &:hover { box-shadow: none !important;  }
}
// .MuiContainer-root.MuiContainer-maxWidthMd  { position: absolute; }
.timeline_item              { background: transparent !important; }
// .videolist__main__screen     { margin-top: 31px;}
.videolist__main__screen     { margin-top: 0px;}

// Social Tab

.social-media-sec           { overflow-y: auto; min-height: 680px; display: flex; flex-wrap: wrap; align-items: center; justify-content: center;
    .row      { width: 100%; text-align: center; align-items: center;
        .d-flex{ align-items: center;
            .col-md-4   {  display: flex; align-items: center; justify-content: center;
                img  { border-radius: 50%}
             }
             .col-md-8   {  display: flex; align-items: center; justify-content: flex-start;
                img  { border-radius: 50%}
             }
        }
    }

}
// Scrollble sec
.dropbox__main__screen { height: 150px !important; width: 100%;}
.scroolable-sec     { position: relative; height: 150px !important; 
    .swiper-button-next { height: 150px;top: 20px; width: 50px; right: 0;
        &::before { width: 100%; height: 100%; position: absolute; top: 0; left: 0; background: rgba(0, 0, 0, 0.5); content: '';filter: blur(4px); }
        &::after    { color: #fff;  z-index: 2;}
    }
    .swiper-button-prev{ height: 150px;top: 20px; width: 50px; left: 0;
        &::before { width: 100%; height: 100%; position: absolute; top: 0; left: -2px; background: rgba(0, 0, 0, 0.5); content: ''; filter: blur(4px); }
        &::after    { color: #fff; z-index: 2;}
    }
}
.timeline_item      { margin: 0 !important; padding: 0 !important;}
// .timeline_container > div  { padding: 0 !important; overflow: unset !important; width: 100%; }
.videoSelected__main__centerCol { height: 680px !important; overflow-y: auto }
.timeline_item_view     { width: 100% !important;} 
.opratory-dragable-list .swiper-container { height: 100%; }
.opratory-left-sec  { margin-bottom: 30px; background: rgba(255,255,255,0.08); }
// .videolist__main__screen button { min-width: 170px; min-height: 40px; }
.PrivateTabIndicator-colorPrimary-18    { background-color: transparent !important;}
.opratory-timeline-sec .footer-tabs .MuiTab-wrapper{
    text-transform: uppercase!important;
}

.waitiing-room-col-sec .waiting-rom-right-sec .videoSelected__main__card__selectedImage{
    top: 12px;
    left: 15px;
    height: 20px;
    font-size: 16px;
    font-weight: 700;
    color: #424242;
    width: 20px;
    border-radius: 2px;
    padding: 0;
    text-align: center;
    line-height: 23px;
    background: linear-gradient(315deg, #FFB438 0%, #FF9F00 100%);
}