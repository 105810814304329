@import "../../../scss/utils/variables";

.goolge_tv_test_drive__main__screen{
    .btn {
        font-size: 3.4rem;
        &.btn-lg{
            max-width: 34.5rem;
            padding: 0.4rem 1rem;
            width: 100%;
        }

        &-outline{
            &-white{
              .spinner-border.text-dark{
                color: $white!important;
              }
            }
              &-default{
                  border: 3px solid $btn-default-border;
                  padding: 0.8rem 1.5rem;
                  transition: transform .2s; /* Animation */

                  &.white{
                    border-color: $white;
                    color: $white;
                    box-shadow: none;
                    &:hover{
                      background-color: $primary-color;
                      border-color: $primary-color;
                      color: $main-font-color;
                      transform: scale(1.1);
                    }
                  }
                  &:hover{
                      background-color: $primary-color;
                  }
              }
        }
    }
}
