

.upload_cetegory{
    
    &__images{
        &__screen{
            width: 1360px;
        }
        &__uploadBox{
            margin-right: 30px;
            .add_profile_picture {
                margin-top: 0;
            }
            .tabs{
                max-width: 250px;
            }
        }
        &__cat_lists{
            overflow-x: hidden;
            overflow-y: scroll;
            width: 100%;
            max-height: 750px;
            min-width: 790px;
            
            .cat_list_box{
                // padding: 0 20px;
                position: relative;
                border-radius: 7px;
                overflow: hidden;
                cursor: pointer;
                width: 355px;
                &.cat_list_item{
                    border: 2px solid transparent;
                    &:hover{
                        border:2px solid #FFB438
                    }
                    &.activeImg{
                        border:2px solid #FFB438
                    }
                }
                img{
                    object-fit: cover;
                    height: 200px;
                    width: 100%;
                }
                // &::after{
                //     content: '';
                //     position: absolute;
                //     top: 0;
                //     left: 0;
                //     bottom: 0;
                //     right: 0;
                //     opacity: 10%;
                //     z-index: 0;
                //     background: linear-gradient(-45deg, #000000, #C66942);
                // }
                .cat_title{
                    position: absolute;
                    top: 50%;
                    left: 0;
                    right: 0;
                    text-align: center;
                    z-index: 1;
                    text-transform: uppercase;
                    transform: translateY(-50%);
                    color: #fff;
                }
            }
        }
    }
   
}
.mb-40{
    margin-bottom: 40px;
}


@media (max-width:1700px){
    .upload_cetegory__images__screen{
        max-width: 85%;
        padding: 20px;
    }
    .add_profile_picture .profile_picture_img_box{
        height: 250px;
    }
}

@media (max-width:1360px){
    .upload_cetegory__images__screen{
        max-width: 100%;
        padding: 20px;
    }
    .upload_cetegory__images__cat_lists{
        min-width: auto;
        width: auto;
    }
    .upload_cetegory__images__cat_lists .cat_list_box{
        width: 100%;
    }
    
}