.Page8
{
    &__main
    {
           display: contents;
           justify-content: center;
           align-items: center;
           flex-direction: column;
           font-size: 12px;
           margin: 15px;

          &__headingDivWrapper
          {
            display: flex;
            width: 100%;
            justify-content: space-between;
            position: relative;
            bottom: 10px;
            align-items: center;
                
            .toggle-button{
                margin-right: 0;
                margin-left: 0;
                span:first-child{
                    width: 40px;
                    height: 22px;
                    left:0;
                    top:0;
                }
            }
            &__rightTxtDiv
            {

                position: relative;
                right: 0px;
                color: white;
                font-size: 15px;
                flex: 1;
            }

            &__leftTxtDiv
            {
                color: white;
                right: 90px;
                font-size: 15px;
            }
          }

            &__upperDivWrapper
            {
                display: flex;
                justify-content: center;
                align-items: center;
         

                &__holdingDiv
                {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  
                }
                label
                {
                    position: relative;
                    top: 10px;
                    right: 5px;
                    color: white;

                }
                &__inputDiv
                {

                    label
                    {
                        position: relative;
                        left: 8px;
                        top: 7px; 
                        font-size: 15px;  
                    }

                    input[type="number"]
                    {
                        height: 32px;
                    
                        position: absolute;
                        text-align: center;
                        font-size: 20px;
                        width: 75px;
                        margin: 0;
                        left: -5px;
                        background-color: transparent;
                        border: 0px;
                        background-image: url('../../../img/Updown.png');
                        background-position: center right;
                        background-size: 13px;
                        outline: none;
                        background-repeat: no-repeat;
                        caret-color: transparent;
                    }
                    input[type="number"]::-webkit-inner-spin-button,
                    input[type="number"]::-webkit-outer-spin-button
                    {
                        -webkit-appearance: inner-spin-button !important;
                        opacity: 0 !important;
                        width: 50px;
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 100%;
                        background-color: blue !important;
                        border: 2.5px solid red !important;
                    }   
                    
                    &__input2
                    {
                        background-color: #525252;
                        height:40px;
                        position: relative;
                        width: 77px;
                        display: flex;
                        border-radius: 2px;
                        justify-content: center;
                        align-items: center;
                        margin: .5rem;
                    }
                }
            }

            &__lowerDivWrapper
            {
                display: flex;
                justify-content: center;
                align-items: center;


                &__holdingDiv
                {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 20px;
                }
                    label
                {
                    position: relative;
                    top: 10px;
                    right: 5px;
                    color: white;

                }

                &__inputDiv
                {
                    
                    label
                    {
                        position: relative;
                        left: 8px;
                        top: 7px;   
                    }

                    input[type="number"] 
                    {
                        height: 32px;
                    
                        position: absolute;
                        text-align: center;
                        font-size: 20px;
                        width: 75px;
                        margin: 0;
                        left: -5px;
                        background-color: transparent;
                        border: 0px;
                        background-image: url('../../../img/Updown.png');
                        background-position: center right;
                        background-size: 18px;
                        outline: none;
                        background-repeat: no-repeat;
                        caret-color: transparent;
                    }
                    input[type="number"]::-webkit-inner-spin-button,
                    input[type="number"]::-webkit-outer-spin-button
                    {
                        -webkit-appearance: inner-spin-button !important;
                        opacity: 0 !important;
                        width: 50px;
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 100%;
                        background-color: blue !important;
                        border: 2.5px solid red !important;
                    }   
                    
                    &__input2
                    {
                        background-color: lightgray;
                        height: 2.5rem;
                        position: relative;
                        width: 6rem;
                        display: flex;
                        border-radius: 2px;
                        justify-content: center;
                        align-items: center;
                        margin: .5rem;
                        margin-left: 2rem;
                    }
                }
            }
        
    }
}