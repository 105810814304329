@import "../../scss/utils/variables";

.gap-15{
    gap: 15px;
}
.gap-10{
    gap: 10px;
}
.owners_profile_creation{
    &__main{
        &__screen{
            // background-image: url('../../img/Background_imageAuthorizedUsers.jpg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;

            .transparent__form--white{
                &.about__screen{
                    max-width: 430px;
                    min-height: 820px;
                }
                .btn.btn-xl{
                    max-width: 100%;
                }
            }
            .form-control,.react-select-container,.react-select-container .react-select__control,.react-tel-input .form-control{
                max-width: 100%;
            }
            .react-datepicker-wrapper{
                display: block;
            }
            form{

                .form-group{

                    label{
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
            }

            .practice_name{
                font-size: 18px;
                line-height: 31px;
            }
        }
        
    }
    &__inner{
        &__screen{
            .mw-820{
                min-width: 900px;
                // margin-top: -25px;
                z-index: 9;
                position: relative;

                .mobile-number{
                    margin-top: 32px !important; 
                }
                .get-code{
                    margin-top: 40px !important;

                }
            }
            .padd-l-20{
                padding-left: 20px;
            }
            .padd-r-20{
                padding-right: 20px;
            }
            &__footer__button{
                position: relative;
                margin-top: 32px !important;
                z-index: 0;

                button{
                    &:first-child{
                        // margin-right: 9px;
                    }
                }
            }
            &__button{
                max-width: 220px !important;
                height: 40px;

                &__deleteButton{
                    background: linear-gradient(135deg, #D0393E 0%, #D54A53 100%);
                    float: right;
                    border:0;
                }
            } 

            .mobile-veryfied{
                padding-top: 0rem !important;
            }
            .react-code-input{
                max-width: 100%;
            }
  
        }
        &__text{
            color: $white;
            font-size: 2rem;
            line-height: 3rem;
            // max-width: 67rem;
            h1{
                margin-top: 2rem;
            }
            h3{
                font-size: 2.7rem;
                font-weight: 500;
                letter-spacing: 7px;
                margin-bottom: 3rem;
            }
            h1,h2,h3,h4,h5,h6{
                color: $white;
            }
            
        }
    }
}

.delete{

    &__screen{
        // margin-top: -38px;

        .heading{

            h4{
                margin-top: 9px !important;
                padding-bottom: 7px;
            }
        }

        .removal-section{

            h4{
                margin-bottom: 21px !important;
                margin-top: 12px !important;
            }

            p{
                margin-bottom: 20px;
                line-height: 21px;
            }
        }

        .confirm-delete{
            margin-left: -39px !important;
            margin-top: 5px;
        }

        .enter-passcode{
            margin-top: 12px !important;

            .form-group{
                label{
                    margin-bottom: 0px;
                }
            }
        }
    }
}