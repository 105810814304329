.videoSelected {
    &__main {
        &__screen {
            // margin-top: -36px;
            // margin-top: -55px;
            // min-width: 1280px;

            &__disabled_screen{
                margin-top: -39px;
            }
        }
        &__header {
            color: white;
            text-align: right;
            // margin-top: 17px;
            // margin-bottom: 15px;
            // transform: translateY(-15px);
            
            &__reset{

                color:#FF9F00;
            }

            button{
                background-color: transparent;
                position: relative;
                padding: 0 15px;

                &:nth-child(2){
                    &::before{
                        content: "";
                        position: absolute;
                        left: -3px;
                        top:0;
                        bottom: 0;
                        width: 2px;
                        height: 100%;
                        background-color: #fff;
                    }   
                }

                &:hover{
                    background-color: transparent;
                }
            }
        }
        &__centerCol {
            flex: 1;
            overflow-x: hidden;
            overflow-y: scroll;
            //height:800px;
            width: 100%;
            // padding-top: 31px;
            padding-left: 15px;
            padding-right: 15px;
           // min-width: 1187px;
            .d-flex{
                // &:after{
                //     content: "";
                //     flex: auto;
                // }
            }
        }
        &__columnFour {
            margin-bottom: 30px;
            &.videoSelected_box{
                height: 200px;
                max-width: 378px;
                min-width: 378px;
                padding: 0 15px;
                margin: 12px 0;
            }
        }
       
        &__card {
            background:#5E89FF;
            height: 200px;
            position: relative;
            border-radius: 2px;
            padding: 0px;
            &.websitevideo{
                border: 2px solid transparent;
                padding: 0;
            }
            &.active{
                border-color: #5E89FF;
            }
            &::before{
                content: "";
                position: absolute; 
                left:0px;
                right: 0px;
                bottom: 0px;
                top:0px;
                background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
            }
            &__video_icon{
                width: 65px;
                height: 38px;
                border-radius: 2px;
                border: 1px solid #ffffff;
                background-color:rgba(66, 66, 66, 0.5) ;
                text-align: center;
                padding: 9px 0;
                margin: 0 auto;
                margin-bottom: 10px;
                display: none;
                img{
                    width: 16px;
                    height: auto;
                }
            }

            &__smalltitile{
                margin-bottom: 0;
                font-size: 12px;
                line-height: 18px;
                text-transform: capitalize;
                display: none;
            }
            &__description{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            
            &__videoImage{
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 2px;
            }
            &__selectedImage {
                width: 20px;
                position: absolute;
                top: 15px;
                left: 15px;
                cursor: pointer;
    
            }
            &__subTitle {
                color: white;
                left: 0;
                right: 0;
                bottom: 10px;
                font-size: 12px;
                position: absolute;
                text-align: center;
            }
            &__title{ 
                margin-bottom: 0; 
                font-size: 15px;
                line-height: 22px;
            }
            &__time{
                display: none;
            }
            &:hover{
                // transform: translateY(-6px) scale(1.06);
                // transition: 0.3s ease-in-out;
                // box-shadow: 0 0 9px 0 #36C5FF;

                .videoSelected__main__card__time{
                    display: inline-block;
                }

                .videoSelected__main__card__subTitle{
                    text-align: left;
                    left: 15px;
                    right: 15px;
                }
                .videoSelected__main__card__video_icon{
                    cursor: pointer;
                }
                .videoSelected__main__card__video_icon,
                .videoSelected__main__card__smalltitile{
                    display: block;
                    margin-left: 0;
                }
            }

        }
        
    }
}
.card{
    width: 35rem;
    height: 20rem;
    background-color: black;
    
}
.model{
    position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4);
}

// .header-detail{
//     color: white;
//     text-align: right;
// }
// .column-four{
//     margin-top: 20px;
    
// }

// .img-size{
//     max-width: 100%;
// }

// .selected-image{
//     width: 21px;
//     margin-left: 10px;
//     margin-top: 10px;
//     /* margin-left: 10px;
//     margin-top: 10px; 
//     width: 21px;
//     height: 17px; */
//     position: absolute;
// }
// .card{
//     width: 35rem;
//     height: 20rem;
//     background-color: black;
    
// }

// /*   .card :hover{
   
//      width: 36rem;
//     height: 25rem;
 
   
// }  */
// .sub-title{
//     color: white;
//     top: 150px;
//     /* margin-top: 100px; */
//     font-size: 12px;
//     position: absolute;
    

//     text-align: center;
// }
// .center-col {
//     flex: 1;
   
//     overflow-x: hidden;
//     overflow-y: scroll;
//     height: 700px;
//     width: 100%;
//   }
//   .video-player{
//     width: 35rem;
//     height: 20rem;
// }
.MuiTypography-h5 {
    margin-left: 125px!important;
}
.website_videos_video_selection {
    .videolist__main__lists__scroll_section{
        max-height: 445px;
        overflow-y: auto;
    }
}

.opratory-timeline-sec{
    .videoSelected__main__centerCol {
        padding-left: 0;
        padding-right: 0;
    }   
    .video_list{
        flex-wrap: wrap;
        margin: 0 -15px;
        display: flex;
    }
    .videoSelected__main__columnFour.videoSelected_box{
        height: 200px;
        max-width: 356px;
        padding: 0;
        min-width: 356px;
        margin: 0 15px 30px;
    }
  
}


@media (max-width:1700px){
    .videoSelected__main__card__title,
    .website_video_library__main__screen .website_videos_rightside .videoSelected__main__card__duration{
        font-size: 11px;
        line-height: normal;
    }
    .videoSelected__main__card:hover .videoSelected__main__card__video_icon{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .website_video_library__main__screen .website_videos_rightside .videoSelected__main__card__video_icon{
        width: 45px;
        height: 25px;
    }
    .videoSelected__main__card__video_icon img{
        width: 12px;
    }
}

@media (max-width:1366px){
    .modal-video .height-82 video{
        max-height: calc(100vh - 30px);
    }
}