@import "../../../scss/utils/variables";


.review__screen{
    &.transparent__form--white{
        width: 1250px;
        height: 740px;
    }
}
.practice__hours_section{
    .disable__time{
        opacity: 0.4;
    }
    .practice__hours_box{
        position: relative;
        .error{
            position: absolute;
            top: -18px;
            left: 0;
        }
    }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
     opacity: 0;
     cursor: pointer;
}

.mh-755{
    min-height: 75.5rem;
}
.mh-835{
    min-height: 83.5rem;
}

.practice_info{

    // margin-top: -13px;
    .inner_white_box{
        height: 708px;
overflow: auto;
    }
    .practice-footer{
        button{
            margin-top: 0 !important;
        }
    }
}

.disabled_plan{
    opacity: 0.5;
}