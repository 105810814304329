
@import "../../../scss/utils/variables";

.modal-video{
    backdrop-filter: blur(8px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: rgba(0,0,0,0.6);
    .fullscreenvideo {
      video{
        padding-bottom: 0!important;
        object-fit: cover;
        max-height: calc(100vh - 70px);
      }
      iframe{
        min-height: calc(100vh - 70px);
        padding-bottom: 0!important;
      }
      .controlsTop{
        top:30px;
        z-index: 9;
        padding: 0 40px;
      }
    }
    .height-82{
      video{
        max-height: calc(100vh - 70px);
        padding-bottom: 70px;
      }
      iframe{
        min-height: calc(100vh - 70px);
        padding-bottom: 70px;
      }
    }
    iframe{
      height: 100%;
      min-height: calc(100vh - 122px);
    }
    video{
        display: block;
        // object-fit: cover;
        // max-height: 80vh;
        max-height: calc(100vh - 122px);
        padding-bottom: 122px;
        background: #000;
    }
    .controlsTop{
      top: -22px;
      left: 0;
      right: 0;
      position: absolute;
      font-size: 15px;
      font-weight: 300;
    }
    .video-show{
      // margin-top: 148px !important;

      .minimize-close-section{
        margin: 0 -10px;
      }
      .video_overly{
     

        h2{
          font-size: 35px;
          margin-top: -95px;
        }
      }
      .normalscreen{
          
        > div + div + div{
  
          > div + div {
            // padding: 13px 0px 24px !important;
            // background-color: rgba(0, 0, 0, 0.6);
            // backdrop-filter: blur(8px);

                .MuiGrid-root {
                  &.ml-auto{
                    margin-left: 206px !important;
                    button{
                        font-size: 30px !important;
                      .MuiIconButton-label{
                          svg{
                            width: 1.5em;
                            height: 1.5em;
                          }
                      }
                    }
                  }
                 


                }
                &.MuiGrid-justify-xs-space-between{

                  .MuiGrid-grid-lg-7{
                    max-width: 63.333333%;
                    flex-basis: 63.333333%;
                    margin-left: -27px;
                  } 
                  .MuiGrid-grid-lg-2{
                    max-width: 13.666667%;
                    flex-basis: 13.666667%;
                  }
                }

          }
        }
      }


    }
}
.video_overly{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(40px);
  background-color: rgba(0,0,0,0.6);
  color: $white;
  h2{
    color: $white;
  }
}
.bg-none.modal-video{
  position: relative;
}
// ::cue{ bottom: 50px; position: 5; margin-bottom: 50px; color: crimson;}

// .modal-video .video-show {
//   transform: translate(0, -50px);
//   transition: transform 0.3s ease-out;
//   width: 1600px;
//   margin: 0 auto;
// }
