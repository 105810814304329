.goolge_tv_header{
    background-color: rgba(255,255,255,0.08);
    &__inner__section{
        max-width: 1556px;
        margin: 0 auto;
    }
    &__menu_link{
        font-size: 28px;
        font-weight: 400;
        color: #fff;
        display: block;
        border-radius: 5px;
        line-height: 1.2;
        &:hover , &__active{
            background-color: #fff;
            color: #424242;
        }
        &__active{
            background: linear-gradient(315deg, #FFB438 0%, #FF9F00 100%);
        }

        &.goolge-tv-menu-link-active{
            background-color: #fff;
            color: #424242;
        }
    }
}