@import "../../scss/utils/variables";

.accesscode{
    &__main{
        &__screen{
            // background-image: url('../../img/Background_imageBilling.jpg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            width: 100%;
            color: $white;
            .transparent__form--white{
                padding: 2rem;
                // min-height: 808px;
            }
            .mw-1270{
                max-width: 1270px;
                width: 100%;;
            }
            .mw-849{
                width: 100%;
                max-width: 849px;
            }
            .mw-1082 {
                max-width: 1082px;
                width: 100%;
            }
            .padd-l-20{
                padding-left: 20px;
            }
            .padd-r-20{
                padding-right: 20px;
            }
            .bg_gray{
                border-radius: 2px;
                background-color: rgba(216,216,216,0.2);
                
            }
            .list-group{
                .list-group-item{
                    min-height: 58px;
                    border: 1px solid rgba(216,216,216,0.2);
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
            .list-group-flush {
                > .list-group-item{
                    border-width: 1px 0 0;
                }
            }
        }
    }
    &__inner{

        &__screen2{
            margin-top:97px !important;
            margin-bottom: 176px;
            .access-code-box{
                width: 430px;
                height: 675px;
                margin: 0 auto;
                border-radius: 7px;
                background-color: rgba(255,255,255,0.08);
                padding: 40px;
                padding-top: 142px;
                position: relative;
                margin-top: -90px;
                margin-left: 133px;

                &.green-border{
                    border: 2px solid #4DA166;
                }
                .heading{
                    font-size: 20px;
                    line-height: 26px;
                    margin-top: 2px;
                }
                p{
                    font-size: 15px;
                    line-height: 22px;
                }
                .access-code-footer{
                    display: flex;
                    position: absolute;
                    left: 0px;
                    right: 0px;
                    bottom: 37px;
                    text-align: center;
                    .btn {
                        // flex: 1;
                        // width: 100%;
                        // max-width: 167px;
                        margin: 0 auto;
                    }
                }
            }
        }
        &__screen{
            border-radius: 7px;
            max-height: 83.5rem;
            width: 100%;
            max-width: 78rem;
            padding: 3.5rem 4rem;

        }
        &__text{
            color: $white;
            font-size: 2rem;
            line-height: 3rem;
            max-width: 67rem;

            p{
                margin-bottom: 0;
            }
            h1{
                margin-top: 3rem;
                font-size: 50px;
                line-height: 50px;
                letter-spacing: 5.5px;
                margin-bottom: 2rem !important;
            }
            h3{
                font-size: 2.7rem;
                font-weight: 500;
                letter-spacing: 7px;
                margin-bottom: 3rem;
            }
            .message{
                max-width: 512px;
                margin: 0 auto;
            }
            h1,h2,h3,h4,h5,h6{
                color: $white;
            }

            button{
                margin-top: 40px;
            }
            
            &.current_access_code{
                margin-top: -72px;
                max-width: 703px;

                p{
                    font-size: 18px;
                }

                h1{
                    margin-top: 22px;
                }
                button{
                    margin-top: 38px !important;
                }
            }
        }
    }
}