@import "../../scss/utils/variables";

    .footer-header{
        header{
            background-color:transparent;
            box-shadow: none;
        }
    }
    .footer-tabs{
        background-color: transparent;
        margin: 0 -10px;
            button{
                min-width: 190px !important;
                text-transform: uppercase;
                font-size: 12px;
                letter-spacing: 0.43px;
                font-weight: 600;
                background-color: transparent !important;
                border: 1.5px solid #FFFFFF;
                height: 35px;
                border-radius: 2px;
                color: #fff;
                margin: 0 10px;
                min-height: 35px;
    
                &.Mui-selected{
                    background-color: #fff !important;
                    color: #424242 !important;
                }
            }
    
            .PrivateTabIndicator-colorPrimary-3{
                background-color: transparent;
            }
    
    }
    .dropbox__main__screen {
        position: relative;
    }

    .border-4{
        border: 1px solid yellow
    }
    // .MuiBox-root    { max-width: 1630px; margin: 0px auto; }
.videoSelected__main__centerCol{ padding-left: 30px; padding-right: 25px; }
.videoSelected__main__centerCol .border-4 { border: none; }
.videoSelected__main__card{ background: transparent !important; border: 3px solid transparent; transition: 0.5s;
    &:hover { box-shadow: none !important; border-color: #f29f33; }
}
// .MuiContainer-root.MuiContainer-maxWidthMd  { position: absolute; }
.timeline_item              { background: transparent !important; }
.videolist__main__screen     { margin-top: 31px;}
.videolist__main__screen     { margin-top: 20px;}

// Social Tab

.social-media-sec           { overflow-y: auto; min-height: 680px; display: flex; flex-wrap: wrap; align-items: flex-start; justify-content: flex-start;text-align:left; background: rgba(255,255,255,0.08);
    .social-sec         { padding: 40px;
        h3              { color: #fff; margin-bottom: 15px; text-align: left; width: 100%; }
        .social-sec-desc    { font-size: 15px; line-height: 22px; color: #fff; margin-bottom: 25px; }
        form            { width: 350px; text-align: left; margin-bottom: 60px;
            label       { width: 100%; font-size: 14px; line-height: 22px; }
            button      { width: 100%;height: 40px; background: #E0E0E0; color: #757575; }    
        }
    }
    .react-select-container .react-select__control {
        min-width: auto;
        max-width: 100%;
    }
    .fb-logo    { width: 85px; }
    .twitter-logo   { max-width: 100px; }
    .drag-social-sec { display: flex; align-items: center; text-align: right;
        img { width: 38px; margin-left: 15px; }
        p{ color: #7a7b7b;font-size: 15px; line-height: 22px; }
    }
    .row      { width: 100%; text-align: center; align-items: center;
        .d-flex{ align-items: center;
            .col-md-4   {  display: flex; align-items: center; justify-content: center;
                img  { border-radius: 50%}
             }
             .col-md-8   {  display: flex; align-items: center; justify-content: flex-start;
                img  { border-radius: 50%}
             }
        }
    }

}
.social-media-footer    {  margin-top: 40px;
    button  { min-width: 170px; min-height: 40px; }
}
.mr-50                  { margin-right: 50px; border-right: 1px solid rgba(216,216,216,0.3); }
// Scrollble sec
.dropbox__main__screen { height: 150px !important; }
.scroolable-sec     { position: relative; height: 150px !important; 
    .swiper-button-next { height: 150px;top: 20px; width: 50px; right: 0;
        &::before { width: 100%; height: 100%; position: absolute; top: 0; left: 0; background: rgba(0, 0, 0, 0.5); content: '';filter: blur(4px); }
        &::after    { color: #fff;  z-index: 2;}
    }
    .swiper-button-prev{ height: 150px;top: 20px; width: 50px; left: 0;
        &::before { width: 100%; height: 100%; position: absolute; top: 0; left: -2px; background: rgba(0, 0, 0, 0.5); content: ''; filter: blur(4px); }
        &::after    { color: #fff; z-index: 2;}
    }
}
.timeline_item      { margin: 0 !important; padding: 0 !important;}
.timeline_container > div  { padding: 0 !important; overflow: unset !important;}
.videoSelected__main__centerCol { height: 680px; overflow-y: auto }
.timeline_item_view     { width: 100% !important;} 


.waitingroom_my_slides{
    
    .MuiBox-root{
        max-width: 1644px !important;
        margin: 0px auto;
        padding: 20px 20px;

        .social-media-sec{
            max-width: 1334px;
            margin: 0 auto;
            margin-top: 10px;
            min-height: 658px;
            align-items: start;
            margin-bottom: 18px;

            .social-sec{

                .row{
                    align-items: baseline;
                }


                form{

                    .form-group{
                        margin-bottom: 6px !important;
                        label{
                            letter-spacing: 0.6px;
                        }
                    }

                    .button-group{
                        margin-top: 20px;
                    }
                }

                .social-media-footer{
                    margin-top: 55px;
                }

                .border-right-light{
                    position: relative;
                    margin-right: 34px;

                    &::after{
                        content: "";
                        position: absolute;
                        right: 4px;
                        top: 43px;
                        width: 1px;
                        height: 362px;
                        width: 1px;
                        background-color: rgba(216,216,216,0.3);
                    }
                }
            }

            .login-steps{
                text-align: left;
                margin-bottom: 39px;
                min-height: 215px;

                h3{
                    font-size: 20px;
                    margin-bottom: 14px;
                }

                .step-number{
                    margin-bottom: 20px;

                    h4{
                        color: #fff;
                        font-size: 18px;
                    }

                    p{
                        font-size: 16px;
                    }
                }
            }

            .drag-items{
                .profile-name{
                    margin-top: 25px;
                    font-size: 14px;
                    letter-spacing: 0.5px;
                }
                .col-md-4{
                    max-width: 24.333333%;

                    img{
                        height: 85px;
                        width: auto;
                        margin-bottom: 0 !important;
                    }
                }
                .drag-social-sec{
                    margin-top: 19px;


                    p{
                        color: #fff;
                        font-size: 15px;
                        letter-spacing: 0.2px;
                    }

                    img{
                        width: 62px;
                        margin-left: 15px;
                        margin-top: -22px;
                    }
                }
            }
        }
    }
    .header--sub{
        padding: 15px 17px 21px;

        .practice_name{
            font-size: 18px;
            line-height: 22px;
        }
    }


    .slide_leftsidebar{
        width: 430px;
        min-width: 430px;
        max-width: 430px;
        height: 660px;
        flex:1;
        margin-right: 40px;

        .waitingroom_slides_category{
            margin-top: 7px;
            padding: 3.4rem 4rem;
            position: relative;
            height: 100%;
        }

        .videolist{ 

            &__main{

                &__screen{
                    margin-top: 0;
                    height: 100%;
                }
                &__header{

                    &__header-title{
                        margin-bottom: 23px;
                    }
                }

                &__lists{

                    hr{
                        margin: 20px 0 16px !important;
                    }

                    &__videoselection{
                        margin-bottom: 18px !important;
                        margin-top: 18px !important;
                    }
                }

                &__button{
                   margin:0 -5px;
                   position: absolute;
                   bottom: 42px;
                    button{
                        margin: 0 5px;
                    }
                }

                &__centerCol{
                    max-width: 960px;
                    min-width: 960px;
                    padding: 0;

                    .no-slide-text{
                        height: 100%;
                        text-align: center;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        margin-left: -36px;
                        margin-top: -22px;

                        h2{
                            font-size: 36px;
                            margin-bottom: 19px;
                            color: #fff;
                        }

                        p{
                            font-size: 16px;
                            color:#FFFFFF;
                        }
                    }
                }
            }
        }
    }
    .slide_rightsidebar{
            
        .videoSelected{ 

            &__main{

                &__centerCol{
                    max-width: 960px;
                    min-width: 960px;
                    padding: 0;

                    .no-slide-text{
                        height: 100%;
                        text-align: center;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        margin-left: -36px;
                        margin-top: -22px;

                        h2{
                            font-size: 36px;
                            margin-bottom: 19px;
                            color: #fff;
                        }

                        p{
                            font-size: 16px;
                            color:#FFFFFF;
                        }
                    }
                }
            }
        }

    }

    .slide-editing{
        max-width: 1440px !important;
        margin: 0 auto;
        // margin-top: 73px;
        margin-top: 19px;
        .slide_leftsidebar{

            .videolist{

                &__main{

                    &__screen{
                        position: relative;
                        padding: 4rem 4rem;
                    }

                    &__button{
                        bottom: 35px;

                        button{
                            margin: 0 6px;
                        }
                        .create-slider-button{
                            max-width: 350px;
                        }
                    }

                    &__header{
                        margin-bottom: 27px;
                        span{
                            font-size: 14px;
                            letter-spacing: 0.9px;
                        }
                        &__header-title{
                            margin-bottom: 16px;
                        }
                    }

                    &__lists{

                        .form-group{
                            margin-bottom: 15px;

                            label{
                                font-size: 14px;
                                letter-spacing: 0.4px;
                            }
                        }   
                    }
                }
            }
        }
        .slide_rightsidebar{
            max-width: 972px;
            margin-top: -1px;

            .footer-header{

                .slide-editing-option{
                    margin-top: 0;
                    margin-bottom: 19px;

                   
                    .MuiBox-root{
                        padding: 0;

                        .MuiTabs-fixed{
                            justify-content: inherit;
                        }
                    }

                    .videolist__main__screen{

                        button{
                            border-right: 1px solid #757575 !important;
                            text-transform: capitalize !important;
                            font-weight: 300 !important;
                            font-size: 13px;

                            &:focus{
                                border:none;
                                box-shadow: none;
                            }

                            &:last-child{
                                border-right: none !important;
                            }

                            &.bold-font{
                                font-weight: 600 !important;
                            }


                        }
                        .dropdown{
                            border-right: 1px solid #757575 !important;
                            padding: 10px 13px;

                        }
                    }

                    .undo-redo-btn{
                        padding: 10px 13px;
                        border-right: 1px solid #757575 !important;

                        button{
                            border-right:none !important;
                            padding: 0 !important;
                            min-width: 40px;
                            min-height: 20px;
                        }
                    }

                    .alignment-block{
                        padding: 7px 13px;
                        border-right: 1px solid #757575 !important;
                        margin: 0 -3px;
                        button{
                            border-right: 0 !important;
                            padding: 0 !important;
                            min-width: 28px;
                            min-height: 28px;
                            margin: 0 3px;
                            svg{
                                height: 26px;
                                width: 26px;
                                fill:#fff;
                                
                            }

                            &:hover{

                                    background-color: #FF9F00 !important;
                                    border-radius: 2px;
                            }
                        }
                    }

                    .color-dropdown{

                        .dropdown-menu{

                            .dropdown-item{
                                height: 20px;
                                width: 100%;
                                margin-bottom: 5px;
                                border:1px solid transparent;
                                padding:5px;
                                &.black{
                                    background:#000000 ; 
                                }
                                &.yellow{
                                   background:#FF9F00 ;
                                }
                                &.red{
                                    background: #ff5f42;
                                }
                                &.green{
                                    background:#757575;
                                }
                                &.blue{
                                    background: #4a79ff;
                                }
                                &.purple{
                                    background: #ad80d6;
                                }
                                &.white{
                                    background: #ffffff;
                                }
                                &.custom{
                                    background: transparent;
                                    color: #ffffff
                                }
                                &:hover{
                                    border:1px solid #ffffff;
                                }
                                &:last-child{
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
                .footer-tabs{

                    .MuiTabs-fixed{
                        justify-content: initial;
                    }
                    .MuiTabs-scroller{
                        
                        .MuiTabs-flexContainer{

                            button{
                                width: 180px !important;
                                min-width: 180px !important;
                                margin: 0 8px !important;
                                
                            }
                        }
                    }
                }

            }

            .videolist{

                &__main{

                    &__screen{
                        position: relative;
                        margin-top: 15px;
                        margin-bottom: 22px;
                        height: 42px;
                        padding: 0px !important;
                        border-radius: 0;

                        button{
                            min-width: 100px;
                            text-align: center;
                            font-size: 15px;
                            line-height: 20px;
                            text-transform: capitalize !important;
                            font-weight: 300 !important;
                            padding: 10px 13px !important;
                            text-align: left;

                            svg{
                                fill:#ffffff;
                            }

                            &.bold-font{
                                font-weight: 600 !important;
                            }
                            &.italic-font{
                               font-style: italic;
                            }
                            &.underline-font{
                               text-decoration: underline;
                            }
                        }
                        .dropdown   {
                            min-width: 100px;
                            text-align: center;

                            .dropdown-arrow{
                                position: relative;
                                padding: 0;
                                width: 100%;
                                text-align: left;
                                font-weight: 300;

                                &::after{
                                    content: "";
                                    position: absolute;
                                    right: 0px;
                                    top: 43%;
                                    border-left: 6px solid transparent;
                                    border-top: 6px solid #FF9F00;
                                    border-right: 6px solid transparent;
                                }
                            }

                            .dropdown-menu{
                                min-width: 99px;
                                width: 99px;
                                border-radius: 0px;
                                left: -12px !important;
                                top: 11px !important;
                                right: 13px !important;
                                background-image: none !important;
                                background-color: #424242;
                                width: auto !important;
                                min-width: auto;

                                .dropdown-item{
                                    color: #ffffff;
                                    padding: 1.10rem 1.5rem;
                                    height: 40px;

                                    &:hover{
                                        background: linear-gradient(315deg, #FFB438 0%, #FF9F00 100%);
                                        color: #424242;
                                    }

                                    button{
                                        padding: 0 !important;
                                        min-width: auto !important;
                                        width: 100% !important;
                                        min-height: auto;
                                    }
                                }
                            }
                        }


                    }


                }
            }
        }
    }

    .waitiing-room-col-sec{

        .waiting-rom-right-sec{
            width: 1134px;
            padding: 10px 0;
            height: 686px;

            .videoSelected{

                &__main{

                    &__centerCol{
                        padding-left: 0;
                        padding-right: 0;

                        .video_list{
                            flex-wrap: wrap;
                            margin: 0 -15px;
                            display: flex;

                            .videoSelected_box{
                                height: 200px;
                                max-width: 356px;
                                padding: 0;
                                margin: 0px 15px;
                                min-width: 356px;
                                margin-bottom: 30px;
                            }
                        }
                    }



                    &__card{

                        &__selectedImage{
                            top: 12px;
                            left: 15px;
                            height: 20px;
                            font-size: 16px;
                            font-weight: 700;
                            color: #424242;
                            width: 20px;
                            border-radius: 2px;
                            padding: 0;
                            text-align: center;
                            line-height: 23px;
                            background: linear-gradient(315deg, #FFB438 0%, #FF9F00 100%);
                        }
                        
                        &:hover{
                            transform: inherit;
                        }

                        .toggle_icon{
                            position: absolute;
                            top: 0px;
                            right: 0;
                            height: 50px;
                            width: 50px;
                            background-color: burlywood;
                            padding: 11px;

                            .bar-icon{
                                height: 1px;
                                width:25px;
                                background-color: #ffffff;
                                position: relative;
                                border-radius: 1px;
                                position: absolute;
                                top: 23px;

                                &::after{
                                    position: absolute;
                                    content: "";
                                    left:0;
                                    right: 0;
                                    top:7px;
                                    height: 1px;
                                    width:25px;
                                    background-color: #ffffff;
                                    border-radius: 1px;
                                }
                                &::before{
                                    position: absolute;
                                    content: "";
                                    left:0;
                                    right: 0;
                                    bottom:7px;
                                    height: 1px;
                                    width:25px;
                                    background-color: #ffffff;
                                    border-radius: 1px;
                                }
                            }
                        }

                        .toogle-menu{
                            background-color: rgba(0, 0, 0, 0.26);
                            position: absolute;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            backdrop-filter: blur(14px);
                            padding: 30px 0 20px 0px;

                            h6{
                                border-bottom: 2px solid rgba(0, 0, 0, 0.1);
                                padding-bottom: 20px;
                                font-size: 15px;
                                margin-bottom: 20px;
                                color: #fff;
                                margin-left: 30px;
                            }
                            .toggle-btn{
                                position: absolute;
                                top: 17px;
                                right: 16px;
                                height: 17px;
                                width: 25px;

                                .bar-icon{
                                    height: 1px;
                                    width:25px;
                                    background-color: #ffffff;
                                    position: relative;
                                    border-radius: 1px;
                                    position: absolute;
                                    top: 9px;

                                    &::after{
                                        position: absolute;
                                        content: "";
                                        left:0;
                                        right: 0;
                                        top:8px;
                                        height: 1px;
                                        width:25px;
                                        background-color: #ffffff;
                                        border-radius: 1px;
                                    }
                                    &::before{
                                        position: absolute;
                                        content: "";
                                        left:0;
                                        right: 0;
                                        top:-8px;
                                        height: 1px;
                                        width:25px;
                                        background-color: #ffffff;
                                        border-radius: 1px;
                                    }
                                }
                            }
                            .menu-list{
                                cursor: pointer;
                                span{
                                    padding: 0;
                                    font-size: 11px;
                                    padding: 5px 0;
                                    padding: 12px;
                                    display: block;
                                    font-size: 12px;
                                }

                                &:hover{

                                    span{
                                        background: linear-gradient(315deg, #FFB438 0%, #FF9F00 100%);
                                    }
                                
                                }
                            }

                            &.bg_red{

                                h6{
                                    border-bottom: 2px solid rgb(255 255 255 / 10%);
                                }
                                .remove_message{
                                    padding: 20px;
                                    display: flex;
                                    flex-direction: column;
                                    padding-top: 0;

                                    span{
                                        font-size: 12px !important;
                                        margin-bottom: auto;
                                        letter-spacing: 0.8px;
                                        display: block;
                                        margin-bottom: 30px;
                                    }
                                }
                            }
                        }

                        .trivia_img_bg_text{
                            position: absolute;
                            top: 0;
                            left: 0px;
                            right: 0px;
                            bottom: 0;
                            justify-content: center;
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            h1{
                                color: #a2711f;
                                line-height: 56px;
                                font-weight: 800;
                            }
                            h3{
                                color: #737373;
                                font-weight: 800;
                                letter-spacing: 1.2px; 
                            }
                        }   
                    }
                }
            }
        }
        .waiting-room-left-sec{
            margin-right: 40px;
            
            .videolist{

               &__submain{

                &__header{

                    &__title{
                        margin-left: 6px;
                    }
                }
               }
                &__main{

                    &__screen{
                        margin-top: 7px;
                        height: 660px;
                        position: relative;
                        padding: 40px 43px;
                        letter-spacing: 0.00938em;
                    }

                    &__button{
                        position: absolute;
                        bottom: 39px;

                        .btn{

                            &.btn-md{
                                width: 167px;
                                min-width: 167px;
                                font-size: 13px;
                            }
                        }
                    }

                    &__header{

                        &__header-title{
                            margin-bottom: 20px;
                            letter-spacing: 0.2px;
                            font-size: 19px;
                        }
                    }

                    &__lists{

                        hr{
                            margin: 20px 0 18px;
                        }

                        &__videoselection{
                            margin: 19px 0 12px;
                            align-items: center;
                            display: flex;
                        }
                    }
                }
            }
        }
    }

}

.slide-preset-drawer{
    position: inherit ;
    z-index: 999 ;

    .slide-preset-section{
        margin-top: 60px;
        overflow:hidden;
        overflow-y: scroll;
        .category_section{
            margin-bottom: 28px !important;
            margin-top: -21px !important;


        }

        .videolist{

            &__main{

                &__header{

                &__header-title{
                    margin-bottom: 10px;
                    display: block;
                }

            }
        }

    }
    }
    .MuiDrawer-paper{

        &.MuiDrawer-paperAnchorLeft{
            width: 675px;
            padding: 52px 65px 0px 77px;
            z-index: 999 !important;
            overflow-y: inherit;
            .MuiGrid-container{

                .videolist{

                    &__main{

                        &__header{

                        &__header-title{
                            font-size: 18px;
                            color: #ffffff;
                            margin-bottom: 8px;
                        }

                        &__detail{
                            color: #fff;
                            max-width: 480px;
                            font-size: 15px;
                            letter-spacing: 0.4px;
                        }
                    }
                }

            }

                .category_section{
                    margin-top: -21px;
                    position: relative;
                    margin-bottom: 29px;

                    label{
                        color: #ffffff;
                        font-size: 14px;
                        margin-bottom: 4px;
                    }

                    &::after{
                        content: "";
                        position: absolute;
                        left:0;
                        right:0;
                        bottom: -22px;
                        height: 2px;
                        width: 511px;
                        background: rgba(255,255,255,0.29);
                    }
                }

                .photo_section{

                    .template_list{
                        display: flex;
                        flex-wrap: wrap;
                        margin: 0 -2px;
                        margin-top: 8px;

                        .MuiGrid-item{
                            margin: 0 15px;
                            margin-bottom: 27px;
                            flex-basis: 38%;
                            
                            .videolist__main__header__header-title{
                                font-size: 16px; 
                            }

                            .MuiCard-root{
                                max-width: 240px;
                                max-height: 135px;
                                height: 135px;
                                padding: 1px;
                                
                                img{
                                    object-fit: cover;
                                    width: 100%;
                                    height: 100%;
                                }

                                &:hover{
                                    transition: 0.3s ease-in-out;
                                    background: linear-gradient(315deg, #FFB438 0%, #FF9F00 100%);
                                    padding: 1px;
                                }
                            }
                        }
                    }

                    .stock-images{
                        margin-top: 15px;
                        display: flex;
                        flex-wrap: wrap;

                        .MuiGrid-item{
                            cursor: pointer;
                            margin-bottom: 30px;
                            max-width: 45%;
                            flex-basis: 45%;

                            .MuiPaper-root{
                                max-height: 160px;
                                max-width: 240px;
                                height: 160px;
                                padding: 1px;

                                &:hover{
                                    transition: 0.3s ease-in-out;
                                    background: linear-gradient(315deg, #FFB438 0%, #FF9F00 100%);
                                    padding: 1px;
                                }
                            }

                        }
                    }
                }
            }
        }
    }
}

.slide_rightsidebar header.MuiPaper-root + div .MuiBox-root  { padding: 0 !important; }
.slide_rightsidebar header.MuiPaper-root + div .MuiBox-root br { display: none; }
.my-slide-left-screen       {
    .videolist__main__lists {  //height: 430px;
        
    }
    .create-slide-btn   {
        button      { max-width: 100%; }
    }
}
.videoSelected__main__columnFour.border-warning.videoSelected_box {
    .videoSelected__main__card  { border-color: #f29f33; }
}
.videoSelected__main__columnFour.videoSelected_box { margin: 12px 0px; }
.waiting-room-triavia {
    .videoSelected__main__card__title { display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;  
        overflow: hidden;}
    .videoSelected__main__card:hover {
        .videoSelected__main__card__title {  -webkit-line-clamp: unset}
    }

}



@media (max-width:1700px){
    .waitingroom_my_slides .slide-editing{
        display: flex;
        gap: 15px;
        flex-wrap: nowrap;
        padding-bottom: 0px;
    }
    .waitingroom_my_slides .slide-editing .slide_leftsidebar .videolist__main__screen{
        padding: 2rem;
        .btn{
            font-size: 1.2rem!important;
            padding: 1rem 1rem;
        }
    }
    .waitingroom_my_slides .slide_leftsidebar{
        width: 30%;
        min-width: auto;
        max-width: auto;
        margin: 0;
    }
    .waitingroom_my_slides .slide-editing .slide_rightsidebar{
        width: 100%;
        zoom: 0.9;
    }
    .upload-video-sec{
        min-height: auto!important;
    }
    .upload-video-sec .file_upload__box{
        min-height: 200px!important;
    }
    .upload-video-sec .upload_file_list{
        height: 100px!important;
    }
    .upload-video-sec .file-upload-input .file-upload-input-form div{
        width: 78%!important;
    }
}

@media (max-width:1200px){
    .waitingroom_my_slides .slide-editing .slide_rightsidebar{
        width: 100%;
    }
}