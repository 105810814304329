

.chairsidePlayer{
    border-radius: 5px;
    overflow: hidden;
    video{
        display: block;
        max-height: 658px;
        object-fit: cover;
    }
}

.chairside_education_playlist{
    &--video__lists{
        max-width: 1170px;
        
        .swiper-button-prev, .swiper-button-next {
            color: #fff;
            opacity: 1;
            background-color: rgba(255, 255, 255, 0.08);
            -webkit-backdrop-filter: blur(32px);
            backdrop-filter: blur(32px);
            z-index: 9;
            margin: 0;
            height: 100%;
            width:36px;
        }
        .swiper-container {
            // padding: 0 20px;
        }
       
        .swiper-button-prev {
            left: 0px;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
        }
        .swiper-button-next{
            right: 0;
            position: absolute;
            top: 0;
            bottom: 0;
        }
        .swiper-wrapper {
            width: auto;
        }
        .swiper-wrapper .swiper-slide {
            // padding: 0 20px;
        }

        .placeholder__box{
            width: 272px;
            height: 153px;
            margin-left: 50px;
            border-radius: 2px;
            img{
                width: 62px;
            }
        }
    }
    &__videoSection{
        padding: 0 24px;
        width: 100%;
        img{
            border-radius: 7px;
        }
        .swiper-container{
            width: 100%;
        }
    }
    &__video_box{
        position: relative;
        height: 150px;
        object-fit: cover;
        &::after{
            content: '';
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.6) 100%);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        h2{
            position: absolute;
            bottom: 0;
            text-align: center;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 22px;
            text-align: center;
            text-transform: uppercase;
            color: #FFFFFF;
            z-index: 2;
            padding: 0 15px;
        }
    }
}