@import "../../scss/utils/variables";

.corporate{
    &__screen{
        // background-image: url('../../img/InvoicesViewBG.png') !important;
        background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
        .practice_name{
            font-size: 18px;
        }
        .channels__item:last-child{
            position: relative;
            margin-left: 60px;
            
            &::after{
                content: "";
                position: absolute;
                left: -40px;
                top: 70px;
                bottom: 0;
                width: 1px;
                height: 107px;
                background-color: rgba(255, 255, 255, 0.6);
            }
        }
    }
}
.corporate{
    &__page{

        &__practice-heading{
            margin-top: -118px;
            margin-bottom: 25px;

            h4{
                font-size: 20px;
                line-height: 26px;
                color: #ffffff;
                margin-bottom: 15px;
            }

            p{
                font-size: 15px;
                line-height: 22px;
                max-width: 492px;
                margin: 0 auto;
            }
        }
    }
    &__item{
        &__divider{
            background-color:rgba(255,255,255,0.6);
            height: 107px;
            width: 1px;
            top: 54px;
            position: relative;
        }
    }
    &__box{
        height: 250px;
        width: 270px;
        border-radius: 7px;
        background-color: rgba(255,255,255,0.08);
        cursor: pointer;
    }

    &__icon{
        max-width: 97px;
    }
    &__name{
        text-transform: uppercase;
    }
}