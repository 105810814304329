@import "../utils/variables";
.btn {
   font-size: 1.4rem;
   text-transform: uppercase;
   font-family: $body-font;
   font-weight: 500;
   border-radius: 2px;
   padding: 1rem 1.5rem;
   border: none;
   position: relative;
   letter-spacing: 1px;
   .spinner-border{
    //  position: absolute;
    //  left: 10px;
   }
   &.cc-btn{
    height: 30px!important;
    padding: 2px 10px!important;
    width: 48px;
    &.sm{
      height: 22px!important;
      padding: 0px 7px!important;
      width: 38px!important;
      line-height: 19px;
      font-weight: normal;
    }
    // font-size: 19px;
    // line-height: 28px;
    // letter-spacing: 0.2px;
      &.active{
          background-color:#fff;
          color: #424242!important;
      }
  }
    &.btn-xl{
      max-width: 35rem;
      width: 100%;
    }
    &.btn-xxl{
      max-width: 38.8rem;
      width: 100%;
    }
    &.btn-lg{
      max-width: 26rem;
      width: 100%;
    }
    &.btn-md{
      max-width: 22rem;
      width: 100%;
    }
    &.btn-sm{
      max-width: 16.5rem;
      width: 100%;
    }
   &.disabled{
        background: #E0E0E0;
        border-color: #E0E0E0;
        color: #757575;
        opacity: 1;
        height: 41px;
        &.btn-link{
          opacity: 0.5;
        }
   }
   &.verifyed {
      background: linear-gradient(135deg, #35A85A 0%, #4DA166 100%);
      color: #424242;
   }
   &.notverifyed{
    &:not(:disabled):not(.disabled),
    &:not(:disabled):not(.disabled):focus{
      background: linear-gradient(135deg, #D0393E 0%, #D54A53 100%);
      color: $white;
    }
     
   }
   &-default{
        @include  btn-default-gradient;
        border-color: $btn-default-border;
        color: $btn-default-color;
        &:hover{
            color: $btn-default-color;
        }
  }
  &-link{
    color: $white;
    font-weight: 400;
    line-height: 1;
    text-transform: none;
    &.disabled{
      background-color: transparent;
      border: none;
    }
    &.primary{
      color: $primary-color!important;
      background-color: transparent;
      &:focus, &.focus,&:hover{
        color:  $primary-color;;
      }
    }
    &:focus, &.focus,&:hover{
      color: $white;
      text-decoration: none;
      box-shadow: none;
    }
    &.white{
      color: $white;
      &:focus, &.focus,&:hover{
        color:  $white;
      }
    }
  }
  &-primary{
    color: $btn-primary-color;
    &:not(:disabled):not(.disabled):active, 
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus, 
    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):focus{
      color: $btn-primary-color;
      background-color: $primary-color;
      outline: #fff solid 1px;
    }
    &:not(:disabled):not(.disabled){
      color: $btn-primary-color;
      background-color: $primary-color;
      box-shadow:none;
      &:hover{
        color: $btn-primary-color;
        background-color: $primary-color;
      }
    }
  }
  &-help-icon{
    color: #fff;
    &:not(:disabled):not(.disabled):active, 
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus, 
    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):focus{
      font-size: 1.5rem;
      font-weight: 400;
      color: #fff;
      // background-color:#6287FF;
      background: linear-gradient(359.98deg, #7287C5 0%, #6287FF 53.71%, #2EA8FF 100%);
      outline: #fff solid 1px;
      border-radius: 2px;
    }
    &:not(:disabled):not(.disabled){
      font-size: 1.5rem;
      font-weight: 400;
      padding: 2px 9px;
      color: #fff;
      letter-spacing: normal;
      background-color: #6287FF;
      box-shadow:none;
      &:hover{
        color: #fff;
        background-color: #6287FF;
      }
    }
  }
  &-red{
    color: $btn-default-color;
    &:not(:disabled):not(.disabled):active, 
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus, 
    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):focus{
      color: $btn-default-color;
      background-color:$red;
      // outline: #fff solid 2px;
    }
    &:not(:disabled):not(.disabled){
      color: $btn-default-color;
      background-color: $red;
      box-shadow:none;
      &:hover{
        color: $btn-default-color;
        background-color: $red;
      }
    }
  }
  &-white{
    color: $main-font-color;
   
    &:not(:disabled):not(.disabled):active, 
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus, 
    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):focus{
      color: $main-font-color;
      background-color: $white;
      outline: #fff solid 0px;
    }
    &:not(:disabled):not(.disabled){
      color: $main-font-color;
      background-color:  $white;
      box-shadow:none;
      &:hover{
        color: $main-font-color;
        background-color:  $white;
      }
    }
  }
  &-green{
    color: $main-font-color;
    &:not(:disabled):not(.disabled):active, 
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus, 
    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):focus{
      color: $main-font-color;
      background: linear-gradient(135deg, #35A85A 0%, #4DA166 100%);
    }
    &:not(:disabled):not(.disabled){
      color: $main-font-color;
      background: linear-gradient(135deg, #35A85A 0%, #4DA166 100%);
      box-shadow:none;
      &:hover{
        color: $main-font-color;
        background: linear-gradient(135deg, #35A85A 0%, #4DA166 100%);
      }
    }
  }
  &-outline{
    &-white{
      .spinner-border.text-dark{
        color: $white!important;
      }
    }
      &-default{
          border: 2px solid $btn-default-border;
          padding: 0.8rem 1.5rem;
          
          &.white{
            border-color: $white;
            color: $white;
            box-shadow: none;
            background: transparent;
            &:hover{
              background-color: $white;
              color: $main-font-color;
            }
          }
          &:hover{
              background-color: $btn-default;
              color: #fff;
          }
      }
  }
}


.channel_btn{
  height: 89px;
  width: 157px;
  border-radius: 5px;
  background-color: rgba(255,255,255,0.08);
  box-shadow: 1px 26px 22px 0 rgba(0,0,0,0.17);
  cursor: pointer;
  &:hover:not(.cc_btn_google_tv),&.active_btn{
      background-color: #FFFFFF;
      svg{
        path, rect{
          fill: #424242;
        }
      }
  }
  &.btn_mylist{
    .close_btn{
      display: none;
    }
    &:hover{
      .close_btn{
        display: block;
      }
      .my_list_added{
        display: none;
      }
     
    }
  }
  &:hover{
    background-color: #FFFFFF!important;
    svg{
      rect{
        stroke: #424242!important;
        fill: transparent;
        stroke-width:2px;
        
      }
      text{
        fill: #424242;
      }
    }
  }
}


@media (max-width:1370px){
  .btn{
    font-size: 1.2rem;
  }
}

