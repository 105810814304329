@import "../../scss/utils/variables";
.selectcategory{
    &__main{
        margin-top: -26px;

        &__headerTitle{
            color:white;
            margin-top: -92px;
            text-align:center;
        }
        &__line{
            color:white;
            text-align:center;
            letter-spacing: 0.8px;
            margin-top: 20px;
            width: 487px;
            font-weight: 300;
            font-size: 15px;
            line-height: 20px;
        }
        &__icon{
            text-align:center;
        }

        &__inner{

            &__screen{
                margin-top: 15px;

                .channels{

                    &__box{
                        margin-bottom: 20px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    &__item{
                        max-width: 360px;
                        width: 100%;
                        margin: 0 50px;
                        text-align: center;
                        &:last-child{
                            margin-left: 18px;

                            &::after{
                                content: none;
                            }
                        }
                    }
                }
            }
        }
    }

    &__body{

        .practice_name{
            font-size: 19px;
        }

    }
}
.selectcategory_image{
    width: 138px;
}
.selectcategory_content{
    text-align:center;
}