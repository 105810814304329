@import "../../scss/utils/variables";

.selectiontabmain{
    display: flex;
    padding-bottom: 44px;
    margin-left:19px;
    margin-top: 30px !important;

    &__right_tab{
        width: 100%;
        position: relative;
        padding: 0 20px;
        // margin-top: -20px;

        iframe{
            margin-bottom: 34px;
        }

        &__color_scheme{
            font-size: 20px;
            line-height: 26px;
        }

        &__color_box{
            // display: flex;
            // margin:0 -20px;
           
            .color_scheme_box{
                // width: 200px;
                // height: 100px;
                // margin: 0 20px;
                position: relative;
                overflow: hidden;
                border-radius: 3px;
                img.selection__colorscheme{
                    width: 100%;
                }
                .disabled{
                    opacity: 0.3;
                }
                .White {
                    .website_videos_color_name{
                        color: $main-font-color;
                    }
                }
                .subscription-note{
                    position: absolute;
                    top: 80%;
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                    width: 100%;
                    text-align: center;
                    font-size: 11px;
                    line-height: 13px;
                    text-align: center;
                }
                .selectiontab__imagetwo{
                    height: 14px;
                    width: 14px;
                }
            }
        }
    }
}
.website_videos_color_name{
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
}
.selectiontabmain__right_tab{

}
.website_videos_layout_selection{
    &__main{
        &__screen{
            // background: linear-gradient(#30302f,#292929);
            // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            // border-radius: 10px 10px 10px;
            // height: 846px;
            // width: 430px;
            // margin-right: 40px;
            max-width: 1677px;
            margin: 0 auto;
            padding: 0 15px;

            .transparent__form--white{
                padding: 2rem 4rem;
                z-index: 2;
                position: relative;
            }
        }
    }   
    &__color_slider{
        max-width: 1160px;
        // margin: 0 -20px;
        .swiper-container{
            padding: 0 20px;
            margin: 0 -40px;
        }
        .swiper-wrapper{
            // padding: 0 20px;
            // margin:0 -20px;
            width: auto;
            .swiper-slide{
                // margin-right: 40px;
                padding: 0 20px;
                &:last-child{
                }
            }
        }
        .swiper-button-next{
            right: 0px;
        }
        .swiper-button-prev{
            left: 0px;
        }
        .swiper-button-prev,
        .swiper-button-next{
            color: $white;
            opacity: 1;
            &::after{
                font-size: 20px!important;
            }
        }
    }
    &__list{
        &__screen{
            // margin-right: 40px;
            width: 430px;
            // padding: 40px;
            margin-left: 0;
            position: relative;
            .subscribe-customise{
                margin-bottom: 15px !important;
            }
        }

        &__box{

            .layout-container{
                .layout-section{
                    margin-bottom: -23px; 

                    h3{ 
                        margin-bottom: 15px;
                    }

                    #swiper-layout{

                        .swiper-button-prev{

                            left: 299px;
                            top: 30px;

                        }
                        .swiper-button-next{

                            top: 31px;
                            left: 329px;

                        }   
                    }
                }
               
            }
        }
    } 
    
    &__headerTitle{
        font-size:20px;
        padding-top: 0px;
        margin-bottom: 10px;
    }
    &__imageone{
        width:21px;
        margin-left:7px; 
        margin-top:-10px;
        position:absolute;
    }
    &__imagetwo{
        width:14px;
        margin-left:7px; 
        margin-top:10px;
        position:absolute;
    }
    &__layoutimage{
        margin-top: -15px;
        border:2px solid #ffc107;
    }
    &__colorscheme{
        margin-top:-15px;
        width:100%;
    }
    
    &__button{
        &__screen{
            width: 40%;
            
        }
    }
}

.website_videos_layout_selection_layout_slider{

    #swiper-layout{
        margin-top: 0 !important;
        .swiper-button-prev{
            left: 299px;
            top: 25px;

            &::after{
                font-size: 18px !important;
            }
        }
        .swiper-button-next{
            top: 25px;
            right: -5px;

            &::after{
                font-size: 18px !important;
            }
        }

        .swiper-wrapper{

            .swiper-slide{

                .layout-screen{

                    .layout-back-image {
                        margin-bottom: 7px;
                    }
                }
            }
        }
    }
}
// width: 21px;
// margin-left: 7px;
// margin-top: -10px;
// position: absolute;
.bordeer{
    border:2px solid #ffc107;
}
// .swiper-wrapper{
//     height: 115px!important;
// }
.subscription-layout{
   
}
.subscription{
    pointer-events: none;
    opacity: 0.5;
    background: #CCC;
}

@media (max-width:1700px){
    .selectiontabmain{
        margin-left: 0!important;
        width: 100%;
    }
    .website_videos_layout_selection__main__screen{
        width: 100%;
        max-width: 80%;
        gap: 20px;
        justify-content: center;
        .website_videos_layout_selection__list__screen{
            width: 30%;
            max-width: 330px;
            margin: 0!important;
        }
        .react-select-container .react-select__control{
            max-width: 100%;
            min-width: 100%;
        }
        .website_videos_layout_selection_layout_slider #swiper-layout .swiper-button-prev{
            left: 220px;
        }
        .website_videos_layout_selection__color_slider .swiper-button-prev{
            left: -10px;
        }
        .website_videos_layout_selection__color_slider .swiper-button-next{
            right: -10px;
        }
        .transparent__form--white{
            padding: 2rem 2rem;
        }
        .layout-back-image{
            min-height: auto;
        }
        .website_videos_layout_selection__color_slider .swiper-button-prev::after, 
        .website_videos_layout_selection__color_slider .swiper-button-next::after{
            font-size: 15px!important;
        }
        .website_videos_layout_selection__color_slider .swiper-container{
            margin: 0!important;
            padding: 0 0px;
        }
        .website_videos_layout_selection__color_slider .swiper-wrapper{
            width: 100%;
        }
        .website_videos_layout_selection__color_slider .swiper-wrapper .swiper-slide{
            padding: 0 15px;
        }
    }
    .selectiontabmain__right_tab{
        max-width: 900px!important;
        width: 70%!important;
        padding: 0!important;
        iframe{
            max-width: 100%!important;
            width: 100%!important;
            height: 450px!important;
        }
    }
    .website_videos_layout_selection__color_slider{
        // max-width: 70%!important;
        max-width: 900px!important;
        width: 100%;
    }
   
}

// @media (max-width:1700px){
//     .selectiontabmain__right_tab{
//         max-width: 100%;
//     }
// }