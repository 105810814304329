@import "../../scss/utils/variables";


.waiting_room_common_section{
    align-items: center;

    &.waiting_room_time_section{
        margin-top: 107px;
        margin-left: -44px;

        .form-group{
            margin-bottom: 16px;
            
            label{
                font-size: 13px;
                letter-spacing: 0.9px; 
            }
        }
    }
    .waiting_room_common_section_time_zone{
        height: 564px !important;
        margin-right: 35px !important;

        .MuiSwitch-root{
            
        width: 40px;
        height: 22px;

        .MuiButtonBase-root{
            left: 4px;

                 + .MuiSwitch-track{
                    background-color: rgba(66,66,66,0.4);
                    border:none;
                }
                
                &.Mui-checked{
                    left: 2px;
                    + .MuiSwitch-track{
                        background-color: #6087fd;
                    }
                }
        }

            .MuiSwitch-thumb{
                width: 18px;
                height: 18px;
                margin-top: 1px;
            }

        }
    }

    .waiting_room_common_section_img{
        height: 564px !important;
        max-width: 978px !important;
        min-width: 978px;
    }

    &.waiting_room_common_section_weather{
        margin: 108px -40px auto;

        .waiting_room_common_section_time_zone{
            // margin-right: 19px !important;
            height: 564px;
            h4{
                margin-bottom: 29px !important;
            }

            .form-group{
                margin-bottom: 16px;

                label{
                    font-size: 13px;
                    letter-spacing: 0.9px; 
                }
            }
        }

        .toggle-button{
            margin-left: 10px !important;
        }

    }

    &.waiting_room_common_section_display_option{
        .waiting_room_common_section_time_zone{
            margin-right: 19px !important;

            h4{
                margin-bottom: 20px !important;
            }

            p{
                margin-bottom: 17px !important;
            }
        }

        .time-zone-check-box{
            width: 286px;
            justify-content: space-between;
            margin-bottom: 3px;
        }

        .form-group{
            margin-bottom: 4px !important;
        }
    }
}


@media (max-width:1700px){
   .app .waitingroom_my_slides .MuiBox-root{
        max-width: 80%!important;
    }
    .waitiing-room-col-sec{
        grid-gap: 15px;
        gap: 15px;
        flex-wrap: nowrap;
        padding-bottom: 0px;
    }
    .waitiing-room-col-sec .waiting-room-left-sec{
        width: 30%;
        margin: 0 !important;
    }
    .waitiing-room-col-sec .waiting-room-left-sec .videolist__main__screen{
        padding: 2rem !important;
        font-size: 1.2rem;
        height: auto!important;
        min-height: 550px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .videolist__submain__header__title{
        font-size: 1.2rem;
    }
    .videolist__main__lists{
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 420px;
    }
    .chairside_education_playlist__inner_screen .videoSelected__main__centerCol,
    .waiting_room_my_video_blank{
        height: 60vh !important;
    }
    .waitiing-room-col-sec .waiting-rom-right-sec{
        width: 100% !important;
        height: 100% !important;
    }
     .waitiing-room-col-sec .waiting-rom-right-sec .videoSelected__main__centerCol,
    .waiting_room_my_video_blank{
        height: 60vh !important;
    }
    .waitiing-room-col-sec .waiting-rom-right-sec .videoSelected__main__centerCol .video_list{
        grid-gap: 10px;
        gap: 10px;
        margin: 0 !important;
        height: 100%;
    }
    .waitiing-room-col-sec .waiting-room-left-sec .videolist__main__button .btn.btn-md{
        width: 100%!important;
        min-width: auto!important;
        margin: 0px!important;
        font-size: 1.2rem!important;
        padding: 1rem 1rem;
    }
     .waitiing-room-col-sec .waiting-room-left-sec .videolist__main__button{
        position: relative!important;
        bottom: 0!important;
        gap: 10px;
    }
    .MuiBox-root .social-media-sec{
        min-height: 70vh!important;
        margin-bottom: 0!important;
    }
    
    .social-media-sec .social-sec form{
        align-items:stretch!important;
        width: auto!important;
        margin-top: 0!important;
    }
    .waiting_room_common_section.waiting_room_time_section{
        margin: 108px auto auto;
    }
    
}

@media (max-width:1366px){
    .waitiing-room-col-sec .waiting-rom-right-sec .videoSelected__main__centerCol,
    .waiting_room_my_video_blank{
        height: 70vh !important;
    }
    .app .MuiBox-root{
        max-width: 85%!important;
    }
    .waitiing-room-col-sec .waiting-room-left-sec .videolist__main__button .btn.btn-md{
        font-size: 1rem !important;
        padding: 1rem 0.5rem;
    }
    .waitingroom_my_slides .MuiBox-root .social-media-sec .login-steps{
        min-height: auto!important;
    }
    .app .waitingroom_my_slides .MuiBox-root{
        max-width: 100%!important;
    }
}

@media (max-width:1200px){
    .waitiing-room-col-sec .waiting-room-left-sec .videolist__main__button .btn.btn-md{
        font-size: 1rem !important;
        padding: 1rem 0.5rem;
    }
}