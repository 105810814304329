@import "../../scss/utils/variables";

.htmlcode{
    &__main{
        height: auto;
        width: 800px;
        border-radius: 7px;
        background-color: #ffffff14;
        cursor: pointer;
        margin-top: -82px;
        padding: 39px;

        &__heading{
            color: white;
            margin-bottom: 24px;
        }
        &__line {
            width: 705px;
            margin-bottom: 20px;
        }
        &__message{
            margin:16px 0 6px;
        }
        &__frame{
            width: 720px;
            height: 300px;
            overflow-y: auto;
            border: 1px solid white;
            background: white;
            margin: 0px 0 34px;
            padding: 19px 22px;
        }
        &__button{
            flex: 1 1;
            height: 40px;
            width:220px;
            border:0;
            border-radius: 2px;
            font-weight: 500;
            margin-right: 20PX;
        }
        &__back{
            flex: 1 1;
            height: 40px;
            width:220px;
            background: transparent;
            color: white;
            border: 2px solid white;
            border-radius: 2px;
            font-weight: 500;
        }
    }
}
