@import "../../scss/utils/variables";
.mw-168{
    min-width: 168px;
}
.embedcode{
    &__main{
        &__screen{
            .react-select-container{
                max-width: 320px;
            }
            .form-control{
                background: rgba(216, 216, 216, 0.2);
                max-width: 320px;
                min-width: 320px;
                &.mx-260{
                    max-width: 260px;
                    min-width: 260px;
                }
                &.mw-100{
                    min-width: 100%;
                }
            }
            .react-select-container .react-select__control{
                background: rgba(216, 216, 216, 0.2);
                max-width: 320px;
                min-width: auto
            }

            h3,h4{
                color: $white;
            }
        }
    }
    &__inner_screen{
        width: 100%;
        max-width: 1582px;
        margin: 0 auto;
        overflow-y: auto;
        height: 90vh;
        padding: 0 1.5rem 0rem 1.5rem;
    }
    &__authorize_domain__section{
        background: rgba(122, 122, 122, 0.2);
        border-radius: 5px;
        min-height: 318px;
        padding: 5rem;

        .form-control , .btn{
            height: 31px;
            padding: 0.6rem 1.5rem;
        }
        .authorize_domain_list{
           
            .domain_item{
                background: #434343;
                border-radius: 5px;
                padding: 2px 15px;
                display: inline-block;
            }
        }
    }
    &__html_code_section{
        background: rgba(122, 122, 122, 0.2);
        border-radius: 5px;
        padding: 5rem;
        width: 100%;
        // max-width: 750px;
    }
}

@media (max-width:1700px){
    .embedcode__inner_screen{
        height: auto;
    }
}