@import "../../scss/utils/variables";

.ediImage{
    &__main__screen{
        background-color: #2b1e17; 
        color: white;
    }
    &__custome_placeholder{
        position: absolute;
        bottom: 20px;
        left: 10px;
        font-size: 13px;
    }
    &__left_side__screen{
        padding: 0 54px 0 96px;
        padding-top: 203px;        
        max-width: 23.5%!important;
        flex-basis: 23.5%!important;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        form{
            width: 100%!important;
            hr {
                margin-bottom: 28px;
            }
        }
        label{
            font-size: 14px !important;
            color: white !important;
            line-height: 22px !important;
            margin-bottom: 3px;
        }
       h5{
          color: white;
          font-size: 20px;
          margin-left: 0;
          margin-bottom: 17px;
       }
       input{
            height: 40px;
            width: 100%;
            border: 0;
            border-radius: 2px;
            background-color: rgba(216, 216, 216, 0.2);
        }
        &_label{
        font-size: 15px!important;
        color: white!important;
        
        }
        &_button{
        //    width: 60%;
        height: 40px;
        width: 300px;
        border-radius: 2px;
        margin-bottom: -10px;
        + br + br + button {
            margin-bottom: 49px !important;
        }
            
        }
        &_closebutton{
            height: 40px;
            width: 300px;
            border-radius: 2px;
            background-color: #d54851!important;
        color: white!important;
        }
        &_textArea{
            height: 170px !important;
            width: 300px !important;
            border-radius: 2px;
            background-color: rgba(216, 216, 216, 0.2);
            font-size: 15px;
            padding: 9px 10px 40px 10px;
            line-height: 22px;
            margin-bottom: 0px;
        }
    }
    &__right_side__screen{
        max-width: 76.5%;
        flex-basis: 76.5%;
        padding-top: 88px;
        h5{
            color: white;
            font-size: 15px;
         }
         p{
             font-size: 15px;
         }
         &_tdbuttonContained{
            width: 40%;
         }
         &_tdbuttonOutlined{
             width: 40%;
             color: white!important;
             border-color: white!important;
         }
         &_formatIcon{
            color: white;
            font-size: 25px!important;
         }
         .pintura-editor{

            background-color: #000;
            position: relative;
            max-height: 864px;
            .PinturaRootWrapper{

                .PinturaRootComponent{

                    .PinturaPanel{
                        padding-top: 40px;
                        .PinturaUtilMain{

                        }
                        .PinturaUtilFooter{
                            color: #fff;
                            position: absolute;
                            top: 25px;
                            left: 0px;
                            right: 0;
                            font-size: 24px;
                            width: 100%;

                            & > div{
                                flex-direction: column-reverse;
                                align-items: baseline;

                                .PinturaControlListScroller{
                                    min-height: 2.375em;  
                                    flex: 1 1 0;
                                    text-align: left;
                                    >div{
                                        padding: 0;
                                    }
                                    ul{

                                        li{
                                            height: 35px;
                                            width: 160px;
                                            border: 2px solid #FFFFFF;
                                            border-radius: 2px;

                                            &:first-child{
                                                display: none;
                                            }
                                            &:nth-child(2){
                                                display: none;
                                            }
                                            &:nth-child(3),
                                            &:nth-child(4),
                                            &:nth-child(5),
                                            &:nth-child(6){
                                                
                                                &.PinturaRadioGroupOption{
                                                    // border:none;
                                                    width: auto;
                                                    // display: block;
                                                    // margin-left: 0;
                                                    // background-color: rgba(255, 255, 255, 0.08);

                                                    label{
                                                        text-indent: -9999px; 
                                                        font-size: 21px;
                                                        svg{
                                                            display: block;
                                                            margin-top: 10px;
                                                            
                                                        }
                                                    }
                                                }
                                            }
                                            // &:nth-child(6){
                                            //     margin-left: 10px !important;
                                            // }
                                            
                                            &.PinturaRadioGroupOption{
                                                label{
                                                    line-height: 35px;
                                                    margin-bottom: 0;
                                                    min-height: 35px;
                                                    text-align: center;
                                                    text-transform: uppercase;
                                                    padding: 0px 1.75em;
                                                    font-size: 20px;
                                                    box-shadow: none;
                                                    background-color: transparent;
                                                    font-family: $SFProTextBold;
                                                    svg{
                                                       display: none; 
                                                    }
                                                }

                                                
                                            }
                                            
                                        }
                                    }

                                    .PinturaRadioGroup{

                                    }
                                }
                            }

                            .PinturaControlPanels{
                                max-width:100%;
                                margin-left: 0px;

                                .PinturaScrollable{
                                    justify-content: left;
                                    border-radius: 2px;
                                    background-color: rgba(255,255,255,0.08);
                                    
                                    > div{
                                        padding: 0;
                                    }
                                    .PinturaRadioGroupOptions{
                                        flex-direction: row-reverse;
                                    }
                                    ul{
                                        
                                        li{
                                            margin-left: 0;
                                            
                                            display: flex;
                                            flex-direction: row;
                                            align-items: center;
                                            padding: 5px 15px;
                                            border-right: 1px solid rgba(255,255,255,0.1);
                                            border-radius: 0;

                                            .PinturaShapeStyleLabel{
                                                color: #fff;
                                                font-weight: 600;
                                                text-transform: capitalize;
                                                display: none;
                                            }

                                            .PinturaDropdownButton{
                                                width: 150px;
                                                box-shadow: none;
                                                border: none;
                                                text-align: left;

                                                .PinturaButtonInner{
                                                    display: block;
                                                }
                                                .PinturaButtonLabel{
                                                    display: block;
                                                    position: relative;
                                                    text-align: left;

                                                    &::after{
                                                        content: "";
                                                        color: #FF9F00;
                                                        position: absolute;
                                                        border-left: 8px solid transparent;
                                                        border-right: 8px solid transparent;
                                                        border-bottom: 8px solid #FF9F00;
                                                        border-top:0;
                                                        right: 2px;
                                                        top: 15px
                                                    }
                                                }
                                            }

                                            .PinturaButtonLabel{

                                                .PinturaColorPreview{
                                                    border-radius: 4px;
                                                    height: 30px;
                                                    width: 30px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .PinturaNavSet{
                position: absolute;
                right: 128px;

                .PinturaButton{

                    .PinturaButtonIcon{

                            color:#eee;
                            font-size: 25px;
                        // g{

                        //     g{
                        //        path + path{
                        //            fill:#fff !important;
                        //        }
                        //     }
                        // }
                    }

                    &.PinturaButtonIconOnly{

                    }
                }
            }
            .PinturaNavTools{
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                .PinturaNavGroup{

                    .PinturaButtonExport{
                        margin-left: 19px;
                        height: 35px;
                        width: 64px;
                        background-color: #fff;
                    }
                }
            }
        }
    }

}

// .ediImage{

//     &__right_side{

//         &__screen {

//             :not(&) .MuiGrid-container{
//                position: fixed;
//                left:0;
//                right:0;
//                bottom: 0;
//                top:0;
//                z-index: 99999;
//                background-color: #000;
//             }
           
//         }
//     }
// }
