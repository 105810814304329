.about{

    &__screen{

        .border-bottom-2{
            margin-bottom: 33px !important;
            margin-top: 15px !important;
        }
        .passcode-section{
            margin-bottom: 22px !important;

            h4{
                margin-bottom: 20px !important;
            }
        }
        .veryfication-code{
            margin-top: 20px !important;

            label{
                font-size: 14px;
            }

            button{
                margin-top: 40px !important;
            }
        }
        .header{
            margin-bottom: -9px !important;
            padding: 0;
        }

        .align-items-center {
            margin-bottom: 11px !important;
        }

        .border-bottom{
            margin-bottom: 40px !important;
            margin-top: 29px !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
        .type-header{

            h4{
                margin-bottom: 25px !important;
            }
            
        }


    }
}
.footer-btn{

    margin: 0 -10px;

    button{
        margin-top: 38px !important;
        max-width: 230px !important;
        margin:0 10px;
    }
}