@import "../../scss/utils/variables";

.authorized_device{
    &__main{
        &__screen{
           // background-image: url('../../img/Background_imageAuthorized_devices.jpg');
           background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;
            width: 100%;

            .authorized_device__section{
                overflow: auto;
                max-height: 765px;
                max-width: 1281px;
                width: 100%;
                justify-content: center;
                margin-top: -12px;
            }
        }
    }
    &__total{
        width: 300px;
        margin-top: 70px;
        margin-left: 20px !important;

        h4{
            margin:0px;
            font-size: 20px;
            color:#FFFFFF;
            font-weight: 400;
        }
        span{
            font-weight: 700;
            margin-right: 6px;
        }

        .border--bottom{
            padding-top: 7px;
        }

        button{
            margin-top: 10px;
        }
    }
    &__inner{
        &__text{
            color: $white;
            padding-bottom: 6rem;
            margin-top: -78px;

            .user__profile:nth-last-child(-n+5){
                margin-bottom: 0!important;
            }
            h1{
               font-size: 51px;
               margin-top: 0;
               margin-bottom: 50px !important;

               &.device-4{
                transform: translateY(69px);
               }
            }
            h3{
                font-size: 2.7rem;
                font-weight: 500;
                letter-spacing: 7px;
                margin-bottom: 3rem;
            }
            h1,h2,h3,h4,h5,h6{
                color: $white;
            }
        }
    }
}


@media (max-width:1700px){
    .authorized_device__main__screen .authorized_device__section{
        max-width: 61%;
    }
    .authorized_device__total{
        margin-right: 20px;
    }
}