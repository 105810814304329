// .website_videos_video_selection{
// 	max-width: 430px;
// }
.videolist {
	&__main {
      
		&__button {
			// display: flex;
			// margin: 0 -8px;
			// margin-top: 66px;
			// text-transform: uppercase;

			&__warning {
				flex: 1;
				margin: 0 8px;
				color: #424242;
				font-weight: 600;
			}
			&__white {
				flex: 1;
				margin: 0 8px;
				color: black;
				background-color: white;
				font-weight: 600;
			}
			&__white:hover {
				flex: 1;
				margin: 0 8px;
				color: black;
				background-color: white;
				font-weight: 600;
			}
		}
		&__screen {
			background: rgba(255,255,255,0.08);
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
				0 6px 20px 0 rgba(0, 0, 0, 0.19);
			border-radius: 10px 10px 10px;
			height: auto;
			// width:75%;
			//width: 100%;
			padding: 40px;
			// margin-top: 63px;
		}
		&__lists {
			//width: 350px;
			// width: 390px;
			// height: 505px;
			// overflow: hidden;
			max-height: 530px;
			overflow-y: auto;
			margin-bottom: 20px;
			hr {
				height: 1px;
				width: 340px;
				background: linear-gradient(
					135deg,
					rgba(255, 255, 255, 0.3) 0%,
					rgba(255, 255, 255, 0.03) 100%
				);
				margin: 20px 0;
			}

			&__videoselection {
				margin-bottom: 20px;
			}
		}
		&__header {
			&__header-title {
				font-size: 20px;
				font-weight: 500;
				margin-bottom: 15px;
			}
		}
	}
	&__submain {
		&__header {
			&__checkbox {
				height: 20px;
				width: 20px;
				object-fit: cover;
			}
			&__title {
				font-size: 15px;
				color: #ebebeb;
				display: inline-block;
				text-transform: capitalize;
			}
		}
	}
}

// .title{
//     font-size: 13px;
//     padding-top: 10px;
//     color: #ebebeb;
// }

// div.video-list-container{
//     /* background-color: black; */
//     background: linear-gradient(#30302f,#292929);
//     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
//     /* margin-left: 50px; */
//      border-radius: 10px 10px 10px;
//      height: 800px;

// }

// div.video-lists{
//     margin-left: 50px;
//     width: 350px;

// }
// .header-title{
//     font-size:20px;
//     padding-top: 25px;
// }