.goolge_tv_video_detail{
    &__main{
        &__screen{
           background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: #fff;
            position: relative;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            &::before{
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, #000000 0%, #000000 28.34%, rgba(0, 0, 0, 0) 100%);
            }
        }
        
    }

    &__inner{
        &__screen{

        }
    }
    &__text{
        color: #fff;
        padding-left: 90px;
        position: relative;
        h1{
            font-size: 6rem;
            font-weight: normal;
            color: #fff;
            text-transform: uppercase;
            margin-bottom: 40px;
        }
        &__duration, &__cetegory, &__description{
            font-size: 2.4rem;
        }
        &__description{
            max-width: 805px;
        }

        
    }
    &__btn_group{
        font-size: 28px;
        >div{
            color: rgba(255,255,255,0.4);
            &:hover{
                color: #fff;
            }
        }
    }
    &__footer_timeline_sliderBox{
         background-color: rgba(0,0,0,0.26);
        backdrop-filter: blur(6px);
        padding: 64px 90px;
        height: 297px;
        width: 100%;
        &__item{
            width: 250px;
            height: 140px;
        }
    }
    &__footer_timeline{
        // background-color: rgba(0,0,0,0.26);
        // backdrop-filter: blur(6px);
        // padding: 64px 90px;
    }
    &__footer_timeline_items{
        max-width: 250px;
        width: 100%;
        img{
            height: 140px;
            max-width: 250px;
            object-fit: cover;
            width: 100%;
        }
        label{
            font-size: 28px;
            color: #FFFFFF;
            line-height: 40px;
            text-align: center;
            margin-top: 15px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
          
        }
    }
}


@media (max-width:1366px){
    .goolge_tv_video_detail{
        &__main{
            &__screen{
              zoom: 0.8;
              padding-top: 7rem;
            }
            
        }
    }
}