@import "../../scss/utils/variables";

.credit_card_declined{
    &__outsdanding__balance{
        margin-top: 7px;
        margin-bottom: 3rem;
        h1{
            line-height: 1;
        }
    }
    &__box{
        background-color: rgba(216,216,216,0.2);
        padding:1.5rem 3rem 2rem 3rem;
        margin-bottom: 2.5rem;
        margin-left: 1rem;
        margin-right: 1rem;
        position: relative;
        .error{
            position: absolute;
            top: -23px;
            right: 0;
            padding: 2px 5px;
            background: linear-gradient(135deg, #D0393E 0%, #D54A53 100%);
            color: $white;
            font-size: 13px;
            &:after{
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                border-bottom: 23px solid #D0393E;
                border-left: 23px solid transparent;
                left: -23px;
                top: 0;
            }
        }
    }
    &__img{
        max-width: 7rem;
    }
    &__info{
        &__name{

        }
        &__number{

        }
    }
}
.credit_card_declined__inner__screen{
    .transparent__form--white{
        min-height: 636px;
    }
}