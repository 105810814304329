@import "../../scss/utils/variables";

.MuiPaper-root
  .MuiDrawer-paper
  .MuiDrawer-paperAnchorLeft
  .MuiPaper-elevation16 {
  background: rgba(255, 255, 255, 0.08);
  color: white;
}
.operator_channel {
  &__main {
    &__screen {
      background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
      background-size: 100% 100%;
      padding-top: 6rem;
      color: $white;
    }
    &__lists {
      overflow: auto;
      max-height: 859px;
      max-width: 1435px;
      width: 100%;
      // margin-top: -86px;
      margin-bottom: 0 !important;

      &__item {
        height: 266px;
        width: 664px;
        border-radius: 7px;
        background-color: rgba(255, 255, 255, 0.05);
        margin-bottom: 40px;

        &__image_box {
          position: relative;
          width: 333px;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        &__play_count {
          height: 35px;
          width: 226px;
          border-radius: 2px;
          background-color: rgba(255, 255, 255, 0.08);
          padding: 5px 7px;
          position: absolute;
          top: 10px;
          left: 14px;
          backdrop-filter: blur(5px);
          color: $white;

          &.playing-type {
            span {
              height: 26px;
              width: 40px;
              border-radius: 2px;
              background: linear-gradient(135deg, #35a85a 0%, #4da166 100%);
              display: inline-block;
              margin-right: 12px;
            }
          }
          &.not-playing-type {
            span {
              height: 26px;
              width: 40px;
              border-radius: 2px;
              background: linear-gradient(315deg, #797875 0%, #383633 100%);
              display: inline-block;
              margin-right: 12px;
            }
          }
        }
        &__video_count {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          width: 80px;
          background-color: rgba(255, 255, 255, 0.8);

          color: $main-font-color;
          h2 {
            font-size: 43px;
            font-weight: 600;
          }
          label {
            font-size: 15px;
          }
        }
        &__title_box {
          padding: 1rem;
          color: $white;
          width: 331px;
          h5 {
            font-size: 15px;
            font-weight: 500;
            text-transform: uppercase;
            color: $white;
          }
        }
      }
    }
    &__schedule {
      &__screen {
        overflow: auto;
        max-height: 859px;
        max-width: 1435px;
        width: 100%;
      }
    }
  }
}
.drawer_btn {
  position: fixed;
  top: 21%;
  left: 0;
  height: 50px;
  width: 100px;
  font-size: 15px;
  line-height: 22px;
  text-align: left;
  padding-left: 20px;
}
.drawer_btn svg {
  font-size: 34px;
  line-height: 34px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 3px;
  color: #ffb438;
}
// .sidemenu-opratory svg {
//   font-size: 22px;
//   line-height: 22px;
//   color: #ffb438 !important;
// }
// .sidemenu-opratory .MuiListItemIcon-root {
//   min-width: unset;
//   flex-shrink: unset;
//   margin-right: 22px;
// }
.sidemenu-opratory .MuiListItemText-root span {
  font-size: 20px;
  line-height: 26px;
}
.sidemenu-opratory ul {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 50px !important;
}
.sidemenu-opratory ul li {
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 10px;
}
.operator_channel__main__lists__item {
  &.practice {
    background-color: #fff;
    color: $main-font-color;
    .operator_channel__main__lists__item__title_box {
        color: $main-font-color;
        h5{
            color:$main-font-color;
        }
       ul{
            padding:23px 17px 4px 21px !important;
        }

      .operator-header {
        position: relative;
        padding-right: 36px;
        align-items: start !important;
        height: 32px;

        .MuiSvgIcon-root {
          cursor: pointer;
          // fill: #fff;
          cursor: pointer;
          position: absolute;
          right: 0;
          top: -8px;
        }
      }
      .channel-play-list {
        font-size: 14px;
        margin-top: 9px;
      }
    }
  }

  .operator_channel__main__lists__item__title_box {
    color: #fff;
    h5{
        color:#fff;
        font-size: 15px;
        line-height: 22px;
    }
   ul{
        padding:23px 17px 4px 21px !important;
    }

  .operator-header {
    position: relative;
    padding-right: 36px;
    align-items: start !important;
    height: 32px;

    .MuiSvgIcon-root {
      cursor: pointer;
      // fill: #fff;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: -8px;
    }
  }
  .channel-play-list {
    font-size: 14px;
    margin-top: 9px;
    .channel_title{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        line-height: 20px;
        // color:$white;
    }
    .duration {
        font-size: 14px;
        line-height: 20px;
    }
    .border--bottom {
      .duration {
        min-width: 60px;
        text-align: right;
        color:$white;
      }
    }
  }
}
  .MuiSvgIcon-root {
    cursor: pointer;
  }
  .chanal_options {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 412px;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(40px);
    border-radius: 0 7px 7px 0;
    z-index: 5;

    &.remove_box {
      color: #fff;

      .chanal_options_header {
        padding-bottom: 16px;

        h5 {
          color: #fff;
        }
      }
    }
    // background-color: rgba(0,0,0,0.26);

    &.channel_detail_box {
      padding: 14px 17px 8px 21px !important;
    }
    &--item {
      border-bottom: 0 !important;
      cursor: pointer !important;
      &:hover {
        background-color: $primary-color !important;
      }
    }

    .rename_form {
      max-width: 380px;
      margin-top: 23px;
      line-height: 21px;
        input {
          font-size: 15px;
          line-height: 22px;
        }
        .form-group {
          label { font-size: 14px; line-height: 22px;  }
          span { font-size: 14px; line-height: 22px; }
        }
    }

    .channel-option-btn {
      padding-right: 23px;
    }

    .chanal_options_header {
      padding-bottom: 16px;
    }
  }
  .list-group {
    .list-group-item {
      border: 0;
      border-bottom: 1px solid rgba(216, 216, 216, 0.2);
      background-color: transparent;
    }
  }
}

.Page12 {
  &__main {
    // width:100vw;
    // height:100vh;
    // background:#2e2b29;
    display: flex;
    justify-content: center;
    align-items: center;
    &__container {
      &__cardRow {
        &__cardCol {
          &__colContainer {
            // padding: 0px !important;
            // width: 35rem !important;
            // margin: 30px 0px 30px 0px;

            &__card {
              width: fit-content;
              border: 0px !important;

              &__cardBody {
                margin: 0px;
                padding: 0px;

                display: flex;
                border-radius: 4px;
                justify-content: flex-start;
                width: 35rem;

                &__imageDiv {
                  background-size: cover;
                  border-radius: 4px;
                  height: 13rem;
                  margin: 0px;
                  padding: 0px !important;

                  &__overlayHeader {
                    font-size: 10px;
                    float: left;

                    &__overlayHeaderLabel {
                      backdrop-filter: blur(20.5px);
                      -webkit-backdrop-filter: blur(15.5px);
                      border-radius: 3px;
                      padding: 5px;
                      font-size: 12px;
                      width: 10rem;
                      margin: 6px;
                      color: white;
                      font-weight: 400;
                      height: 30px;
                      display: flex;
                      align-items: center;

                      span {
                        background: url("https://images.unsplash.com/photo-1515041219749-89347f83291a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bWluaW9ufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60");
                        height: 16px;
                        width: 16px;
                        float: left;
                        display: block;
                        margin-right: 11px;
                      }
                    }
                  }

                  &__overlay {
                    display: flex;
                    float: right;
                    height: 100%;
                    width: 3.5rem;
                    background: rgba(255, 255, 255, 0.836);
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-top: -42px;
                  }
                }

                &__textDiv {
                  &__w3-ul {
                    list-style: none;
                    color: white;

                    li {
                      border-bottom: .1rem solid #434444;
                      padding: 9px;
                      font-size: 13px;

                      span {
                        float: right;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.MuiList-root {
  margin-bottom: -36px;
  ul {
    padding: 12px 15px 11px !important;
    color: white !important;
    margin-left: 75px !important;
    padding-top: 0 !important;

    li {
      list-style: disc;
      margin: 3px 0;
      font-size: 15px;
      color: rgba(255, 255, 255, 0.65);
      line-height: 35px;
      letter-spacing: 1.3px;
      cursor: pointer;
      &.activelink {
        color: #fff;
      }
    }
  }
}


.operatory-roomsidebar{
  margin-top:-253px;
  margin-left: 16px;
  width: 345px !important;
  
  h6{
      padding: 0rem 1.5rem;
  }
  .MuiListItem-button{
      padding-bottom: 0;
      .MuiListItemIcon-root{
          min-width: 44px;
          svg{
              width: 1.5em;
              height: 1.5em;
              color:#ff9f00 !important;
          }
      }

      .subtitle{
          span{
              font-size: 20px;
          }
      }
  }

  .MuiList-root{
    margin-bottom: -36px;
    ul{
        padding: 12px 15px 11px !important;
        color: white !important;
        margin-left: 75px !important;
        padding-top: 0 !important;
        margin-bottom: 10px;
        li{
            list-style: disc;
            margin: 3px 0 ;
            font-size: 15px;
            color: rgba(255, 255, 255, 0.65);
            line-height: 35px;
            letter-spacing: 1.3px;
            cursor: pointer;
            &.activelink{
                color: #fff;
            }
        }
        li.activelink {
            color: rgba(255, 255, 255, 1);
            font-family: $SFProSemibold;
            font-weight: 600;
         }
    }
  }

  .channel-room-bt{
    position: absolute;
    min-width: 285px !important; 
    bottom: 40px;
    left: 22px;
    right: 20px;
    padding: 0 38px 0 0px;

    .subscribe-btn{
        margin-bottom: 15px;
    }
}
.lookingvideo{
    
    color: #fff;
    padding-left: 16px;
    padding-right: 16px;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    position: absolute;
    top: 80px;

}
}
.mw-80  { max-width: 80%; }
.operator_channel__main__lists__item .chanal_options .chanal_options_header { position: relative; border-bottom: none !important;
  &:after { position: absolute; top: 100%; left: 50%; transform: translateX(-50%);width: 412px; height: 1px; content: ''; background: rgba(216, 216, 216, 0.3); }
}
.mb-200   { margin-bottom: 200px !important; }
.opratory-timeline-sec {
  .opratory-timeline-sec-row { max-width: 1644px;  padding: 20px; }
}

@media (max-width:1700px){
  .opratory-timeline-sec .opratory-timeline-sec-row{
    max-width: 80%;
  }
  .operator_channel__main__lists{
    max-height: 100%;
    max-width: 85%;
    .px-20{
        padding-left: 1rem;
        padding-right: 1rem;
    }
  }
  .operator_channel__main__lists__item{
    width: 100%;
    // height: 250px;
    margin-bottom: 20px;
    overflow: hidden;
  }
  .operator_channel__main__lists__item__image_box{
    width: 40%;
  }
  .operator_channel__main__lists__item__title_box{
    width: 60%;
  }
}