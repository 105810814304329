@import "../../../../scss/utils/variables";

.videoSliderSectionTitle{
    font-size: 3.8rem;
    font-weight: 400;
    color: $white;
    line-height: 1;
}
.videoSliderSectionVscroll{

}
.videoSliderSection{
    // overflow: hidden;
    // overflow-x: scroll;
    // -ms-overflow-style: none; /* Edge, Internet Explorer */
    // scrollbar-width: none; /* Firefox */
    
}
.videoSliderSection__slide{
    max-width: 356px;
}

.videoSliderSection::-webkit-scrollbar {
    display: none; 
}