@import "../../scss/utils/variables";

.error {
  &__default {
    &__screen {
      // background-image: url('../../img/Background_error.jpeg');
      padding-top: 6rem;
      color: $white;
      background: linear-gradient(135deg, rgba(208, 57, 62, 0.93) 0%, rgba(213, 74, 83, 0.93) 100%);
      // @include default-error-gradient
    }
  }
}

.main {
  &__screen {
    background-size: 100% 100%;
    padding-top: 6rem;
    color: $white;
    width: 100%;
    background: linear-gradient(
      315deg,
      rgba(40, 40, 40, 0.93) 0%,
      rgba(0, 0, 0, 0.8835) 100%
    );
  }
  &__inner {
    &__text {
      color: $white;
      font-size: 2rem;
      line-height: 3rem;
      max-width: 67rem;
      h1 {
        margin-top: 2rem;
      }
      h3 {
        font-size: 2.7rem;
        font-weight: 500;
        letter-spacing: 7px;
        margin-bottom: 3rem;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $white;
      }
    }
  }
}
.selectiontab__main__screen {
  .subscription-layout {
    position: absolute;
  }
  #swiper-layout {
    width: 100%;
    height: auto;
  }
  .layout-back-image {
    position: relative;
    border: 3px solid transparent;
    border-radius: 2px;
    margin-bottom: 20px;
    .layout-front-image {
      position: absolute;
      margin-top: 0;
      margin-left: 0;
      top: 5px;
      left: 5px;
      width: 14px;
      height: 14px;
      border: 1px solid #424242;
    }
    &.active {
      border-color: #ffc107;
      .layout-front-image {
        border: none;
      }
    }
  }
}
.website_videos_layout_selection__main__screen {
  .subscription-layout {
    position: absolute;
  }
  #swiper-layout {
    width: 100%;
    height: auto;
    margin-top: -11px;
  }
  .layout-back-image {
    position: relative;
    border: 3px solid transparent;
    border-radius: 2px;
    margin-bottom: 20px;
    min-height: 97px;
    .layout-front-image {
      position: absolute;
      margin-top: 0;
      margin-left: 0;
      top: 5px;
      left: 5px;
      width: 14px;
      height: 14px;
      border-radius: 2px;
      border: 1px solid #424242;
    }
    &.active {
      border-color: #ffc107;
      .layout-front-image {
        border: none;
      }
    }
  }

  .layout-screen {
    margin-top: 42px;
  }
}
.html-sec {
  margin-top: auto;
  margin-bottom: auto;
}
.chairside_education__main__screen .MuiTabs-vertical button {
  position: relative;
  // &::after    { position: absolute; width: 100%; height: 100%; background-color: #201f20; content: ''; }
  // svg         { position: relative; z-index: 2;}
}
.subtitle span {
  font-size: 15px;
}
.file_upload__box input {
  width: 100%;
}

.waitiing-room-col-sec {
  display: flex;
  -webkit-display: flex;
  flex-wrap: wrap;
  .waiting-room-left-sec {
    width: 430px;
    margin-right: 40px;
  }
  .waiting-rom-right-sec {
    width: 1134px;
  }
  .slide_rightsidebar {
    .videoSelected__main__centerCol {
      max-width: unset !important;
    }
  }
}
.no-slide-text {
  button {
    color: #ff9f00;
  }
}

.google_tv_videoDetail_footer_slider{
  .swiper-button-prev, .swiper-button-next{
      color: #fff;
      background-color: rgba(255,255,255,0.08);
      // right: 0px !important;
      -webkit-backdrop-filter: blur(6px);
      backdrop-filter: blur(6px);
      width: 50px;
      height: 140px;
      top: 64px;
      margin-top: 0;
  }
  .swiper-button-next{
    right: 0;
  }
  .swiper-button-prev{
    left: 0;
  }
}




@media (max-width:1700px){
  body{
    zoom: 0.9;
    #root{
      height: 100%;
      > div:not(.fb_reset){
        height: 100%;
      }
    }
    
  }
  .MuiSkeleton-wave{
    height: 100%!important;
    width: 100%!important;
    display: block!important;
   }

   .videoRequest__main__screen{
    min-height: 100vh;
    justify-content: start!important;
   }
   .videoRequest__inner__screen{
    margin-top: 0!important;
   }

   .zoom_button_on_smallscreen{
    zoom: 1.1!important;
   }
}

@media (max-width:1366px){
  body{
   zoom: 0.8;
  }
  .zoom_button_on_smallscreen{
    zoom: 1.2!important;
   }
 }