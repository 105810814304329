@import "../../scss/utils/variables";


.settings{
    &__main{
        &__screen{
           // background-image: url('../../img/Background_imageSettings.jpg');
           background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;
            .refer__success__message{
                color: $white;
                h1{
                    color: $white;
                }
            }

            .practice_name{
                font-size: 18px;
                line-height: 28px;
            }
        }

        &__invitation{

            margin-top: -128px;

            h1{
                font-size: 49px;
                margin-bottom: 13px;
            }

            p{
                font-size: 18px;
                margin-bottom: 12px !important; 
            }

            .justify-content-center{

                button{
                    max-width: 235px;
                }
            }
        }

        &__refer_form{
            margin-top: -70px;
            height: 653px;

            h4{
                margin-bottom: 20px !important;
            }

            form{

                .form-group{

                    &.inputerror{

                        .text--danger{
                            font-size: 14px;
                        }
                    }
                }

                button{
                    margin-top: 17px !important;
                }
            }
        }
    }
    &__item{
        max-width: 200px;
        margin-bottom: 18px;

        &:nth-last-child(-n+4){
            margin-bottom: 0;
        }
    }
    &__inner{
        &__screen{
            overflow: auto;
            max-width: 966px;
            width: 100%;
            margin-top: 19px;
            margin-bottom: 85px !important;

            .practice_name{
                font-size: 19px;
                line-height: 31px;
            }

            .header{
                &--sub{
                    button{

                        span{
                            font-size: 17px;
                        }
                    }
                }

            }
        }
    }
    &__box{
        height: 200px;
        width: 200px;
        padding: 30px;
        border-radius: 7px;
        background-color: rgba(255,255,255,0.08);
        cursor: pointer;
        position: relative;
        margin-bottom: 15px;
        &:hover,&:focus{
            &::after{
                content: '';
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: 5;
                border-radius: 7px;
                box-shadow: inset  0 0 0 0.2rem $primary-color;
            }
            // &::before{
            //     content: '';
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     right: 0;
            //     bottom: 0;
            //     background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
            //     border-radius: 0 0 7px 7px;
            //     z-index: 5;
            // }
        }
    }
    &__name{
        text-transform: uppercase;
    }
}


@media (max-width:1700px){
    .settings__main__screen,
    .accesscode__main__screen,
    .billing__main__screen,
    .authorized_section__main__screen,
    .practiceInfo__main__screen,
    .error__default__screen,
    .addons__main__screen,
    .view_account_profile__main__screen,
    .active_profiles__main__screen,
    .authorized_device__main__screen,
    .language__main__screen,
    .edit_profile__main__screen,
    .manage_device__main__screen,
    .domain__main__screen,
    .style_goolge_tv_test_drive__main__screen__23vUY,
    .update_admin_profile__main__screen,
    .upgradeplan__main__screen,
    .operator_channel__main__screen{
        min-height: 100vh;
    }
    .caption__inner{
        width: 95%!important;
    }
    .caption__inner__card{
        padding: 2rem;
    }
    .caption__inner__card__body ul li label .caption-square{
        width: 158px!important;
        height: 100px!important;
    }
    .caption__inner__card__body ul li label p{
        margin: 0 0px 0 20px!important;
        min-width: 100px!important;
    }
    
   
    .caption__inner__card__body ul li:nth-child(3) .caption-square:after{
        width: 90%!important;
    }
    .caption__inner__card__body ul li:nth-child(2) .caption-square:after{
        width: 70%!important;
    }
    .caption__inner__card__body ul li label .caption-square:after{
        width: 60%!important;
    }
}