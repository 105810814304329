@import "../../../scss/utils/variables";


.chairside_education_close_caption{
    &__main{
        &__screen{
            //background-image: url('../../../img/Background_imageChannels.jpg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;

            .practice_name{
                font-size: 19px;
                letter-spacing: 0.2px;
            }
        }
    }
    &__inner{
        &__screen{
            // overflow: auto;
            max-width: 1729px;
            width: 100%;
            margin-top: 66px ;
        }
        &__text{
            color: $white;
            h1,h2,h3,h4,h5,h6{
                color: $white;
            }
        }
    }
    &__player{
        width: 530px;
        margin-left: 20px;
        margin-right: 15px;
        
        h3{
            font-size: 27px;
            margin-bottom: 37px !important;
        }
        .video-title{
            position: relative;
            margin-bottom: 40px;
            h3{
                font-size: 20px;
                margin-bottom: 24px !important;  
            }

            &::after{
                content:"";
                position: absolute;
                left: 15px;
                bottom: -20px;
                height: 1px;
                width: 430px;
                background: linear-gradient(135deg, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0.03) 100%);
            }

            p{
                font-size: 15px;
                letter-spacing: 0.6px;
            }
        }
    }
    &__player__box{
        position: relative;
        margin-bottom: 31px !important;
        .MuiContainer-root {
            max-width: 100%;
            padding-right: 0 !important;

            > div{

                > div + div{
                    width: 100%;

                    > div{
                        height: 37px;
                        p{
                            font-size: 16px;
                        }

                        button{
                            padding: 8px 12px;
                            .MuiIconButton-label{

                                svg{
                                    width: 2em;
                                    height: 2em;
                                }
                               
                            }
                        }

                        .MuiSlider-colorPrimary{
                            width: 338px;
                        }
                    }
                }
            }
        }

        div{
           

            video{
                width: 510px !important;
                height: 287px !important;
                object-fit: cover;
            }
        }

    }
    &__addframe{
        min-width: 1162px;
        padding: 0 20px;
        max-height: 900px;
        position: relative;

        .fs-11{
            font-size: 10px;
            letter-spacing: 0.3px;
        }
        .undo-steps{
            background-image: url("../../../img/Undowhite.png");
            position: absolute;
            top: 0;
            right: 32px;
            background-size: contain;
            height: 24px;
            width: 24px;
        }
        &__lists{
            max-height: 650px;
            overflow-y: auto;
            padding-right: 15px;

            .border--bottom{
                padding: 3px 0 2px;
                position: relative;
                border:none;
                
                &::before{

                    content: "";
                    position: absolute;
                    left:0;
                    right:0;
                    bottom: -3px;
                    height: 1px;
                    width: 100%;
                    background: linear-gradient(135deg, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0.03) 100%);
                }
            }
        }
        .remove_cc_btn{
            height: 30px;
            width: 30px;
            border: 1px solid #FFFFFF;
            border-radius: 2px;
        }
        textarea.form-control{
            height: 70px;
            // width: 767px;
            background-color: #FFFFFF;
            min-height: auto;
            margin-bottom: 0;
            border-radius: 0;
            font-size: 15px;
            overflow: hidden;

            &:focus,
            &:hover{
                border: 2px solid #F99F1B;
            }
        } 
        .video_cc_time_field{
            input{
                background-color: transparent;
                color: $white;
            }
           
        }
    }
}

@media (max-width:1700px){
    .chairside_education_close_caption__inner__screen{
        max-width: 85%;
        margin-top: 0;
    }
    .chairside_education_close_caption__player{
        width: 30%;
    }
    .chairside_education_close_caption_right_side{
        width: 70%!important;
    }
    .chairside_education_close_caption__addframe{
        width: 100%;
        min-width: auto;
    }
    .chairside_education_close_caption__player__box div video{
        width: 100%!important;
        height: auto!important;
    }
    .chairside_education_close_caption__addframe textarea.form-control{
        height: 60px;
        padding: 1rem 2rem;
    }
    .chairside_education_close_caption__addframe__lists{
        max-height: 586px;
    }
}