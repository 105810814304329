@import "../../scss/utils/variables";

.overflow-scroll{
    max-height: 8rem;
    overflow: auto;
    display: block;
}
.practiceInfo{
    &__main{
        &__screen{
            // background-image: url('../../img/Background_imagePractice info.jpg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;
           
            .practice_name {
                font-size: 18px;
                line-height: 30px;
            }
            .review__screen{
                .transparent__form--white{
                    width: 1250px;
                    height: 628px;
                }


            }
            .practice_info{
               
                .practice_hours{

                    .practice_week{

                        .form-group{
                            margin-bottom: 12px !important;

                            label{
                                width: 100px;
                            }

                            span{
                                font-size: 14px;
                            }
                        }
                    }
                }

                .transparent__form{

                    &--white{
                        margin-top: -71px;

                        .form-group{
                            margin-bottom: 25px !important;
                            label{
                                font-size: 15px;
                                line-height: 16px;
                                margin-bottom: -2px;
                            }
                            span{

                                &.text--gray{
                                    color: rgba(255,255,255,0.6);
                                    line-height: 23px;
                                }
                            }
                        }
                    }
                }

                .border-right-hr{
                    position: relative;

                    &::after{
                        position: absolute;
                        content: "";
                        height: 480px;
                        width: 1px;
                        background-color: rgba(216,216,216,0.3);
                        right:0;
                        top:40px;
                    }
                }
            }

            .basic_information{
                width: 820PX;
                margin-top: -74PX;
                min-height: 788PX;

                .form-group{

                    h4{
                        line-height: 15px; 
                    }
                }

                .basic_information_right_side{
                    padding: 0 20px;
                }
            }
            .additional_information{
                width: 820PX;
                margin-top: -78PX;
                min-height: 835PX;

                .form-group{

                    h4{
                        line-height: 15px; 
                    }
                }

                .additional_information_right_side{
                    padding: 0 20px;
                }
            }
        }
        
    }
    &__suggestion{
        width: 320px;
        h4{
            margin:0px;
            font-size: 20px;
            line-height: 50px;
            color:#FFFFFF;
            font-weight: 400;

            span{
                color:#FF9F00;
                font-size: 50px;
                font-weight: bold;
            }
        }
        .divider{
            background-color: rgba(255,255,255,0.29);
            height: 2px;
            margin: 35px 0;
        }
        .form-group .form-control{
            max-width: 260px;
            min-width: auto;
            margin-top: 10px;
        }
    }
    &__inner.practice-manager{
        width:450px;

        .practice__inner__header{

            h4{
                margin:0px 0px 24px 0;
                font-size: 20px;
                line-height: 26px;
                color:#FFFFFF;
                font-weight: 400;
            }
            p{
                font-size: 15px;
                line-height: 22px;
                color:#FFFFFF;
                margin:0px;
            }
        }
        .practice__inner__body{
            padding:40px 0;

            .form-control{
                min-width: auto;
                max-width: 100%;
            }
        }

    }
    &__inner.practice-locations{

        width: 1274px;

        .practice__inner__header{

            h4{
                margin:0px 0px 24px 0;
                font-size: 20px;
                line-height: 26px;
                color:#FFFFFF;
                font-weight: 400;
            }
            .location-icon{
                max-width: 38px;
                max-height: 52px;
            }
            .form-control{
                min-width: auto;
                max-width: none;
            }
            .search-list{
                display: flex;
                align-items:center;
                margin-top: 28px;

                li{

                    &.active{
                        border:none;
                        a{
                            color:#fff;
                        }
                    }

                    a{
                        color: rgba(255,255,255,0.6);;
                        font-size: 22px;
                        line-height: normal;
                        padding: 4px;
                        display: block;

                        &:hover{
                            color:#fff;
                        }
                    }
                }
            }
        }
        .practice__inner__body{

            padding:40px 0;

            table{
                border:0px;
                text-align: left;

                th , td{
                    border:0px;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 22px;
                    color:#FFFFFF;
                    text-transform: uppercase;
                    vertical-align: middle;
                }
                td{
                    text-transform: none;
                    font-weight: 300;
                    border-bottom: 1px solid rgba(255,255,255,0.3);
                    padding:1.5rem 0.75rem;
                    
                    .color-yellow{
                        color:#FF9F00;
                    }
                    .color-red{
                        color:#FF4D54;
                    }
                    .color-green{
                        color:#4DA166;
                    }
                }
            }
        }

    }
    &__inner.practice-hours{
        width: 1624px;

        .border-left{
            border-color: #8c8c8c !important;
        }

        .custom-flex{
            display: flex;
            align-items: center;
            margin: 0 -7px;

            &.disable-item{
                .form-group{
                    &:not(:first-child){
                        opacity: 0.5;
                        pointer-events: none;
                    }
                }
            }

            .form-group{
                margin:0px;
                flex: 1;
                max-width: 100%;
                padding: 0 7px;

                &:first-child{
                    flex:0 0 120px;
                    max-width: 120px;
                }
                &.sm-fg-box50{
                    flex:0 0 50px;
                    max-width: 50px;
                    padding-top: 14px;
                }
                &.sm-fg-box30{
                    flex:0 0 30px;
                    max-width: 30px;
                    padding-top: 14px;
                }

                .form-control{
                    min-width: auto;
                    max-width: 100%;
                }
            }
        }
        
    }
    &__inner.practice-edit-info{
        width: 820px;
    }

    &__inner{
        padding:38px 40px;
        width: 1250px;
        border-radius: 7px;
        background-color: rgba(255,255,255,0.08);

        &__header{
            
            
        }
        &__body{

            padding:0px 0;
            min-height: 540px;

            h4{
                margin:0px 0px 10px 0;
                font-size: 20px;
                line-height: 26px;
                color:#FFFFFF;
                font-weight: 400;

                a{
                    font-size: 15px;
                    line-height: 22px;
                    color:#FF9F00;
                    margin:0px;
                    font-weight: normal;
                }
            }

            .form-group{
                
                margin-bottom: 25px;

                .custom-input-group{

                    background-color:rgba(216, 216, 216, 0.2);
                    border-radius: 2px;

                    .input-group-prepend{

                        align-items:center;
                        padding: 0 10px;
                        position: relative;
                    
                    &:after{
                        content:"";
                        width: 1px;
                        height: 24px;
                        position: absolute;
                        background-color: rgb(255 255 255 / 40%);
                        right: 0;
                    }    

                        img{
                            height: 18px;
                            width: 25px;
                            margin-right: 8px;
                        }
                    }

                    .form-control{
                        min-width: inherit;
                        max-width: 100%;
                        width: auto;
                        background-color:transparent;
                    }
                }

                label{
                    display: block;
                    line-height: normal;
                }
                p{
                    color:rgba(255,255,255,0.6);
                    margin: 0;
                }
            }
            .practice-time-list{

                li{
                    margin-bottom: 25px;
                    color:rgba(255,255,255,0.6);

                    span{
                        font-weight: 500;
                        color:rgba(255,255,255,1);
                        min-width: 120px;
                        display: inline-block;
                    }
                }
            }

             

        }
        &__footer{
            .button-section{
                padding-top: 120px;
            }
            ul{
                li{
                    display: inline-block;
                    margin: 0 8px;
                    
                    a{
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 22px;
                        color:#FFFFFF;
                        text-transform: uppercase;
                        vertical-align: middle;
                        letter-spacing: 0.5px;
                        display: block;
                        padding: 8px;
                    }
                    .active{
                        color:#FF9F00;
                    }
                }
            }
        }

    }
}
.practice{

    &__hours_section{

        .border-right-hr{
            position: relative;

            &::after{
                position: absolute;
                content: "";
                height: 480px;
                width: 1px;
                background-color: rgba(216,216,216,0.3);
                right:0;
                top:40px;
            }
        }
    }
}