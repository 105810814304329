@import "../../scss/utils/variables";

.signin{
    &__main{
        &__screen{
            //background-image: url('../../img/Background_imageUsers.jpg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;
            width: 100%;
            &.loading_screen{
                h1{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 50px;
                    line-height: 70px;
                    text-align: center;
                    color: #FFFFFF;
                }
                .progress{
                    width: 100%;
                    max-width: 620px;
                    background-color: #585858;
                    height: 6px;
                }
                .progress-bar{
                    
                }
            }
            
            .transparent__form--white{

                &.signin__box{
                    max-width: 430px;
                    min-height: 675px;
                    width: 100%;
                    margin-top: -76px;
                    padding: 38px 40px;

                    form{
                        margin-top: 7px;
                    }
                    .input-password{
                        input[type=password] ::after{
                            content: "*";
                            position: absolute;
                            left:0;
                            right: 0;
                            font-size: 20px;
                        }
                    }
                }
                
                &.welcome__box{
                    max-width: 430px;
                    min-height: 675px;
                    width: 100%;
                    box-shadow: inset  0 0 0 0.2rem #35A85A;
                }
            }
        }
    }
    &__inner{
        &__text{
            color: $white;
            font-size: 2rem;
            line-height: 3rem;
            max-width: 67rem;
            h1{
                margin-top: 2rem;
            }
            h3{
                font-size: 2.7rem;
                font-weight: 500;
                letter-spacing: 7px;
                margin-bottom: 3rem;
            }
            h1,h2,h3,h4,h5,h6{
                color: $white;
            }
        }
    }
}

.delete-profile{
    margin-top: 94px !important;

    .delete-heading{
        padding-bottom: 50px !important;
    }

    .profile-remove{
        font-size: 19px;
        margin-bottom: 15px !important;
    }
    .remmoval-meesage{
        font-size: 15px;
        margin-bottom: 24px !important;
    }
    .delete-checksign{
        margin-bottom: 44px !important;

        div{
            padding-left: 0;
        }
    }
    .passcode{
        margin-bottom: -2px;
        label{
            font-size: 14px;
        }
    }
}

.profile-deleted{
    margin-top: -110px !important;

    h4{
        margin-bottom: 18px !important;
        font-size: 19px;
    }

    button{
        color: #d3434a;
        background-color: #fff;
    }
}

.welcome__thalamus{
    &__text{
        color: #fff;
        font-size: 2rem;
        line-height: 3rem;

        h1{
            font-size: 7.8rem;
        }
        p{
            display: inline-block;
            max-width: 64rem;
        }
    }
}

.setpasscode_screen .mw-350{
    max-width: 100%;
}
@media (max-width:1700px){
    .signin__main__screen{
        min-height: 100vh;
    }
    .signin__main__screen .transparent__form--white.signin__box{
        min-height: 520px;
        max-width: 400px;
    }
}