@import "../../../../scss/utils/variables";


.goolge_tv_waiting_room{
    &__main{
        &__screen{
            background: linear-gradient(315deg, #2E2E2E 0%, rgba(5,5,5,0.95) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;
            min-height: 100vh;
        }
        
    }

    &__inner{
        &__screen{
            margin-left: 170px;
            height: 850px;
            overflow: hidden;
            overflow-y: auto;
        }
    }

    &__search_box{
        width: 1135px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 100%);
        padding: 10px 32px;
        border: 0px;
        color: white;
        height: 75px;
        font-size: 27px;
        border-radius: 5px 5px 5px;
    }
}

.videoSliderSectionTitle{
    font-size: 3.8rem;
    font-weight: 400;
    color: $white;
    line-height: 1;
}

@media (max-width:1700px){
    .navGoogleTvSidebar .navSidebar__tabItem{
        margin-left: 0;
    }
    .goolge_tv_waiting_room{
        &__inner{
            &__screen{
                height: 80vh;
            }
        }
    }
}
@media (max-width:1366px){
    .goolge_tv_waiting_room{
        &__inner{
            &__screen{
                height: 120vh;
            }
        }
    }
}