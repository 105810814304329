@import "../../scss/utils/variables";

.selectiontab{
    &__main{
        &__screen{
            background: linear-gradient(#30302f,#292929);
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            border-radius: 10px 10px 10px;
            height: 500px;
            width: 450px;
        }
    }   
    &__list{
        &__screen{
            margin-left: 50px;
            width: 350px;
        }
    }     
        
    
    &__headerTitle{
        font-size:20px;
        padding-top: 50px;
    }
    &__button{
        &__screen{
            width: 40%;
            
        }
    }
}