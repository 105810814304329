@import "../../scss/utils/variables";

.page10
{
    &__main
    {
        // width: 100vw;
        // height: auto;
        // background-color:rgb(43, 43, 43);
        // display:flex; padding: 10rem;
        // justify-content: center;
        // align-items: center;
        height: auto;
        padding: 10rem;
        justify-content: center;
        align-items: center;

        &__div1
        {
            background-color:rgba(160, 160, 160, 0.137);
            // width:22rem;
            height:40rem;
            margin-right: 3rem;
            padding: 2rem;

            h4
            {
                color: white;
            }
            
            &__inputfildDiv
            {
                margin-bottom: .5rem;
                label
                {
                    color: white;
                    display:  inline-flex;
                    display: inline-flex;
                    vertical-align: top;
                    margin-left: 1rem;
                }
    
                input[type="checkbox"]{
                    -webkit-appearance: initial;
                    appearance: initial;
                    background:white;
                    width: 1.5rem;
                    height: 1.5rem;
                    border: none;
                    border-radius: .1rem;
                    position: relative;
                }
                input[type="checkbox"]:checked {
                    background: rgb(11, 97, 255);
                }
    
                input[type="checkbox"]:checked:after
                 {
                   
                    content: '☓';
                    font-weight: 900;
                    color: #fff;
                    position: absolute;
                    left: 25%;
                    top: 5%;
                    
                 }
            }

            hr
            {
                width: 19em;
                height: .2rem;
                color: rgba(211, 211, 211, 0.582);
                border-radius: .5rem;
            }
            button
              {
                  background-color: white;
                  border-radius: .2rem;
                  border: none;
                  width: 100%;
                  color: rgba(66,66,66,255);
                  font-size: 13px;
                  padding-left: 1rem;
                  padding-right: 1rem;
                  padding-bottom: .5rem;
                  padding-top: .5rem;
                  font-weight: bold;
                  position:relative;
                  top: 18%;
                  
              }

            &__buttonDiv
            {
               position: relative;
               top: 150px;
              button
              {
                  background-color: white;
                  border-radius: .2rem;
                  border: none;
                  width: 9rem;
                  color: rgba(66,66,66,255);
                  font-size: 13px;
                  padding-left: 1rem;
                  padding-right: 1rem;
                  padding-bottom: .5rem;
                  padding-top: .5rem;
                  font-weight: bold;
                  
              }

              button:active
                    {

                        background-color:rgb(231, 231, 231);
                    }
             }
            }

            &__div2
            {
                    background-color:transparent;
                    width: 70rem;
                    height: 40rem;
                    // display: grid;
                    // grid-template-columns: repeat(auto-fill, minmax(240px,1fr));
                    // justify-content: center;
                    // grid-gap:2px;
                    // grid-row-gap:0px;
                    display: flex;
                    justify-content: space-evenly;
                    flex-wrap: wrap;
                    
                    &__gridbox
                    {  

                          float: left;
                        img
                        {
                            width: 20rem;
                            height: 11.7rem;
                            // border-radius: .2rem;
                            margin-bottom: -0.4rem;
                            position: relative;
                            
                        }

                        label
                        {
                            display: flex;
                            justify-content: center;
                            margin-bottom: -30px;
                            position: relative;
                            top: -25px;
                            color: white;
                        }
                    
                        
                        &__iconDiv
                        {
                            img
                            {
                                position: relative;
                                bottom: 97px;
                                width: 61px;
                                height: 55px;
                                /* right: 95px; */
                                opacity: .9;
                                float: right;
                            }
                            

                        }
                        
                    }
            }
           
        }
        
       
    }