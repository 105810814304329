@import "../../scss/utils/variables";


.domain{
    &__main{
        &__screen{
                // background-image: url('../../img/Background_imageAddons.jpg');
                background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
                background-size: 100% 100%;
                padding-top: 6rem;
                color: $white;
                width: 100%;

                .practice_name{
                    font-size: 18px;
                    line-height: 30px;
                }
        }
    }
    &__suggestion{
        width: 296px;
        margin-top: -22px;

        h4{
            margin:0px;
            font-size: 20px;
            line-height: 50px;
            color:#FFFFFF;
            font-weight: 400;

            &.total-price{
                margin-top: 5px;
            }

            span{
                color:#FF9F00;
                font-size: 50px;
                font-weight: bold;
            }
        }
        .divider{
            background-color: rgba(255,255,255,0.29);
            height: 2px;
            margin-bottom: 20px;
            margin-top: 27px;
        }
        .form-group .form-control{
            max-width: 260px;
            min-width: auto;
            margin-top: 10px;
        }
        .custom_check{

            position: relative;

            input{

                position: relative;
                height: 0px;
                width:0px;
            }

            label{
                font-size: 15px;
                margin: 0px;
                cursor:pointer;
                position: relative;
                padding-left: 28px;
            }
            .custom_check_toggle{
                display: inline-block;
                cursor:pointer;

                img{
                    max-width: 18px;
                    margin-left: 10px;
                }
            }

            label:before{
                content:"";
                height: 20px;
                width: 20px;
                position: absolute;
                left: 0;
                top:0;
                background-color:#fff;
                border-radius:2px;
            }

            input:checked + label:before{
                background: linear-gradient(359.98deg, #7287C5 0%, #6287FF 53.71%, #2EA8FF 100%);
            }
            input:checked + label:after{
                content:"";
                background-image:url('../../img/IconClose.svg');
                height: 12px;
                width: 12px;
                position: absolute;
                left: 4px;
                top: 4px;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }
    &__inner{
        padding:38px 40px;
        width: 890px;
        min-height: 726px;
        border-radius: 7px;
        background-color: rgba(255,255,255,0.08);
        margin-left: auto !important;
        margin-right: 220px !important;
        margin-top: 30px;

        &__header{

            h4{
                margin:0px 0px 15px 0;
                font-size: 20px;
                line-height: 26px;
                color:#FFFFFF;
                font-weight: 400;
            }
            p{
                font-size: 15px;
                line-height: 22px;
                color:#FFFFFF;
                margin:0px;
            }
            .link-icon{
                img{
                    height: 47px;
                    width: 57px;
                    transform: rotate(90deg);
                    margin: 3px;
                }
            }
            
        }
        &__body{

            padding:40px 0;
            // max-height: 432px;

            .button-section{
                // padding: 40px 0;
                text-align: center;
            }

            table{
                border:0px;
                text-align: left;
                margin-top: 1px;

                thead{

                    th{
                        padding: 1.75rem 0.75rem 0;
                    }
                }

                th , td{
                    border:0px;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 18px;
                    color:#FFFFFF;
                    text-transform: uppercase;
                    vertical-align: middle;
                   
                }
                // tbody{
                //     display: block;
                //     max-height: 410px;
                //     overflow-y:auto;
                //     overflow-x: hidden;
                //     padding: 0 20px;
                //     margin: 0 -20px;
                // }
                // thead, tbody tr {
                //     display: table;
                //     width: 100%;
                //     table-layout: fixed;/* even columns width , fix width of table too*/
                // }
                td{
                    text-transform: none;
                    font-weight: 300;
                    border-bottom: 1px solid rgba(255,255,255,0.3);
                    padding: 0.5rem 0.75rem;
                    height: 80px;

                    .down-arrow{
                        height:32px;
                        width: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .down-arrow img{
                        max-width: 20px;

                    }
                }
            }

            tbody{
                tr{
                    td{
                        &.button-td{
                            width: 43.5%;
                            padding: 0;

                            .domains-added{
                                max-width: 349px;
                            }
                        }
                    }
                }
            }

        }
        &__footer{
            .button-section{
                margin: 0 -10px;
                .btn{
                    // width: 167px !important;
                    // padding-right: 0;
                }
            }
            .choose-device{
                font-size: 14px;
            }
            ul{
                li{
                    display: inline-block;
                    margin: 0 8px;
                    
                    a{
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 22px;
                        color:#FFFFFF;
                        text-transform: uppercase;
                        letter-spacing: 0.5px;
                        display: block;
                        padding: 8px;
                    }
                    .activepage{
                        color:#FF9F00;
                    }
                }
            }
        }

            // .no-domain{
            //     button{
            //         margin-top: 22px !important;
            //     }
            // }

            .subscribe-btn{
                button{
                    max-width: 444px;
                }
            }

            .add-doamin-btn {
                margin-top: 30px !important;
                margin-bottom: 20px !important;
            }
    }
}