@import "../../scss/utils/variables";

.restricted_access{
    &__main{
        &__screen{
            //background-image: url('../../img/Background_imageUsers.jpg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;
            width: 100%;
        }
    }
    &__inner{
        &__text{
            color: $white;
            font-size: 2rem;
            line-height: 3rem;
            max-width: 67rem;
            padding-bottom: 5rem;
            h1{
                margin-top: -2rem;
                margin-bottom: 1rem !important;
            }
            h3{
                font-size: 2.7rem;
                font-weight: 500;
                letter-spacing: 7px;
                margin-bottom: 3rem;
            }

            p{
                margin-bottom: 4rem;
                font-size: 18px;
            }
            h1,h2,h3,h4,h5,h6{
                color: $white;
            }
        }
    }
   
}