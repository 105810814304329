@import "../../scss/utils/variables";

.addons{
    &__main{
        &__screen{
            // background-image: url('../../img/Background_imageAddons.jpg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;
            width: 100%;
        }
        
    }

    &__section{
        overflow: auto;
        max-height: 765px;
        width: 100%;
        margin-top: -159px;
    }
    &__item{
        max-width: 270px;
        margin-bottom: 30px;
        &:nth-last-child(-n+4){
            margin-bottom: 0;
        }
    }
    &__total {
        font-size: 20px;
        max-width: 298px;
        transform: translateY(-88px);
        
        span{
            font-weight: 700;
        }

        .summary-title{
            font-size: 17px;
            margin-bottom: 11px;
            line-height: 26px;
        }
    }
    &__box{
        height: 250px;
        width: 270px;
        padding: 30px;
        border-radius: 7px;
        background-color: rgba(255,255,255,0.08);
        cursor: pointer;
        position: relative;
        &:hover,&:focus{
            &::after{
                content: '';
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: 5;
                border-radius: 7px;
                box-shadow: inset  0 0 0 0.2rem $primary-color;
            }
        }
    }
    &__inner{
        &__text{
            color: $white;
            padding-bottom: 6rem;
            margin-top: -68px;
            h1{
                margin-top: 2rem;
            }
            h1,h2,h3,h4,h5,h6{
                color: $white;
            }
            .mt-auto{
                margin-top: 170px !important;
                h4{
                    margin-bottom: 18px !important;
                }
                p{

                }
            }
           
        }
    }
   
}
@media (max-width:1700px){
    .addons__total{
        font-size: 16px;
    }
}