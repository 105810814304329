
.waiting-room-header{
    display: flex;
    height: 756px;

    .header-left-side{
        width: 430px;
        height: 562px;
        margin-right: 40px;
    }

    .header-right-side{
        width: 969px;
        height: 562px;
    }
}
.Page4
{
    &__main
    {
        width: 100vw;
        height: 100vh;
        padding-top: 5rem ;
        &__div1
        {
            height: 100%;
            border-radius: .5rem;
            background: rgba(160, 160, 160, 0.137);
            position: relative;
            padding: 38px 51px;

            &__row
            {
                position: relative;
                height: 100%;

                &__upperDiv
                {
                   
                    position: relative;
                    // top: 5rem;
    
                    h1{
                        width: max-content;
                        color: white;
                        line-height: 26px;
                        margin-bottom: 15px;
                        font-size: 20px;
                    }
                    p {
                        width:  max-content;
                        color: white;
                        margin-bottom: 0px;
                        font-size: 14px;
                        line-height: 22px;
                    }
    
                    button{
                        background-color: rgba(194, 194, 194, 0.534);
                        border: none;
                        color: white;
                        border-radius: .2rem;
                        position: absolute;
                        bottom: 6px;
                        height: 40px;
                        top: inherit;
                        left: 0;
                        right: 0;
                        width: 100%;
                        text-align: center;
                    }
                }
                &__lowerDiv{
                    margin-left: 90px;
                }
                
            }
         
        }
      
        &__div2{

            background: rgba(160, 160, 160, 0.137);
            border-radius: .5rem;
            // backdrop-filter: blur( 9.5px );
            // -webkit-backdrop-filter: blur( 9.5px );
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1.5rem;
            position: relative;

            &__button{
                background-color: rgb(230, 230, 230);
                border: none;
                color: gray;
                font-weight: 500;
                position: relative;
                top: 20rem;
            }
            img{
                max-width: 100%;
                // height: 100%; 
                position: relative;
            }
            label{
                position: absolute;
                top: 46px;
                left: 67px;
                color: aliceblue;
                text-transform: uppercase;
                font-size: 19px;
                font-weight: 500;
                letter-spacing: 2.8px;
                
            }
        
        }
    }
}

@media (max-width:1700px){
    .waiting-room-header{
        max-width: 85%;
        width: 100%;
        height: auto;
        margin-bottom: auto!important;
    }
    .waiting-room-header .header-left-side{
        width: 30%;
    }
    .waiting-room-header .header-right-side{
        width: 70%;
    }
    .Page4__main__div1{
        padding: 3rem;
    }
    .Page4__main__div1__row{
        margin: 0!important;
    }
}