@import "../../scss/utils/variables";

.main{
    &__screen{
        // background-image: url('../../img/Background_imageHome.jpg');
    }
}
.caption{
    &__page{
        
    }
    
    &__inner{

        width: 1630px;

        
        &__card{

            padding:38px 40px;
            border-radius: 7px;
            background-color: rgba(255,255,255,0.08);
            min-height: 765px;
            &__header{

                padding-right:20px;

                h4{
                    margin:0px 0px 10px 0;
                    font-size: 20px;
                    line-height: 26px;
                    color:#FFFFFF;
                    font-weight: 400;
                }
                p{
                    font-size: 15px;
                    line-height: 22px;
                    color:#FFFFFF;
                    margin:0px;
                }
            }
            &__body{

                padding:40px 0;
    
                ul{
                    padding:0px;
                    margin:0px;
    
                    li{
                        display: block;
                        position: relative;
                        margin-bottom: 28px;
    
                        input{
                            position: absolute;
                            height:0px;
                            width:0px;
                            opacity: 0;
                            margin:0px;
                        }
                        label{
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            margin:0px;
                            font-size: 15px;
                            line-height: 22px;
                            font-weight: 500;
                            color:#fff;

                            .caption-square{
                                height:120px;
                                width: 200px;
                                background-color:rgba(216,216,216,0.2);
                                position: relative;
                            }
                            .caption-square:after{
                                content:"";
                                width: 120px;
                                height: 20px;
                                background-color:#fff;
                                position: absolute;
                                left: 0;
                                right: 0;
                                margin: 0 auto;
                                bottom: 20px;
                            }
                            p{
                                margin: 0 28px;
                                min-width: 140px;
                                text-align: left;
                            }
                            .caption-switch{
                                width: 60px;
                                height: 22px;
                                border-radius:20px;
                                background-color:#B3B3B3;
                                position: relative;
                                cursor: pointer;
                            }
                            .caption-switch:after{
                                content:"";
                                width: 18px;
                                height: 18px;
                                background-color:#fff;
                                position: absolute;
                                border-radius: 100%;
                                top: 2px;
                                left: 3px;
                                transition: all 0.2s ease-in-out;
                            }
                        }
                        input:checked + label .caption-switch{
                            background:#7287C5;
                        }
                        input:checked + label .caption-switch:after{
                            left: 34px;
                        }
                        input:checked + label .caption-square:after{
                            background-color:#FFB438;
                        }
                    }
                    li:nth-child(2) .caption-square:after{
                        height: 24px;
                        width: 150px;
                    }
                    li:nth-child(3) .caption-square:after{
                        height: 30px;
                        width: 170px;
                    }
                }
    
            }
            &__footer{
                .btn{
                    max-width: 100%;
                }
            }
        }


        &__preview{

            &__box{

                margin-bottom: 35px;
                position: relative;
                img{
                    max-width: 100%;
                    display: block;
                }
            }
            &__foot{
                text-align: center;
                position: absolute;
                bottom: 30px;
                width: 100%;
                left: 0;
                span{
                    display: inline-block;
                    &.__1{
                        font-size: 24px;
                    }
                    &.__2{
                        font-size: 30px;
                    }
                    &.__3{
                        font-size: 46px;
                    }
                    line-height: 1;
                    color:#fff;
                    font-weight: 500;
                    background-color:rgba(0,0,0,0.63);
                    padding: 8px 24px;
                }
            }
        }
    }
}