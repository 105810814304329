.chairside_playlist_main_screen{
    font-size: 1.5rem;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    letter-spacing: 0.5px;
    overflow-y: auto;
    max-height: 89vh;
    padding-bottom: 3rem;
    .chaiside_create_play_list_box{
        height: 266px;
        // width: 663px;
        background: #3F3F3F;
        border-radius: 7px;
        font-size: 14px;
    }
    .waiting_room_channel__main__lists__item{
        width: 100%;
    }
}

@media (max-width:1366px){
    .chairside_playlist_main_screen{
        max-height: 95vh;
    }
}