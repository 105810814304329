.Page9
{

    &__main
    {
        width: 100vw;
        height: 100vh;
        // background-color: rgb(12, 12, 12);
        display: flex;
        justify-content: center;
        align-items:center ;

        &__schedule{
            display: flex;
        }
        &__baseDiv
        {
            width: 1576PX;
            height:auto;
            border-radius: 5px;
            padding: 35px;
            overflow: auto;
            .schedule_section{
                display: flex;
            }
            &__headingDiv
            {
                h4
                {
                    color: white;
                    font-size: 20px;
                    margin-bottom: 15px;
                }

                p
                {
                    color: white;
                    max-width: 725px;
                    margin-bottom: 35px;
                }
            }

            &__timeTab
            {
               flex:1;
               
                &__twoTabDiv
                {
                    display: flex;
                    justify-content: end;
                    margin: 0 -15px;

                    hr
                    {
                        color: white;
                        height: 7rem;
                        width: .1rem;
                        display: flex;
                        align-self: center;
                        position: relative;
                        top: 30px;
                        height: 145px;
                        width: 2px;
                        right: -13px;
                        margin-right: 30px;
                        
                    }
                }
                
               
            }

            &__timeTab2
            {
                // float: right;
                // margin-top: -555px;
                // margin-right: -3px;
                flex:1;

                &__twoTabDiv
                {
                    display: flex;
                    justify-content: end;
                    margin: 0 -15px;

                    hr
                    {
                        color: white;
                        height: 7rem;
                        width: .1rem;
                        display: flex;
                        align-self: center;
                        position: relative;
                        top: 30px;
                        height: 145px;
                        width: 2px;
                        right: -13px;
                        margin-right: 30px;
                    }
                }
                
               
            }

            &__buttonDiv
            {
                &__button1
                {
                    width: 200px;
                    margin: 5px;
                    border: 0px;
                    border-radius: 2px;
                    margin-left: 30px;
                    margin-right: 20px;
                    color: darkgray;
                    font-weight: 500;
                    height: 35px;
                }

                &__button2
                {
                   
                    width: 200px;
                    margin: 5px;
                    border: 0px;
                    border-radius: 2px;
                    background-color: transparent;
                    color: darkgray;
                    border: 2px solid white;
                    font-weight: 500;
                    height: 35px;
                }
            }

        }
    }
}