@import "../../../scss/utils/variables";


.review__screen{
    &.transparent__form--white{
        width: 1250px;
        height: 810px;
        margin-top: -65px;
        
        .review-heading{
            margin-bottom: 26px !important;
        }

        .review-day{
            margin-bottom: 3px !important;
        }
        .review-form-group{
            margin-bottom: 20px !important;
            label{
                font-size: 15px;
                line-height: 18px;
                padding-top: 3px;
                padding-bottom: 6px;
            }
            span{
                font-size: 14px;
                line-height: 22px;
            }
        }

    }
}
.practice__hours_section{
    .disable__time{
        opacity: 0.4;
    }
    .practice__hours_box{
        position: relative;
        .error{
            position: absolute;
            top: -18px;
            left: 0;
        }
    }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
     opacity: 0;
     cursor: pointer;
}

.mh-755{
    min-height: 75.5rem;
}
.mh-835{
    min-height: 83.5rem;
}

.custom{

    &__multi{
        &__step{

            &__form{
                max-width: 101rem;


            }
        }
    }
}

.practice{

    &__hours_section{

        .form-heading{

            h4{
                margin-bottom: 15px !important;
            }
        }
        .w-auto{

            .justify-content-between{

                label{
                    font-size: 15px;
                    line-height: 15px;
                    margin-bottom: 6px !important;
                }
            }
            .align-items-center{
                label{
                    font-size: 15px;
                    line-height: 22px;
                }
            }

        }
    }
}