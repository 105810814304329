@import "../../scss/utils/variables";

.manage_device{
    &__main{
        &__screen{
            // background-image: url('../../img/Background_imageAddons.jpg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;
            width: 100%;
            .device_icon{
                width: 63px;
            }
        }
    }

}

.manage{
    &__suggestion{
        width: 298px;
        margin-top: -113px;
        
        p{
            color:#ffffff;
        }
        h4{
            margin:0px;
            font-size: 20px;
            line-height: 50px;
            color:#FFFFFF;
            font-weight: 400;

            span{
                color:#FF9F00;
                font-size: 50px;
                font-weight: bold;
            }

            &:first-child{
                margin-bottom: 13px;
            }
        }
        .divider{
            background-color: rgba(255,255,255,0.29);
            height: 2px;
            margin: 35px 0;
        }

        .manage_remove_suggestion{
            margin-top: 155px !important;
        }
    }
  
    &__inner{
        padding:38px 40px;
        width:1058px;
        border-radius: 7px;
        background-color: rgba(255,255,255,0.08);
        min-height: 726px;
        margin-right: 134px !important;
        margin-top: -71px;

        &__header{
            p{
                color: #ffffff;
                margin-bottom: 50px;
            }
            h4{
                margin:0px;
                font-size: 20px;
                line-height: 24px;
                color:#FFFFFF;
                font-weight: 400;
                margin-bottom: 16px;
            }
            .form-control{
                min-width: auto;
                max-width: none;
            }

           
        }
        &__body{
            max-height: 426px;
            overflow-y: auto;
            
            .authorized_device{
                button{
                    max-width: 444px;
                    margin-top: 40px;
                }
            }
            table{
                border:0px;
                text-align: left;
                thead{
                    // display: table;
                    // width: 100%;
                    // table-layout: fixed;

                    th{
                        padding: 4px 10px 4px 11px;
                        line-height: 32px;
                    }
                }
                th , td{
                    border:0px;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 33px;
                    color:#FFFFFF;
                    text-transform: uppercase;
                    vertical-align: middle;

                    &:first-child{
                        padding-left: 0;
                    }
                    &:last-child{
                        padding-right: 0;
                    }
                }
                // tbody{
                //     display: block;
                //     max-height:368px;
                //     padding: 0 20px;
                //     margin: 0 -20px;

                //     tr{
                //         display: table;
                //         width: 100%;
                //         table-layout: fixed;
                //     }
                // }
                td{
                    text-transform: none;
                    font-weight: 300;
                    border-bottom: 1px solid rgba(255,255,255,0.3);
                    padding:13px 8px 17px;
                    
                    button{

                        &.btn-md{
                            width: 167px;
                            float: right;
                        }
                       
                    }
                    img{
                        margin-right: 14px;
                    }
                    .down-arrow{
                        height:32px;
                        width: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .down-arrow img{
                        max-width: 20px;
                    }
                }
            }
        }
        &__footer{
            // margin-top: 46px !important;

            .subscribe-btn{
                .btn{
                    max-width: 444px;
                }
            }

            &__pagination{
                margin-top: 3.5rem;
                ul{
                    li{
                        display: inline-block;
                        margin: 0 14px;
                        
                        a{
                            font-size: 15px;
                            font-weight: 400;
                            line-height: 22px;
                            color:#FFFFFF;
                            text-transform: uppercase;
                            vertical-align: middle;
                            letter-spacing: 0.5px;
                            display: block;
                            padding: 8px;
                        }
                        .active{
                            color:#FF9F00;
                        }
                    }
                }
            }
           

            .additional-device-add{
                margin-top: -26px !important;

                .button-section{
                    margin-right: -12px;
                    .btn-sm{
                        max-width: 167px;
                        margin: 0 2.5px;
                    }
                }
            }

            .react-select-container{

                .react-select{

                    &__menu{
                        background-color: rgba(216, 216, 216, 0.2);
                    }

                    &__control{

                        &--menu-is-open{
                            .react-select__indicator{
                                    transform: rotate(180deg);
                            }
                        }
                    } 
                }
            }
        }
    }
}



@media (max-width:1700px){
    .manage__inner,
    .domain__inner{
        width: 70%;
        min-height: 80vh;
        margin-right: 14px !important;
    }
}