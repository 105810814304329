@import "../../scss/utils/variables";

.corporate-billing{
    &__main{
        &__screen{
            // background-image: url('../../img/Background_imageBilling.jpeg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;

            .tabs{
                margin-top: -87px;
                width: 249px;
            }
        }
    }
    
}
.corporate-billing{
    &__inner.billing-verify-code .billing__inner__body{
        padding-bottom: 40px;
    }
    &__inner.billing-verify-code .billing__inner__footer{
        p{
            margin: 0;
            font-size: 14px;
        }
    }
    &__inner{
        padding:38px 40px;
        width: 430px;
        border-radius: 7px;
        background-color: rgba(255,255,255,0.08);
        
        &__header{
            text-align: center;

            h4{
                margin:0px 0px 10px 0;
                font-size: 20px;
                line-height: 26px;
                color:#FFFFFF;
                font-weight: 400;
            }
            p{
                font-size: 15px;
                line-height: 22px;
                color:#FFFFFF;
                margin:0px;
            }
        }
        &__body{

            padding:40px 0 100px;
            overflow-y:auto;

            .user__box{

                .user__avtar{
                    height:200px;
                    width: 200px;
                    border-radius: 7px;
                    background-color: rgba(0, 0, 0, 0.26);
                    margin: 0 auto;
                    position: relative;
                    overflow: hidden;

                    img{
                        object-fit: cover;
                    }
                    span{
                        height: 30px;
                        background: linear-gradient(315deg, #2E2E2E 0%, rgba(5,5,5,0.95) 100%);
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        line-height: 30px;
                        font-weight: 500;
                        text-align: center;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
                .user__name{
                    line-height: 22px;
                    font-size: 15px;
                    font-weight: 500;
                    text-align: center;
                    margin-top: 15px;
                }
            }

            
             
        }
        &__footer{
            p{
                font-size: 15px;
                line-height: 22px;
                text-align: center;
            }
            .btn{
                max-width: 100%;
            }
            .verification-code-input{
                display: flex;
                justify-content: space-between;
                position: relative;
                margin-bottom: 20px;

                input{
                    min-width: inherit;
                    max-width: inherit;
                    width: 65px;
                    height: 80px;
                    font-size: 5.3rem;
                    font-weight: 400;
                    color: #fff;
                    background-color:rgba(216, 216, 216, 0.2);
                    text-align: center;
                }
            }
        }
    }
}
.corporate-billingcard{
    &__page{

    }
    
    &__inner{

        &__panel{
            padding:44px 50px 56px 50px;
            width: 560px;
            height: 654px;
            border-radius: 7px;
            position: relative;

            .succesfully-removed{
                background-color: rgba(53,58,72,0.7);

                p{
                    font-size: 18px;
                    font-weight: 600;
                }
            }
            .back-btn{
                button{

                    background-color: #fff !important;
                    color: #C74C43 !important;
                }
            }
            .stripe-logo{
                height: 26px;
                width: 119px;
                position: absolute;
                right: 15px;
                top: 15px;
            }

            &__header{
                position: relative;
                text-align: center;
                min-height: 172px;
                margin-top: -6px;
                h4{
                    margin:0px 0px 15px 0;
                    font-size: 20px;
                    line-height: 26px;
                    color:#FFFFFF;
                    font-weight: 400;
                }
                p{
                    font-size: 15px;
                    line-height: 22px;
                    color:#FFFFFF;
                    margin:0px;
                }
                .card-type{
                    margin-top: 22px;
                    margin-bottom: 33px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img{
                        width: 52px;
                        height:37px;
                        margin-right: 20px;
                    }
                    p{
                        font-size: 20px;
                        line-height: 26px;
                        color:#FFFFFF;
                        font-weight: 400;
                        margin:0px;
                        text-transform: capitalize;
                    }
                }
            }
  
            &__body.declined{
                border-color: $btn-danger-border;
                position: relative;
                overflow: hidden;
                padding-bottom: 60px;
                .card__declined{
                    height: 30px;
                    width: 100%;
                    position:absolute;
                    bottom: 0;
                    font-size: 1.5rem;
                    background-color: $btn-danger-border;
                    left: 0;
                }
            }
            &__body{
                margin-top: -7px;
                // height: 290px;
            }
            &__body.mastercard-bg{
                background-color: rgba(53,58,72,0.7);
                // height: 290px;
            }
            &__body.visa-bg{
                background-color: rgba(34,77,186,0.5);
                margin-top: -7px;
                // height: 290px;
            }
            &__body.amex-bg{
                background-color: rgba(0,126,205,0.4);
                margin-top: -7px;
                // height: 290px;
            }
            &__body.discovercard-bg{
                background-color: rgba(255,162,0,0.3);
                margin-top: -7px;
                // height: 290px;
            }
            &__body.isReplace{
                background-color: transparent!important;
                padding: 32px 55px!important;
            }

            &__body{

                .form-group{

                    label{
                        font-size: 14px;
                        line-height: 20px;
                        margin-bottom: 4px;
                    }
                    .text--danger{
                        font-size: 13px;
                        font-weight: 600;
                    }
                }
            }
            &__body.cc-confirmation{
                height: 290px;
                width: 460px;
                border: 2px solid #424242;
                border-radius: 16px;
                background: linear-gradient(135deg, #35A85A 0%, #4DA166 100%);
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: -8px;

                p{
                    color: #424242;
                    font-size: 19px;
                    font-weight: 500;
                    line-height: 26px;
                    text-align: center;
                    margin-top: -4px;
                }
            }
            &__body.secondary-cc-add{
                height: 290px;
                width: 460px;
                border-radius: 16px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: -7px;
            

                p{
                    color: #fff;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 26px;
                    text-align: center;
                    margin-top: 18px;
                }
            }
            &__body.remove-conform{
                height: 290px;
                width: 460px;
                border: 2px solid #FFFFFF;
                border-radius: 16px;
                background-color: rgba(53,58,72,0.7);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: -14px;

                p{
                    color: #fff;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 26px;
                    text-align: center;
                    margin-bottom: 0;
                }

                &.confirm-remove{
                    margin-top: -8px;

                    p{
                        font-size: 16px;
                        font-weight: unset;
                        line-height: 22px;
                        margin-top: 21px;
                    }
                }
            }
            &__body{
                border: 2px solid #FFFFFF;
                border-radius: 16px;
                padding:32px 55px;
                // margin-bottom: 30px;
                
                .form-group.cvv-img {

                    label{

                        position: relative;
                        padding-right: 40px;
                        display: block;

                        img{
                            width:33px;
                            height: 25px;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                        }
                    }
                    input{
                        padding-right: 50px;
                    }
                    .what-cvv{
                        height: 25px;
                        width: 23px;
                        border-radius: 2px;
                        background: linear-gradient(359.98deg, #7287C5 0%, #6287FF 53.71%, #2EA8FF 100%);
                        position: absolute;
                        text-align: center;
                        line-height: 25px;
                        right: 20px;
                        top: 34px;
                        cursor: pointer;
                        user-select: none;

                        &:hover span{
                            visibility: visible;
                            opacity: 1;
                            transform: scale(1);
                        }
                        span{
                            height: 90px;
                            width: 215px;
                            border-radius: 2px;
                            background: linear-gradient(359.98deg, #7287C5 0%, #6287FF 53.71%, #2EA8FF 100%);
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            overflow: hidden;
                            padding: 8px 10px;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            transform: scale(0);
                            transform-origin: bottom right;
                            visibility: hidden;
                            opacity: 0;
                            transition: all 0.2s ease-in-out;

                            img{
                                height:44px;
                                width:60px;
                                margin-right: 8px;
                            }
                            p{
                                font-size: 12px;
                                line-height: 18px;
                                margin:0px;
                                text-align: left;
                            }
                        }
                    }

                }
                .form-group.icon-card input{
                    padding-right: 50px;
                }
                .form-group.icon-card .icon-img{
                    height: 25px;
                    width: 35px;
                    position: absolute;
                    top: 34px;
                    right: 20px;
                }
                .form-group input{
                    min-width: auto;
                    max-width: 100%;
                }
                 
            }
            &__footer{
                // padding-top: 36px;
                button{
                    transform: translateY(1px);
                }
                p{
                    font-size: 15px;
                    line-height: 22px;
                    text-align: center;

                    a{
                        color:#FF9F00;
                    }
                }
            }
        }
    }
}