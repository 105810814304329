@import "../../scss/utils/variables";

.main {
  &__screen {
    background: linear-gradient(
      315deg,
      rgba(40, 40, 40, 0.93) 0%,
      rgba(0, 0, 0, 0.8835) 100%
    ) !important;
    .channels__item:last-child {
      position: relative;
      // margin-left: 60px;

      // &::after {
      //   content: "";
      //   position: absolute;
      //   left: -40px;
      //   top: 70px;
      //   bottom: 0;
      //   width: 1px;
      //   height: 107px;
      //   background-color: rgba(255, 255, 255, 0.6);
      // }
    }
  }
}
.channel_detail {
  &__page {
    position: relative;
    z-index: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-top: 6rem;
    color: #fff;
    width: 100%;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: linear-gradient(
        90deg,
        #000000 0%,
        #000000 28.34%,
        rgba(0, 0, 0, 0) 100%
      );
    }
    .channel-detail {
      margin: -324px 0 auto 92px;
      textarea.form-control {
        width: 717px;
        min-height: 98px;
        background: rgba(209, 209, 209, 0.2);
        border: 2px solid #ff9f00;
        box-sizing: border-box;
        color: $white;
      }
      h2 {
        font-size: 36px;
        color: #fff;
        font-weight: 500;
        margin-bottom: 28px;
      }
      .duration {
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 20px;
        display: block;
        letter-spacing: 0.7px;
      }
      .detail {
        max-width: 640px;
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 40px;
      }

      .channel-detail-btn {
        .btn-primary {
          // width: 216px;
          margin-right: 20px;
        }
        .cc-btn {
          // height: 33px !important;
          // width: 52px;
          // font-size: 21px;
          // line-height: 23px;
        }
        .edit-btn {
          color: #424242 !important;
          padding: 6px 13px !important;
          outline: none;
          &.edit-active {
            color: #fff;
          }
        }
      }
    }

    .header--sub {
      padding: 15px 18px 13px;

      .practice_name {
        font-size: 19px;
        letter-spacing: 0.2px;
      }
    }

    .channel_detail_swiper {
      padding: 45px 0 45px 72px;
      margin: 0 -20px;
      background-color: rgba(0, 0, 0, 0.26);
      backdrop-filter: blur(6px);

      .swiper-button-prev {
        color: #fff;
        background-color: rgba(255, 255, 255, 0.08);
        left: 0px !important;
        backdrop-filter: blur(6px);
        height: 100%;
        width: 171px;
        top: 0;
        bottom: 0;
        margin-top: 0;
        &.swiper-button-disabled {
          opacity: 0;
        }
      }
      .swiper-button-next {
        color: #fff;
        background-color: rgba(255, 255, 255, 0.08);
        right: 0px !important;
        backdrop-filter: blur(6px);
        height: 100%;
        bottom: 0;
        width: 171px;
        top: 0;
        margin-top: 0;
        &.swiper-button-disabled {
          opacity: 0;
        }
      }
      .swiper-slide {
        width: 248px;
        height: 140px;
        margin: 0 20px;

        .channel_detail_item {
          position: relative;
          &:after {
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0.0001) 0%,
              rgba(0, 0, 0, 0.6) 100%
            );
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            content: "";
            width: 100%;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-height: 140px;
          }
          label {
            position: absolute;
            left: 0;
            right: 0;
            font-size: 13px;
            bottom: 10px;
            margin-bottom: 0;
            text-align: center;
            z-index: 2;
          }
        }

        &:hover {
          transform: translateY(-13px) scale(1.13);
          transition: 0.3s ease-in-out;
          background: linear-gradient(315deg, #ffb438 0%, #ff9f00 100%);
          padding: 1px;
        }
      }
    }
  }
  &__item {
    &__divider {
      background-color: rgba(255, 255, 255, 0.6);
      height: 107px;
      width: 1px;
      top: 54px;
      position: relative;
    }
  }
  &__box {
    height: 250px;
    width: 270px;
    border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.08);
    cursor: pointer;
  }
  &__name {
    text-transform: uppercase;
  }
}
.addLeftMargin {
  margin-left: 20px !important;
}
.makeEditButtonActive {
  background: #ff9f00 !important;
}

.editBoxCss {
  // position: absolute !important;
  // width: 717px !important;
  // height: 98px !important;
  // left: 90px !important;
  // top: 411px !important;

  background: rgba(209, 209, 209, 0.2) !important;
  border: 2px solid #ff9f00 !important;
  box-sizing: border-box !important;
  margin-bottom: 25px !important;
  min-height: 90px !important;
}

.addMarginTop {
  margin-top: 20px !important;
}

.activeCC {
  color: #000000 !important;
  background: #ffffff !important;
}


@media (max-width:1700px){
  .main__screen{
    min-height: 100vh;
    height: 100%;
  }
}