@import "../../scss/utils/variables";

.tos{
    &__main{
        &__screen{
            // background-image: url('../../img/Background_imageUpdates.jpeg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
        }
    }
    &__inner{
        &__screen{
            background-color: #fff;
            border-radius: 7px;
            max-height: 83.5rem;
            height: 100%;
            width: 100%;
            max-width: 78rem;
            padding: 3.5rem 4rem;
            margin-top: 59px;
        }
        &__text{
            overflow: auto;
            height: 563px;
            position: relative;
            h2,h3,h4,h5,h6{
                margin-bottom: 1.5rem;
                padding-top: 1.5rem;
            }

            .scrollbar-container {
                // height: auto!important;
                .ps__rail-y{
                    .ps__thumb-y{
                        // height: 109px !important;
                    }
                }
            }
        }
    }
    &__not_accepted__form{
        width: 100%;
        max-width: 80rem;
        max-height: 80rem;
        height: 100%;
       
        h4{
            color: $white;
            margin-bottom: 2rem;
        }
        .tos-na-content{
            max-width: 556px;
        }
    }
    &__success__message{
        color: $white;
        h1{
            color: $white;
            margin-bottom: 7px;
            font-size: 49px;
        }
    }
}


@media (max-width:1700px){
    .tos__main__screen{
        min-height: 100vh;
    }
    .tos__inner__text{
        height: 80vh;
    }
}