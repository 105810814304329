.loader{
    position: absolute;
    // background-color: rgba(0,0,0,0.70);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    backdrop-filter: blur(2px);
}


.goolge_tv__loader{
    .spinner-border{
        height: 6rem;
        width: 6rem;
        border: 0.40em solid currentColor;
        border-right-color: transparent;
    }
}