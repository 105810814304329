.add_profile_picture{
    margin-top: 76px;

    .upload-footer{
        margin: 0 -10px;
        button{
            margin-top: 30px;
            margin: 0 10px;
            flex: 1;

            &.btn-danger{
                max-width: 180px;
                margin-top: 30PX;
            }

            &.btn-primary{
                // max-width: 269px;
            }
        }
    }
    h4{
        font-size: 19px;
    }
    .transparent__form--dark{
        max-width: 560px;
    }
    .profile_picture_img_box{
        height: 360px;
        background-color: rgba(10,10,10,1);
        .ReactCrop:not(.ReactCrop--active){
            .ReactCrop__crop-selection{
                // left: 50%!important;
                // top: 50%!important;
                // -webkit-transform: translate(-50%, -50%)!important;
                // transform: translate(-50%, -50%)!important;
            }
        }
        &.round {
            .ReactCrop{
                div{
                    border-radius: 100%;
                }
            }
        }
        .ReactCrop{
            div{
                height: 100%;
                // border-radius: 100%;
                .ReactCrop__image{
                    display: block;
                    max-width: 100%;
                    max-height: fill-available;
                    // height: 100%;
                    // margin: 0 auto;
                    object-fit: contain;
                }
            }
        }
    }

    .transparent__form--white{
        background-color: rgb(60 60 60 / 52%);
    }
}
.modal-content{
    border: 0;
}