.about{

    &__screen{

        .header{
            margin-bottom: -7px !important;
        }

        .align-items-center {
            margin-bottom: 11px !important;
        }

        .border-bottom{
            margin-bottom: 39px !important;
            margin-top: 27px !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        .unsecure-profile{
            margin-top: 198px !important;

            h4{
                font-size: 20px;
            }

            p{
                font-size: 16px;
                max-width: 350px;
                line-height: 22px;
            }
        }
    }
}