// b,
strong , .font-weight-bold{
    font-family: $SFProSemibold;
    font-weight: 500!important;
}
.h-auto{
    height: auto!important;
}
.mr-20{
    margin-right: 2rem;
}
.mb-20{
    margin-bottom: 2rem!important;
}
.ml-20{
    margin-left: 2rem;
}
.mx-20{
    margin-left: 2rem;
    margin-right: 2rem;
}
.px-20{
    padding-left: 2rem;
    padding-right: 2rem;
}
.pr-20{
    padding-right: 2rem!important;
}
.pl-20{
    padding-left: 2rem!important;
}
.py-20{
    padding-top: 2rem!important;
    padding-bottom: 2rem!important;
}
.px-7_5{
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.mw-350{
    max-width: 350px;
}
.mw-680{
    max-width: 68rem;
}
.mw-519{
    max-width: 519px;
}
.mw-300{
    width: 100%;
    max-width: 300px;
}
.fs-11{
    font-size: 11px;
}
.fs-20{
    font-size: 2rem;
}
.fs-14{
    font-size: 14px;
}
.fs-50{
    font-size: 50px;
}
.text{
    &--danger{
        color: #FF3F34;
    }
    &--primary{
        color: $primary-color;
    }
}

.border{
    &--right{
        border-right:1px solid rgba(216,216,216,0.3);
    }
    &--left{
        border-left:1px solid rgba(216,216,216,0.3);
    }
    &--bottom{
        border-bottom:1px solid rgba(216,216,216,0.3);
    }
    &--top{
        border-top:1px solid rgba(216,216,216,0.3);
    }
}