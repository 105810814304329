@import "../../scss/utils/variables";

.PrivateTabIndicator-colorSecondary-4 {
    background-color: white!important;
}
.MuiTab-wrapper{
    text-transform:none!important;
}

.Operatory{
  &__main{
      &__screen{
          //background-image: url('../../img/Background_imageNew_channel.jpg');
          background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
          background-size: 100% 100%;
          padding-top: 6rem;
          color: $white;
          .Page6__main__right-side{
            min-width: 1160px;
            .create_operatory_screen{
              min-width: 1160px;
              background-repeat: no-repeat;
              background-size: cover;
              justify-content: center;
              min-height: 652px;
            }
          }

          &--theme__lists{
            min-height: 163px;

            h3{
              color: #fff;
            }
          }
          &--slide{
            min-width: 1160px;
            background-image: url('../../img/bg.png');
            background-repeat: no-repeat;
            background-size: cover;
            justify-content: center;
            min-height: 652px;

            &--innerbox{
              padding: 40px;
            }
            &--footer{

                label{
                  background-color: white;
                  color: black;
                  font-weight: 800;
                  font-size: 17px;
                  padding: 1px 17px;
                  margin-bottom: 0;
                }

                h3{
                  font-size: 3rem;
                  background-color:rgb(212, 77, 77) !important;
                  width: 100%;
                  height: 100%;
                  padding: 9px 16px;
                  color: #fff;
                  margin: 0;
                }

                &--text{
                  font-size: 2rem;
                  background-color: #fff;
                  color: #424242;
                  padding: 11px !important;
                  p{
                    font-size: 2rem;
                    color: #424242;
                    padding: 0;
                  }
                }
            }
          }
          &--practice_name{
            color: $white;
          }

      }
      &__inner_screen{
        max-width: 1630px;
        margin-top: 5rem !important;
      }
    }
    
    &__show_footer , &__show_header , &__show_timeline{
        border-bottom: 1px solid #727272;
      padding-bottom: 50px;
      margin-bottom: 50px;
      width: 100%;
      .channel_detail{
        // margin-top: -3px;
        // margin-left: 6px;
        .timeline-title{
          font-weight: 600;
        }
      }
    }
    &__show_header{
      padding-top: 50px;
    }
    &__show_timeline{
      border: none;
      margin-bottom: 0;
    }
}
.new_room{
    &__main{
        background: rgba( 208, 208, 208, 0.10 );
        backdrop-filter: blur( 3.0px );
        // -webkit-backdrop-filter: blur( 3.0px );
        border-radius: 5px;
        // width: 50vw;
        // height: auto;
        width:800px;
        height:auto;
        margin-top: 47px;
        // display: flex;
        justify-content: center;
        align-self:center;
        padding: 40px;  
        height: 770px;
    }
    &__headingText{
        font-size: 2rem;
        text-align: left;
        padding: 0px;
        color: azure;
        margin-bottom: 15px;
    }
    &__subheadingText{
        font-size: 1.5rem;
        padding:0px;
        // margin-left: -200px;
        color: rgba(240, 255, 255, 0.863);
        text-align: left;
        margin-bottom: 25px;
    }
    &__channelName {
        // text-align: left;
        background-color: rgba(179, 179, 179, 0.281);
        font-size: 1.1rem;
        margin-top: 0px;
        border: 0px;
        color:white;
        padding: 8px;
        width: 350px;
        height: 40px;
    }
    &__hr{
        background-color: rgba(161, 161, 161, 0.644);
        width:100%;
    }
    &__textArea{
        max-width: 90%;
        min-height: 30rem;
        border-radius: 2px;
        background-color: rgba(255, 255, 255, 0.96);
        color: #424242;
        padding: 2rem;
        margin-left: 19px;
    }
}
.Operatory__main__screen--theme__lists{
  h3{
    color: $white;
  }
  .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before{
    border-color: #fff;
    background-color: #fff;
  }
}
.theme{
  &__box{
    .custom-checkbox{
      max-width: 200px;
      .custom-control-label{
        padding: 0;
      }
        &.sm .custom-control-label:before{
          top: 5px;
          left: 5px;
          margin-top: 0;
        }
        &.sm input:checked + .custom-control-label:after{
          top: 12px;
          left: 8px;
        }
    }
  }
}

.Page6{

  &__main{

    &__screen-slide{

      .operatory_room_innerbox{
        min-height: 652PX;
        padding:40px 53px 37px;
      }

    }

    &__left-slide{

      .channel-name{
        // margin-top: -72px;
      }
      .channel-body{
        // margin-top: -38px;
    }
   
      .channel-checkbox{
        margin-top: 15px;
      }
      &__div1{

        &__footer{

          &__footerRow{

            &__button{
              margin-bottom: 20px ;
            }
          }
        }

        &__body{
          margin-top: 15px;
          .channel_detail{
            margin-top: 0px;
            position: relative;
            label{
              font-size: 14px;
              letter-spacing: 0.8px;
              margin-bottom: 1px;
            }
            .btn{
              text-transform: none;
              padding: 5px 15px;
              background: rgba(111, 111, 111, 0.8);
              border-radius: 3px;
            }
            p{
              font-size: 11px;
              color: #C5C5C5;
              margin-bottom: 0;
            }
          }
          &__header{
            margin-bottom: 27px;
          }

          .show-channel-checkbox{
            // margin-top: -10px;
            margin-bottom: 15px ;
          }



          .Operatory__show_img{
            max-width: 145px;
            width: 100%;
            // margin-bottom: 12px;
          }
          .Operatory_show_checkbox{
            position: relative;
            // width: 104px;
            margin-bottom: 10px;


            .custom_checkbox{
              position: absolute;
              top: -8px;
              right: 0;

              .MuiSwitch-root{
                width: 40px;
                height: 22px;

                .MuiSwitch-thumb{
                  width: 18px;
                  height: 18px;
                }
              }
            }
          }
        }
        &__header{
           h6{
            font-size: 19px;
            font-weight: 400;
            letter-spacing: 0.4px;
           }
        }
      }

      .transparent__form--white{
        // min-height: 850px;
        padding: 42px 40px 46px;
      }
    }
  }
}




// label
// {
//   color:white;
// }

.form-switch {
    display: inline-block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    // position: fixed;
    margin-left: 20px;
    padding: 0px;
  }
  
  .form-switch i {
    position: relative;
    display: inline-block;
    margin-right: .5rem;
    width: 46px;
    height: 26px;
    background-color: #e6e6e6;
    border-radius: 23px;
    vertical-align: text-bottom;
    transition: all 0.3s linear;
  }
  
  .form-switch i::before {
    content: "";
    position: absolute;
    left: 0;
    width: 42px;
    height: 22px;
    background-color: #fff;
    border-radius: 11px;
    transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
    transition: all 0s linear;
  }
  
  .form-switch i::after {
    content: "";
    position: absolute;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: #fff;
    border-radius: 11px;
   
    transform: translate3d(2px, 2px, 0);
    transition: all 0.1s ease-in-out;
  }
  
  .form-switch:active i::after {
    width: 28px;
    transform: translate3d(2px, 2px, 0);
  }
  
  .form-switch:active input:checked + i::after { transform: translate3d(16px, 2px, 0); }
  
  .form-switch input { display: none; }
  
  .form-switch input:checked + i { background-color: #6087fd; }
  
  .form-switch input:checked + i::before { transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0); }
  
  .form-switch input:checked + i::after { transform: translate3d(22px, 2px, 0); }

  .ScreenC{

    &__main
    {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background-image: url('../../img/bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        justify-content: center;
        margin: 0px;
        align-items: flex-end;

        &__labelUpper
        {
          display: flex;
          align-self: flex-start;
          margin-top: 1em;
          font-weight: 600;
          margin-top: -4px;
          color: #fff;
        }



}
}

.footer
{
background-color:white;
font-size:1em;
p
{
padding: 0.3em;
}
}
#news
{
background-color:white;
font-size:1em;
color:black;
padding:.2em 1em;
}
.flexFont {
height:8em;
width:95%;
height:fit-content;

padding:1%;

}

.smaller {
font-size: 1em;
width: 100%;
height:100%;
font-weight:800;
padding: 0.3em;
}
.operatory-room { background: #000; }


@media (max-width:1700px){
  .Operatory__main__inner_screen{
      max-width: 85%;
      width: 100%;
      margin: 30px 0;
  }
  .Operatory__main__left-slide{
  }
  .Operatory__main__screen .Page6__main__right-side{
    max-width: 70%;
    min-width: auto;
    width: 100%;
  }
  .Page6__main__left-slide .transparent__form--white{
    padding: 2rem;
    height: auto!important;
  }
  .Page6__main__left-slide__div1__header h6{
    line-height: normal;
  }

  .Operatory__show_header{
    padding-top: 2rem;
  }

  .Operatory__show_footer, 
  .Operatory__show_header, 
  .Operatory__show_timeline{
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }

  .Page6__main__left-slide__div1__body .Operatory__show_img{
    max-width: 108px;
  }

  .operatory__channel__priview_iframe{
    height: auto!important;
    width: 100%!important;
    border: 0px;
    transform: scale(1)!important;
    transform-origin: 0px 0px;
    aspect-ratio: 9/6;
  }
}
