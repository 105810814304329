
@import "../../../../scss/utils/variables";
.hide  {
  display: 'none'
}
.active {
  color:#ffa819
}

.navGoogleTvSidebar{
  position: absolute;
  left:0px;
  top: 0;
  bottom: 0;
  width: 140px;
  padding: 0 15px;
  overflow: hidden;
  z-index: 999;
  &__inner{
    position: relative;
    padding:0 0 6rem 6rem;
  }
  h2{
    color: #fff;
    font-weight: 400;
    margin-left: 50px;
    white-space: nowrap;
  }
  .navSidebar__tabItem{
    // position: absolute;
    width: 100%;
    margin-left: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }
  .MuiTabs-scroller{
    z-index: 99999;
  }
  .MuiTabs-indicator {
    display: none
  }
  .MuiTabs-vertical{
    .MuiTabs-flexContainer{
      &:last-child{
        &:hover{
          .sidebartwo{
            display: block !important;
          }
        }
      }
    }
    button.MuiTab-root{
        padding: 13px 12px;
        position: relative;
        min-height: auto;
        line-height: 23px;
        opacity: 1;
        margin: 10px 0;
        .navIcons{
          width: 72px;
          min-width: 72px;
          margin-right: 40px;
          svg{
          }
        }
        
        &.Mui-selected{
          #playlistIcon{
            path{
              fill:#FFB438;
            }
          }
          #IconSearch{
            path{
              fill:#FFB438;
            }
          }
          #allChannel,#SearchIcon,#newChannels,#myChannels,#SettingIcon{
            path{
              fill:#FFB438;
            }
          }
          #HomeIcon, #addMyListIcon{
            g{
              fill:#FFB438;
            }
          }
          #specializationsIcon{
            path{
              fill:#FFB438;
            }
          }
          svg,g {
            // fill:#FFB438;
            // stroke:#FFB438;
          }
          .tabname{
            color: #ffa819;
          }
        }


    }

  }
  
  .MuiTab-wrapper{
    font-size: 20px;
    display: flex;
    align-items:flex-start;
    flex-direction: row;
    justify-content:left;
    align-items: center;
    .tabname{
        white-space: nowrap;
        transition: transform .1s;
        text-align: left;
        width: calc(100% - 30px);
        font-size: 28px;
        font-weight: 400;
        color:#fff;
        opacity: 0;
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
    }
  }
  .MuiSvgIcon-root{
    margin-bottom: 0!important;
  }
  &::after{
    content: '';
    width: 430px;
    height: 100%;
    -webkit-transition: all .1s ease;
    -moz-transition: all .1s ease;
    -ms-transition: all .1s ease;
    -o-transition: all .1s ease;
    transition: all .1s ease;

    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.26);
    backdrop-filter: blur(40px);
    -webkit-transform: translateX(-430px);
    -moz-transform: translateX(-430px);
    -ms-transform: translateX(-430px);
    -o-transform: translateX(-430px);
    transform: translateX(-430px);
  }
  
  &.activeNav{
    width: 430px;
    .sidebar__btn , .lookingvideo{
      opacity: 1;
      visibility:visible;
    }
    .sidebar__btn{
      bottom: 15px;
    }
    .navSidebar__inner{
      z-index: 99999;
    }
    .MuiTabs-vertical{
      .tabname{
        opacity: 1;
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px);
      }
      button.MuiTab-root{
        width: 100%;
        min-width: 100%;
      }
    }
    &::after{
      width: 430px;
      z-index: 9999;
      -webkit-transform: translateX(0px);
      -moz-transform: translateX(0px);
      -ms-transform: translateX(0px);
      -o-transform: translateX(0px);
      transform: translateX(0px);
    }
    .sidebartwo{
      display: block;
      z-index: 99999;
    }
  }
}


@media (max-width:1700px){
  .navGoogleTvSidebar .navSidebar__tabItem{
    margin-left: 0px;
  }
}