@import "../../scss/utils/variables";

.forgot_access_code{
    &__main{
        &__screen{
            // background-image: url('../../img/Background_imageHome.jpeg');
            background-size: 100% 100%;
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            .practice_list{
                padding: 38px 40px;
                max-width: 890px;
                width: 100%;
                height: 830px;
                border-radius: 7px;
                margin-left: auto !important;
                margin-top: 30px;
                margin: 0 auto;
                border: 1px solid rgb(255 255 255 / 16%);
                overflow: hidden;
                overflow-y: scroll;

                table{

                    thead{

                        th, td{
                            border: 0px;
                            font-size: 15px;
                            font-weight: 400;
                            line-height: 18px;
                            color: #FFFFFF;
                            text-transform: uppercase;
                            vertical-align: middle;
                            padding: 1.75rem 0 0.75rem 0;
                        }

                       
                    }
                    tbody{
                        // height: 300px;
                        overflow:hidden;
                        overflow-y: scroll;
                        
                        tr{

                            td{
                                color: #FFFFFF;
                                font-weight: 300;
                                border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                                padding: 0.75rem 0 0.75rem 0;
                                // line-height: 37px;
                                vertical-align: middle;
                                border-top:0;
                                
                            }

                            &:last-child{

                                td{
                                    border-bottom: 0;
                                }
                            }
                        }
                       
                    }
                }
            }
        }
    }
    &__inner{
        &__screen{
            // background: linear-gradient(315deg, #2E2E2E 0%, rgba(5,5,5,0.95) 100%);
            border-radius: 10px;
            max-height:90rem;
            height: 100%;
            width: 100%;
            max-width: 111rem;
            // padding: 3.5rem 4rem;
            // backdrop-filter: blur(15px);
        }
        &__text{
            color: $white;
            width: 100%;
            max-width: 68rem;
            font-size: 2rem;
            h1{
                margin-bottom: 2rem;
                margin-top: 2rem;
            }
            p{
                margin-bottom: 3.5rem;
            }
            h3{
                font-size: 2.7rem;
                font-weight: 500;
                letter-spacing: 7px;
                margin-bottom: 3rem;
            }
            h1,h2,h3,h4,h5,h6{
                color: $white;
            }
        }
        &__enter_info{
            max-width: 35rem;
            width: 100%;
            color: $white;
            h1,h2,h3,h4,h5,h6{
                color: $white;
            }
        }
    }
}


@media (max-width:1700px){
    .forgot_access_code__inner__screen{
        max-width: 70%;
        max-height: 70%;
    }
}