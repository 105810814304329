@import "../../scss/utils/variables";

.react-select-container{
	// min-width: 350px;
	max-width: 350px;
	width: 100%;
	&.w-260{
		max-width: 260px;
		min-width: 260px;
		.react-select__control{
			max-width: 260px;
			min-width: 260px;
		}
	}
	&.w-77{
		max-width: 77px;
		min-width: 77px;
		.react-select__control{
			max-width: 77px;
			min-width: 77px;
		}
	}
	&.bg_white{
		.react-select__control{
			background-color: #fff;
		}
	}
	.react-select__indicator-separator {
		display: none;
	}
	&.react-select__multi{
        .react-select__control{
			padding: 0.4rem 1rem 0.4rem 0.6rem;
            height: auto;
            min-height: 4rem;
			max-height: 15rem;
			overflow-y: auto;
            .react-select__clear-indicator{
                display: none;
            }
			.react-select__indicator{
				margin-bottom: auto;
				margin-top: 12px;
			}
		}
		.react-select__value-container{
			padding:0px; 
		}
	}
	.react-select__value-container{
		padding: 0 4px 0 0; 
	}
	&.react-select__tagStyle{
		.react-select__option{
			display: inline-block;
			overflow: unset;
			width: auto;
			border: none!important;
			border-radius: 0;
		}
	} 
	&.select-style-2{
		.mr-3{
			margin-right: 0!important;
		}
		.react-select__value-container{
			padding: 0;
		}
		.react-select__control{
			border: none;
			min-height: auto;
			min-width: 8rem;
		}
		.react-select__menu{
			min-width: 128px;
			right: 0;
		}
	}
	&.select-style-3{
		
	}
	&.select-border-danger{
		.react-select__control{
			border-color: $color-danger!important;
		}
	}
	&.bg__yellow{
		.react-select__control{
			background-color: rgba(255, 159, 0, 0.15);
			min-width: auto;
			&--is-disabled{
				background-color: rgba(255, 159, 0, 0.15)!important;
				opacity: 0.5;
			}
		}
	}
	&.invalid-field{
		box-shadow:none;
		.react-select__control{
			box-shadow: inset 0 0 0 0.2rem #d47074;
		}
	}
	.react-select{
		&__menu{
			margin-top: -1px;
			box-shadow: none;
            background-color:rgba(46, 46, 46, 1);
            background-position: 0 0;
            background-repeat: no-repeat;
			z-index: 5;
            /* Fallback */
            // background-image: url('../../img/dropdown__bg.jpeg');
        
            /* CSS gradients */
            // background-image: url('../../img/dropdown__bg.jpeg'), 
            //                     -moz-linear-gradient(45deg, rgba(5,5,5,0.95) 0%, rgba(46,46,46,0.95) 100%);
            // background-image: url('../../img/dropdown__bg.jpeg'), 
            //                     -webkit-linear-gradient(45deg, rgba(5,5,5,0.95) 0%, rgba(46,46,46,0.95) 100%);
            // background-image: url('../../img/dropdown__bg.jpeg'), 
            //                     -o-linear-gradient(45deg, rgba(5,5,5,0.95) 0%, rgba(46,46,46,0.95) 100%);
            // background-image: url('../../img/dropdown__bg.jpeg'), 
            //                     -ms-linear-gradient(45deg, rgba(5,5,5,0.95) 0%, rgba(46,46,46,0.95) 100%);
            // background-image: url('../../img/dropdown__bg.jpeg'), 
            //                     linear-gradient(45deg, rgba(5,5,5,0.95) 0%, rgba(46,46,46,0.95) 100%);
            backdrop-filter: blur(15px);
			border: 0;
			overflow: hidden;
			border-radius: 0px;
		}
		
		&__control{
			font-size: 1.5rem;
            font-weight: 400;
            color: $white;
            height: 40px;
            min-width: 350px;
			max-width: 350px;
            width: 100%;
            border-radius: 0px;
            background-color: rgba(216,216,216,0.2);
            border: 0;
            padding: 0.1rem 1rem;
			
			&:hover{
				border-color:#D3D3D3;
				box-shadow: none;
			}
			&--is-focused{
				border-color:#D3D3D3;
				box-shadow: none;
				// box-shadow: inset  0 0 0 0.2rem $primary-color;
			}
			&--is-disabled{
				background-color: rgba(216, 216, 216, 0.2)!important;
				opacity: 0.5;
			}
			&--menu-is-open{
				.react-select__indicator{
					
				}
			}
			&--is-disabled{
				background-color: #fff;
			}
			.react-select__value-container{
				padding: 0 4px; 
			}
		}
		
		&__value-container{
			padding-right: 12px;
			padding-left: 12px;
			.react-select__input{
				color: $white;
			}
		}
		&__single-value{
			color:$white;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			margin:0;
			font-size:1.5rem;
		}
		&__placeholder{
			color:$white;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&__multi-value{
            background: linear-gradient(359.98deg, #6287FF 0%,  #2EA8FF 100%);
			color: $white-color;
			margin:2px 4px;
			&__remove{
				&:hover{
					background-color: transparent;
					color: $white;
					cursor: pointer;
				}
			}
			&__label{
				color: $white-color;
				font-size: 1.4rem;
			}
		}
        
		&__indicator{
			padding: 0;
			svg{
				transition: 0.2s ease;
			}
		}
		&__menu-list{
			padding:0;
			max-height: 200px;
		}
		&__option{
			font-family: $body-font;
			font-size: 1.4rem;
			padding:1rem 1.5rem;
			color: $white;
			border:0px solid transparent;
			line-height: 1;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			&:first-child{
				border-top: 0px solid #e9ebed;
				border-top-left-radius: 0px;
				border-top-right-radius: 0px;
			}
			&:last-child{
				border-bottom: 0px solid #e9ebed;
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
			}
			&--is-selected{
                background: linear-gradient(359.98deg, #6287FF 0%,  #2EA8FF 100%);
				color: #fff;
				border: 0px solid #126BD6!important;
			}
			&--is-focused{
                background: linear-gradient(359.98deg, #6287FF 0%,  #2EA8FF 100%);
				color: #fff;
				border: 0px solid #126BD6!important;
			}
		}
	}
}

.react-datepicker-popper{
	z-index: 9!important;
}

.w-104{
    &.react-select-container .react-select__control{
        min-width: 10.4rem;
    }
}

.w-101{
    &.react-select-container .react-select__control{
        min-width: 7.7rem;
    }
}

.domain{

	&__inner{

		&__footer{

			
	.react-select{
		&__menu{
            background-color:rgba(216, 216, 216, 0.2);
			height: 160px;
			overflow-y: auto;
		}
		
		
		&__value-container{
			padding-right: 12px;
			padding-left: 12px;
			.react-select__input{
				color: $white;
			}
		}
		&__single-value{
			font-size: 16px;
		}

		&__option{

			padding:1.2rem 1.5rem;

			&:first-child{
				border-top: 0px solid #e9ebed;
				border-top-left-radius: 0px;
				border-top-right-radius: 0px;
			}
			&:last-child{
				border-bottom: 0px solid #e9ebed;
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
			}

		}
	}
		}
	}
}

.card_brand__img{
	max-width: 35px;
}