@import "../../scss/utils/variables";

.verify_identity_box{
    &.transparent__form--white{
        min-height: 675px;
        max-width: 429px;
        margin-top: -72px;
        min-width: 410px;

        .verify-heading{
            margin-bottom: 38px !important;

            h4{
                margin-bottom: 20px;
            }
        }
    }
    .user__profile{
        order: initial;
        margin-bottom: 17px !important;

        &__box{
            margin-bottom: 13px !important;
        }
    }

    .entercode{
        max-width: 350px;
        label{
            font-size: 14px !important;
        }
    }

    .send-code{

        p{
            transform: translateY(10px);
        }
    }
}



@media (max-width:1700px){
    .verify_identity_box.transparent__form--white{
        min-height: unset;
        height: unset;
        .h-100{
            min-height: 55vh;
        }
    }
}

@media (max-width:1366px){
    .verify_identity_box.transparent__form--white{
        min-height: unset;
        height: unset;
        .h-100{
            min-height: 72vh;
        }
    }
}