@import "../../scss/utils/variables";

.main {
  &__screen {
    // background-image: url("../../img/Background_imageHome.jpg");
  }
}
.assign_manager_sucess{
  border: 2px solid #35A85A;
}
.notification {
  &__page {

    &.add_manage_location{

      .add_manager_section{
        margin-left: 322px !important;
        table{
          tbody{
              tr{
                th{
                  &:nth-child(2){
                    padding: 18px 12px !important;
                  }
                }
               td{
                 
                padding: 18px 12px !important;
                &:first-child{
                  padding-left: 0px !important;
                }
                 &:last-child{
                   padding-right: 8px !important;
                 }

               }
              }
          }
        }
      }
      .tabs{
        //  margin-top: -81px;
         width: 247px;

         ul{

          li{
            label{
              font-size: 18px;
            }
          }
         }
      }

      .notification{

        &__inner{
          min-height: 805px;
          max-height: 805px;
          margin-top: 0px;
        }
      }

      .practice_name {
        font-size: 18px;
        line-height: 32px;
      }

      .notification{

        &__inner{

          &__body{

            &.add_manage_table{
              table{

                tbody{
                    tr{
                     td{
                      padding: 13px 20px 19px 22px !important;
                      &:first-child{
                        padding-left: 0px !important;
                      }
                       &:last-child{
                         padding-right: 0px !important;
                       }
                     }
                    }
                }
              }
            }
          }
        }

      }
    }
  }
  &__inner {
    padding: 38px 40px;
    width: 1274px;
    border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.08);
    min-height: 785px;
    max-height: 785px;
    &__header {
      h4 {
        margin: 0px;
        font-size: 20px;
        line-height: 26px;
        color: #ffffff;
        font-weight: 400;
      }
      h3 {
        font-size: 50px;
        line-height: 50px;
        color: #ffffff;
        margin: 0px;
      }
    }
    &__body {
      padding: 40px 0;
      .collapse:not(.show) {
        height: 20px !important;
        overflow: hidden;
        display: block;
      }
      .collapsing {
        min-height: 20px !important;
      }
      table {
        border: 0px;
        text-align: left;
        tbody {
          display: block;
          max-height: 410px;
          overflow-y: auto;
          overflow-x: hidden;
          padding: 0 20px;
          margin: 0 -20px;
        }
        thead,
        tbody tr {
          display: table;
          width: 100%;
          table-layout: fixed; /* even columns width , fix width of table too*/
        }
        th,
        td {
          border: 0px;
          font-size: 15px;
          font-weight: 400;
          line-height: 22px;
          color: #ffffff;
          text-transform: uppercase;
          vertical-align: middle;
        }
        td {
          text-transform: none;
          font-weight: 300;
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
          padding: 2rem 0.75rem;
          vertical-align: top;
          .down-arrow {
            height: 32px;
            width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.4s ease;
            &.active_arrow {
              transform: rotateZ(-180deg);
            }
          }
          .down-arrow img {
            max-width: 20px;
          }
        }
      }
      .notification__empty {
        height: 511px;
        background-color: #ffffff;
        border-radius: 5px;

        h4 {
          font-size: 36px;
          line-height: 43px;
          color: #424242;
          margin: 0px 0px 20px 0;
        }
        p {
          font-size: 15px;
          line-height: 22px;
          color: #424242;
          font-weight: normal;
          margin: 0px;
        }
      }

      &.add_manage_table{
        max-height: 497px;

        table{
          tr{
            td{
              line-height: 27px !important;
              padding:18px 20px 21px 20px !important;

              &:last-child{
                padding-right: 0 !important;
              }

              &:nth-child(2){
                padding: 0.75rem !important;
              }
            }
          }
        }

      }

    }
    &__footer {

      ul {
        li {
          display: inline-block;
          margin: 0 8px;

          a {
            font-size: 15px;
            font-weight: 400;
            line-height: 22px;
            color: #ffffff;
            text-transform: uppercase;
            vertical-align: middle;
            letter-spacing: 0.5px;
            display: block;
            padding: 8px;
          }
          .activepage {
            color: #ff9f00;
          }
        }
      }

      &.add_manage_footer{
        margin-bottom: 24px;

        button{
         max-width: 444px !important;
        }
     }
    }

    .add_manage_header{

      position: relative;
      h4{
        margin-bottom: 20px;
        cursor: pointer;
      }
      .form-group{
          label{
            font-size: 14px;
            line-height: 38px;
            margin-bottom: -4px;
          }
      }

      .location_icon{
        position: absolute;

        img{
          width: 59px;
        }
      }
    }
  }
  &__inner.practice-manager {
    width: 450px;

    .practice__inner__header {
      h4 {
        margin: 0px 0px 24px 0;
        font-size: 20px;
        line-height: 26px;
        color: #ffffff;
        font-weight: 400;
      }
      p {
        font-size: 15px;
        line-height: 22px;
        color: #ffffff;
        margin: 0px;
      }
    }
    .practice__inner__body {
      padding: 40px 0;

      .form-control {
        min-width: auto;
        max-width: 100%;
      }
    }
  }
}
