
@import "../../scss/utils/variables";
.hide  {
  display: 'none'
}
.active {
  color:#ffa819
}

.navSidebar{
  position: absolute;
  left:0px;
  top: 120px;
  bottom: 0;
  width: 85px;
  padding: 0 15px;
  overflow: hidden;
  z-index: 99;
  &__inner{
    position: relative;
    padding:0 0 6rem 0;
  }
  .navSidebar__tabItem{
    position: absolute;
    top: 165px;
  }
  .lookingvideo{
    // top: -180px;
    visibility: hidden;
  }
  .sidebar__btn , .lookingvideo{
   opacity: 0;
   position: absolute;
   left: 0;
   right: 0;
  }
  .MuiTabs-scroller{
    z-index: 99999;
  }
  .MuiTabs-vertical{
    // margin-top: 250px;
    // top: 250px;

    .MuiTabs-flexContainer{
      &:last-child{

        &:hover{
  
          .sidebartwo{
            display: block !important;
          }
        }
      }
    }
    button.MuiTab-root{
        // width: 85px;
        padding: 13px 12px;
        position: relative;
        min-height: auto;
        line-height: 23px;
        .navIcons{
          width: 30px;
          margin-right: 19px;
          svg{
          }
        }
        
        &.Mui-selected{
          .savedVideo{
            path{
              fill:#FFB438;
            }
          }
          #Specialisation{
            
          }
          #IconSearch, #chirsidePlaylist{
            path{
              fill:#FFB438;
            }
          }
          svg,g {
            fill:#FFB438;
            stroke:#FFB438;
          }
          .tabname{
            color: #FFB438;
          }
        }


    }

  }
  
  .MuiTab-wrapper{
    font-size: 20px;
    display: flex;
    align-items:flex-start;
    flex-direction: row;
    justify-content:left;
    align-items: center;
    .tabname{
        white-space: nowrap;
        // padding-left: 15px;
        transition: transform .5s;
        text-align: left;
        width: calc(100% - 30px);
        font-size: 20px;
        font-weight: 400;
        color:rgba(255,255,255,0.6);
        opacity: 0;
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
    }
  }
  .MuiSvgIcon-root{
    margin-bottom: 0!important;
  }
  &::after{
    content: '';
    width: 345px;
    height: 100%;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;

    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.26);
    backdrop-filter: blur(40px);
    -webkit-transform: translateX(-345px);
    -moz-transform: translateX(-345px);
    -ms-transform: translateX(-345px);
    -o-transform: translateX(-345px);
    transform: translateX(-345px);
  }
  .sidebartwo{
    display: none;
    padding-left: 85px;
    position: relative;
    margin-top: 0;
    li{
      color: rgba(255,255,255,0.6);
      text-indent: -10px;
      padding: 6px 10px;
      cursor: pointer;
      &.activecetegory{
        color: #fff;
      }
      &::before{
        width: 5px;
        margin-left:0;
      }
    }
  }
  &.activeNav{
    width: 345px;
    .sidebar__btn , .lookingvideo{
      opacity: 1;
      visibility:visible;
    }
    .lookingvideo{
      // top: 50px;
    }
    .sidebar__btn{
      bottom: 15px;
    }
    .navSidebar__inner{
      z-index: 99999;
    }
    .MuiTabs-vertical{
      .tabname{
        opacity: 1;
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px);
      }
      button.MuiTab-root{
        width: 100%;
      }
    }
    &::after{
      width: 345px;
      z-index: 9999;
      -webkit-transform: translateX(0px);
      -moz-transform: translateX(0px);
      -ms-transform: translateX(0px);
      -o-transform: translateX(0px);
      transform: translateX(0px);
    }
    .sidebartwo{
      display: block;
      z-index: 99999;
    }
  }
}

@media (max-width:1700px){
  .navSidebar__inner{
    overflow-y: auto;
    overflow-x: hidden;
  }
}