@import "../../scss/utils/variables";

.color_scheme{
    height: 80px;
    width: 170px;
    margin-top: 10px;
    cursor: pointer;
    border-style: none;

   
  

    &__scheme-button{
       
        text-align: center;
        padding-top: 10px;
        color: $white;
        font-size: 13px;
        
    }
    &__check-box{
      margin-left: 5px;

    }
    &__checkbox_image{
      height: 12px;
      margin-left: 5px;
    }
    &__subscription-button{
      font-size: 10px;
      text-align: center;
      color: $white;
      font-weight: 900;
    }
    
}



.active{
  border-style: solid;
  border-color: $yellow;
}
.subscription{
  pointer-events: none;
     opacity: 0.5;
     background: #CCC;
}
.selected-image{
  width: 21px;
  margin-left: 10px;
  margin-top: 10px;
  /* margin-left: 10px;
  margin-top: 10px; 
  width: 21px;
  height: 17px; */
  position: absolute;
}
.card{
  width: 25rem;
  //height: 20rem;
  background-color:transparent;
  
}
.sub-title{
  color: white;
  top: 150px;
  /* margin-top: 100px; */
  font-size: 12px;
  position: absolute;
  

  text-align: center;
}


.swiper-button-prev:after, .swiper-button-next:after{
  font-family: swiper-icons;
    font-size: 25px!important;
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
}
.dropdown-chevron{
  .btn-md{
    height: 41px;
  }
}
.cc_status_btn_text{
  text-transform: uppercase;
  font-size: 12px;
}

// .modal-video{
//     backdrop-filter: blur(8px);
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: 1050;
//     display: none;
//     width: 100%;
//     height: 100%;
//     overflow: hidden;
//     outline: 0;
//     background-color: rgba(0,0,0,0.6);
// }
// .bg-none.modal-video{
//   position: relative;
// }
// .modal-video .video-show {
//   transform: translate(0, -50px);
//   transition: transform 0.3s ease-out;
//   width: 1600px;
//   margin: 0 auto;
// }
