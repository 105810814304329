@import "../../scss/utils/variables";


.language{
    &__main{
        &__screen{
            //background-image: url('../../img/Background_imageLanguages.jpg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            width: 100%;
            color: $white;
        }
    }
    &__list{
        width:  1080px;//820px;
        min-height: 619px;
        margin-top: 88px;
    }
    &__system {
        width: 430px;
        min-height: 619px;
        margin-top: -69px;
        justify-content: space-between;
    }
    &__inner{

        &__header{
            padding-right:20px;

            h4{
                margin:-2px 0px 14px 0;
                font-size: 20px;
                line-height: 26px;
                color:#FFFFFF;
                font-weight: 400;
            }
            p{
                font-size: 15px;
                line-height: 22px;
                color:#FFFFFF;
                margin:0px;
            }
        }
        &__body{
            margin-top: 21px !important;
            .system-language{
                margin-bottom: -5px;
            }
            p{
                margin-bottom: 9px;
            }
            .custom_radio_select{
                padding:0px;
                margin:0px;

                li{
                    display: block;
                    position: relative;
                    input{
                        position: absolute;
                        height:0px;
                        width:0px;
                        opacity: 0;
                        margin:0px;
                    }
                    label{
                        display: block;
                        margin:0px;
                        font-size: 30px;
                        line-height: 50px;
                        font-weight: 500;
                        color:rgba(255,255,255,0.20);
                        cursor: pointer;
                    }
                    input:checked + label{
                        color:rgba(255,255,255,1);
                    }
                }
            }

        }
        &__footer{

            ul{
                li{
                    display: inline-block;
                    margin: 0 8px;
                    
                    a{
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 22px;
                        color:#FFFFFF;
                        text-transform: uppercase;
                        vertical-align: middle;
                        letter-spacing: 0.5px;
                        display: block;
                        padding: 8px;
                    }
                    .active{
                        color:#FF9F00;
                    }
                }
            }
        }
    }
}