@import "../utils/variables";

.dropdown{
    &-header{
        font-size: 1.5rem;
    }
    &-menu{
        // height: 398px;
        width: 278px;
        font-size: 1.5rem;
        min-width: 278px;
        margin-top: 0;
        border: none;
        border-radius: 0;
        color: $white;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        background-image:linear-gradient(315deg, #2E2E2E 0%, rgba(5,5,5,0.95) 100%) , url('../../img/dropdown_menu_bg.jpg');
    }
    &.show{
        .menu__btn{
            background: linear-gradient(315deg, #2E2E2E 0%, rgba(5,5,5,0.95) 100%);
            border-radius: 0;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
        }
    }
    &-item{
        color: $white;
    }
}
.modal{
    backdrop-filter: blur(8px);
    .modal-xxl{
        max-width: 1600px;
    }
}
.modal-content{
    background-color: transparent;
}

// .add_profile_picture__screen{
//     max-width: 1540px;
//     width: 100%;
//     .transparent__form--dark{
//         max-width: 560px;
//     }
//     .profile_picture_img_box{
//         height: 360px;
//         background-color: rgba(10,10,10,0.68);
//     }
// }