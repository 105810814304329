.editor {
  position: relative;
}

.mainEditor {
  height: 100%;
}

.resizer {
  /* All resizers are positioned absolutely inside the element */
  position: absolute;
}
/* Placed at the right side */
.resizer-r {
  cursor: col-resize;
  height: 100%;
  right: 0;
  top: 0;
  width: 5px;
}
.resizer-l {
  cursor: col-resize;
  height: 100%;
  left: 0;
  top: 0;
  width: 5px;
}

/* Placed at the bottom side */
.resizer-b {
  bottom: 0;
  cursor: row-resize;
  height: 5px;
  left: 0;
  width: 100%;
}

.resizer-t {
  top: 0;
  cursor: row-resize;
  height: 5px;
  left: 0;
  width: 100%;
}

// .editor{
//     width: 960px;
//     display: 'table'
// }
.main-container {
  // display: table-row;
  height: 1080px;
  // width: 100%;
  width: 1920px;
  background-color: white;
  // display: flex;
}
.container {
  width: 480px;
  display: table-cell;
}
.moveable-container {
  border-style: dashed;
  border-width: thin;
  padding: 10px;
  color: black;
  position: absolute; //relative;
  z-index: 999;
  // height: 200px;
  // width: 200px;
}
.slide_image_active{
  border-color: #ffc107;
}
.moveable-button {
  cursor: move !important;
  font-size: 65px;
  position: absolute;
  right: 0px;
  top: -70px;
  border: none;
  background: none;
}
.moveable-text-container { // #moveable
  transform: translate3d(70px, 50px, 0px);
  min-width: 400px;
  min-height: 400px;
  max-height: 1000px;
  max-width: 1800px;
  width: 800px;
}
.moveable-image-container { // #moveable_image
  // transform: translate3d(1220px, 50px, 0px);
  // min-width: 400px;
  // min-height: 400px;
  // max-height: 1000px;
  // max-width: 1800px;
  height: 400px;
  width: 400px;
  cursor: move;
}
.blank_title {
  font-size: 60px;
}
.editable_image {
  width: 100%;
  height: 100%;
}
.footer-tabs {
  .Mui-disabled {
    color: white !important;
    background-color: gray !important;
  }
}
.transition_tab {
  .transition_active {
    background: #ff9f00;
    color: white;
  }
}
.toggle_active {
  background: #ff9f00 !important;
  color: white !important;
}


.imageLoading::after {
  content: '';
  position: absolute;
  width: 60px;
  height: 60px;
  border:  solid 4px transparent;
  border-top-color:  #FFFFFF !important;
  border-left-color: #FFFFFF !important;
  border-radius: 50%;
  animation: loading-bar-spinner 400ms linear infinite;
  overflow: hidden;
  top: 50%;
  left: 50%;
  margin-left: -30px;
  margin-top: -30px;
}

.imageLoading::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0, 0.5);
}

@keyframes loading-bar-spinner {
0%   { transform: rotate(0deg);   transform: rotate(0deg); }
100% { transform: rotate(360deg); transform: rotate(360deg); }
}
