@import "../../scss/utils/variables";

.main{
    &__screen{
        // background-image: url('../../img/Background_imageHome.jpg');
    }
}
.email{
    &__page{

    }
    &__inner{
        padding:38px 40px;
        width: 430px;
        height:785px;
        border-radius: 7px;
        background-color: rgba(255,255,255,0.08);
        margin-top: -16px;

        &__header{

            h4{
                margin:4px 0px 15px 0px;
                font-size: 20px;
                line-height: 26px;
                color:#FFFFFF;
                font-weight: 400;
            }
            p{
                font-size: 15px;
                line-height: 22px;
                color:#FFFFFF;
                margin:0px;
            }
        }
        &__body{

            padding:24px 0;
            overflow-y:auto;

            .email_check_list{
                li{
                    position: relative;
                    margin-bottom: 21px;

                    .custom_inner_check{       
                        padding-left: 28px;
                        padding-top: 12px;
                        padding-bottom: 0;
                    }

                    .custom_check{
                        position: relative;
                        input{
                            position: relative;
                            height: 0px;
                            width:0px;
                        }
                        label{
                            font-size: 15px;
                            margin: 0px;
                            cursor:pointer;
                            position: relative;
                            padding-left: 41px;
                        }
                        .custom_check_toggle{
                            display: inline-block;
                            cursor:pointer;
                            img{
                                max-width: 18px;
                                margin-left: 10px;
                            }
                        }
                        label:before{
                            content:"";
                            height: 20px;
                            width: 20px;
                            position: absolute;
                            left: 0;
                            top:0;
                            background-color:#fff;
                            border-radius:2px;
                        }
                        input:checked + label:before{
                            background: linear-gradient(359.98deg, #7287C5 0%, #6287FF 53.71%, #2EA8FF 100%);
                        }
                        input:checked + label:after{
                            content:"";
                            background-image:url('../../img/IconClose.svg');
                            height: 12px;
                            width: 12px;
                            position: absolute;
                            left: 4px;
                            top: 4px;
                            background-size: contain;
                            background-repeat: no-repeat;
                        }
                    }
                    .down-arrow{
                        width: 22px;
                        transition: all 0.4s ease;
                    }
                    .active_arrow{
                        .down-arrow{
                            transform: rotateZ(-180deg);
                        }
                    }
                }
            }
             
        }
        &__footer{
            
            .btn{
                max-width: 100%;
            }
        }

        &.assign_manager{
            margin-top: 0;
            height: 805px;
            position: relative;
            margin-right: 479px !important;
            .assign_manager_header{

                h4{
                    margin: 2px 0px 15px 0px;
                    font-size: 19px;
                }

            }
            .assign_manager_body{

                .form-group{

                    label{
                            font-size: 14px;
                    }
                }
            }
            .assign_manager_footer{
                position: absolute;
                left: 40px;
                right: 40px;
                bottom: 40px;
            }
        }
    }
}

@media (max-width:1700px)
{
    .email__inner.assign_manager{
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin: 0!important;
        height: 90vh;
    }
}