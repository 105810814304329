// File: theme.scss
// Override default BT variables:
// $grid-columns:      12;
$grid-gutter-width: 20px;

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 1200px,
  // Extra large screen / wide desktop
  xl: 1920px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

// $spacer: 1rem !default;
// $spacers: () !default;
// // stylelint-disable-next-line scss/dollar-variable-default
// $spacers: map-merge(
//   (
//     0: 0,
//     1: ($spacer * .25),
//     2: ($spacer * .5),
//     3: $spacer,
//     4: ($spacer * 1.5),
//     5: ($spacer * 2.6)
//   ),
//   $spacers
// );


$brand-color:#F25555;
$primary-color:#FF9F00;
$secondary-color:#272727;

$theme-colors: (
    primary: #FF9F00
);


$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$grays: () !default;
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #D0393E  !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: () !default;
$colors: map-merge(
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
  ),
  $colors
);

$primary:       $brand-color;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);

$link-color:#157cf7;
$light-grey-color:#68737c;


$btn-primary:#F25555;
$btn-primary-border:#F25555;
$btn-primary-color:#424242;

$btn-default:#272727;
$btn-default-color:#FFFFFF;
$btn-default-border:#272727;



$btn-gray-color:#E0E0E0;

$btn-red:#FF3F34;
$btn-red-border:#E13D34;

$btn-danger:#DD4132;
$btn-danger-border:#DD4132;

$btn-warning:#F0B532;
$btn-warning-text-color:#1C2852;

$btn-success:#79C753;

$dark-color : #272727;  
$title-color : #424242;
$grey-color : #434343;
$white-color:#fff;
$black-color:#000;
$bg-color: #edeff1;
$color-gray:#757575;
$bg-light-red:#F0B532;

$color-danger:#FF3F34;
$color-warning:#FFA400;
$color-success: #59A600;


$main-font-color:#424242;
$menu-link-color:#b1b1b1;
$border-color:#d3d3d3;

$link-hover:darken($brand-color,10%);


$body-font :  'Roboto', sans-serif;
$title-font:  'Roboto', sans-serif;
$SFProSemibold: 'Roboto', sans-serif;
$SFProMedium:  'Roboto', sans-serif;
$SFProTextBold: 'Roboto', sans-serif;
$SFProTextRegular: 'Roboto', sans-serif;

