@import "../../scss/utils/variables";

.signin{

    &__main{

        &__screen{
            background: #000;
        }
    }

    &__inner{

        &__screen{
            margin-top: -80px;

            h1{
                margin-bottom: 3px !important;
            }
            p{
                margin-bottom: 12px;
            }


        }
    }
}
.new_profile{
    &__main{
        &__screen{
            //background-image: url('../../img/Background_imageUsers.jpg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;
            width: 100%;
            .transparent__form--white{
                &.about__screen{
                    max-width: 410px;
                    min-height: 820px;
                }
            }
            .add_profile_picture__screen{
                max-width: 1535px;
                width: 100%;
                .transparent__form--white{
                    max-width: 560px;
                }
                .profile_picture_img_box{
                    height: 360px;
                    background-color: rgba(10,10,10,0.68);
                    .ReactCrop{
                        div{
                            height: 100%;
                            border-radius: 100%;
                            .ReactCrop__image{
                                display: block;
                                max-width: 100%;
                                max-height: fill-available;
                                // height: 100%;
                                // margin: 0 auto;
                                // object-fit: contain;
                            }
                        }
                    }
                }
            }

            .header--sub{
                // margin-bottom: 60px !important;
            }
        }
    }
    &__section{
        overflow: auto;
        max-height: 820px;
        max-width: 980px;
        width: 100%;
    }
    &__img {
        width: 200px;
        height: 200px;
        max-width: 200px;
        &:nth-of-type(4n + 4){
            margin-right:0 ;
        }
    }
    &__box{
        height: 200px;
        width: 100%;
        border-radius: 7px;
        background-color: rgba(0,0,0,0.26);
        z-index: 2;
        position: relative;
        backdrop-filter: blur(39px);
        overflow: hidden;
        cursor: pointer;
        &:hover{
            &::after{
                content: '';
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: 5;
                border-radius: 7px;
                box-shadow: inset  0 0 0 0.2rem $primary-color;
            }
            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
                border-radius: 0 0 7px 7px;
            }
        }
    }
    &__picture{
        .default_img{
            max-width: 108px;
            height: auto;
        }
        img{
            object-fit: cover;
            width: 100%;
            height: 200px;
        }
    }
    &__inner{
        &__screen{
           
        }
        &__text{
            color: $white;
            font-size: 2rem;
            line-height: 3rem;
            max-width: 67rem;
            h1{
                margin-top: 2rem;
            }
            h3{
                font-size: 2.7rem;
                font-weight: 500;
                letter-spacing: 7px;
                margin-bottom: 3rem;
            }
            h1,h2,h3,h4,h5,h6{
                color: $white;
            }
        }
    }
   
}


@media (max-width:1700px){
    .new_profile__section{
        gap:2rem;
    }
    .new_profile__main__screen{
        overflow-y: auto;
        min-height: 100vh;
    }
    .new_profile__main__screen .add_profile_picture__screen{
        max-width: 90%;
        margin: 0 auto;
    }
    .new_profile__main__screen .transparent__form--white.about__screen{
        min-height: auto;
    }
    .new_profile__picture img,
    .new_profile__img,
    .new_profile__box{
        height: 150px;
        width: 150px;
    }
    .new_profile__img{
        width: 150px;
        max-width: 150px;
        margin: 0!important;
    }
    .new_profile__img:nth-of-type(4n + 4){
        // margin-right: 1rem!important;
        // margin-left: 1rem!important;
    }
    .new_profile__main__screen .add_profile_picture__screen .profile_picture_img_box{
        height: 250px;
    }
    .new_profile__main__screen .add_profile_picture__screen .transparent__form--white{
        padding: 2rem;
    }
    .about__screen .border-bottom-2{
        margin-bottom: 20px !important;
        margin-top: 0px !important;
    }
    .footer-btn button{
        margin-top: 1px!important;
    }
}