@import "../../scss/utils/variables";

.waitingRoom__main__screen__footer_option{
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
    &--time_weather{
        min-width: 121px;
    }

    .footer_message{
        padding: 0 52px 30px 40px;
    }
}