@import "../../scss/utils/variables";

.edit_profile{
    &__main{
        &__screen{
           // background-image: url('../../img/Background_imageUsers.jpg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;
            width: 100%;

            .footer-save-button{
                margin-top: 40px !important;
            }
            .transparent__form--white{
                max-width: 560px;
                width: 100%;
                &.bg_red{
                    background: linear-gradient(135deg, #D0393E 0%, #D54A53 100%);
                }
                &.about__screen{
                    max-width: 410px;
                    min-height: 820px;
                }
            }
            .add_profile_picture__screen{
                .profile_picture_img_box{
                    height: 360px;
                    background-color: rgba(10,10,10,0.68);
                    .ReactCrop{
                        div{
                            height: 100%;
                            border-radius: 100%;
                            .ReactCrop__image{
                                display: block;
                                max-width: 100%;
                                max-height: fill-available;
                            }
                        }
                    }
                    &.bg_white {
                        background-color: rgba(#ffffff,0.07);
                    }
                }
            }
        }
    }
    &__section{
        overflow: auto;
        max-height: 820px;
        max-width: 980px;
        width: 100%;
    }
    &__img {
        width: 200px;
        height: 200px;
        max-width: 200px;
        &:nth-of-type(4n + 4){
            margin-right:0 ;
        }
    }
    &__box{
        height: 200px;
        width: 100%;
        border-radius: 7px;
        background-color: rgba(0,0,0,0.26);
        z-index: 2;
        position: relative;
        backdrop-filter: blur(39px);
        overflow: hidden;
        cursor: pointer;
        &:hover{
            &::after{
                content: '';
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: 5;
                border-radius: 7px;
                box-shadow: inset  0 0 0 0.2rem $primary-color;
            }
            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
                border-radius: 0 0 7px 7px;
            }
        }
    }
    &__picture{
        .default_img{
            max-width: 108px;
            height: auto;
        }
        img{
            object-fit: cover;
            width: 100%;
            height: 200px;
        }
    }
    &__inner{
        &__screen{
           
        }
        &__text{
            color: $white;
            font-size: 2rem;
            line-height: 3rem;
            max-width: 67rem;
            h1{
                margin-top: 2rem;
            }
            h3{
                font-size: 2.7rem;
                font-weight: 500;
                letter-spacing: 7px;
                margin-bottom: 3rem;
            }
            h1,h2,h3,h4,h5,h6{
                color: $white;
            }
        }
    }
   
}

@media (max-width:1700px){
    .edit_profile__main__screen .transparent__form--white.about__screen{
        min-height: 760px;
    }
    .edit_profile__main__screen{
        zoom: 0.9;
    }
}