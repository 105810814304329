@import "../../scss/utils/variables";

.active_profiles{
    &__main{
        &__screen{
            // background-image: url('../../img/Background_imageUsers.jpg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;
            width: 100%;

            .active_profiles__section{
                overflow: auto;
                max-height: 765px;
                max-width: 1281px;
                width: 100%;
                // justify-content: center;
                // min-height: 680px;
            }
        }
    }
    &__total{
        width: 300px;
        margin-bottom: auto !important;
        justify-content: start !important;
        margin-top: 58px !important;
        padding-left: 21px !important;
        span{
            font-weight: 700;
        }
        .restriction-message{
            margin-bottom: 20px !important;
            max-width: 264px;
        }
        .remove-message{
            max-width: 250px;
        }
        .verifyed{
            color: #424242 !important;
        }

        &.border{

            &--bottom{

                margin-top: 23px !important;
                margin-bottom: 37px !important;
                
            }
        }
    }
    &__inner{
        &__text{
            color: $white;
            padding-bottom: 6rem;
            margin-top: -40px;

            .user__profile:nth-last-child(-n+5){
                margin-bottom: 0!important;
            }
            h1{
                margin-top: 2rem;
                font-size: 51px;
                // margin-bottom: -10px !important;
            }
            h3{
                font-size: 2.7rem;
                font-weight: 500;
                letter-spacing: 7px;
                margin-bottom: 3rem;
            }
            h1,h2,h3,h4,h5,h6{
                color: $white;
            }
        }
    }
}

@media (max-width:1700px){
    .active_profiles__inner__text h1{
        margin-bottom: 3rem!important;
    }
    .active_profiles__total{
        padding: 0 15px!important;
        min-width: 222px
    }
}