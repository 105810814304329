@import "../../scss/utils/variables";

.color_scheme{
    height: 80px;
    width: 170px;
    margin-top: 10px;
    cursor: pointer;
    border-style: none;

   
  

    &__scheme-button{
       
        text-align: center;
        padding-top: 10px;
        color: $white;
        font-size: 13px;
        
    }
    &__check-box{
      margin-left: 5px;

    }
    &__checkbox_image{
      height: 12px;
      margin-left: 5px;
    }
    &__subscription-button{
      font-size: 10px;
      text-align: center;
      color: $white;
      font-weight: 900;
    }
    
}



.active{
  border-style: solid;
  border-color: $yellow;
}
.subscription{
  pointer-events: none;
     opacity: 0.5;
     background: #CCC;
}