.pagethree{
    &__main{
        // background-color: black;
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
       
        &__parent-div{
            background: rgba(179, 176, 176, 0.445);
            height: 77rem;
            width: 43rem;
            padding:29px;
            border-radius: .5rem;
            border: 2.5px solid green;
            display: flex;
            justify-content: space-around;
            align-content: space-between;
            margin-top: 76px;



            &__div1
            {
                position: relative;
                top: 18rem;
                padding: 2rem;
                color: honeydew;
                text-align: center;
            }
            &__div2
            {
              position: relative;
              bottom: 1.5rem;

              &__button
              {
                  background-color: rgb(226, 139, 25);
                  border: none;
                  color: black;
                  font-weight: 500;
                  width: 100%;
                  height: 40px;
                  bottom: -5px;
                  border-radius: 2px;
                  max-width: 100%;
              }
            }
        }
       
       
    }
}
