@import "../../scss/utils/variables";

.main{
    &__screen{
        // background-image: url('../../img/Background_imageHome.jpg');
    }
}
.invoice{
    &__page{

    }
    &__suggestion{
        width: 320px;
        position: absolute;
        right: 0;
        height: 100%;
        top: 0;
        bottom: 0;
        h4{
            margin:0px;
            font-size: 20px;
            line-height: 26px;
            color:#FFFFFF;
            font-weight: 400;
        }
        .divider{
            background-color: rgba(255,255,255,0.29);
            height: 2px;
            margin: 35px 0;
        }
        .form-group .form-control{
            max-width: 260px;
            min-width: auto;
            margin-top: 10px;
        }
        .custom_check{

            position: relative;

            input{

                position: relative;
                height: 0px;
                width:0px;
            }

            label{
                font-size: 15px;
                margin: 0px;
                cursor:pointer;
                position: relative;
                padding-left: 20px;
            }
            .custom_check_toggle{
                display: inline-block;
                cursor:pointer;

                img{
                    max-width: 18px;
                    margin-left: 10px;
                }
            }

            label:before{
                content:"";
                height: 14px;
                width: 14px;
                position: absolute;
                left: 0;
                top:4px;
                background-color:#fff;
                border-radius:2px;
            }

            input:checked + label:before{
                background: linear-gradient(359.98deg, #7287C5 0%, #6287FF 53.71%, #2EA8FF 100%);
            }
            input:checked + label:after{
                content:"";
                background-image:url('../../img/IconClose.svg');
                height: 8px;
                width: 8px;
                position: absolute;
                left: 3px;
                top: 7px;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }
    &__inner.invoice-view{
        width: 860px;
        padding: 20px;
        background-color:rgba(255,255,255,0.08);
        height: 100%;
        min-height: 850px;
    }
    &__inner{
        padding:38px 40px;
        width: 890px;
        border-radius: 7px;
        background-color: rgba(255,255,255,0.08);
        min-height: 705px;
        
        &__header{

            h4{
                margin:0px;
                font-size: 20px;
                line-height: 26px;
                color:#FFFFFF;
                font-weight: 400;
            }
            .form-control{
                min-width: auto;
                max-width: none;
            }
            .year-change{

                display: flex;
                align-items: center;
                justify-content: flex-end;

                .year-change-arrow{
                    margin-right: 10px;

                    button{
                        padding: 8px;
                        transition: all 0.2s ease-in-out;

                        .arrow-right{
                            transform: rotateY(-180deg);
                        }
                    }
                    button:hover{
                        opacity: 0.6;
                    }
                }

                h3{
                    font-size: 50px;
                    line-height: 50px;
                    color:#FFFFFF;
                    margin:0px;
                }
            }
        }
        &__body{

            .no-invoice-box{
                height: 100%;
                background-color: #fff;
                min-height: 511px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;

                h2{
                    font-size: 36px;
                    margin-bottom: 15px;
                }
                p{
                    color: #424242;
                    text-align: center;
                    font-size: 15px;
                }
            }
            padding:40px 0;

            table{
                border:0px;
                text-align: left;
                thead{
                    display: table;
                    width: 100%;
                    table-layout: fixed;
                }
                th , td{
                    border:0px;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 22px;
                    color:#FFFFFF;
                    text-transform: uppercase;
                    vertical-align: middle;
                }
                tbody{
                    display: block;
                    max-height: 410px;
                    overflow-y: auto;
                    overflow-x: hidden;
                    padding: 0 20px;
                    margin: 0 -20px;

                    tr{
                        display: table;
                        width: 100%;
                        table-layout: fixed;
                        td:first-child{
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            padding-right: 1.5rem;
                        }
                    }
                }
                td{
                    text-transform: none;
                    font-weight: 300;
                    border-bottom: 1px solid rgba(255,255,255,0.3);
                    padding:1.5rem 0.75rem;
                    white-space: nowrap;
                    .down-arrow{
                        height:32px;
                        width: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .down-arrow img{
                        max-width: 20px;

                    }
                }
            }

            .invoice__empty{
                height:511px;
                background-color:#FFFFFF;
                border-radius: 5px;

                h4{
                    font-size: 36px;
                    line-height: 43px;
                    color:#424242;
                    margin:0px 0px 20px 0;
                }
                p{
                    font-size: 15px;
                    line-height: 22px;
                    color:#424242;
                    font-weight: normal;
                    margin:0px;
                    max-width: 532px;
                    text-align: center;
                }
            }
        }
        &__footer{

            ul{
                li{
                    display: inline-block;
                    margin: 0 8px;
                    
                    a{
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 22px;
                        color:#FFFFFF;
                        text-transform: uppercase;
                        vertical-align: middle;
                        letter-spacing: 0.5px;
                        display: block;
                        padding: 8px;
                    }
                    .active{
                        color:#FF9F00;
                    }
                }
            }
        }

        .invoice-box{
            
            display: flex;
            align-items: stretch;
            background-image: url('../../img/InvoicesViewBG.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            max-height: 840px;
            overflow: hidden;
            overflow-y: scroll;

            .invoice-left{
                background-color:rgba(0,0,0,0.26) ;
                flex:0 0 240px;
                max-width: 240px;
                padding-top: 58px;
                padding-bottom: 58px;
                padding-left: 20px;
                padding-right: 10px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;

                .invoice-head{

                    .invoice-logo{
                        max-width: 196px;
                    }
                    .invoice-date{
                        font-size: 12px;
                        line-height: 21px;
                        margin: 6px 0 0 35px;
                    }
                    .invoice-number{
                        margin-top: 33px;
                        position: relative;
                        padding-left: 35px;

                        img{
                            height: 20px;
                            width: 19px;
                            position: absolute;
                            left: 0;
                            top: 5px;
                        }
                        h4{
                            font-size: 20px;
                            line-height: 26px;
                            color:#fff;
                            margin: 0 0 5px 0;
                        }
                        p{
                            font-size: 12px;
                            line-height: 21px;
                            margin: 0;
                        }
                    }
                }
                .invoice-foot{

                    ul{
                        margin: 0px;
                        padding: 0;
                        list-style: none;

                        li{
                            display: block;
                            position: relative;
                            padding-left: 35px;
                            margin-top: 15px;

                            img{
                                position: absolute;
                                width: 16px;
                                left: 0;
                                height: 16px;
                                top: 0;
                            }
                            p{
                                font-size: 12px;
                                line-height: 21px;
                                margin: 0;
                            }
                        }
                    }
                }
            }
            .invoice-content{
                flex:0 0 calc(100% - 240px);
                max-width: calc(100% - 240px);

                .content-head{
                    padding: 58px 40px 25px;
                    display: flex;
                    align-items:flex-start;

                    .invoice-to{
                        font-size: 20px;
                        line-height: 26px;
                        flex:0 0 150px;
                        width: 150px;
                        
                        .invoice-date{
                            font-size: 14px;
                            line-height: 21px;
                           margin-top: 6px;
                        }
                    }
                    h3{
                        line-height: 34px;
                        font-size: 28px;
                        color:#fff;
                        margin-bottom: 24px;
                    }
                    .invoice-party{
                        flex: 1;
                        max-width: 100%;
                        text-align: right;

                        
                        address{
                            font-size: 12px;
                            line-height: 21px;
                            max-width: 224px;
                            margin:0px;
                            margin-left:auto;
                            text-align: right;
                        }
                    }
                }
                .content-body{

                    .product-item-table{
                        padding: 30px 40px;
                        border-top:1px dashed #979797;
                        border-bottom:1px dashed #979797;
                    }
                    .product-detail-table{
                        padding: 30px 40px;
                        border-bottom:1px dashed #979797;
                        &:last-child{
                            border-bottom: 0;
                        }
                    }
                    .product-total-table{
                        padding: 12px 40px 50px;
                    }
                    .table{
                        margin: 0px;
                        border:0px;
                        color:#fff;

                        th{
                            &:first-child{
                                text-align: left;
                            }
                            border:none;
                            padding:0px 0px 10px 0px;
                            font-size: 20px;
                            font-weight: 500;
                            text-transform: capitalize;
                            line-height: 26px;
                            text-align: right;
                        }
                        td{
                            &:first-child{
                                text-align: left;
                            }
                            font-size: 14px;
                            padding: 0;
                            line-height: 40px;
                            text-align: right;
                            border:none;
                        }
                    }
                }
            }
        }
    }
}

.practice-invoice .react-pdf__Page__svg, .affilate-invoice .react-pdf__Page__svg svg{
    // width: 100% !important;
    // height: auto !important;
}


@media (max-width:1700px){
    .invoice__inner{
        width: 60%;
        max-width: 890px;
    }
    .invoice__suggestion{
        // position: relative;
    }
    .invoice__inner.invoice-view{
        min-height: 700px;
        height: 89%!important;
    }
    .invoice__inner .invoice-box{
        height: 100%!important;
    }
}
@media (max-width:1200px){
    .invoice__suggestion{
        position: relative;
    }
}