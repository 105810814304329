@import "../../scss/utils/variables";

.inactive{
    &__inner{
        &__screen{
            margin-top: -135px;
        }
        &__text{
            color: $white;
            font-size: 2rem;
            img{
                max-width: 228px;
            }
            h1{
                margin-top: 1rem;
                margin-bottom: 15px !important;
            }
            p{
                margin-bottom:34px !important;
            }
            h3{
                font-size: 2.7rem;
                font-weight: 500;
                letter-spacing: 7px;
                margin-bottom: 3rem;
            }
            h1,h2,h3,h4,h5,h6{
                color: $white;
            }
        }
    }
}
.credit_card_declined{
    &__main{
        &__screen{
            // background-image: url('../../img/Background_imageBilling.jpeg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;
        }
    }
    &__inner__screen{
        max-width: 560px;
        width: 100%;
    }
}