@import "../../../scss/utils/variables";


.goolge_tv_waiting_room{
    &__main{
        &__screen{
            background: linear-gradient(315deg, #2E2E2E 0%, rgba(5,5,5,0.95) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;
            min-height: 100vh;
        }
        
    }

    &__inner{
        &__screen{
            margin-left: 170px;
            height: 850px;
            overflow: hidden;
            overflow-y: auto;
        }
    }
}

.goolge_tv_video_detail{
    &__main{
        &__screen{
           background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: #fff;
            position: relative;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            &::before{
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, #000000 0%, #000000 28.34%, rgba(0, 0, 0, 0) 100%);
            }
           
        }
        
    }

    &__inner{
        &__screen{

        }
    }
    &__text{
        color: #fff;
        padding-left: 90px;
        position: relative;
        h1{
            font-size: 6rem;
            font-weight: normal;
            color: #fff;
            text-transform: uppercase;
            margin-bottom: 40px;
        }
        &__duration, &__cetegory, &__description{
            font-size: 2.4rem;
        }
        &__description{
            max-width: 805px;
        }

        
    }
    &__btn_group{
        font-size: 28px;
        font-weight: 400;
        >div{
            color: rgba(255,255,255,0.4);
            &:hover{
                color: #fff;
            }
        }
    }
   
}

.goolge_tv_video_Player_Screen{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    z-index: 99999;
    background: #000;
    video{
        width: 100vw!important;
        height:100vh !important;
        object-fit: cover;
        top: 0;
        left: 0;
    }
    .mini-close{
        position: absolute;
        top: 15px;
        right: 30px;
    }
}

@media (max-width:1700px){
    .goolge_tv_video_Player_Screen{
        video{
            width: 100%!important;
            height:100% !important;

        }
}
}
@media (max-width:1366px){
    .goolge_tv_waiting_room,
    .goolge_tv_video_detail{
        &__main{
            &__screen{
              zoom: 0.8;
              padding-top: 7rem;
            }
            
        }
    }
}