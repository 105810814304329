@import "../../../scss/utils/variables";


.goolge_tv_waiting_room{
    &__main{
        &__screen{
            background: linear-gradient(315deg, #2E2E2E 0%, rgba(5,5,5,0.95) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;
            min-height: 100vh;
        }
        
    }

    &__inner{
        &__screen{
            margin-left: 170px;
            height: 850px;
            overflow: hidden;
            overflow-y: auto;
        }
    }
}

@media (max-width:1366px){
    .goolge_tv_waiting_room{
        &__main{
            &__screen{
              zoom: 0.8;
              padding-top: 7rem;
            }
            
        }
    }
}