
.layout-front-image{
   margin-left: 5px;
   height: 30px;
   margin-top: -52px;
   border-style:ridge ;

}
.active{
    border-style: solid;
    border-color: yellow;
}

.subscription-layout-note .subscription-layout-note{
    position: absolute;
    top: 70%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    left: 0;
    right: 0;
}
.subscription-layout-note .layout-back-image {
    pointer-events: none;
    opacity: 0.25;
}
.subscription-layout-note {
    pointer-events: none;
}
.subscription-layout-note h4{
    opacity: 0.25;
    pointer-events: none;
}
/* .subscription{
    pointer-events: none;
    opacity: 0.5;
    background: #CCC;
} */

/* .subscription-layout{
    position:relative;
    top:125px;
    text-align:center;
    
    font-weight:900;
} */
.layout-screen{
    margin-top: 50px;
}
#swiper-layout{
    width: 500px;
    height: 300px;
}

#swiper-layout .swiper-button-next{
    color: white;
    top: 25px;
    right: 0;
    /* left:325px; */
}

#swiper-layout .swiper-button-prev{
    color: white;
    left: 270px;
     top: 25px;
     
     
}