@import "../../scss/utils/variables";

.videoRequest{
    &__main{
        &__screen{
            // background-image: url('../../img/Background_imageWaiting_room.jpg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            width: 100%;
            color: $white;
        }
    }
    &__inner{
        &__screen{
            height: 100%;
            width: 100%;
            max-width: 800px;
            padding: 3.5rem 0rem;
            margin-top: 59px;
        }
        &__text{
            overflow: auto;
            height: 563px;
            position: relative;
            h2,h3,h4,h5,h6{
                margin-bottom: 1.5rem;
                padding-top: 1.5rem;
            }

        }
    }
}