@import "../../scss/utils/variables";

.profile_selection{
    &__main{
        &__screen{
            //background-image: url('../../img/Background_imageUsers.jpg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;
            .user__profile__section{
                overflow: auto;
                max-height: 680px;
                max-width: 1030px;
                width: 100%;
                // justify-content: space-between;
                
                // min-height: 680px;
                // .user__profile{
                //     width: 200px;
                //     max-width: 200px;
                //     order: 12;
                //     &.order-3{
                //         order: 4;
                //     }
                //     &.owner{
                //         order: 0;
                //     }
                //     &.manager{
                //         order: 1;
                //     }
                //     &.authorized_user{
                //         order: 2;
                //     }
                //     &.guest{
                //         order: 5;
                //     }
                //     &.order{
                //         order: 3;
                //     }
                //     &__box{
                //         height: 200px;
                //         width: 100%;
                //         border-radius: 7px;
                //         // background-color: rgba(0,0,0,0.26);
                //         z-index: 2;
                //         position: relative;
                //         backdrop-filter: blur(39px);
                //         overflow: hidden;
                //         cursor: pointer;
                //         &:hover{
                //             &::after{
                //                 content: '';
                //                 position: absolute;
                //                 height: 100%;
                //                 width: 100%;
                //                 z-index: 5;
                //                 border-radius: 7px;
                //                 box-shadow: inset  0 0 0 0.2rem $primary-color;
                //             }
                //             &::before{
                //                 content: '';
                //                 position: absolute;
                //                 top: 0;
                //                 left: 0;
                //                 right: 0;
                //                 bottom: 0;
                //                 background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
                //                 border-radius: 0 0 7px 7px;
                //                 z-index: 5;
                //             }
                //         }
                //         &.owner{
                //             // background-color: rgba(0,0,0,0.26);
                //             .user__profile__footer {
                //                 // background: linear-gradient(315deg, #2E2E2E 0%, rgba(5,5,5,0.95) 100%);
                //             }
                //         }
                //         &.dentist {
                //             // background-color: rgba(255,255,255,0.08);
                //             backdrop-filter: blur(12px);
                //             .user__profile__footer {
                //                 background-color: #E0E0E0;
                //                 color: $main-font-color;
                //             }
                //         }
                //         &.manager {
                //             // background-color: rgba(0,0,0,0.26);
                //             .user__profile__footer {
                //                 // background: linear-gradient(315deg, #2E2E2E 0%, rgba(5,5,5,0.95) 100%);
                //             }
                //         }
                //         &.authorized_user{
                //             // background-color: rgba(0,0,0,0.26);
                //             .user__profile__footer {
                //                 // background: linear-gradient(315deg, #2E2E2E 0%, rgba(5,5,5,0.95) 100%);
                //             }
                //         }
                //         &.guest{
                //             // background-color: rgba(255,255,255,0.08);
                //             .user__profile__footer {
                //                 // background-color: #E0E0E0;
                //                 color: $main-font-color;
                //             }
                //         }
                //     }
                //     &__picture{
                //         img{
                //             object-fit: cover;
                //             width: 100%;
                //             height: 200px;
                //         }
                //     }
                //     &__footer{
                //         font-size: 14px;
                //         letter-spacing: 0;
                //         height: 30px;
                //         border-radius: 0 0 7px 7px;
                //         font-weight: normal;
                //         position: absolute;
                //         bottom: 0;
                //         width: 100%;
                //         left: 0;
                //     }
                //     &__name{
                //         font-size: 1.5rem;
                //         text-transform: uppercase;
                //     }
                // }
            }
        }
    }
    &__inner{
        &__text{
            color: $white;
            font-size: 2rem;
            line-height: 3rem;
            max-width: 1200px;
            margin-top: -87px;

            h1{
                margin-top: 2rem;
                margin-bottom: 40px !important;
            }
            h3{
                font-size: 2.7rem;
                font-weight: 500;
                letter-spacing: 7px;
                margin-bottom: 3rem;
            }
            h1,h2,h3,h4,h5,h6{
                color: $white;
            }
        }
        
    }
}
.custom-scroll{
    ::-webkit-scrollbar-track {
        background:transparent;
    }

    ::-webkit-scrollbar {
        width: 4px;
        border-radius: 2px;
    }
    ::-webkit-scrollbar-thumb {
        background: #FFFFFF;
        height:109px;
        
        
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #FFFFFF;
    }

}
