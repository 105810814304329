@import "../../scss/utils/variables";

.subscription{
    &__main{
        &__screen{
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            width: 100%;
            color: $white;
            .transparent__form--white{
                padding: 2rem;
                // min-height: 808px;
            }
            .mw-1270{
                max-width: 1330px;
                width: 100%;;
            }
            .mw-849{
                width: 100%;
                max-width: 849px;
                // min-height: 868px;

                .inner_white_box{

                    &.review_grand_total{
                        padding: 2rem 4rem;
                        // height: 376px;
                        // overflow: auto;
                    }

                    h4{
                        line-height:24px;
                    }

                    &.payment-box{
                        padding: 24px 40px 24px;
                    }
                }
                .payment_scroll{
                    max-height: 600px;
                    overflow-y: auto;
                    padding-right: 1rem;
                }
                .review-footer-btn{

                    .complete-btn{

                        max-width: 569px;
                    }
                }
            }
            .mw-1082 {
                max-width: 1082px;
                width: 100%;
            }
            .padd-l-20{
                padding-left: 20px;
            }
            .padd-r-20{
                padding-right: 20px;
            }
            .bg_gray{
                border-radius: 2px;
                background-color: rgba(216,216,216,0.2);
                
            }
            .list-group{
                .list-group-item{
                    min-height: 60px;
                    border: 1px solid rgba(216,216,216,0.2);
                    padding-left: 20px;
                    padding-right: 20px;
                    label{
                        margin: 0;
                    }
                    .discount{
                     color: #00D215;   
                    }
                }
            }
            .list-group-flush {
                > .list-group-item{
                    border-width: 1px 0 0;
                }
            }
            .suite_sub_title{
                background: #E7E7E7;
                border-radius: 5px;
                font-size: 12px;
                line-height: 22px;
                color: #5a5a5a;
                padding: 0rem 1rem;
                display: inline-block;
                margin-right: 8px;
                font-weight: 400;
            }
            .suite_title{
                    font-size: 15px;
                    line-height: 22px;
                    /* identical to box height, or 147% */


                    color: $main-font-color;
            }
            .suite_price{
                strike{
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 22px;
                    text-align: right;
                    color: rgba(66, 66, 66, 0.6);
                }
            }
            .promo_code_section{
                position: relative;
            }
            .promo_code_clear_btn{
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
            }
            .payment_info_text{
                font-size: 14px;
                line-height: 20px;
                color: #969696;
            }
            .custom{

                &__multi{

                    &__step{

                        &__form{

                            max-width: 75rem;
                        }
                    }
                }
            }
        }
    }
    &__completed{
        height: 372px;
        width: 541px;
        border:2px solid #35A85A;
        .subscription__inner__text{
            font-size: 1.5rem;
            line-height: 22px;
        }
        &.transparent__form--white{
            padding: 4rem;
            padding-top: 3rem;
            margin-top: 207px;
        }
    }
    &__inner{
        &__screen{
            border-radius: 7px;
            max-height: 83.5rem;
            width: 100%;
            max-width: 78rem;
            padding: 3.5rem 4rem;
            margin-top: 196px !important;
        }
        &__text{
            color: $white;
            font-size: 2rem;
            line-height: 3rem;
            max-width: 67rem;
            margin: 0 auto;

            h1{
                margin-top: 2rem;
                margin-bottom: 9px !important;
            }

            p{
                margin-bottom: 1rem;
                font-size: 18px;
            }
            h3{
                font-size: 2.7rem;
                font-weight: 500;
                letter-spacing: 7px;
                margin-bottom: 3rem;
            }
            h1,h2,h3,h4,h5,h6{
                color: $white;
            }
            
        }
    }
}
@media (max-width:1700px){
    .subscription__main__screen{
        overflow-y: auto;   
        min-height: 100vh;
        height: 100%;
    }
    .subscription__main__screen .mw-1270{
        max-width: 80%;
        margin-bottom: 30px!important;
    }
    .subscription__main__screen .mw-849{
        margin-bottom: 30px!important;
    }
    .practice_info .inner_white_box{
        padding: 2rem;
        height: auto;
    }
    .app .inner_white_box,
    .app .inner_white_box.mh-335{
        padding: 2rem!important;
    }
    .app .form-control, .app .react-tel-input .form-control{
        min-width: 100%;
    }
}