.preview__modal{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999;
    overflow: hidden;
    .mini-close{
        position: absolute;
        top: 30px;
        right: 30px;
    }
}