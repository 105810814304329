.timeline_container{
    // height: 150px;
    position: absolute;
    left: 0;
    width:  100%;
    overflow: hidden;
    overflow-x: scroll;
    padding: 0;

}

/* Hide scrollbar for Chrome, Safari and Opera */
.timeline_container::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .timeline_container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

.timeline_container .videoSelected__main__card__title{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 15px;
}

.timeline_item_view{
    // height: '100%';
    // width: '100%';
    height: 160px;
    width: 250px;
    position: relative;

}
.timeline_item{
    background: linear-gradient(359.98deg, rgb(114, 135, 197) 0%, rgb(98, 135, 255) 53.71%, rgb(46, 168, 255) 100%);
    height: 150px;
    position: relative;
    border-radius: 2px;
    padding: 2px;
}
.img_view{
    height: 100%;
    // width: 100%;
    object-fit: cover;
    width: 250px;
    max-width: none;
}
.duration{
    color: white;
}
.timeline_total_time{
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: right;
    margin-top: -25px;
    padding: 0 30px 0 0;
}
.scroolable-sec{
    .btn_prv, .btn_next{
        background-color: rgba(255, 255, 255, 0.08);
        backdrop-filter: blur(6px);
        z-index: 9;
    }
    .btn_prv{
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;

    }
    .btn_next{
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
    }
}

//handle the remove effect when the card is dragged out from timeline
.overlayClass{
    width:100%;
    height:108%;
    background-color:rgba(0,0,0,0.7);
    z-Index:20;
    position:absolute;
    justify-content:center;
    align-items:center;
    display:flex;

    &:after{
        content:"REMOVE";
        font-size: 20px;
        font-weight: 700;
    }
}

