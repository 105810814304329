@import "./scss/utils/variables";

@import "~bootstrap/scss/bootstrap";


@import "./scss/utils/functions";
@import "./scss/utils/mixins";
@import "./scss/utils/helper";

@import "./scss/base/reset";
@import "./scss/base/typography";

@import "./scss/components/buttons";
@import "./scss/components/custome_scroll";
@import "./scss/components/_dropdown";
@import "./scss/components/tooltips";
@import "./scss/components/user_profile_box";

@import "./scss/layout/forms";
@import "./scss/layout/page";

.app{
  height: 100vh;
  overflow: hidden;
  width: 1920px;
  max-width: 1920px;
  margin: 0 auto;
  // max-height: 1080px;
  min-height: 1080px;
}
body{
  // background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
}
#root:empty {

  position: absolute;
  top: calc(50% - 1rem);
  left: calc(50% - 1rem);

  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid #FF9F00;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;

  }
  .rCSwtyrwf .moveable-control {
    width: 40px !important;
    height: 40px !important;
    margin-top: -20px !important;
    margin-left: -20px !important;
  }

  .rCSwtyrwf .moveable-rotation{
    height: calc(90px * var(--zoom)) !important;
  }

  .main-editor-moveable-target .rCSwtyrwf .moveable-control{
    background: #4af !important;
  }
  .rCSwtyrwf{
    z-index: 1000 !important;
  }
  
  .moveable-control-box{
    --moveable-color: #ff9f00 !important;
  }
  .MuiSkeleton-root{
    background-color: rgba(255, 255, 255, 0.2)!important;
  }
  .MuiSkeleton-wave::after{
    background: linear-gradient(90deg, transparent, rgba(160, 160, 160, 0.2), transparent)!important;
  }
//   @keyframes spinner-border {
//   to { transform: rotate(360deg); }
// }


.react-contextmenu {
  background-color: #212122;
  background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    color: #fff;
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 160px;
    outline: none;
    opacity: 0;
    padding: 5px 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
}

.react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: #fff;
	cursor: pointer;
    font-weight: 400;
    line-height: 1.5;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap;
    a{
      color: #fff;
    }
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #FF9F00;
    border-color: #FF9F00;
    text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
    color: #878a8c;
}

.react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0,0,0,.15);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
	padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
    content: "▶";
    display: inline-block;
    position: absolute;
    right: 7px;
}

.example-multiple-targets::after {
    content: attr(data-count);
    display: block;
}


@media (max-width:1700px){
  .app{
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
    min-height: unset;
  }
}