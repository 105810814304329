html{
    font-size: 62.5%;
}
html,body{
	height: 100%;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,500&display=swap');
body{	
    font-size: 1.5rem;
	font-family: $body-font;
	font-weight: 300;
	letter-spacing: 0.5px;
	color: $main-font-color;
	background-color: #EDEFF1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	p{
		
	}
}
h1, .jumbo-heading_2{
	font-size: 5rem;
	line-height: 7rem;
	font-weight: 500;
}
h2 {
	font-size: 3.0rem;
	line-height: 3.0rem;
	font-weight: 500;
}
h3 {
	font-size: 2.5rem;
	line-height: 2.5rem;
	font-weight: 500;
}
h4 {
	font-size: 2.0rem;
	line-height: 2.0rem;
	font-weight: 500;
}
h5{
	font-size: 1.5rem;
	line-height: 1.5rem;
	font-weight: 500;
}
h6{
	font-size: 1.3rem;
	line-height: 1.3rem;
	font-weight: 500;
}
.jumbo-heading {
	font-size: 6rem;
	line-height: 6rem;
}
h1,h2,h3,h4,h5,h6, .jumbo-heading, .jumbo-heading_2{
	font-family: $title-font;
	color: $title-color ;
	letter-spacing: normal;
}
a{
	color: $main-font-color;
	&:hover{
		text-decoration: none;
	}
}


/* width */
::-webkit-scrollbar {
	width: 6px;
	border-radius: 6px;
}

/* Track */
::-webkit-scrollbar-track {
	background:transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #B9B9B9;
	border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #B9B9B9;
}
@media (max-width:1370px){
	html{
		// zoom: 0.9;
	}
}
@media (max-width:1700px){
	h1, .jumbo-heading_2{
		font-size: 3rem;
		line-height: normal;
	}
}