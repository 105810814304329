@import "../../scss/utils/variables";
.website_video_library{
    &__main{
        &__screen{
            //background-image: url('../../img/Background_imageWebsite_video_library.jpg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;

            .website_videos_leftside{
                margin-right: 7px;
                margin-top: 14px;

                .website_videos_video_selection{
                    margin-right: 0;
                    width: 430px;
                    min-height: 842px;
                    max-height: 842px;
                    padding: 40px;
                    .videolist__main__lists{
                        max-height: none;
                        overflow: hidden;
                    }
                    .videolist{

                        &__main{

                            &__lists{

                                &_hr{
                                    margin: 18px 0 21px;
                                }
                            }

                            &__header{

                                &__videolist__main__lists{
                                    margin-bottom: 12px;
                                }

                                &__header-title{
                                    margin-bottom: 12px;
                                }
                            }

                            &__button{
                                position: absolute;
                                left: 40px;
                                right: 40px;
                                bottom: 39px;
                            }
                        }

                        &__submain{

                            &__header{
                                margin-top: 23px;

                                &__checkbox{
                                    margin-right: 20px !important;
                                }
                            }
                        }
                    }
                }
            }

            .website_videos_rightside{

                .videoSelected{

                    &__main{

                        &__centerCol{
                            padding-left: 30px;
                            height: 690px!important;
                            // margin-top: -15px;
                        }

                        &__card{

                            &:hover{
                                transform: translateY(-12px) scale(1.12) !important;
                                .videoSelected__main__card__duration{
                                    visibility: visible;
                                }
                            }

                            &__video_icon{
                                width: 58px;
                                height: 35px;
                                margin-bottom: 4px;
                            }

                            &__subTitle{
                                // left: 12px; 
                                p{
                                    // max-width: 333px;
                                }
                            }

                            &__title{
                                // max-width: 333px;
                            }  
                            
                            &__duration{
                                display: inline-block;
                                font-size: 15px;
                                margin-left: 10px;
                                visibility: hidden;
                            }

                        }
                    }
                }
            }

            .practice_name{
                font-size: 18px;
                line-height: 30px;
            }
        }
        &__inner__screen{
            max-width: 1600px;
            margin: 0 auto;
            margin-top: 47px !important;
            width: 100%;
        }
    }
}
.website_videos{
    &__main{
        &__screen{
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;
        }
    }
    &__inner{
        &__screen{
           
        }
        &__text{
            color: $white;
            font-size: 2rem;
            line-height: 3rem;
            max-width: 67rem;
            h1{
                margin-top: 2rem;
            }
            h3{
                font-size: 2.7rem;
                font-weight: 500;
                letter-spacing: 7px;
                margin-bottom: 3rem;
            }
            h1,h2,h3,h4,h5,h6{
                color: $white;
            }
        }
    }
   
 }

.website_videos_setup{

    &__inner{

        &__screen{
            
            width: 1603px;

            .website_videos_leftside{
                width: 452px;
                float: left;  
                height: 100%; 

                .transparent{

                    &__form--white{
                        height: 100%;
                    }
                }
            }
            .website_videos_rightside{
                width: 1151px;
                float: right;

                .videoSelected{

                    &__main{

                        &__screen{

                            min-width: 1176px;
                        }
                    }
                }
            }

            .videoSelected{

                &__main{

                        &__screen{
                            margin-top: 11px;
                        }

                        
                }
            }
        }
    }
}

@media (max-width:1700px){
    .website_videos__main__screen{
        overflow-y: auto;
    }
    .website_video_library__main__screen{
        min-height: 100vh;
    }
    .website_video_library__main__screen .website_videos_leftside .website_videos_video_selection{
        max-width: 330px;
        padding: 2rem;
        min-height: unset;
    }
    .website_video_library__main__screen .website_videos_rightside .videoSelected__main__centerCol{
        padding: 0 30px!important;
        height: 630px!important;
        > .d-flex{
            gap: 20px;
            padding: 20px 0;
        }
    }
    .videoSelected__main__columnFour.videoSelected_box{
        width: 100%;
        min-width: 31%!important;
        max-width: 31%!important;
        height: 150px!important;
        padding: 0!important;
        margin: 0!important;
    }
    .videoSelected__main__card{
        height: 150px!important;
    }
    .website_video_library__main__screen .website_videos_leftside{
        margin: 0!important;
    }
    .website_video_library__main__screen .website_videos_leftside .website_videos_video_selection .videolist__main__button{
        position: relative;
        left: 0;
        right: 0;
        bottom: 0;

        .btn{
            font-size: 1.3rem;
        }
    }
    .website_video_library__main__inner__screen{
        max-width: 80%;
    }
    .website_videos_video_selection .videolist__main__lists__scroll_section{
        max-height: 300px!important;
        overflow-x:hidden
    }
}