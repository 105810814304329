@import "../../scss/utils/variables";

.news_feed{
    &__main{
        // height: 100px;
        height: 773px;

        .news_feed_dropdown{
            height: 521px;
            overflow-y: auto;

            .ac{
                h6{
                    svg{
                        transform: rotate(-180deg);
                        -webkit-transform: rotate(-180deg);
                    }
                }
            }

            h6{
                font-size: 15px;
                margin-bottom: 20px;
                margin-left: 32px !important;
                position: relative;
                display: inline-block;
                svg{
                               
                    font-size: 3.5rem;
                    position: absolute;
                    top: -13px;
                    right: -46px;
                    transition: all .3s ease-in-out;
                    cursor: pointer;

                }
            }

            .MuiCollapse-entered{
                margin-left: 30px;

                .MuiCollapse-wrapper{

                    .MuiCollapse-wrapperInner{

                        h6{
                            
                        }
                    }
                }
            }

            img{
                width: 18px !important;
                height:18px !important;
                cursor: pointer;
            }

        }

        &.waiting_room_common_section_newsfeed{
            height: 773px !important;
            width: 430px !important;
            border-radius: 7px;
            background-color: rgba(255,255,255,0.08);
            margin: 0 auto;
            margin-top: 38px !important;
            padding: 4rem !important; 
            padding-right: 20px !important;

}
    }
}

::-webkit-scrollbar {
    width: 4px;
    border-radius: 6px;
}


@media (max-width:1700px){
    .news_feed__main.waiting_room_common_section_newsfeed{
        height: auto !important;
    }
    .news_feed__main .news_feed_dropdown{
        height: 420px;
    }
    .operatory_room_channel_footer__inner__screen .transparent__form--white, .waiting_room_channel_footer__inner__screen .transparent__form--white{
        padding: 2rem;
    }
}