@import "../../scss/utils/variables";

.PrivateTabIndicator-colorSecondary-4 {
    background-color: white!important;
}
.MuiTab-wrapper{
    text-transform:none!important;
}
.waitingRoom{
  &__main{
      &__screen{
          //background-image: url('../../img/Background_imageNew_channel.jpg');
          background-size: 100% 100%;
          padding-top: 6rem;
          color: $white;
          background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
          &--slide{
            min-width: 1160px;
            background-image: url('../../img/bg.png');
            background-repeat: no-repeat;
            background-size: cover;
            justify-content: center;
            min-height: 652px;
            &--innerBox{
              padding: 40px;
              position: relative;
              .theme_bg{
                height: 100%;
                object-fit: cover;
                position: absolute;
                left: 0;
                top: 0;
              }
            }
            &--footer{
              // width: 948px;
              height: 124px;
              margin-bottom: 8px;
              .footer_news{
                  margin: 0;
                  font-size: 24px;
                  font-weight: 500;
                  line-height: 30px;
                  text-align: center;
                  color: #fff;
                  text-transform: uppercase;
                  width: 96px;
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  &.theme_4{
                    top: 0;
                    align-items: start;
                    padding: 9px 14px;
                    text-align: center;
                    justify-content: center
                  }
              }
              h3{
                font-size: 24px;
                padding: 7px 16px;
                font-weight: 500;
                color: #fff;
                margin: 0;
                left: 96px;
                top:0;
                position: absolute;
                &.theme_4{
                  font-size: 22px;
                  top: 3px;
                  padding-left: 30px;
                }
              }

              &--text{
                font-size: 1.8rem;
                color: #424242;
                top:40px;
                padding: 9px 16px;
                left: 96px;
                position: absolute;
                &.theme_4{
                  left: 10px;
                }
                p{
                  font-size: 1.8rem;
                  color: #424242;
                  padding: 0;
                }
              }
              &--weather{
                margin-left: 10px;
                position: relative;
                max-width: 122px;
                .time{
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  padding: 8px 5px;
                  color: #424242;
                  width: 78px;
                  font-weight: 500;
                  text-align: center;
                }
                .temp{
                  position: absolute;
                  bottom: 0;
                  right: 0;
                  padding: 8px 1px;
                  color: #fff;
                  width: 42px;
                  font-weight: 500;
                  text-align: center;            
                }
              }
            }
            // .footer
// {
// background-color:white;
// font-size:1em;
// p
// {
// padding: 0.3em;
// }
// }
// #news
// {
// background-color:white;
// font-size:1em;
// color:black;
// padding:.2em 1em;
// }
// .flexFont {
// height:8em;
// width:95%;
// height:fit-content;

// padding:1%;

// }
          }
          &--practice_name{
            color: $white;
          }
         
      }
      &__inner_screen{
        max-width: 1630px;
        margin-top: 5rem !important;
      }

      &__left-slide{

        &__div1{
          
          &__footer{

            .footer_btn{
              margin-bottom: 20px;
            }
          }
          &__header{
            h6{
             font-size: 19px;
             font-weight: 400;
             letter-spacing: 0.4px;
            }
         }

          &__body{
            
            margin-top: 15px;

            .channel_detail{
              // margin-top: -5px;
              position: relative;
              .timeline-title{
                font-weight: 600;
              }
              label{
                font-size: 15px;
                letter-spacing: 0.8px;
                margin-bottom: 1px;
              }
              .btn{
                text-transform: none;
                padding: 5px 15px;
                background: rgba(111, 111, 111, 0.8);
                border-radius: 3px;
              }
              p{
                font-size: 11px;
                color: #C5C5C5;
                margin-bottom: 0;
              }
            }
            &__header{
              margin-bottom: 27px;
            }
  
            .show-channel-checkbox{
              margin-bottom: 15px ;
            }
  
  
  
            .waiting__show_img{
              max-width: 145px;
              width: 100%;
              // margin-bottom: 12px;
            }

            .waiting_checkbox{
              position: relative;
              // width: 104px;
              margin-bottom: 10px;
  
              .custom_checkbox{
                position: absolute;
                top: -8px;
                right: 0;
  
                .MuiSwitch-root{
                  width: 40px;
                  height: 22px;
  
                  .MuiSwitch-thumb{
                    width: 18px;
                    height: 18px;
                  }
                }
              }
            }

          
          }
        }
  
        .transparent__form--white{
          min-height: 850px;
          padding: 42px 40px 46px;
        }
      }

    }
    
    &__show_footer , &__show_header , &__show_timeline{
      // cursor: pointer;
      border-bottom: 1px solid #727272;
      padding-bottom: 20px;
      margin-bottom: 20px;
      width: 100%;
    }

    &__show_footer{
      border: none;
    }
}
.new_room{
    &__main{
        background: rgba( 208, 208, 208, 0.10 );
        backdrop-filter: blur( 3.0px );
        // -webkit-backdrop-filter: blur( 3.0px );
        border-radius: 5px;
        // width: 50vw;
        // height: auto;
        width:800px;
        height:auto;
        margin-top: 47px;
        // display: flex;
        justify-content: center;
        align-self:center;
        padding: 40px;  
        height: 770px;
    }
    &__headingText{
        font-size: 2rem;
        text-align: left;
        padding: 0px;
        color: azure;
        margin-bottom: 15px;
    }
    &__subheadingText{
        font-size: 1.5rem;
        padding:0px;
        // margin-left: -200px;
        color: rgba(240, 255, 255, 0.863);
        text-align: left;
        margin-bottom: 25px;
    }
    &__channelName {
        // text-align: left;
        background-color: rgba(179, 179, 179, 0.281);
        font-size: 1.1rem;
        margin-top: 0px;
        border: 0px;
        color:white;
        padding: 8px;
        width: 350px;
        height: 40px;
    }
    &__hr{
        background-color: rgba(161, 161, 161, 0.644);
        width:100%;
    }
    &__textArea{
        max-width: 90%;
        min-height: 30rem;
        border-radius: 2px;
        background-color: rgba(255, 255, 255, 0.96);
        color: #424242;
        padding: 2rem;
        margin-left: 19px;
    }
}
.waitingRoom__main__screen--theme__lists{
  max-width: 1160px;
  h3{
    color: $white;
  }
  .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before{
    border-color: #fff;
    background-color: #fff;
  }
 .swiper-container {
    padding: 0 20px;
    margin: 0 -40px;
 }
 .swiper-button-prev {
  left: 0px;
 }
  .swiper-wrapper {
    width: auto;
  }
 .swiper-wrapper .swiper-slide {
    padding: 0 20px;
  }
  
.swiper-button-prev, .swiper-button-next {
  color: #fff;
  opacity: 1;
}
}
.theme{
  &__box{
    width: 200px;
    .custom-checkbox{
      max-width: 200px;
      .custom-control-label{
        padding: 0;
      }
        &.sm .custom-control-label:before{
          top: 5px;
          left: 5px;
          margin-top: 0;
        }
        &.sm input:checked + .custom-control-label:after{
          top: 12px;
          left: 8px;
        }
    }
  }
}


@media (max-width:1700px){
  .waitingRoom__main__screen{
    overflow-y: auto;
    min-height: 100vh;
  }
  .waitingRoom__main__inner_screen{
    max-width: 85%;
    width: 100%;
    margin: 30px 0;
  }
  .waitingRoom__main__screen--slide{
    width: 100%;
    min-width: 100%;
    min-height: 567px;
  }
  .waitingRoom__main__screen--theme__lists{
    max-width: 100%;
    width: 100%;
  }
  .waitingRoom__main__left-slide .transparent__form--white{
    padding: 2rem;
  }
  .waitingRoom__main__right-side{
    width: 100%;
    min-width: auto;
    max-width: 70%;
  }
  .waitingRoom__main__screen--slide--innerBox{
    padding: 15px;
  }
  .waitingRoom__main__screen--slide--footer{
    // height: 115px;
  }
  .waitingRoom__main__screen--slide--footer .footer_news{
    font-size: 16px;
    line-height: 20px;
    width: 79px;
  }
  .waitingRoom__main__screen--slide--footer h3{
    font-size: 18px;
    padding: 12px 5px;
  }
  .waitingRoom__main__screen--slide--footer .footer_news.theme_4{
    padding: 20px 14px;
  }
  .waitingRoom__main__screen--slide--footer--text.theme_4{
    padding: 15px 10px;
  }
  .waitingRoom__main__screen--slide--footer--text{
    padding: 5px 5px;
  }
  .theme__box,
  .theme__box .custom-checkbox{
    max-width: 150px!important;
  }

  .waitingRoom__main__left-slide__div1__body .waiting__show_img{
    max-width: 110px;
    width: 100%;
  }
  .waitingRoom__main__left-slide__div1__footer .footer_btn .btn{
    padding: 1rem 0.5rem;
    font-size: 1.2rem;
  }

}

@media (max-width:1200px){
  .theme__box,
  .theme__box .custom-checkbox{
    max-width: 120px!important;
  }
  .waitingRoom__main__inner_screen{
    max-width: 93%;
  }
 
  .waitingRoom__main__screen--slide{
    min-height: 55%;
  }
  .waitingRoom__main__left-slide__div1__footer .footer_btn .btn{
    padding: 1rem 0.5rem;
    font-size: 1rem;
  }

  .waitingRoom__main__screen--slide--footer .footer_news{
    font-size: 16px;
    line-height: 20px;
    width: 68px;
  }
  .waitingRoom__main__screen--slide--footer .footer_news.theme_4{
    width: 80px;
    padding: 26px 14px;
  }
  .waitingRoom__main__screen--slide--footer h3{
    font-size: 14px;
    padding: 17px 5px;
    left: 80px;
  }
  .waitingRoom__main__screen--slide--footer--text p{
    font-size: 14px;
  }
  .waitingRoom__main__screen--slide--footer--text{
    left: 80px;
  }
}

