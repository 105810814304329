@import "../../scss/utils/variables";


.designate{

    &__page{
        // background-image: url('../../img/Background_imageBilling.jpeg') !important;
        background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
        .practice_name {
            font-size: 19px;
        }
    }
    &__inner{
        padding:38px 40px;
        width: 870px;
        height: 805px;
        border-radius: 7px;
        background-color: rgba(255,255,255,0.08);
        margin-top: -64px;
        position: relative;
        color:#fff;

        .primary-card-section{
            margin-right: 80px;
            position:relative;

            &::after{
                content: "";
                position: absolute;
                height: 604px;
                width: 1px;
                background-color: rgba(216,216,216,0.3);
                right: -40px;
                top: 44px;
            }
        }
        .card-section{
            flex: 1;
            width: 350px;
            max-width: 350px;
            h4{
                font-size: 20px;
                color: #fff;
                margin-top: 4px;
                margin-bottom: 20px;
            }

            p{
                font-size: 14px;
                line-height: 22px;
                max-width: 350px;
                margin-bottom: 24px;
            }

            .form-group{
                label{
                    font-size: 14px;
                    line-height: 22px;
                    margin-bottom: 4px;
                }
                .react-select{

                    &__multi-value{
                        width: 100%;

                        &__label{
                            width: 100%;
                        }
                    }
                    
                    &__placeholder{
                        color: rgba(255,255,255,0.4);
                    }
                }
                input{
                    min-width: 100%;
                }

                &.disabled{
                    opacity: 0.4;
                }
            }
        }
        .stripe-logo {
            height: 26px;
            width: 119px;
            position: absolute;
            right: 15px;
            top: 15px;
        }

        &__header{

            .search-keyword{
                margin-right: 26px;
            }
            h4{
                margin:0px;
                font-size: 20px;
                line-height: 26px;
                color:#FFFFFF;
                font-weight: 400;
            }
            h3{
                font-size: 50px;
                line-height: 50px;
                color:#FFFFFF;
                margin:0px;
            }
            .left-right-arrow{
                img{
                    padding: 0;
                    max-height: 13px;
                }

                .btn-link{
                    transform: rotate(90deg);
                    height: 26px;
                    padding: 4px;

                    &.next-arrow{
                        transform: rotate(-90deg);
                        margin-right: 23px;
                    }
                }
            }
        }
        &__body{
            padding:40px 0 ;
            padding-bottom: 25px !important;

            .collapse:not(.show){
                height: 20px!important;
                overflow: hidden;
                display: block;
                width: 622px;
            }
            .collapsing{
                min-height: 20px!important;
            }
            table{
                border:0px;
                text-align: left;
                margin-top: -14px;

                tbody{
                    display: block;
                    max-height: 450px !important;
                    overflow-y:auto;
                    overflow-x: hidden;
                    padding: 0 20px;
                    margin: 0 -20px;

                    tr{

                        td:first-child{
                            padding-left:0 !important;
                            text-align: left;
                        }
                        td:last-child{
                            padding-left:0;
                            text-align: right;
                        }
                    }
                }
                thead, tbody tr {
                    display: table;
                    width: 100%;
                    table-layout: fixed;/* even columns width , fix width of table too*/

                    th:first-child{
                        padding-left:0 !important;
                        text-align: left;
                    }
                    th:last-child{
                        padding-right:14px;
                        text-align: right;
                    }

                    th{
                        &:nth-child(2){
                            padding:0.6rem 3rem 0.6rem 3.5rem !important;
    
                            .pr-3{
                                padding-right: 0 !important;
                                button{
                                    margin: 0;
                                    margin-right: -8px;
                                }
                            }
                        }
                    }
                }
                th , td{
                    border:0px;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 22px;
                    color:#FFFFFF;
                    text-transform: uppercase;
                    vertical-align: middle !important;
                }
                td{
                    text-transform: none;
                    font-weight: 300;
                    border-bottom: 1px solid rgba(255,255,255,0.3);
                    padding:2.5rem 2.0rem !important;
                    padding-top: 26px;
                    padding-bottom: 26px;
                    vertical-align: middle !important;

                    &:nth-child(2){
                        padding: 2.5rem 0rem 2.5rem 3.5rem !important;

                        .pr-3{
                            padding-right: 0 !important;
                            button{
                                margin: 0;
                                margin-right: -8px;
                            }
                        }
                    }
                    .down-arrow{
                        height:40px;
                        width: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: all 0.4s ease;
                        &.active_arrow{
                            transform: rotateZ(-180deg);
                        }
                    }
                    .down-arrow img{
                        max-width: 20px;

                    }
                }
            }
            .notification__empty{
                height:511px;
                background-color:#FFFFFF;
                border-radius: 5px;

                h4{
                    font-size: 36px;
                    line-height: 43px;
                    color:#424242;
                    margin:0px 0px 20px 0;
                }
                p{
                    font-size: 15px;
                    line-height: 22px;
                    color:#424242;
                    font-weight: normal;
                    margin:0px;
                  
                }
            }
        }
        &__footer{

            ul{
                li{
                    display: inline-block;
                    margin: 0 13px !important;
                    
                    a{
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 22px;
                        color:#FFFFFF;
                        text-transform: uppercase;
                        vertical-align: middle;
                        letter-spacing: 0.5px;
                        display: block;
                        padding: 8px;
                    }
                    .activepage{
                        color:#FF9F00;
                    }
                }
            }
        }


        .designate-footer{
            button{
                max-width: 167px;
                margin-right: 13px;
            }
        }
    }
}

@media (max-width:1700px){
    .designate__inner{
        margin-top: 0!important;
    }
}