@import "../../scss/utils/variables";

.authorized_section{
    &__main{
        &__screen{
            // background-image: url('../../img/Background_imageAuthorized_users.jpg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;

            &__about__screen{
                width:760px;
                min-height: 675px;
                margin-top: 100px;

                h4{
                    margin-top: 5px !important;
                    margin-bottom: 17px !important;
                }
            }

            .transparent__form--white{
                &.add_authorized_user{
                    max-width: 430px;
                    min-height: 738px;
                    width: 100%;
                    margin-top: -72px;

                    .adduser-form-header{
                        h4{
                            margin-bottom: 18px !important;
                        }
                        p{
                            margin-bottom: 5px;
                        }
                    }

                    .form-group{

                        label{
                            font-size: 14px;
                            line-height: 21px;
                        }
                    }
                }
            }

            .user_profile_section{
                overflow: auto;
                max-height: 675px;
                width: 100%;
                margin-top: 39px;

                .user__profile{
                    width: 180px;
                    .user__profile__box{
                        margin-bottom: 14px !important;
                        // width: 200px;
                        margin: 0 auto;
                    }
                }
                &__owner_detail{
                    margin-top: 17px;
                    span{
                        display: block;
                        margin-bottom: 8px;
                    }
                }
                .user__profile__name{
                    margin-bottom: 27px;
                }
            }
        }
    }
    &__inner{
        &__text{
            color: $white;
            font-size: 2rem;
            line-height: 3rem;
            max-width: 1200px;
            h1{
                margin-top: 2rem;
            }
            h3{
                font-size: 2.7rem;
                font-weight: 500;
                letter-spacing: 7px;
                margin-bottom: 3rem;
            }
            h1,h2,h3,h4,h5,h6{
                color: $white;
            }
        }
        
    }
}

@media (max-width:1700px){
    .authorized_section__main__screen__about__screen{
        min-height: auto;
    }
}