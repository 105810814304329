@import "../../scss/utils/variables";

.waiting_room{
    &__main{
        &__screen{
           
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
           // background-color: black;
            //background-image: url('../../img/Background_imageSetup.jpeg');
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;
        }
    }
    &__inner{
        &__screen{
           
        }
        &__text{
            color: $white;
            font-size: 2rem;
            line-height: 3rem;
            max-width: 67rem;
            h1{
                margin-top: 2rem;
            }
            h3{
                font-size: 2.7rem;
                font-weight: 500;
                letter-spacing: 7px;
                margin-bottom: 3rem;
            }
            h1,h2,h3,h4,h5,h6{
                color: $white;
            }
        }
    }
   
}