.playlist_modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(13.5914px);   
    .normalscreen{
      video{
        max-height: '870px!important';
        object-fit: cover;
      }
    }
    
}

.playlist_modal .makeStyles-playerWrapper-1{
    height: 100%;
    min-height: auto;
    width: 100%;
}

.playlist_modal .controlsTop {
    top: -22px;
    left: 0;
    right: 0;
    position: absolute;
    font-size: 15px;
    font-weight: 300;
}

.playlist_video_list{
    .channel_detail_swiper {
        margin: 0 -20px;
        background-color: rgba(0, 0, 0, 0.26);
        backdrop-filter: blur(6px);
  
        .swiper-button-prev {
          color: #fff;
          background-color: rgba(255, 255, 255, 0.08);
          left: 0px !important;
          backdrop-filter: blur(6px);
          height: 100%;
          width: 171px;
          top: 0;
          bottom: 0;
          margin-top: 0;
          &.swiper-button-disabled {
            opacity: 0;
          }
        }
        .swiper-button-next {
          color: #fff;
          background-color: rgba(255, 255, 255, 0.08);
          right: 0px !important;
          backdrop-filter: blur(6px);
          height: 100%;
          bottom: 0;
          width: 171px;
          top: 0;
          margin-top: 0;
          &.swiper-button-disabled {
            opacity: 0;
          }
        }
        .swiper-slide {
          width: 248px;
          height: 140px;
          margin: 0 20px;
  
          .channel_detail_item {
            position: relative;
            &:after {
              background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.0001) 0%,
                rgba(0, 0, 0, 0.6) 100%
              );
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              content: "";
              width: 100%;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              max-height: 140px;
            }
            label {
              position: absolute;
              left: 0;
              right: 0;
              font-size: 13px;
              bottom: 10px;
              margin-bottom: 0;
              text-align: center;
              z-index: 2;
            }
          }
  
          &:hover {
            transform: translateY(-13px) scale(1.13);
            transition: 0.3s ease-in-out;
            background: linear-gradient(315deg, #ffb438 0%, #ff9f00 100%);
            padding: 1px;
          }
        }
      }
}

.playlist_modal video{
    // width: 100vw!important;
    // height: 100vh!important;
    // object-fit: cover;
    // top: 0;
    // left: 0;
}