.ps__thumb-y{
    background-color: #424244;
    width: 4px;
}
.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y{
    opacity: 1;
}
.ps:hover > .ps__rail-x, 
.ps:hover > .ps__rail-y, 
.ps--focus > .ps__rail-x, 
.ps--focus > .ps__rail-y, 
.ps--scrolling-x > .ps__rail-x, 
.ps--scrolling-y > .ps__rail-y{
    opacity: 1;
}