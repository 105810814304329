@import "../../scss/utils/variables";

.main{
    &__screen{
        background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
        .channels__item:last-child{
            position: relative;
            margin-left: 60px;
            
            &::after{
                content: "";
                position: absolute;
                left: -40px;
                top: 70px;
                bottom: 0;
                width: 1px;
                height: 107px;
                background-color: rgba(255, 255, 255, 0.6);
            }
        }
    }
}
.react-select-container {
    &.react-select__lable_style{
        max-width: 100%;
        margin: 0 -4px;
        .react-select__control{
            background-color: transparent;
            max-width: 100%;
            min-width: auto;
            padding: 0;
        }
        .react-select__indicators{
            display: none;
        }
        .react-select__multi-value{
            min-width: 300px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}
.purchaseCompleted_box{
    margin-top: 105px;
    padding: 4rem;
}
.purchaseCompleted_box_info{
    margin-top: 139px;
    h4{
        font-weight: normal;
    }
}
.purchase{
    &__page{
        .review_card{
            height: 831px;
            overflow-y: auto;
            border-radius: 2px;

            .inner_white_box{
                padding:43px 17px 41px;

                .review_order{
                    margin-bottom: 30px;
                    padding-left: 21px;
                }
                .order-detail{
                    border: 3px solid #F8F7F7;
                    margin-bottom: 33px;
                    position: relative;
                    
                    &::after{
                        content: "";
                        position: absolute;
                        left: -2px;
                        bottom: -19px;
                        height: 1px;
                        width: 47px;
                        border: 1px solid #FF9F00;
                    }
                    .order-header {
                        justify-content: space-between;
                        background-color: #F8F7F7;
                        padding: 11px 20px 11px 20px;

                        h4{

                            .edit-order{
                                color: #FF9F00;
                                font-size: 15px;
                                font-weight: 400;
                                margin-left: 10px;
                            }
                        }
                    }

                    .order-summary{

                        padding: 15px 20px 20px;

                        .delivery-address{

                            h4{
                                margin: 15px 0 11px;

                                .order-instruction{
                                    font-size: 15px;
                                    font-weight: 400;
                                }
                            }
                            .address-list{
                                display: flex;
                                flex-flow: wrap;
                                margin: 0 -2.5px;

                                .address{
                                    height: 28px;
                                    width: 307px;
                                    border-radius: 2px;
                                    background: linear-gradient(359.98deg, #7287C5 0%, #6287FF 53.71%, #2EA8FF 100%);
                                    color: #fff;
                                    padding: 3px 9px;
                                    margin:2.5px;
                                    -webkit-column-break-inside: avoid;
                                    page-break-inside: avoid;
                                    break-inside: avoid;
                                }
                            }
                        }
                    }
                }
            }
            .form-group{
                // position: relative;
                // .what-cvv{
                //     height: 25px;
                //     width: 23px;
                //     border-radius: 2px;
                //     background: linear-gradient(359.98deg, #7287C5 0%, #6287FF 53.71%, #2EA8FF 100%);
                //     position: absolute;
                //     text-align: center;
                //     line-height: 25px;
                //     right: 8px;
                //     top: 31px;
                //     color: #fff;
                //     cursor: pointer;
                //     user-select: none;

                //     &:hover span{
                //         visibility: visible;
                //         opacity: 1;
                //         transform: scale(1);
                //     }
                //     span{
                //         height: 90px;
                //         width: 215px;
                //         border-radius: 2px;
                //         background: linear-gradient(359.98deg, #7287C5 0%, #6287FF 53.71%, #2EA8FF 100%);
                //         position: absolute;
                //         bottom: 0;
                //         right: 0;
                //         overflow: hidden;
                //         padding: 8px 10px;
                //         display: flex;
                //         align-items: center;
                //         justify-content: flex-start;
                //         transform: scale(0);
                //         transform-origin: bottom right;
                //         visibility: hidden;
                //         opacity: 0;
                //         transition: all 0.2s ease-in-out;

                //         img{
                //             height:44px;
                //             width:60px;
                //             margin-right: 8px;
                //         }
                //         p{
                //             font-size: 12px;
                //             line-height: 18px;
                //             margin:0px;
                //             text-align: left;
                //         }
                //     }
                // }

                .react-select-container{

                    .react-select__placeholder{
                        color: #424242;
                    }
                }

                &.select-location{

                    .react-select-container{

                        .react-select__control{
                            background-color: rgba(255, 159, 0, 0.15);
                            &--is-disabled{
                                background-color: rgba(255, 159, 0, 0.15)!important;
                                opacity: 0.5;
                            }
                        }
                    }
                }
            }

            .grand-total{
                padding: 0 20px;

                .select-currency{
                    margin-bottom: 14px !important;
                }
                .shipping-charge{
                    margin-top: 20px !important;
                }
                .tax-charge{
                    margin-top: -5px !important;  
                }
                .total-summary{
                    margin-top: 22px !important;
                }
            }
        }
        .payment-section{
            padding: 40px !important;
            padding-right: 17px !important;

            .review_heading{
                margin-bottom: 21px;

                .custom-checkbox{

                    .custom-control-label{
                        margin-bottom: 10px;
                    }
                }

                .pay-icons{
                    margin-left: 15px;

                    img{
                        margin-right: 7px;
                    }
                    .que-icon{
                        margin:0 7px;
                    }
                }
            }
            .usenewcreditcard{
                padding-left: 57px;
                position: relative;

                &::after{
                    content:"";
                    position: absolute;
                    left: 15px;
                    top: 103px;
                    height: 26px;
                    width: 1px;
                    border: 1px solid rgba(66,66,66,0.4);
                }
            }

            .custom-checkbox{

                .custom-control-label{
                    margin-bottom: 12px;
                }
            }
        }
        .purchase-footer{
            margin-top: 20px;

            .review_btn{
                margin-right: 20px;
                width: 300px;
            }
        }
        .purchase_card{
            color: #424242;
            height: 750px;
            overflow-y: auto;

            .purchase_detail{
                padding: 4rem;
                background-color: #fff;
                width: 541px;
                

                h4{
                    color: #424242;
                }

                .hardware_Detail{

                    .device_detail{
                        border: 2px solid rgba(216,216,216,0.2);
                        border-radius: 2px;
                        margin-bottom: 25px;

                        ul{
                            margin-bottom: 6px !important;
                            li{

                                &.bg_gray{
                                    background-color: rgba(216,216,216,0.2);
                                    border: 0;
                                }
                               
                            }
                        }

                        .extend_licence{

                            li{
                                border:0;
                                padding: 10px 20px;

                                &.mw-300{
                                    max-width: 273px;
                                }
                            }
                        }
                    }

                    .hardware_heading{
                        
                        .form-group{
                            margin-bottom: 26px;
                        }
                    }
                }
            }

            .practices_Detail{
                background-color: #F7F7F7;
                padding:43px 40px;
                width: 429px;
                margin-left: auto;

                h4{
                    color: #424242;
                    margin-bottom: 20px !important;
                }

                .numberof_orders{
                    margin-bottom: 20px;

                    p{
                        font-size: 12px;
                        color: rgba(66,66,66,0.6);
                        font-weight: 500;
                        margin-bottom: 5px;
                    }

                    span{
                        font-size: 22px;
                        font-weight: 600;
                    }
                }

                .select-location{
                    label{
                        font-size: 12px;
                    }

                    .react-select{

                        &__control{
                            background-color: #fff;
                        }
                        &__placeholder{
                            font-size: 14px;
                            color: rgba(66,66,66,0.4);
                        }
                    }
                }
            }
        }
        .transparent__form--white{
            padding: 2rem;
            width: 1010px;
            height: 877px;
            margin-top: -13px;
            .inner_white_box{
                .order_number{
                    
                    .arrow{
                        margin-left: 15px;
                        .btn{
                            padding: 0;
                            height: 26px;
                            width: 15px;

                            svg{
                                width: 19px;
                                height: 19px;
                                transform: rotate(90deg);

                                path{
                                    fill: rgba(66,66,66,0.2);
                                }
                            }

                            &.next-click{
                                margin-left: 11px;
                                svg{
                                    transform: rotate(-90deg); 
                                }
                            }
                        }
                    }
                }
                &.purchase_header{
                    padding: 1.9rem 4rem;
                    
                    .total_summary{
                        .d-flex{
                            float: right;
                        }
                    }
                    .total_order,
                    .order_amount{
                        margin-left: 32px;

                        h4{
                           span{
                            color: #FF9F00;
                            margin-left: 3px;
                           } 
                        }
                    }
                }
            }
            .order_number{

                .arrow{
                    margin-right: auto;
                    margin-left: 20px;
                }
            }
        }

    }
    &__item{
        &__divider{
            background-color:rgba(255,255,255,0.6);
            height: 107px;
            width: 1px;
            top: 54px;
            position: relative;
        }
    }
    &__box{
        height: 250px;
        width: 270px;
        border-radius: 7px;
        background-color: rgba(255,255,255,0.08);
        cursor: pointer;
    }
    &__name{
        text-transform: uppercase;
    }
}