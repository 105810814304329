@import "../../scss/utils/variables";

.manage{
    &__main{
        &__screen{
            // background-image: url('../../img/InvoicesViewBG.png');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: #fff;

            .tabs{
                margin-top: -48px;
                width: 251px;
            }

            .managing-box{
                margin-right: 55px !important;
            }
        }
    }
    
}
.managecard{
    &__page{

    }
    &__suggestion{
        width: 268px;
        margin-top: -142px;
        p{
            color:#ffffff;
            max-width: 223px;
        }
        h4{
            margin:0px;
            font-size: 20px;
            line-height: 50px;
            color:#FFFFFF;
            font-weight: 400;

            span{
                color:#FF9F00;
                font-size: 50px;
                font-weight: bold;
            }
        }
        .divider{
            background-color: rgba(255,255,255,0.29);
            height: 2px;
            margin: 29px 0;
        }
    }
    &__inner{

        &__panel{
            padding:33px 40px 34px 42px;
            width: 1274px;
            height:805px;
            border-radius: 7px;
            position: relative;



        }
        .search-keyword{
            margin-right: 26px;
        }
        .search-section{
            margin-top: 20px;
        }

        &__body{
            margin-top: 23px;
            .add_new_location{
                button{
                    width: 444px !important;
                    margin-top: 36px;
                    margin-bottom: 34px;
                }
            }
            table{
                border:0px;
                text-align: left;
                thead{
                    // display: table;
                    // width: 100%;
                    // table-layout: fixed;
                }
                th , td{
                    border:0px;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 22px;
                    color:#FFFFFF;
                    text-transform: uppercase;
                    vertical-align: middle;

                    &:first-child{
                        padding-left: 0;
                    }
                }
                // tbody{
                //     display: block;
                //     max-height:368px;
                //     padding: 0 20px;
                //     margin: 0 -20px;

                //     tr{
                //         display: table;
                //         width: 100%;
                //         table-layout: fixed;
                //     }
                // }
                td{
                    text-transform: none;
                    font-weight: 300;
                    border-bottom: 1px solid rgba(255,255,255,0.3);
                    padding:1.83rem 0.75rem;
                    
                    &:first-child{
                        padding-left: 0;
                    }

                    &:last-child{
                        padding-right: 0;
                    }
                    button{

                        &.btn-md{
                            width: 167px;
                            float: right;
                        }
                       
                    }
                    img{
                        margin-right: 14px;
                    }
                    .down-arrow{
                        height:32px;
                        width: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .down-arrow img{
                        max-width: 20px;

                    }
                    &.unassign{
                        color: #FF4D54;
                    }

                    &.pending{
                        color:#FF9F00;
                    }
                }
            }
        }

        &__header{
            position: relative;
            .location-img{
                height: 56px;
                width: 61px;
                position: absolute;
                right: 10px;
                top: 1px;
            }

            h4{
                margin-top: 10px;
                margin-bottom: 6px;
            }
            &__tab{
                display: flex;
                font-size: 24px;
                margin: 0 -10px;
                margin-right: 84px;

                li{
                    margin: 0 10px;
                    color: rgba(255,255,255,0.6);

                    &.active{
                        color: rgba(255,255,255,1);
                        border:none;
                    }
                }
            }
        }
    }
}

.manage{
    &__page{

    }
    &__inner{

        &__header{


            h4{
                margin:0px;
                font-size: 20px;
                line-height: 26px;
                color:#FFFFFF;
                font-weight: 400;
            }
            h3{
                font-size: 50px;
                line-height: 50px;
                color:#FFFFFF;
                margin:0px;
            }

        }

    }

}

@media (max-width:1700px){
    .app .notification__page.add_manage_location .add_manager_section{
        margin-left: 0!important;
        width: 100%;
    }
    .notification__page.add_manage_location .notification__inner{
        width: 80%!important;
        margin: 0 auto;
        min-height: 700px!important;
    }    
}
@media (max-width:1200px){
    .notification__inner .add_manage_header > .d-flex h4{
        display: none;
    }
    .app .notification__inner__body table th, .notification__inner__body table td,
    .app .notification__inner__body table thead th:nth-child(2), .notification__inner__body table tbody tr th:nth-child(2),
    .app .notification__page.add_manage_location .notification__inner__body.add_manage_table table tbody tr td
    {
        padding: 0.75rem!important;
    }
    .notification__inner__body table thead th:last-child, .notification__inner__body table tbody tr th:last-child{
        width: 150px!important;
    }
    .notification__inner__body.add_manage_table table thead th:nth-child(2), .notification__inner__body.add_manage_table table tbody tr th:nth-child(2){
        width: 363px!important;
    }
    .notification__inner__body.add_manage_table table thead th:first-child, .notification__inner__body.add_manage_table table tbody tr th:first-child{
        width: 183px!important;
    }
    .notification__inner__body table thead, .notification__inner__body table tbody tr{
        table-layout:auto!important;
    }
}