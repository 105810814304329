@import "../../scss/utils/variables";


.referral{
    &__main{
        &__screen{
           // background-image: url('../../img/Background_imageSettings.jpg');
           background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;

                .practice_name{
                    font-size: 18px;
                }
        }
    }
    &__page{

    }
    &__inner{
        padding:38px 40px;
        width: 1058px;
        border-radius: 7px;
        background-color: rgba(255,255,255,0.08);
        min-height: 704px;
        max-height: 704px;
        margin-top: -70px;

        &__header{
            padding-right:80px;
            position: relative;

            .no_referal_user{
                position: absolute;
                top: -2px;
                right: 5px;
                max-width: 64px;
            }

            h4{
                margin:0px 0px 15px 0;
                font-size: 20px;
                line-height: 26px;
                color:#FFFFFF;
                font-weight: 400;
            }
            p{
                font-size: 15px;
                line-height: 22px;
                color:#FFFFFF;
                margin:0px;
            }


        }
        &__body{

            padding:40px 0 0px 0;

            table{
                border:0px;
                text-align: left;
                margin: 0;
                tbody{
                    display: block;
                    max-height: 410px;
                    overflow-y:auto;
                    overflow-x: hidden;
                }
                thead, tbody tr {
                    display: table;
                    width: 100%;
                    table-layout: fixed;/* even columns width , fix width of table too*/
                }
                th{
                    padding: 17px 5px 0px;
                }
                th , td{
                    border:0px;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 22px;
                    color:#FFFFFF;
                    text-transform: uppercase;
                    vertical-align: middle;

                    &:first-child{
                        padding-left: 0;
                        width: 171px;
                    }
                    &:nth-child(2){
                        width: 182px;
                    }
                    &:nth-child(3){
                        width: 288px;
                    }
                    &:last-child{
                        width: 180px;
                    }
                }
                td{
                    text-transform: none;
                    font-weight: 300;
                    border-bottom: 1px solid rgba(255,255,255,0.3);
                    padding:19px 7px;
                    span{
                        height:10px;
                        width:10px;
                        display: inline-block;
                        margin-right: 10px;
                    }
                    .color-yellow{
                        background-color:#FF9F00;
                    }
                    .color-red{
                        background-color:#D0393E;
                    }
                    .color-green{
                        background-color:#4DA166;
                    }
                }
            }

            .referral__empty{
                background-color:#FFFFFF;
                border-radius: 5px;
                max-width: 720px;
                margin: 0 auto;
                margin-top: -11px;
                height: 481px !important;

                h4{
                    font-size: 36px;
                    line-height: 43px;
                    color:#424242;
                    margin:68px 0px -33px 0;
                }
                p{
                    font-size: 15px;
                    line-height: 22px;
                    color:#424242;
                    font-weight: normal;
                    margin:0px;
                    max-width: 532px;
                    text-align: center;
                    margin-top: 45px;
                }
                &__btn{
                    margin-top: 127px;
                    width: 460px;

                    Button{
                        white-space: nowrap;
                        max-width: 100%;
                    }
                }
            }
        }
        &__footer{

            ul{
                li{
                    display: inline-block;
                    margin: 0 8px;
                    
                    a{
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 22px;
                        color:#FFFFFF;
                        text-transform: uppercase;
                        vertical-align: middle;
                        letter-spacing: 0.5px;
                        display: block;
                        padding: 8px;
                    }
                    .activepage{
                        color:#FF9F00;
                    }
                }
            }
        }
        
        &.no_referred_header{
            margin-top: -70px;

            .referral_doctor{
                max-width: 978px;
                height: 511px !important;

                &.referral__empty {
                    max-width: 978px;
                    height: 511px !important;

                    h4{
                        margin: -2px 0px -33px 0 !important;
                    }
                }
            }

            .no_referred_subheader{
                position: relative;


            }
        }
    }

    &__subscribe{

        &__inner{
            width: 800px !important;
            min-height: 653px !important;
            max-height: 653px !important;
            margin-top: -70px !important;
        }
    }
}

@media (max-width:1700px){
    .referral__inner{
        margin-top: auto!important;
    }
}