@import "../../scss/utils/variables";


.chairside_education{
    &__main{
        &__screen{
            //background-image: url('../../img/Background_imageChairside.jpg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;
            .search-page{
                .search-video-page{
                    // display: flex;
                    // margin: 0 -15px;

                    // .search-page-video{

                    //     .card{
                    //         margin: 0 15px;
                    //     }
                       
                    // }
                }

                .search-page-video{

                    .card{
                        width: 356px;
                        height: 200px;
                        position: relative;
                        margin:0 15px;
                        margin-bottom: 30px;

                        video{
                            width: 100%;
                            height: 100%;
                        }

                        p{
                            position: absolute;
                            bottom: 18px;
                            left: 0;
                            right: 0;
                            color: #fff;
                            text-align: center;
                            font-size: 15px;
                            max-width: 230px;
                            margin: 0 auto;   
                        }

                        &::before{
                            content: "";
                            position: absolute;
                            background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
                            left:0;
                            right: 0;
                            bottom: 0;
                            top: 0;
                            z-index: 0;
                        }
                    }
                }
            }


            .swiper-slide {
                width: 356px !important;
                height: 200px;
                margin-right: 30px !important;
                // margin-left: 30px;
                margin-bottom: 30px;
                .card{
                    width: 100%;   
                    position: relative;
                    border:none;
                    border-radius: 2px;
                    // width: 356px !important;
                    .chairsidevideo{
                        height: 100%;
                        img{
                            border-radius: 2px;
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                    }   
                    video{
                        height: 100%;
                        width: 100%;
                    }
                    h4{
                        position: absolute;
                        bottom: 18px;
                        left: 0;
                        right: 0;
                        color: #fff;
                        text-align: center;
                        font-size: 15px;
                        max-width: 230px;
                        margin: 0 auto;    
                        text-transform: uppercase;
                        span{
                            text-transform: none;
                        }
                    }

                    &::before{
                        content: "";
                        position: absolute;
                        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
                        left:0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                        z-index: 0;
                    }
                }
            }

            .swiper-button-prev{
                // display: none !important;
                width: 47px;
                top: 35px !important;
                bottom: 0;
                padding-top: 0 !important;
                margin-top: 0;
                left: 0px !important;
                color: #ffffff !important;
                // background-color: rgba(255,255,255,0.08);

                &::after{
                    top: 46%;
                    position: absolute;
                    left: 20px;
                }
            }
            .swiper-button-next{
                right:111px !important;
                color:#ffffff;
                top:51%;

                &::after{
                    font-size: 22px !important;
                }
            }
            .MuiTabs-indicator{
                display: none;
            }
            // .MuiTabs-vertical{

            //     button{
            //         min-width: 20px;
            //         max-width: 89px;
            //         padding: 12px 12px;
            //     }
            // }

            .header--sub{
                padding: 16px 18px;

                .practice_name{
                    font-size: 18px;
                }
            }
        }
    }
    &__inner{
        &__screen{
            .sidebar__btn{
                padding: 0 12px;
            }
            .tabcontent{
                max-width: 1560px;
                margin: 0 auto;
                width: 100%;
                &.home{
                    max-width: 91%;
                    margin-left: 180px;
                    .MuiBox-root{
                        padding: 24px 0px;
                    }
                }
            }
            .MuiBox-root{

                .MuiTypography-root{

                    .swiper-container-horizontal{

                        .swiper-button-disabled{
                            display: none;
                        }
                    }
                }
            }
            .MuiBox-root{
                padding-right: 0;
            }

            .menu-button{

                &:hover{
            
                    .second-sidebar{
                        display: block;
                    }
                }
            }

            #video-slider-disable .swiper-button-prev  {
                // display: none;
              }
              #video-slider-enable .swiper-button-prev  {
                display: block;
                // background-image: url(../../img/Background_imageBilling.jpeg);
                background-size: 300px 100px;
                height: 200px;
                top: 12%;
                padding-top: 81px;
                margin-left: 0px;
                left: 30px !important;
                color: #ffffff;
                
              }
              
                #video-slider-enable  .swiper-button-next,
                #video-slider-disable .swiper-button-next{
                    font-size: 15px!important;
                    display: block;
                    background-size: 339px 100px;
                    height: 200px;
                    width: 60px;
                    top: 35px;
                    bottom: 0;
                    padding-top: 0 !important;
                    margin-top: 0;
                    margin-left: 0px !important;
                    color: #ffffff !important;
                // background-color: rgba(255, 255, 255, 0.08);
                    right: 0px !important;
                // backdrop-filter: blur(6px);

                &::after{
                    top: 46%;
                    position: absolute;
                    right: 20px;
                }
                }

                #video-slider-enable  .swiper-button-next ::after {
                visibility: hidden;
                }
                #video-slider-enable  .swiper-button-prev,
                #video-slider-disable .swiper-button-prev{
                    // backdrop-filter: blur(6px);
                }
                .MuiBox-root{
                    padding-right: 0;
                }
        }
        &__text{
            color: $white;
            font-size: 2rem;
            line-height: 3rem;
            max-width: 67rem;
            h1{
                margin-top: 2rem;
            }
            h3{
                font-size: 2.7rem;
                font-weight: 500;
                letter-spacing: 7px;
                margin-bottom: 3rem;
            }
            h1,h2,h3,h4,h5,h6{
                color: $white;
            }
        }
    }
    
   
}
.hover_description{
    color:white;
    // background: url("https://s3.ap-south-1.amazonaws.com/thalamus.media/images/chairside_media/thumb_image/temp/1615382482.jpg") no-repeat right, black;
    background-image: 
        linear-gradient(180deg, rgba(0, 0, 0), rgba(0, 0, 0, 0.58)), url("https://s3.ap-south-1.amazonaws.com/thalamus.media/images/chairside_media/thumb_image/temp/1615382482.jpg") no-repeat right;
        background-blend-mode: lighten;
        width: 100%;
        height: 259px;
    &__title{
       font-size: 36px;
        text-transform: uppercase;
        letter-spacing: 0.2px;
        margin-bottom: 17px;
        span{
            text-transform: none;
        }
    }
    &__duration{
        font-size: 13px;
        margin-bottom: 31px;
    }
    &__description{
        font-size: 13px;
        width: 694px;
        color: $white;
        height: 79px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;

        p,span{
            color: $white!important;
        }
        p{
            display: contents;

            &::after{
                content: "\A";
                white-space:pre;
            }
        }
    }
    &__image{
        // margin-left: 1060px;
        // margin-top: -205px;
        // opacity: 0.5;
        // height: 150px;
    }
}

.search_main{
    width: 1135px;
    background: linear-gradient(90deg, rgba(255,255,255,0.08) 0%, rgba(255,255,255,0) 100%);
    padding:2px 32px 10px;
    border: 0px;
    color: white;
    height: 75px;
    font-size: 27px;
    border-radius: 5px 5px 5px;
}
.search_main::placeholder{
    font-size: 27px;
}
.card-border{
    border:1px solid yellow!important;
    width:28rem!important;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

.MuiPaper-root {
    backdrop-filter: blur(10px);
    color: #fff;
    background-color: rgba(0,0,0,0.26);
}

.MuiBox-root{

    .MuiTypography-root{

        .MuiGrid-container{
            // position: fixed;
            // top: 0;
            // left: 0;
            // right: 0;
            // bottom: 0;
            // z-index: 9999;
            // background-color: #000;

            .MuiGrid-grid-lg-3{
                max-width: 23.5%;
                flex-basis: 23.5%;
            }
            .MuiGrid-grid-lg-9{
                max-width: 76.5%;
                flex-basis: 76.5%;
                padding-top: 88px;

                .pintura-editor{

                    background-color: #000;
                    position: relative;
                    max-height: 864px;
                    .PinturaRootWrapper{

                        .PinturaRootComponent{

                            .PinturaPanel{
                                padding-top: 40px;
                                .PinturaUtilMain{

                                }
                                .PinturaUtilFooter{
                                    color: #fff;
                                    position: absolute;
                                    top: 25px;
                                    left: 0px;
                                    right: 0;
                                    font-size: 24px;
                                    width: 100%;

                                    & > div{
                                        flex-direction: column-reverse;
                                        align-items: baseline;

                                        .PinturaControlListScroller{
                                            min-height: 2.375em;  
                                            flex: 1 1 0;
                                            text-align: left;
                                            >div{
                                                padding: 0;
                                            }
                                            ul{

                                                li{
                                                    height: 35px;
                                                    width: 160px;
                                                    border: 2px solid #FFFFFF;
                                                    border-radius: 2px;

                                                    &:first-child{
                                                        display: none;
                                                    }
                                                    &:nth-child(2){
                                                        display: none;
                                                    }
                                                    &:nth-child(3),
                                                    &:nth-child(4),
                                                    &:nth-child(5),
                                                    &:nth-child(6){
                                                        
                                                        &.PinturaRadioGroupOption{
                                                            // border:none;
                                                            width: auto;
                                                            // display: block;
                                                            // margin-left: 0;
                                                            // background-color: rgba(255, 255, 255, 0.08);

                                                            label{
                                                                text-indent: -9999px; 
                                                                font-size: 21px;
                                                                svg{
                                                                    display: block;
                                                                    margin-top: 10px;
                                                                    
                                                                }
                                                            }
                                                        }
                                                    }
                                                    // &:nth-child(6){
                                                    //     margin-left: 10px !important;
                                                    // }
                                                    
                                                    &.PinturaRadioGroupOption{
                                                        label{
                                                            line-height: 35px;
                                                            margin-bottom: 0;
                                                            min-height: 35px;
                                                            text-align: center;
                                                            text-transform: uppercase;
                                                            padding: 0px 1.75em;
                                                            font-size: 20px;
                                                            box-shadow: none;
                                                            background-color: transparent;
                                                            font-family: $SFProTextBold;
                                                            svg{
                                                               display: none; 
                                                            }
                                                        }

                                                        
                                                    }
                                                    
                                                }
                                            }

                                            .PinturaRadioGroup{

                                            }
                                        }
                                    }

                                    .PinturaControlPanels{
                                        max-width:100%;
                                        margin-left: 0px;

                                        .PinturaScrollable{
                                            justify-content: left;
                                            border-radius: 2px;
                                            background-color: rgba(255,255,255,0.08);
                                            
                                            > div{
                                                padding: 0;
                                            }
                                            .PinturaRadioGroupOptions{
                                                flex-direction: row-reverse;
                                            }
                                            ul{
                                                
                                                li{
                                                    margin-left: 0;
                                                    
                                                    display: flex;
                                                    flex-direction: row;
                                                    align-items: center;
                                                    padding: 5px 15px;
                                                    border-right: 1px solid rgba(255,255,255,0.1);
                                                    border-radius: 0;

                                                    .PinturaShapeStyleLabel{
                                                        color: #fff;
                                                        font-weight: 600;
                                                        text-transform: capitalize;
                                                        display: none;
                                                    }

                                                    .PinturaDropdownButton{
                                                        width: 150px;
                                                        box-shadow: none;
                                                        border: none;
                                                        text-align: left;

                                                        .PinturaButtonInner{
                                                            display: block;
                                                        }
                                                        .PinturaButtonLabel{
                                                            display: block;
                                                            position: relative;
                                                            text-align: left;

                                                            &::after{
                                                                content: "";
                                                                color: #FF9F00;
                                                                position: absolute;
                                                                border-left: 8px solid transparent;
                                                                border-right: 8px solid transparent;
                                                                border-bottom: 8px solid #FF9F00;
                                                                border-top:0;
                                                                right: 2px;
                                                                top: 15px
                                                            }
                                                        }
                                                    }

                                                    .PinturaButtonLabel{

                                                        .PinturaColorPreview{
                                                            border-radius: 4px;
                                                            height: 30px;
                                                            width: 30px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .PinturaNavSet{
                        position: absolute;
                        right: 128px;

                        .PinturaButton{
    
                            .PinturaButtonIcon{

                                    color:#eee;
                                    font-size: 25px;
                                // g{

                                //     g{
                                //        path + path{
                                //            fill:#fff !important;
                                //        }
                                //     }
                                // }
                            }

                            &.PinturaButtonIconOnly{

                            }
                        }
                    }
                    .PinturaNavTools{
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 100%;
                        .PinturaNavGroup{

                            .PinturaButtonExport{
                                margin-left: 19px;
                                height: 35px;
                                width: 64px;
                                background-color: #fff;
                            }
                        }
                    }
                }
            }
        }
        .ediImage__left_side__screen{
            padding: 0 54px 0 96px;
            padding-top: 203px;

            form{
                width: 100% !important;

                .ediImage__left_side__screen_label{
                    font-size: 14px !important;
                    color: white !important;
                    line-height: 22px !important;
                    margin-bottom: 3px;
                }

                input{
                    height: 40px;
                    width: 100%;
                    border: 0;
                    border-radius: 2px;
                    background-color: rgba(216,216,216,0.2);
                    color: #fff;
                }

                hr{
                    margin-bottom: 28px;
                }
                .ediImage__left_side__screen_textArea{
                    height: 170px !important;
                    width: 300px !important;
                    border-radius: 2px;
                    background-color: rgba(216,216,216,0.2);
                    font-size: 15px;
                    padding: 9px 10px 40px 10px;
                    line-height: 22px;
                    margin-bottom: 0px;
                }

                .ediImage__left_side__screen_button{
                    height: 40px;
                    width: 300px;
                    border-radius: 2px;
                    margin-bottom: -10px;

                }
                button + br + br + button{
                    margin-bottom: 49px !important;
                }
                &:last-child{
                    margin-bottom: 49px;
                }
               
            }

            button{
                height: 40px;
                width: 300px;
                border-radius: 2px;
            }

            h5{
                font-size: 20px;
                margin-left: 0;
                margin-bottom: 17px;
            }
        } 
    }  
}
.PinturaControlListScroller ul li[data-selected ="true"]{
    background-color: #fff;
    color: #424242;
}
.PinturaControlListScroller ul li[data-selected ="true"]{
    background-color: #fff;
    color: #424242;
    
}

// .PinturaControlListScroller ul li label{

//     &[title="Drawing"]{

//         :not(&)[data-selected ="true"]{

//            :not(&).PinturaRadioGroupOption{

//                &:nth-child(3),
//                &:nth-child(4),
//                &:nth-child(5),
//                &:nth-child(6){
//                    display: block !important;
//                }
//            }
//         }
//     }
    
// }
.PinturaRootPortal{

    .PinturaDetailsPanel{

        border-radius: 5px;
        min-width: 150px;
        .PinturaDropdownPanel{

            .PinturaRadioGroup{

                .PinturaRadioGroupOptions{

                    .PinturaRadioGroupOption{

                        label{
                            span{
                                font-size: 18px;
                                font-family: 'Roboto', sans-serif;
                                font-weight: 400;
                            }

                            
                        }

                    }
                }
            }
        }
    }
}

.fullscreenvideo{

    // .MuiGrid-align-items-xs-center{
    //     top:30px;
    //     z-index: 9;
    //     padding: 0 40px;

    // }

    .video_overly {

        h2{
            font-size: 35px;
            margin-top: -31px;
        }
    }

    // div + div + div{
    //     > div{
    //         &.MuiGrid-root {
    //             display: none;
    //         }
    //     }
    // }
}




.chairside_education_playlist{
    &__main__screen{
        background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
        background-size: 100% 100%;
        padding-top: 6rem;
        color: $white;
    }
    &__inner_screen{
        width: 100%;
        max-width: 1630px;
    }
    &__left_sidebar{
        padding: 42px 40px 46px;
        max-width: 430px;
        align-self: flex-start;
        h6{
            font-size: 19px;
            font-weight: 400;
            letter-spacing: 0.4px;
        }
       
    }
}


@media (max-width:1700px){
    .chairside_education__main__screen,
    .chairside_education_playlist__main__screen{
        min-height: 100vh;
    }
    .chairside_education__inner__screen .tabcontent{
        max-width: 90%;
        margin-left: 60px;
    }
    .chairside_education__inner__screen .tabcontent.home{
        max-width: 90%;
        margin-left: 60px;
    }
    .chairside_education__main__screen .swiper-slide{
        width: 250px!important;
        height: 150px;
        margin-right: 20px !important;
        margin-bottom: 20px;
    }
    .chairside_education__main__screen .swiper-slide .card{
        height: 100%;
    }
    .chairside_education__inner__screen #video-slider-enable .swiper-button-next, 
    .chairside_education__inner__screen #video-slider-disable .swiper-button-next,
    .chairside_education__inner__screen #video-slider-enable .swiper-button-prev{
        height: 150px;
        top: 33px;
    }
    .myListCatelogue{
        margin: -14px 0 0 0;
        height: 80vh;
        display: flex;
        gap: 0px 20px;
        padding: 33px 20px 15px 20px;
    }
    .myListCatelogue .card:hover{
        transform: translateY(-10px) scale(1.05);
    }
    .myListCatelogue .mylist-box{
        width: calc(24% - 12px);
    }
    .myListCatelogue .card{
        height: 150px;
        width: 100%!important;
        margin: 0 0 20px 0!important;
    }
 
    .chairside_playlist_main_screen{
        .px-20{
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
    .chairside_playlist_main_screen .chaiside_create_play_list_box{
        height: 250px!important;
        width: 100%!important;
    }
    .placeholder__box{
        height: 150px!important;
        width: 100%!important;
        max-width: 250px;
        img{
            max-width: 50px;
        }
        p{
            font-size: 12px!important;
        }
    }
    .home_swipers{
        height: 80vh;
    }
    .dropdown-chevron{
        width: 97%;
        left: 20px;
    }
   .normalscreen{
    max-width: 100%!important;
   }
   .shareBtn{
    margin: 0 20px!important;
   }
   .chairside_education_playlist__inner_screen{
        max-width: 80%;
        gap: 20px;
        padding: 0px;

        .waitiing-room-col-sec{
            gap: 15px;
            flex-wrap: nowrap;
            padding-bottom: 20px;
        }
        .waitiing-room-col-sec .waiting-room-left-sec{
            width: 30%;
            margin: 0!important;
           
            .videolist__main__screen{
                padding: 2rem!important;
                font-size: 1.2rem;
                height: 65vh!important;
                .videolist__main__header__header-title{
                    font-size: 16px;
                    line-height: normal;
                }
                .videolist__submain__header__title{
                    font-size: 1.2rem;
                }
            }
        }
        .waitiing-room-col-sec .waiting-rom-right-sec{
            width: 100%!important;
            height: 100%!important;
        }
        .videolist__main__lists{
            overflow-x: hidden;
            overflow-y: auto;
        }
        .waitiing-room-col-sec .waiting-rom-right-sec .videoSelected__main__centerCol .video_list .videoSelected_box{
            max-width: 31%!important;
            height: 150px!important;
            min-width: 31%!important;
            margin: 0px!important;
        }
        .waitiing-room-col-sec .waiting-rom-right-sec .videoSelected__main__centerCol .video_list{
            gap: 10px;
            margin: 0!important;
        }
        .videoSelected__main__card{
            height: 150px!important;
        }
        .videoSelected__main__centerCol{
            height: 63vh!important;
        }
   }
   .chairside_education_playlist__left_sidebar{
        max-width: unset;
        padding: 2rem;
        font-size: 1.2rem;
    h6{
        font-size: 16px;
        line-height: normal;
    }
    .form-control, .react-tel-input .form-control{
        min-width: auto;
    }
   }
   .chairside_education_playlist__videoSection{
    padding: 0!important;
    max-width: 70%;
        img{
            width: 100%!important;
            height: auto!important;
        }
        .chairside_education_playlist__video_box{
            height: auto;
            h2{
                font-size: 1rem;
                line-height: normal;
            }
        }
        .normalscreen{
            width: 100%;
            min-height: auto;
        }
        .player_processbar{
            min-width: 40%!important;
        }
        .control_section {
            padding: 1.5rem 2rem!important;
        }
        
   }

   .channels__page {
    .dropbox__main__screen,
    .scroolable-sec,
    .timeline_item_view,
    .timeline_item{
        // height: 100px!important;
        width: 100%;
    }
    .img_view{
        // width: 150px;
    }
    .videoSelected__main__card__title{
        font-size: 12px;
    }

   
   }

   .chairside-edit-playlist-img{
        min-width: 130px!important;
        height: 85px!important;
    }
   .chairsidePlayer video{
        max-height: 430px;
   }
   .chairside_education_playlist--video__lists{
    width: 100%;
    max-width: 100%!important;
   }
   .chairside_education_playlist--video__lists .swiper-wrapper{
    width: 100%!important;
   }
   .chairside_education_playlist--video__lists .placeholder__box{
        width: 200px!important;
        height: 130px!important;
   }

  .app .custom-checkbox .custom-control-label{
    font-size: 1.2rem;
    padding-left: 3rem;
   }

   .channel_detail__page{
        min-height: 100vh;
    }
    .channel_detail__page .channel-detail{
        margin: 0 auto auto auto!important;
        width: 100%;
        padding: 0 70px;
    }

    .chairside_education__main__screen .header--sub .practice_name,
    .channel_detail__page .header--sub .practice_name{
        font-size: 1.4rem!important;
    }
    
    .screenshot-image-modal .modal-content{
        max-width: 90%;
    }
    .screenshot-image-modal .modal-content .screenshot-image-preview{
        height: auto;
    }
    .search_main{
        max-width: 90%;
    }

    .ediImage__left_side__screen{
        padding: 88px 2rem 2rem 2rem!important;
    }
    .pintura-editor{
        width: 100%!important;
    }
    .pintura_editor_main_section{
        height:100vh!important;
    }
    .MuiBox-root .MuiTypography-root .MuiGrid-container .MuiGrid-grid-lg-9 .pintura-editor{
        max-height: 800px!important;
    }
    .MuiBox-root .MuiTypography-root .MuiGrid-container .MuiGrid-grid-lg-9 .pintura-editor .PinturaRootWrapper .PinturaRootComponent .PinturaPanel{
        padding-top: 100px;
    }
    .app .playlist_modal .normalscreen video{
        max-height: 90vh!important;
    }

   html .app .ediImage__left_side__screen_textArea,
   html .app .ediImage__left_side__screen form .ediImage__left_side__screen_button,
   html .app .ediImage__left_side__screen button,
   .MuiBox-root .MuiTypography-root .ediImage__left_side__screen form .ediImage__left_side__screen_textArea{
        width: 100%!important;
    }
}

@media (max-width:1366px){
    .chairside_education_playlist__videoSection{
        padding: 0!important;
        max-width: 70%;
    }
    .chairside_education_playlist__inner_screen{
        gap: 20px;
        padding: 0px 20px;
    }
    .chairside-edit-playlist-img{
        min-width: 130px!important;
        height: 85px!important;
    }
    .home_swipers{
        height: 95vh;
    }
    .chairside_education_playlist__inner_screen .videoSelected__main__centerCol{
        height: 75vh !important;
    }
    .chairside_education_playlist__inner_screen .waitiing-room-col-sec .waiting-room-left-sec .videolist__main__screen{
        height: 100%!important;
    }
    .chairside_education__inner__screen .tabcontent{
        max-width: 100%;
        margin-left: 0px;
    }
}

@media (max-width:1200px){
    .myListCatelogue .mylist-box{
        width: calc(33% - 12px);
    }
}