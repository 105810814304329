@import "../../scss/utils/variables";

.initial_setup{
    &__main{
        &__screen{
            // background-image: url('../../img/Background_imageSetup.jpeg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;
            .fw400{
                font-weight: 400 !important;
            }
            .text--gray{
                color:rgba(255,255,255,0.6);
            }
            .add-pracinfo{
                .form-group{
                    .d-flex{
                        label{
                            font-size: 1.4rem;
                            line-height: 22px;
                        }
                    }
                }
            }
            .mw-820{
                min-width: 820px;
                padding: 38px 40px;

                form{
                    margin-top: 5px !important;

                    .form-heading{
                        margin-bottom: 30px !important;
                    }

                    .form-group{

                        label{
                            font-size: 14px;
                        }
                    }
                }
            }
            .mh-835{
                min-height: 83.5rem;
                min-width: 820px;
                margin-top: -29px;

                .form-heading{
                    margin-bottom: 26px !important;
                }
            }
            .padd-l-20{
                padding-left: 20px;
            }
            .padd-r-20{
                padding-right: 20px;
            }
            .margin-l-20{
                margin-left: 20px;
            }
            .margin-r-20{
                margin-right: 20px;
            }
        }
    }
    &__inner{
        &__screen{
            margin-top: -131px;

            button{
                margin-top: 26px !important;
            }

            .restricted_access{
                margin-top: 82px;
                max-width: 70rem;
                
                h2{
                    font-size: 50px;
                }
                p{
                    font-size: 20px;
                    max-width: 523px;
                    margin: 0 auto;
                }

                button{
                    margin-top: 36px !important;
                }
            }
        }
        &__text{
            color: $white;
            font-size: 2rem;
            line-height: 3rem;
            max-width: 67rem;
            h1{
                margin-top: 2rem;
                margin-bottom: 11px !important;
            }
            h3{
                font-size: 2.7rem;
                font-weight: 500;
                letter-spacing: 7px;
                margin-bottom: 3rem;
            }
            h1,h2,h3,h4,h5,h6{
                color: $white;
            }
            
        }
        
    }
   
}


@media (max-width:1700px){
    .initial_setup__main__screen .mw-820,
    .owners_profile_creation__inner__screen .mw-820{
        zoom: 0.9;
    }
    .initial_setup__main__screen{
        padding-bottom: 6rem;
        min-height: 100vh;
    }
}