@import "../../scss/utils/variables";

.help{
    &__screen{
        background-color: #242323 !important;

        .channels__item:last-child{
            position: relative;
            margin-left: 60px;
            
            &::after{
                content: "";
                position: absolute;
                left: -40px;
                top: 70px;
                bottom: 0;
                width: 1px;
                height: 107px;
                background-color: rgba(255, 255, 255, 0.6);
            }
        }

        .header--sub{
            margin-top: 80px !important;
        }

    }
}
.help{
    &__page{
       
        .help_sidebar{
            width: 345px;
            left: 0px;
            top: 168px;
            background: rgba(0, 0, 0, 0.26);
            backdrop-filter: blur(106.013px);
            border-radius: 0px 7px 0px 0px;
            padding: 40px 0px 40px 32px;
            margin-right: 71px;
            position: fixed;
            height: 929px;
            // overflow-y: auto;

            
            .help_menu{

                .heading{
                    font-size: 20px;
                    line-height: 26px;
                    font-weight: 600;
                    margin-bottom: 0;
                    color: #fff;
                    padding: 0;
                    min-height: auto;
                    width: 100%;
                    max-width: 100%;
                    cursor: auto;
                    .MuiTab-wrapper{
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                    }

                    img{
                        margin-right: 17px;
                    }
                }
                .help_submenu{
                    margin-left: 59px !important;
                    margin-top: 10px;
                    padding: 0;
                    margin-bottom: 13px;

                    li{
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 26px;
                        color: rgba(255, 255, 255, 0.6);

                        
                        .sidebar-item-text{
                            margin: 0;

                            span{
                                font-size: 14px;
                            }
                        }
                        &.active{
                            color: #fff;
                        }
                    }
                }
            }
        }
        .help_rightsidebar{
            
            width: 1368px;
            padding-bottom: 57px;
            margin-left: 416px;

            .search-input{
                margin-bottom: 57px;
                input{
                    width: 1370px;
                    height: 75px;
                    background: linear-gradient( 90deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.0001) 100%);
                    border-radius: 7px 0px 0px 7px;
                    box-shadow: none;
                    padding: 22px 0 21px 33px;
                    outline: 0;
                    font-weight: 500;
                    font-size: 27px;
                    line-height: 32px;
                    color: #FFFFFF;
                    border: none;
                    max-width: 100%;

                    &::placeholder{
                        font-size: 27px;
                        line-height: 32px;
                        color: rgba(255, 255, 255, 0.4);

                    }
                }

                .react-select-container{
                    max-width: 100%;

                    .react-select__control{
                        max-width: 100%;
                        opacity: 0;
                        display: none;
                    }
                }
            }

            .help_page_heading{

                h4{
                    font-weight: 500;
                    font-size: 27px;
                    line-height: 32px;
                    color: #FFFFFF;
                    margin-bottom: 30px;
                    margin-left: 28px;
                    display: inline-block;
                }
               

                .back-wrrow{
                    margin-top: -12px;
                    margin-left: 5px;
                }
            }

            .help_page_guide{
                background: rgba(255, 255, 255, 0.08);
                border-radius: 7px;
                padding: 35px 20px 30px 20px;
                margin-bottom: 35px;
                height: 520px;
                overflow: hidden;
                overflow-y: scroll;

                .help_description{
                    display: flex;
                    min-height: 284px;
                    margin-bottom: 91px;

                    .help_desc{
                        flex: 0 0 50%;
                        padding: 0 20px;
                        a{
                            color:#F99F1B;
                            display: inline-block;
                        }
                        h5{
                            font-size: 20px;
                            line-height: 26px;
                            color: #fff;
                            margin-bottom: 22px;
                        }

                        .help_detail{
                            color:  #FFFFFF;
                            p{
                                color:  #FFFFFF;
                                font-size: 15px;
                            }
                            ul{
                                margin-bottom: 20px;
                                list-style-type: auto;
                                margin-left: 20px;
                                color:  #FFFFFF;


                                li{
                                    font-size: 15px;
                                    line-height: 22px;
                                }
                            }
                        }
                    }

                    .help-img{
                        flex: 0 0 50%; 
                        align-items: center;
                        display: flex; 
                        justify-content: center;
                        padding: 0 20px;
                    }

                    &:nth-child(even){
                        flex-direction: row-reverse;
                    }

                    &.top-align{
                        align-items: flex-start;
                    }
                    &.center-align{
                        align-items: center;
                    }
                }
            }
            .help_contact_us{
                background: rgba(255, 255, 255, 0.08);
                border-radius: 7px;
                padding: 35px 30px 30px 39px;
                text-align: center;

                p{
                    font-size: 27px;
                    line-height: 27px;
                    font-weight: 500;
                    margin-bottom: 25px;
                    color: #FFFFFF;
                }

               
            }

            .MuiBox-root {
                padding: 0;

                p{
                    
                }
            }
        }

    }
    &__item{
        &__divider{
            background-color:rgba(255,255,255,0.6);
            height: 107px;
            width: 1px;
            top: 54px;
            position: relative;
        }
    }
    &__box{
        height: 250px;
        width: 270px;
        border-radius: 7px;
        background-color: rgba(255,255,255,0.08);
        cursor: pointer;
    }
    &__name{
        text-transform: uppercase;
    }
}
.app{
    .contact{
        &__main{
            &__screen{
                background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
                padding-top: 6rem;
                color: $white;
            }
        }
        &__inner{
            &__screen{
                width: 100%;
                max-width: 1392px;
                margin: 0 auto;
            }
        }
        &__header{
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.0001) 100%);
            backdrop-filter: blur(32.6194px);
            border-radius: 7px 0px 0px 7px;
            padding: 2rem 4rem;
            margin-bottom: 30px;
            label{
                font-weight: 500;
                font-size: 20px;
            }
            p{
                font-size: 20px;
            }
        }
        &__content {
            background: rgba(255, 255, 255, 0.08);
            backdrop-filter: blur(32.6194px);
            border-radius: 7px;
            padding: 4rem;
            color: $white;
            h1{
                color: $white;
                font-size: 40px;
            }
            img.custome_icon{
                max-height: 20px;
            }
            .custome_icon{
                max-width: 20px;
            }
            .inputBox{
                position: relative;
                display: inline-block;
                span{
                    position: absolute;
                    bottom: 5px;
                    left: 10px;
                    right: 10px;
                    height: 1px;
                    background-color: #EAEAEA;
                }
            }
            .col-6{
                .react-select-container{
                    max-width: 100%;
                }
            }
            .react-select-container{
                // max-width: 50%;
                width: 100%;
                .react-select__control{
                    max-width: none;
                    width: 100%;
                    background: rgba(255, 255, 255, 0.08);
                    backdrop-filter: blur(32.6194px);
                    border-radius: 7px;
                }
                .react-select__menu{
                    background: rgba(19, 19, 19, 0.9);
                    // border: 1px solid #F99F1B;
                    box-sizing: border-box;
                    backdrop-filter: blur(60px);
                    border-radius: 7px;
                }
            }
            input{
                background: rgba(255, 255, 255, 0.08);
                backdrop-filter: blur(32.6194px);
                border-radius: 7px;
                position: relative;
                &:focus{
                    border: 0!important;
                }
                &:focus + span{
                    background-color: $primary-color;
                }
            }
            textarea.form-control{
                background: rgba(255, 255, 255, 0.08);
                border: 1px solid #FFFFFF;
                box-sizing: border-box;
                backdrop-filter: blur(32.6194px);
                border-radius: 7px;
                min-height: 140px;
                // max-width: 50%;
                color: $white;
                &:focus{
                    border-color: $primary-color;
                }
            }
        }
    }
}
.active-color {
    color: #fff !important;
  }

@media (max-width:1700px){
  .help__page .help_sidebar{
    position: static;
    width: 30%;
  }
  .help__page .help_rightsidebar{
    margin-left: 0;
    width: 70%;
    padding-right: 20px;
  }
  .app .help_rightsidebar .MuiBox-root{
    max-width: 100%!important;
  }
}