@import "../utils/variables";


.tooltip{
    font-size: $body-font!important;
    padding: 0!important;
    
    .arrow{
        display: none;
    }
    .tooltip-inner{
        border-radius: 1px;
        background-color: rgba(0,0,0,0.26);
        line-height: 14px;
        text-align: center;
        font-size: 12px!important;
    }
}

.help_tooltip{
    .tooltip{
      .tooltip-inner{
        border-radius: 1px;
        background-color: rgba(0,0,0,1)!important;
        line-height: 16px;
        text-align: center;
        font-size: 12px!important;
        padding: 3px;
        margin-top: 5px;
      }
    }
  }