@import "../../scss/utils/variables";

.dropbox{
    &__main{
        &__screen{
            background: linear-gradient(#30302f,#292929);
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            border-radius: 10px 10px 10px;
            height: 130px;
            width: 100%;
            margin-top: 15px;
        }
        &__label{
            text-align: center;
            margin-top: 10px;
            font-size: 30px;
        }
        &__dropimage{
            margin-top: 25%;
            
        }
        &__screen1{
            width: 100%;
            margin-left: 20px;
          
        }
    }
}