@import "../../scss/utils/variables";

.file_upload{
    &__section{
        
    }
    &__box{
        box-sizing: border-box;
        height: 209px;
        width: 480px;
        border: 1px solid rgba(255,255,255,0.3);
        border-radius: 7px;
        position: relative;
        // padding: 3rem;
    }
    &__item{
        padding: 1rem 2rem;
        border-radius: 8px;
        background: #F2F2F2;
        position: relative;
        z-index: 5;
        min-height: 68px;
        &--name{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 1.4rem;
            color: #131313;
            max-width: 170px;
        } 
    }
    &__close_btn{
        height: 20px;
        width: 20px;
        min-width: 20px;
        border-radius: 20px;
        background-color: #131313;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 0 15px;
        svg{
            // width: 8px;
        }
    }
    &__progress{
        width: 100%;
        // max-width: 15.4rem;
        height: 1rem;
        position: relative;
        margin-bottom: 4px;
        // margin-left: 33px;
        border-radius: 20px;
        background: #D9D9D9;
        &.text-danger,
        &.text-danger.completed{
            .progress-bar{
                background: $color-danger!important;
                .clear__icon{
                    svg{
                        fill:#000000!important;
                    }
                }
            }
        }
        &.completed{
            .progress-bar{
                background: linear-gradient(109deg, #FF9F00 0%, #FFDAA5 100%);
                svg{
                    fill:#fff!important;
                }
            }
        }
        .progress-bar{
            background: linear-gradient(109deg, #FF9F00 0%, #FFDAA5 100%);
            
        }
        .clear__icon{
            position:absolute;
            right: 5px;
            z-index: 5;
            cursor: pointer;
        }
    }
    &__drug__file__section{
        background-color: rgba(#D8D8D8 ,20%);
        width: 100%;
        font-size: 13px;
    }
}

.error{
    &--label{
        border-radius: 8px;
        background: rgba(208, 57, 62, 0.10);
        color: rgba(208, 57, 62, 0.80);
        padding: 1rem 1rem;
        font-weight: 500;
        display: block;
        // max-width: 15.4rem;
        margin-left: 15px;
    }
    &--message{
        border-radius: 2px;
        background: linear-gradient(135deg, #D0393E 0%, #D54A53 100%);
        color: $white;
        padding: 0.3rem 1rem;
        display: block;
    }
}