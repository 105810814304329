@import "../../scss/utils/variables";
.header{
    padding: 0rem 1.5rem;
    z-index: 1051;
    height: 60px;
    &.white{
        &--transparent{
            background-color: rgba(255,255,255,0.08);
            backdrop-filter: blur(6px);
            z-index: 999;
            left: 0;
            right: 0;
        }
    }
    .practice_name{
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 3.2px;
        line-height: 38px;
        text-transform: uppercase;
        color: $white;
        white-space: nowrap;
    }
    .exit_test_drive_btn{
        border-radius: 5px;
        padding: 0.6rem 1.5rem;
        text-transform: capitalize;
        white-space: nowrap;
        &:hover{
            svg{
                path{
                    fill: #424242;
                }
            }
        }
    }
    &--sub{
        font-size: 2rem;
        padding: 13px 18px;
        margin-bottom: 5px;
        position: relative;
        z-index: 2;
        
        .invoice-btn{
          padding-right: 20px !important;
          font-size: 20px;
          position: relative;
          margin-right: 20px;

          &::before{
              content: "";
              position: absolute;
              right: 0;
              top: 2px;
              height: 23px;
              width: 2px;
              background-color: #fff;
          }
        }
        .btn.btn-link{
            font-size: 2rem;
        }
        .subtitle{
            color: #fff;
        }
    }
    &__right{
        &__buttons{
            .user{
                &__notification{

                    &.dropdown-menu{
                        border-bottom-left-radius: 2px;
                        border-bottom-right-radius: 2px;
                    }
                }
            }

            .menu__btn{
               padding-bottom: 2rem;
               margin-top: 1rem;
               padding-left: 0.9rem;
               padding-right: 0.9rem;
            //    margin-left: 0.5rem;
            //    margin-right: 0.5rem;

            &.notification_btn{
                position: relative;

                .notificaiton-icon{
                    position: absolute;
                    right: -6px;
                    bottom: 10px;
                    height: 22px;
                    width: 22px;
                    background-color: #fff;
                    border-radius: 50%;
                    color: #FF9F00;
                    line-height: 23px;
                    font-size: 10px;
                }
            }
            }
            .nav-link{
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            }

            .trial_days_txt{
                font-size: 12px;
                white-space: nowrap;
                letter-spacing: 2.2px;
                margin-right: 10px;
            
            }
        }
    }

    .trial_note{
        color: $white;
        letter-spacing: 2px;
        margin-right: 39px;
        
        h4{
            color: $white;
            letter-spacing: 4px;
        }
        .trail_days{

        }
    }
    .expired_trial{
        margin-right: 19px;

        h4{
            padding-right: 7px;
        }
        .trial_days_txt{
            letter-spacing: 0.6px;
            margin-right: 23px;
            width: 106px;
            padding-left: 0 !important;
            font-size: 13px;
        }
    }
    .admin__profile{
        
    }
    .dropdown.show{
        svg{
            path{
                fill: $primary-color;
            }
        }
    }
}
.user__profile{
    &:not(.admin){
        &.dropdown-menu{
            padding: 0;
            background: #E0E0E0;
            left: 3px !important;
            .btn-link{
                color: $main-font-color;
            }
            .user__profile--header{
                background: linear-gradient(315deg, #2E2E2E 0%, rgba(5,5,5,0.95) 100%);
                padding: 12px 15px !important;
            }
        }
    }
   
    &--name{
        font-size: 2rem;
        font-weight: 500;
        color: $white;
        margin-bottom: 5;
    }
    &--type{
        margin: 0;
    }
    &--img{
        height: 160px;
        width: 160px;
        position: relative;
        margin: 10px 0 15px 0;
        // margin-bottom: 5px;
        > img{
            object-fit: cover;
            height: 160px;
            width: 160px;
            border-radius: 100%;
        }
        .edit--btn{
            position: absolute;
            top: 0;
            right: 0;
            display: none;
        }
        &:hover{
            .edit--btn{
                display: block;
            }
        }
        .default_icon{
            max-width: 108px;
        }
    }
    &--footer{
        padding-bottom: 0;
        margin-top: 5px;
        .icon{
            height: 18px;
            width: 18px;
        }

        .btn-link{
            padding: 6px 19px;
        }

        .justify-content-between{
            padding-bottom: 5px;
        }
    }
}
.user__notification{
    width: 410px;
    height: 197px;
    font-size: 13px;
    letter-spacing: 0;
    padding: 0;
    overflow: hidden;
    &__lists{
        padding: 15px 20px 0 20px;
        h6{
            color: $primary-color;
        }
        .user__notification__list{
            margin-bottom: 15px;
            padding-bottom: 15px;
            width: 335px;
            p{
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* number of lines to show */
                -webkit-box-orient: vertical;
                max-width: 320px;
            }
            &:last-child{
                margin-bottom: 0!important;
                padding-bottom: 0!important;
                border-bottom: 0;
            }
        }
    }
    &__footer{
        .btn{
            font-size: 13px;
        }
    }
   
    .logomark{
        width: 40px;
        padding:10px 5px;
        background-color: rgba(0,0,0,0.26);

    }
}
.react-contextmenu-wrapper{
    height: 100%;
}
@media (max-width:1700px){
    .react-contextmenu-wrapper{
        min-height: 100vh;
        height: 100%;
    }
    .header--sub {
        font-size: 1.4rem!important;
        padding: 5px 15px!important;
        .invoice-btn::before{
            height: 15px;
        }
        .btn.btn-link{
            font-size: 1.4rem;
            svg{
                height: 15px;
            }
        }
    }
    .header__right__buttons{
        button, #logout_tooltip{
            padding: 0.5rem 0.5rem!important;
            margin:0!important;
            svg{
                height: 15px!important;
            }
        }
        a.nav-link{
            padding: 0.5rem 0.5rem!important;
            svg{
                height: 25px!important;
            }
        }
    }
    .app .contact__header{
        margin-bottom: 15px!important;
    }
    .app .contact__content{
        padding: 3rem!important;
    }
    .app .contact__inner__screen{
        max-width: 95%!important;
        margin: 0 auto!important;
    }
    .app .contact__content textarea.form-control{
        margin-bottom: 0!important;
        min-height: 120px!important;
    }
}
.contextMenu{
    font-size: 1.5rem;
    margin-top: 0;
    border: none;
    border-radius: 5px;
    color: #fff;
    padding: 1.5rem;
    background-color: #212122;
    a{
        color: #fff;
        font-size: 1.5rem;
    }
    .react-contextmenu-item{
        padding: 0.5rem 0;
    }
}