
.playlist_video_list_main{
    .channel_detail_swiper {
        .swiper-button-prev, .swiper-button-next {
            color: #fff;
            opacity: 1;
            background-color: rgba(255, 255, 255, 0.08);
            -webkit-backdrop-filter: blur(32px);
            backdrop-filter: blur(32px);
            z-index: 9;
            margin: 0;
            height: 100%;
            width:36px;
        }
        .swiper-container {
            padding: 0 20px;
        }
       
        .swiper-button-prev {
            left: 0px;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
        }
        .swiper-button-next{
            right: 0;
            position: absolute;
            top: 0;
            bottom: 0;
        }
        .swiper-slide {
          .channel_detail_item {
            position: relative;
            border: 1.5px solid transparent;
            &.activeVideo {
              border-color: #FF9F00;
            }
            &:after {
              background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.0001) 0%,
                rgba(0, 0, 0, 0.6) 100%
              );
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              content: "";
              width: 100%;
            }
            img {
              width: 100%;
              height: 185px;
              object-fit: cover;
            }
            label {
              position: absolute;
              left: 0;
              right: 0;
              font-size: 13px;
              bottom: 10px;
              margin-bottom: 0;
              text-align: center;
              z-index: 2;
              color: #fff;
              text-transform: uppercase;
            }
          }
  
       
        }
      }
   
}

.showPlaylist{
    height: 350px!important;
    .playlist_video_list{
        position: relative;
        opacity: 1;
    }
    .showPlaylistVideo{
      .view_playlist{
        display: none;
      }
      .close_playlist{
        display: block;
        margin-bottom: 0;
      }
      img{
        transform: rotate(180deg);
      }
    }
}
.close_playlist{
  display: none;
}

.playlist_video_list{
    opacity: 0;
    padding: 0 80px;
    transition: 0.3s ease;
    max-width: 1543px;
    margin: 0 auto;
}
.showPlaylistVideo{
    text-align: center;
    color: #fff;
    img{
      transition: 0.3s ease
    }
    p{
        color: #fff;
    }
}