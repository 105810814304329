@import "../../scss/utils/variables";
.mw-168{
    min-width: 168px;
}
.emailVideo{
    &__main{
        &__screen{
            height: 100%;
            .react-select-container{
                max-width: 320px;
            }
            .form-control{
                background: rgba(216, 216, 216, 0.2)!important;
                max-width: 320px!important;
                min-width: 320px!important;
                &.mx-260{
                    max-width: 260px;
                    min-width: 260px;
                }
                &.mw-100{
                    min-width: 100%;
                }
            }
            .react-select-container .react-select__control{
                background: rgba(216, 216, 216, 0.2);
                max-width: 320px;
                min-width: auto;
            }

            h3,h4{
                color: $white;
            }
            .history-link{
                border-bottom: 1px solid #FFFFFF;
            }
        }
    }
    &__inner_screen{
        width: 100%;
        margin: 0 auto;
        // overflow: scroll;
        // height: 90vh;
        padding: 18px 1.5rem 0rem 1.5rem;
    }
    &__filter_box{
        margin-left: 13px;
        margin-bottom: 50px;
        .btn-primary{
            max-width: 116px;
            height: 53px;
            background-color: #FF9F00;
            border-radius: 5px;
            text-transform: none;
            font-weight: 500;
            font-size: 20px;
        }
        .form-control{
            height: 53px;
            background: #505050!important;
            border-radius: 5px;
            margin-bottom: 0!important;
            font-size: 19px;
            &.searchBox{
                min-width: 396px!important;
                background-image: url('../../img/search_filter_icon.png')!important;
                padding-left: 50px;
                background-repeat: no-repeat!important;
                background-position: left 11px center !important;
            }
            &.custom-datepicker{
                min-width: 316px!important;
                background-image: url('../../img/filter_date_icon.png')!important;
                padding-left: 50px;
                background-repeat: no-repeat!important;
                background-position: left 11px center !important;
            }
        }
        .react-select-container .react-select__control{
            height: 53px;
            border-radius: 5px!important;
            background: #505050!important;
        }
        .react-select-container .react-select__single-value, .react-select-container .react-select__option{
            font-size: 19px;
            font-weight: 400;
        }
    }
    &__filter_section{
        border-radius: 7px;
        background-color: rgba(255, 255, 255, 0.08);
        padding: 13px 42px;
        max-width: 1134px;
         .react-select-container .react-select__control{
                background: rgba(216, 216, 216, 0.2);
                // max-width: 155px;
                // min-width: 155px;
            }
    }
    &__inner__body{
        // padding: 30px 30px;
        width: 1378px;
        // height: 831px;
        // border-radius: 7px;
        // background-color: rgba(255, 255, 255, 0.08);
        table{
            border:0px;
            text-align: left;
            tbody{
                display: block;
                max-height: 630px;
                min-height: 595px;
                overflow-y: auto;
                overflow-x: hidden;
                padding: 0;
                tr{

                    td:first-child{
                        padding-left:0 !important;
                        // text-align: left;
                    }
                    td:last-child{
                        // padding-left:0;
                        // text-align: right;
                    }
                }
            }
            thead{
                margin-bottom: 15px;
            }
            thead, tbody tr {
                display: table;
                width: 100%;
                table-layout: fixed;/* even columns width , fix width of table too*/

                th:first-child{
                    padding-left:0 !important;
                    // text-align: left;
                }
                th:last-child{
                    // text-align: right;
                }
                th{
                    font-size: 18px;
                }
            }
            th , td{
                border:0px;
                font-size: 16px;
                font-weight: 400;
                line-height: 26px;
                color:#FFFFFF;
                // text-transform: uppercase;
                vertical-align: middle !important;
                padding:0.5rem 2.0rem ;
            }
            td{
                text-transform: none;
                font-weight: 300;
                border-bottom: 1px solid rgba(255,255,255,0.3);
                // padding:2.5rem 2.0rem !important;
                padding-top: 22px;
                padding-bottom: 22px;
                vertical-align: middle !important;
              
                .down-arrow{
                    height:40px;
                    width: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.4s ease;
                    &.active_arrow{
                        transform: rotateZ(-180deg);
                    }
                }
                .down-arrow img{
                    max-width: 20px;

                }
            }
        }
        
    }
   
    &__html_code_section{
        background: rgba(122, 122, 122, 0.2);
        border-radius: 5px;
        padding: 4rem;
        width: 100%;
        max-width: 761px;
        &.email_box{
            margin-top: 6rem;
            padding: 3rem 5rem;
        }
        &.sms_box{
            margin-top: 6rem;
            padding: 3rem 4rem;
        }
    }
    &__bottom_section{
        max-width: 1590px;
        margin: 0 auto;
        width: 100%;
    }
}

.paginationCustome{
    ul{
        li{
            .btn{
                font-size: 18px;
                display: inline-flex;
            }
            .activepage{
                color: #FF9F00;
            }
        }
    }
}
.emailSHowOnHover{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover{
        overflow: visible; 
        white-space: normal;
        height:auto;  /* just added this line */
    }
}


.emailVideo__main__screen{
    overflow: auto;
}

@media (max-width:1700px){
    .emailVideo__bottom_section{
        padding: 0 2rem;
        gap: 2rem;
    }
    .emailVideo__html_code_section.email_box,
    .emailVideo__html_code_section.sms_box{
        margin-top: 0rem;
    }
}