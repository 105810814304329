@import "../../../scss/utils/variables";


.goolge_tv_profile{
    &__main{
        &__screen{
            background: linear-gradient(315deg, #2E2E2E 0%, rgba(5,5,5,0.95) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;
            min-height: 100vh;
            height: 100%;
            h2{
                font-weight: normal;
                color: #fff;
            }
        }
        
    }

    &__inner{
        &__screen{
            width: 100%;
            max-width: 1580px;
            margin:0 auto;
        }
    }

    &__img__section{
        h2{
            font-size: 38px;
            font-weight: normal;
        }
    }

    &__img_box{
        background-color: rgba(0,0,0,0.26);
        width: 100%;
        max-width: 720px;
        min-height: 720px;
        min-width: 720px;
        img{
            // max-width: 545px;
            margin: auto;
        }
        &__footer{
            height: 100px;
            border-radius: 0 0 2px 2px;
            font-size: 38px;
            letter-spacing: 0.28px;
            line-height: 44px;
            background-color: #E0E0E0;
            color: #424242;
            &__admin{
                background: linear-gradient(315deg, #2E2E2E 0%, rgba(5,5,5,0.95) 100%);
                color: #fff;
            }
        }
    }

    &__details{
        font-size: 28px;
        color: #fff;
        letter-spacing: 0;
        line-height: 40px;
        &__list_item{
            font-size: 38px;
            letter-spacing: 0.5px;
            line-height: 44px;
            border-radius: 5px;
            background-color: rgba(255,255,255,0.1);
            margin-bottom: 25px;
            padding: 12px 22px;
            height: 66px;
            color: rgba(255,255,255,0.4);
            cursor: pointer;
            transition: all 0.2s;
            &:hover{
                transform: scale(1.06);
                color: rgba(66,66,66,0.4);
                background-color: #fff;
                label{
                    color: #424242;
                }
            }
            label{
                margin: 0;
                color: #fff;
            }
        }
    }
}


@media (max-width:1700px){
    .goolge_tv_profile{
        &__img_box{
            max-width: 100%;
            min-width: 100%;
        }
    }
}
@media (max-width:1366px){
    .goolge_tv_profile{
        &__main{
            &__screen{
              zoom: 0.8;
              padding-top: 7rem;
            }
            
        }
    }
}