
.ck-editor__editable_inline{
    height: 600px;
    width: 800px;
    background-color: white;
}

.ck.ck-editor {
    display: flex;
    flex-direction: column-reverse;
}