
.dragdrop{
    &__main{
        &__screen{
            flex: 1;   
            overflow-x: hidden;
            overflow-y: scroll;
            height: 500px;
            width: 100%;
            
        }
        &__columnScreen{
            margin-top: 20px;
        }
        &__videoCard{
           width: 35rem;
           height: 15rem;
        }
        &__selectedImage{
            width: 21px;
            margin-left: 10px;
            margin-top: 10px;
            position: absolute;
        }
        &__subtitle{
            color: white;
            top: 150px;
            font-size: 12px;
            position: absolute;
        }
    }
}