
.videoImageBox{
    height: 200px;
    width: 356px;
    position: relative;
    transition: all 0.2s;
    overflow: hidden;
    &::after{
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 0;
    }
    &:hover{
        transform: scale(1.15);
        &::after{
            background-color: rgba(0,0,0,0.8);
        }
        .videoImageBoxInfo{
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    img{
        border-radius: 2px;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    .videoImageBoxInfo{
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        z-index: 1;
        text-align: center;
        color: #fff;
        height: 33px;
        h2{
            font-size: 2.4rem;
            color: #fff;
            font-weight: 400;
            margin: 0;
            padding: 0 15px;
        }
    }
    .videoTImageBoxTitle{
        
       
    }
}