@import "../../scss/utils/variables";


.update_admin_profile{
    &__main{
        &__screen{
            // background-image: url('../../img/Background_imageSecurity.jpg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;

            &.billing{

                &__page{
                    .header--sub{
                        padding: 16px 18px;
                    }
                    .practice_name{
                        font-size: 18px;
                    }
                }
            }
            .tabs{
                margin-top: 24px;
                width: 248px; 

                ul{

                    li{

                        label{
                            font-size: 19px;
                        }
                       
                    }
                }
            }

            .update_phone_number{
                margin-top: 68px;

                .update_admin_profile{

                    &__inner{

                        &__body{
                            margin-top: 117px;
                        }
                    }
                }
            }
        }
    }
    &__inner{
        padding:38px 40px;
        width: 430px;
        min-height: 675px;
        border-radius: 7px;
        background-color: rgba(255,255,255,0.08);

        
        .mobile-veryfied{
            padding-top: 2.5rem !important;
            margin-top: -37px !important;
            
            button{
                margin-top: 11px !important;
            }

            .justify-content-between{
                .fs-14{
                    line-height: 17px; 
                }
            }
        }
        .mobile-number-update{
            // margin-bottom: -66px;
            label{
                font-size: 14px;
                line-height: 23px;
                margin-bottom: 2px;
            }
            button{
                margin-top: 22px !important;
            
            }
        }
        // .react-tel-input{
        //     position: relative;

        //     .form-control{
        //         padding-left: 8rem;
        //     }
        //     .flag{
        //         width: 16px;
        //         height: 12px;
        //         top: 43%;
        //         left: 2%; 
        //     }

        //     .selected-flag{

        //         .flag{
        //             top: 45%;
        //             left: 5%;
        //         }

        //     }

        //     &::after{
        //         content: "";
        //         position: absolute;
        //         top: 10px;
        //         left: 16%;
        //         height: 21px;
        //         width: 1px;
        //         border-radius: 2px;
        //         background-color: rgba(255,255,255,0.6); 
        //     }

        // }

        &__header{
            &.mobile_verified{

                h4{
                    margin: 2px 0px 13px 0;
                }

                // .react-tel-input{

                //     .flag-dropdown{

                //         .selected-flag{

                //             .flag {
                //                 transform: scale(1.5);
                //             }
                //         }
                //     }
                // }
            }
            h4{
                margin:4px 0px 12px 0;
                font-size: 20px;
                line-height: 26px;
                color:#FFFFFF;
                font-weight: 400;
            }
            p{
                font-size: 15px;
                line-height: 25px;
                color: #FFFFFF;
                margin: 0px;
                letter-spacing: 0.6px;
            }
            .fs50{
                font-size: 50px;
                letter-spacing: 5.5px;
                line-height: 50px;
                color:#fff;
            }

            &.phone_number_update{

                p{
                    margin-bottom: -1px;
                }
            }
        }
        &__body.min-height-none{
            min-height: inherit;
        }
        &__body{

            padding:22px 0;
            // overflow-y:auto;
            
            .form-group{

                label{
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    margin-bottom: 2px;
                }
                input{
                    // text-transform: capitalize;

                    &#email{
                        // text-transform: lowercase;
                    }
                }
                input[type="email"]{
                    // text-transform: lowercase;
                }
                input:-webkit-autofill,
                input:-webkit-autofill:hover,
                input:-webkit-autofill:focus,
                input:-webkit-autofill:active {
                    transition: background-color 5000s ease-in-out 0s;
                    -webkit-text-fill-color: #fff !important; 
                }

                // .react-tel-input{
                //     position: relative;

                //     .form-control{
                //         padding-left: 8rem;
                //     }
                //     .flag{
                //         width: 16px;
                //         height: 12px; 
                //         top: 43%;
                //         left: 2%; 
                //     }

                //     .selected-flag{

                //         .flag{
                //             top: 45%;
                //             left: 5%;  
                //         }

                //     }

                //     &::after{
                //         content: "";
                //         position: absolute;
                //         top: 10px;
                //         left: 16%;
                //         height: 21px;
                //         width: 1px;
                //         border-radius: 2px;
                //         background-color: rgba(255,255,255,0.6); 
                //     }

                // }
            }
            .user__box{

                .user__avtar{
                    height:200px;
                    width: 200px;
                    border-radius: 7px;
                    background-color: rgba(0, 0, 0, 0.26);
                    margin: 0 auto;
                    position: relative;
                    overflow: hidden;

                    img{
                        object-fit: cover;
                    }
                    span{
                        height: 30px;
                        background: linear-gradient(315deg, #2E2E2E 0%, rgba(5,5,5,0.95) 100%);
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        line-height: 30px;
                        font-weight: 500;
                        text-align: center;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
                .user__name{
                    line-height: 22px;
                    font-size: 15px;
                    font-weight: 500;
                    text-align: center;
                    margin-top: 15px;
                }
            }
            .verification-code-input{
                display: flex;
                justify-content: space-between;
                position: relative;
                margin-bottom: 20px;

                input{
                    min-width: inherit;
                    max-width: inherit;
                    width: 65px;
                    height: 80px;
                    font-size: 5.3rem;
                    font-weight: 400;
                    color: #fff;
                    background-color:rgba(216, 216, 216, 0.2);
                    text-align: center;
                }
            }
            .custom-input-group{

                background-color:rgba(216, 216, 216, 0.2);
                border-radius: 2px;

                .input-group-prepend{

                    align-items:center;
                    padding: 0 10px;
                    position: relative;
                
                &:after{
                    content:"";
                    width: 1px;
                    height: 24px;
                    position: absolute;
                    background-color: rgb(255 255 255 / 40%);
                    right: 0;
                }    

                    img{
                        height: 18px;
                        width: 25px;
                        margin-right: 8px;
                    }
                }

                .form-control{
                    min-width: inherit;
                    max-width: 100%;
                    width: auto;
                    background-color:transparent;
                }
            }
        }
        &__footer{
            // margin-top: 23px !important;
            p{
                font-size: 15px;
                line-height: 22px;
                text-align: center;
            }
            .btn{
                max-width: 100%;
            }
            .verification-code-input{
                display: flex;
                justify-content: space-between;
                position: relative;
                margin-bottom: 20px;

                input{
                    min-width: inherit;
                    max-width: inherit;
                    width: 65px;
                    height: 80px;
                    font-size: 5.3rem;
                    font-weight: 400;
                    color: #fff;
                    background-color:rgba(216, 216, 216, 0.2);
                    text-align: center;
                }
            }

            &.phone_number_up_footer{
                margin-top: auto !important;
            }
        }
    }
}

@media (max-width:1700px){
    .update_admin_profile__inner{
        position: relative;
        z-index: 9999999;
    }
}