@import "../../scss/utils/variables";

.waiting_room_channel{
    &__main{
        &__screen{
            //background-image: url('../../img/BackgroundimageWaitingroom.jpg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: $white;

            .waiting_room_news_feed{
            }
        }
    }
    &__schedule{
        &__screen{
            // overflow: auto;
            // max-height: 859px;
            max-width: 1576px;
            height: 880px;
            margin-top: 11px !important;

            .transparent__form--white{
                padding: 4rem;

                .form-heading{

                    margin-bottom: 34px !important;

                    h4{
                        margin-bottom: 19px !important;
                    }
                    p{
                        letter-spacing: 0.6px;
                    }
                }
            }
            .custom-scroll{
                overflow: hidden;
                overflow-y: auto;
                max-height: 610px;

                .practice{

                    &__hours_section{
                        .after--hour{
                            margin-right: -10px !important;
                        }
                        .from_start{
                            margin-left: -10px;
                            margin-bottom: 14px;
                        }
                        .to_end{
                            margin-left: -10px;
                        }

                        .custom-radio-btn{
                             > span{

                                &.MuiSwitch-root{

                                    width: 40px;
                                    height: 22px;
                                    margin: 1px 18px;
                                    
                                    .MuiButtonBase-root{
                                        left: 4px;

                                             + .MuiSwitch-track{
                                                background-color: rgba(66,66,66,0.4);
                                                border:none;
                                            }
                                            
                                            &.Mui-checked{
                                                left: 2px;
                                                + .MuiSwitch-track{
                                                    background-color: #6087fd;
                                                }
                                            }
                                    }
                                    .MuiIconButton-label{

                                        .MuiSwitch-thumb{
                                            width: 18px;
                                            height: 18px;
                                            margin-top: 1px;
                                        }
                                    }
                                }
                             }
                        }

                        .after_hour{
                            label{
                                margin-left: -9px !important;
                            }
                        }

                        .border--right{
                            border-right: none;
                            position: relative;

                            &::after{
                                content: "";
                                position: absolute;
                                top: 35px;
                                right: -1px;
                                bottom: 0;
                                height: 143px;
                                width: 1px;
                                background-color: rgba(255,255,255,0.3);
                            }
                        }
                    }
                }

                .border--left{
                    padding-left: 40px !important;
                    border:0;
                    position: relative;

                    &::after{
                        position: absolute;
                        content: "";
                        top: 0px;
                        bottom: 0;
                        left: -10px;
                        width: 1px;
                        height: 100%;
                        background-color: rgba(255, 255, 255, 0.3);
                    }
                }

                   
            }
        }
    }
}
.Page9__main__baseDiv { background: rgba(255,255,255,0.08);
    button  { min-width: 220px; min-height: 40px; }
    button.btn-secondary { background: #fff; color: #757575; }
}

::-webkit-scrollbar-track {
    background:transparent;
}

::-webkit-scrollbar {
    width: 4px !important;
    border-radius: 7px;
}
::-webkit-scrollbar-thumb {
    background: #FFFFFF !important;
    height:109px !important;
    
    
}

::-webkit-scrollbar-thumb:hover {
    background: #FFFFFF !important;
}