@mixin btn-default-gradient{
	background: #272727;
	background: -moz-linear-gradient(180deg, #4A4A4A 0%, #272727 100%);
	background: -webkit-linear-gradient(180deg, #4A4A4A 0%, #272727 100%);
	background: -o-linear-gradient(180deg, #4A4A4A 0%, #272727 100%);
	background: -ms-linear-gradient(180deg, #4A4A4A 0%, #272727 100%);
	background: linear-gradient(180deg, #4A4A4A 0%, #272727 100%);
}

@mixin default-error-gradient{
	background: #D54A53;
	background: -moz-linear-gradient(135deg, #D0393E 0%, #D54A53 100%);
	background: -webkit-linear-gradient(135deg, #D0393E 0%, #D54A53 100%);
	background: -o-linear-gradient(135deg, #D0393E 0%, #D54A53 100%);
	background: -ms-linear-gradient(135deg, #D0393E 0%, #D54A53 100%);
	background: linear-gradient(135deg, #D0393E 0%, #D54A53 100%);
}