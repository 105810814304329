@import "../../scss/utils/variables";

.subscriptionpay{
    &__main{
        &__screen{
            // background-image: url('../../img/Background_imageBilling.jpg') !important;
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            color: #fff;

            .tabs{
                margin-top: -48px;
                width: 251px;
            }

            .practice_name{
                font-size: 19px;
            }
        }
    }
    
}
.subscriptioncard{
    &__page{

    }
    &__inner{

        &__panel{
            padding:32px 40px 43px 42px;
            width: 1060px;
            height:805px;
            border-radius: 7px;
            position: relative;
            margin-top: -32px;


        }
        .search-keyword{
            margin-right: 26px;
        }
        .search-section{
            margin-top: 23px;
        }

        &__body{
            margin-top: 23px;
            .add_new_location{
                button{
                    width: 444px !important;
                    margin-top: 31px;
                    margin-bottom: 34px;
                }
            }
            table{
                border:0px;
                text-align: left;
                thead{
                    th{
                        &:first-child{
                            padding-left: 0;
                        }
    
                        &:last-child{
                            padding-right: 0;
                            text-align: right;
                        }  
                    }
                }
                th , td{
                    border:0px;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 22px;
                    color:#FFFFFF;
                    text-transform: uppercase;
                    vertical-align: middle;
                }
                // tbody{
                //     display: block;
                //     max-height:368px;
                //     padding: 0 20px;
                //     margin: 0 -20px;

                //     tr{
                //         display: table;
                //         width: 100%;
                //         table-layout: fixed;
                //     }
                // }
                td{
                    text-transform: none;
                    font-weight: 300;
                    border-bottom: 1px solid rgba(255,255,255,0.3);
                    padding:1.89rem 0.75rem;
                    
                    &:first-child{
                        padding-left: 0;
                    }

                    &:last-child{
                        padding-right: 0;
                    }
                    button{

                        &.btn-md{
                            width: 167px;
                            float: right;
                        }
                       
                    }
                    img{
                        margin-right: 14px;
                    }
                    .down-arrow{
                        height:32px;
                        width: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .down-arrow img{
                        max-width: 20px;

                    }
                    &.unassign{
                        color: #FF4D54;
                    }

                    &.pending{
                        color:#FF9F00;
                    }
                }
            }
        }

        &__header{
            position: relative;
            .location-img{
                height: 56px;
                width: 61px;
                position: absolute;
                right: 10px;
                top: 1px;
            }

            h4{
                margin-top: 8px;
            }
            &__tab{
                display: flex;
                font-size: 24px;
                margin: 0 -10px;
                margin-right: 84px;

                li{
                    margin: 0 10px;
                    color: rgba(255,255,255,0.6);

                    &.active{
                        color: rgba(255,255,255,1);
                        border:none;
                    }
                }
            }
        }
    }
}

.subscriptionpay{
    &__page{

    }
    &__inner{

        &__header{


            h4{
                margin:0px;
                font-size: 20px;
                line-height: 26px;
                color:#FFFFFF;
                font-weight: 400;
            }
            h3{
                font-size: 50px;
                line-height: 50px;
                color:#FFFFFF;
                margin:0px;
            }

        }

    }

}