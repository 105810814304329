.videoSelected__main__card{
    position: relative;
    .myVideoOptions{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(#30302f, #292929);
    }
}

.waiting_room_my_video_blank{
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    .btn-link{
        color: #FF9F00;
    }
      h1{
          color: #FFFFFF;
          font-size: 36px;
          letter-spacing: 0;
          line-height: 43px;
      }
  }
.bg_red{
    background: linear-gradient(135deg, #D0393E 0%, #D54A53 100%) !important;
}
.upload-video-sec       { display: flex; flex-wrap: wrap; justify-content: center; width: 100%; min-height: 685px;
    .heading-label  { font-size: 20px; line-height: 26px; margin-bottom: 15px; }
    .fileupload-desc { margin-bottom: 25px;
        label       { font-size: 15px; line-height: 20px; }
    }
    .transparent__form--white   { max-width: 820px;}
    .file-upload-input      { margin: 0px -20px; margin-bottom: 20px;
        label, div { margin-bottom: 3px; font-size: 14px; line-height: 20px; /*width: 50%;*/ text-align: left; }
        > div       { width: calc(50% - 40px);margin: 0px 20px; }
        .file-upload-input-form { flex-wrap: wrap;
            input   { min-width: unset; width: calc(100% - 0px); margin-right: 20px; }
            button     { width: 65px; max-height: 41px;}
        }
    }
    .subtitle-label     {
        p       { font-size: 15px;line-height: 22px; margin-bottom: 0; }
    }
    .file_upload__box { width: 100%; min-height: 315px; }
    .file_upload__note  { font-size: 15px; line-height: 22px; }
    .file-upload-btn    { min-width: 220px; min-height: 40px; margin-top: 40px; }
    
}
.upload-video-sec .upload_file_list{
    // height: 200px;
    overflow: hidden;
    overflow-y: scroll;
    position: relative;
    z-index: 5;
}
.my-videotoggle-btn{
    position: relative;
    z-index: 9;
}
.my-videotoggle-btn         { 
    img                 { right: -5px; width: 35px; height: 35px; }
 }
 .videoSelected__main__card .myVideoOptions     { background: transparent; }
 .videoSelected__main__card.menu-opened .my-videotoggle-btn img,    
 .videoSelected__main__card.menu-opened .videoSelected__main__card__subTitle { display: none; }
 .videoSelected__main__card.menu-opened .my-video-bg-img img       { filter: blur(10px); -webkit-filter: blur(10px); }
 .menu-icon-img                 { top: 0; position: absolute; right: 0; margin-top: 0 !important; }
 .videoSelected__main__card.menu-opened  .myVideoOptions h6 { padding: 20px 30px 5px !important; }
 .myVideoOptions button         { width: 145px; height: 40px; }
 .myvideo-menu-item         { font-size: 14px; line-height: 40px; display: block; padding: 0px 30px !important; }
 .myvideo-menu-item:hover   { background: #f29f33; }
 .remane-popup-details      { padding: 0px 15px; }
 .remane-popup-details input    { min-width: unset !important;}
 .remove-popup-details          { text-align: center;}
 .remove-popup-details span      { margin-top: 20px; margin-bottom: 40px; display: block }
//  .remove-popup-details  .btn-primary {   background: linear-gradient(180deg, #4A4A4A 0%, #272727 100%); color: #fff !important; }
.videoSelected__main__card.menu-opened .status_panding{
    display: none!important;
}
.status_panding{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 1.5rem;
    z-index: 5;
    h4{
        color: #FFFFFF;
        position: relative;
        z-index: 2;
        font-weight: normal;
        letter-spacing: 0.3;
    }
    &::after{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #292929;
        opacity: 0.5;
    }
}