.specialization_box{
    position: relative;
    transition: all 0.2s;
    // transform: scale(1.01);

    &:hover{
        transform: scale(1.05);
    }
    &__img{

    }
    &__title{
        text-transform: uppercase;
        color: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        h2{
            color: #fff;
        }
    }
}

