@import "../utils/variables";

.user__profile{
    // width: 210px;
    // max-width: 210px;
    order: 12;
    &.order-3{
        order: 4;
    }
    &.owner{
        order: 0;
    }
    &.manager{
        order: 1;
    }
    &.authorized_user{
        order: 2;
    }
    &.guest{
        order: 500;
    }
    &.order{
        order: 0;
    }
    &__box{
        // height: 200px;
        width: 100%;
        max-width: 150px;
        margin: 0 auto;
        border-radius: 7px;
        // background-color: rgba(0,0,0,0.26);
        z-index: 2;
        position: relative;
        // backdrop-filter: blur(39px);
        cursor: pointer;
        &:hover,
        &:active, 
        &.selected{
            .user__profile__picture{
                border-color: #FFB438;
            }
            // &::after{
            //     content: '';
            //     position: absolute;
            //     height: 100%;
            //     width: 100%;
            //     z-index: 5;
            //     border-radius: 7px;
            //     box-shadow: inset  0 0 0 0.2rem $primary-color;
            // }
            // &::before{
            //     content: '';
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     right: 0;
            //     bottom: 0;
            //     background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
            //     border-radius: 0 0 7px 7px;
            //     z-index: 5;
            // }
        }
        &.error.selected {
            .user__profile__picture{
                border-color: $color-danger;
            }
            // &::after{
            //     box-shadow: inset  0 0 0 0.2rem $color-danger;
            // }
           
        }
        &.owner{
            // background-color: rgba(0,0,0,0.26);
            .user__profile__picture{
                background: linear-gradient(209deg, #F99F1B 0%, #F38E1D 100%);
            }
            .user__profile__footer {
                // background: linear-gradient(315deg, #2E2E2E 0%, rgba(5,5,5,0.95) 100%);
            }
            .user__profile__icon{
                svg{
                    path{
                        fill: #FBA60A;
                    }
                }
            }
        }
        &.dentist {
            // background-color: rgba(255,255,255,0.08);
            // backdrop-filter: blur(12px);
            .user__profile__footer {
                // background-color: #E0E0E0;
                color: $main-font-color;
            }
        }
        &.manager {
            // background-color: rgba(0,0,0,0.26);
            .user__profile__footer {
                // background: linear-gradient(315deg, #2E2E2E 0%, rgba(5,5,5,0.95) 100%);
            }
        }
        &.authorized_user{
            // background-color: rgba(0,0,0,0.26);
            .user__profile__footer {
                // background: linear-gradient(315deg, #2E2E2E 0%, rgba(5,5,5,0.95) 100%);
            }
        }
        &.guest{
            // background-color: rgba(255,255,255,0.08);
            .user__profile__footer {
                // background-color: #E0E0E0;
                color: $main-font-color;
            }
        }
    }
    &__picture{
        height: 140px;
        width: 140px;
        border-radius: 140px;
        margin: 0 auto;
        background-color: #424242;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border: 2px solid #424242;
        &:hover,
        &:active, 
        &.selected{
            border-color: #FFB438;
        }
        img{
            height: 100%;
            object-fit: cover;
            &.default_img{
                max-width: 60px;
                height: auto;
            }
        }
    }
    &__icon{
        position: absolute;
        right: -10px;
        bottom: 0;
    }
    &__footer{
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        background-color: #E0E0E0;
        border-radius: 7px;
        height: 30px;
        display: flex;
        align-items: center;
        color: #424242;
        justify-content: center;
        margin-top: 22px;
        display: inline-flex;
        padding: 5px 20px;
        &.bg-none{
            background-color: transparent;
        }

        &.owner{
            background: linear-gradient(315deg, rgba(251, 168, 8, 0.93) 0%, rgba(244, 146, 25, 0.93) 100%);
            backdrop-filter: blur(5.436563491821289px);
            color: #fff;
        }
    }
    &__name{
        font-size: 1.5rem;
        text-transform: uppercase;
    }
}

.user__profile__new:hover::after{
    box-shadow: inset  0 0 0 0.2rem transparent;
}

@import "../utils/variables";

.authorized_device{
    width: 200px;
    max-width: 200px;
    &__box{
        height: 200px;
        min-width: 200px;
        width: 100%;
        border-radius: 7px;
        background-color: rgba(255,255,255,0.08);
        z-index: 2;
        position: relative;
        backdrop-filter: blur(39px);
        overflow: hidden;
        cursor: pointer;
        &.pc{
           .authorized_device__picture{
               img{
                height: auto;
                width: 80%;
               }
           }
        }
        &.ipad {
            .authorized_device__picture{
                img{
                 height: 112px;
                 width: auto;
                }
            }
        }
    }
    &__name{
        font-size: 1.5rem;
        text-transform: uppercase;
    }
    &__picture{
        img{
            object-fit: cover;
            width: 100%;
            height: 200px;
            &.default_img{
                max-width: 108px;
                height: auto;
            }

        }
    }
}

@media (max-width:1700px){
    .user__profile{
        // width: 150px;
        // max-width: 150px;
       
    }
   .app .active_profiles__inner__text .user__profile:nth-last-child(-n+5){
        margin-bottom: 20px!important;
    }
    .user__profile__box{
        // height: 150px;
        // max-width: 150px;
        margin:0 auto;
    }
    .user__profile__picture img{
        // height: 100%;
    }
    .react-datepicker-wrapper{
        width: 100%;
    }
}