@import "../../scss/utils/variables";

.trial{
    &__main{
        &__screen{
            // background-image: url('../../img/Background_imageHome.jpeg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
        }
    }
    &__inner{
        &__screen{
            // background: linear-gradient(315deg, #2E2E2E 0%, rgba(5,5,5,0.95) 100%);
            border-radius: 10px;
            max-height: 90rem;
            height: 100%;
            width: 100%;
            max-width: 111rem;
            padding: 3.5rem 4rem;
            margin-bottom: 7px;
            // backdrop-filter: blur(15px);

            h1{
                margin-bottom: 35px;
            }
            h3{
                margin-bottom: 19px;
            }

            p{
                margin-bottom: 38px; 
            }
        }
        &__text{
            color: $white;
            font-size: 2rem;
            line-height: 3rem;
            max-width: 62rem;
            &.trial__extend_screen{
                max-width: 70rem;
            }
            a{
                color: $white;
                border-bottom: 1px solid $white;
            }
            h1{
                font-size: 8.8rem;
                margin-bottom: 4rem;
                margin-top: 2rem;
            }
            p{
                margin-bottom: 3.5rem;
            }
            h3{
                font-size: 2.7rem;
                font-weight: 500;
                letter-spacing: 7px;
                margin-bottom: 3rem;
                line-height: 3.9rem;
            }
            h1,h2,h3,h4,h5,h6{
                color: $white;
                text-transform: uppercase;
            }
            .btn-235{
                min-width: 235px;
            }
        }
    }
}
.extend-request{
    h1{
        font-size: 5rem;
    }
    p{
        max-width: 60rem;
        display: inline-block;
    }
}
@media (max-width:1370px){
    // .trial__inner__text .btn-235{
    //     min-width: 150px;
    //     max-width: 150px;
    // }
    // .trial__inner__text{
    //     font-size: 1.2rem;
    //     line-height: 1.3;
    //     h1{
    //         font-size: 5rem;
    //     }
    //     h3{
    //         font-size: 1.8rem;
    //     }
    // }
}
@media (max-width:1700px){
    .trial__main__screen{
        min-height: 100vh;
    }
    .trial__inner__screen{
        height: 70%;
        width: 70%;
        min-height: 60rem;
    }
    .extend-request h1{
        // font-size: 3rem;
    }
}