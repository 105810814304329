@import "../../scss/utils/variables";
.operatory_channel_channel_footer{
    &__main__screen{
    //    background-image: url('../../img/BackgroundimageWaitingroom.jpg');
    background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
        background-size: 100% 100%;
        padding-top: 6rem;
        color: $white;
        .MuiTypography-body1{
            font-size: 1.5rem;
            font-family: $body-font;
            font-weight: 300;
            letter-spacing: 0.5px;
        }
        .main__hr{
            border-left: .2rem solid rgba(160, 160, 160, 0.137);
            height: 597px;
            width: .2rem;
            position: relative;
            top: 2.5rem;
            margin: 0 50px;
        }
    }
    &__inner__screen{
        width: 100%;
        max-width: 1400px;
        margin-top: 5px !important;

        .transparent__form--white{
            font-size: 1.5rem;
            // max-width: 430px;
            height: 675px;
            padding: 4rem;
            &.custom_message{
                max-width: 430px;
            }
        }

        .transparent__form--white.leftVBox{
            font-size: 1.5rem;
            max-width: 430px;
            min-width: 430px;
            min-height: 562px;
            width: 100%;
        }
        .transparent__form--white.rightBox{
            padding: 20px;
        }
    }
    &__inner{
        &__text{
            color: $white;
            h1{
                margin-top: 2rem;
            }
            h3{
                font-size: 2.7rem;
                font-weight: 500;
                letter-spacing: 7px;
                margin-bottom: 3rem;
            }
            h1,h2,h3,h4,h5,h6{
                color: $white;
            }
        }
    }
    
    &__panel_body{
        margin: 0 auto;
        margin-top: 76px;
        margin-left: 217px;

        .transparent{

            &__form--white{
                width: 940px
            }
        }

        &__form{

            .form-group{
                h4{
                    margin-bottom: 17px;
                }

                p{
                    margin-bottom: 25px;
                }
            }

            textarea{

                &.form-control{
                    margin-bottom: 39px;
                }
            }
        }

        .message-header{
            margin-top: 10px;
            margin-bottom: 49px !important;

            h4{
                
            margin-bottom: 18px;

            }
        }
    }
       
}

// .Page5
// {
//     &__main
//     {
//         // width: 100vw;
//         height: auto;
//         padding: 11rem;
//         margin-left: -176px;
//         // background-color: rgb(22, 22, 22);
//         padding-top: 5rem ;
    
     

//         &__hr{ 
  
            // border-left: .2rem solid rgba(216,216,216,0.3);
            // height: 597px;
            // width: .2rem;
            // margin-left: 149px;
            // position: relative;
            // top: 3.5rem;
            // margin-right: 30px;
         
//         }
        
//         &__div1
//         {
//             width: 430px;
//             height: 675px;
//             border-radius: 0.5rem;
//             background: rgba(160, 160, 160, 0.137);    
//             position: relative;
//             left: 10rem;
//             padding-left: 2.6rem;
//             padding-right: 2rem;
//             padding: 40px;            
//             &__row
//             {
//                 display: flex;
//                 height: -webkit-fill-available;
//                 align-content: space-between;
                
//                 &__upperDiv
//                 {
//                    margin-top: 1rem;
//                     h4
//                     {
//                         margin-bottom: 15px;
//                         color: white;

//                     }
//                     p 
//                     {
//                         width:  auto;
//                         color: white;
//                         margin-bottom: 9px;
//                         max-width: 350px;
//                         font-size: 15px;
                      
//                     }
//                     label
//                     {
//                         margin-top: 1.5rem;
//                         color: white;
//                         font-size: 14px;

//                     }

    
//                     button
//                     {
//                         background-color: rgb(134, 134, 134);
//                         border: none;
//                         color: white;
//                         border-radius: .2rem;
//                         text-align: left;
//                     }
//                     textarea
//                     {
                       
//                         margin: 0px;
//                         padding: 10px;
//                     }
//                 }
//                 &__lowerDiv
//                 {
                   
//                     &__button
//                     {
//                         // background-color: rgb(17, 224, 121)!important;
//                         border: none;
//                         color: black;
//                         font-weight: 500;
//                         width: 350px;
//                         margin-top: 18px;
//                         margin-bottom: 10%;
//                         // position: relative;
//                         // top: 20rem;
//                     }
                  
                
//                 }
                
//             }
         
//         }

//         &__div2
//         {
            
//             &__heading{
//                 margin-bottom: 49px;
//             }
//             width: 25rem;
//             margin-top: 48px;
//             position: relative;
//             // right: 12rem;
//             // top: 2.2rem;
//             left: 2rem;
//             &__cancel{
//                 margin-left: .8rem;
//                 background-color: rgba(160, 160, 160, 0.137);
//                 border: 1.5px solid white;
//                 border-radius: .2rem;
//                 width:4.0rem;
//                 color:white;
//                 font-size: 14px;
//             }
//             &__inputWrapperDiv
//             {

//                 ul{
//                     display: flex;
//                     width: 100%;
//                     flex-direction: row;
//                     align-items: center;
//                     margin-bottom: 15px;
//                     li{

//                         &.number{
//                             font-size: 20px;
//                             margin-right: 20px;
//                         }

//                         display: flex;
//                         align-items: center;

//                         button{
//                             display: block;
//                             height: 30px;
//                             width: 30px;
//                             padding: 3px 9px;
//                         }
//                     }
//                 }
            
//                 input
//                 {
//                     width: 20rem;
//                     background-color:rgba(160, 160, 160, 0.137) ;
//                     color: whitesmoke;

                
//                 }
//                 label
//                 {
//                     color: white;
//                     float: left;
//                     margin-right: .8rem;
//                     font-weight: bold;
//                     text-align: center;
//                 }
               
                
//                 &__inputDiv
//                 {
//                     display: contents;
//                     margin: .5rem;
//                 }
//              }

//              h4
//              {
//                  width: 20rem;
//                  color: white;
//                  margin-bottom: 22px;

//              }
//              p 
//              {
//                  color: white;
//                  margin-bottom: 0px;
//                  font-size: 15px;
//              }
       
            
//         }
      
      
//     }
// }

.footer-header{
    header{
        background-color:transparent;
        box-shadow: none;
    }
}
.footer-tabs{
    background-color: transparent;
    .MuiTabs-fixed{
        align-items: center;
        justify-content: center;
        display: flex;
    }
        button,.custome-link-tab{
            min-width: 190px !important;
            text-transform: uppercase;
            font-size: 12px;
            letter-spacing: 0.43px;
            font-weight: 600;
            background-color: transparent !important;
            border: 1.5px solid #FFFFFF;
            height: 35px;
            border-radius: 2px;
            color: #fff;
            margin: 0 10px;
            min-height: 35px;

            &.Mui-selected{
                background-color: #fff !important;
                color: #424242 !important;
            }
        }

        .PrivateTabIndicator-colorPrimary-3{
            background-color: transparent;
        }

}