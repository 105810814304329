
@import "../../../scss/utils/variables";
$base-color: linear-gradient(315deg, #FFB438 0%, #FF9F00 100%);
.home_swipers{
    height: 950px;
    overflow-y: auto;
    position: relative;
    overflow-x: hidden;
    padding-top: 35px;
    padding-left: 10px;
    // padding-left: 24px;
    padding-bottom: 35px;
    .swiper-container{
        // overflow: visible;
        margin-left: -15px;
        padding-top:33px;
    }
    .swiper-wrapper{
        // padding-left: 30px;
        margin-left: 30px;
    }
    .card{
        .subTitle{
            color: white;
            left: -3px;
            right: 0;
            bottom: 13px;
            font-size: 12px;
            position: absolute;
            padding-right: 54px;
            padding-left: 15px;
            padding-bottom: 15px;
            text-align: left;
            display: none;
            .video_downarrow{
                width: 26px;
                position: absolute;
                bottom: -12px;
                left: 50%;
                margin-left: -13px;
                opacity: 0;
            }
            .check-sign{
                height: 30px;
                width: 30px;
                position: absolute;
                right: 10px;
                bottom: 6px;
                // display: none;
            }
            .video_icon{
                width: 60px;
                height: 35px;
                border-radius: 2px;
                border: 1px solid #ffffff;
                background-color: rgba(66, 66, 66, 0.5);
                text-align: center;
                padding: 9px 0;
                margin: 0 auto;
                margin-bottom: 12px;
                margin-left: -1px;
                // display: none;
                cursor: pointer;
                img{
                    width: 16px;
                    height: auto;
                }

            }

            .title{
                margin-bottom: -11px;
                font-size: 15px;
                // line-height: 22px;
                // text-transform: uppercase;
                // padding-left: 54px;
            }
            .smalltitile{
                margin-bottom: 0;
                font-size: 15px;
                line-height: 18px;
                text-transform: capitalize;
                margin-left: 0;
                max-width: 252px;
                // display: none;
            }
        }
        &:hover{
            transform:translateY(-13px) scale(1.13);
            transition: 0.3s ease-in-out;
            background: linear-gradient(315deg, #FFB438 0%, #FF9F00 100%);
            padding: 1px;
            .overlay-text{
                display: flex;
            }
            .video_downarrow{
                opacity: 1;
            }
            .video_title{
                display: none;
            }
            .subTitle{
                text-align: left;
                display: block;
                .check-sign{
                    // display: block;
                }
                .video_icon{
                    // display: block;
                    // margin-left: -1px;
                }
                .title{
                    // font-size: 12px;
                    // text-transform: capitalize;
                    // padding-left: 0;
                }
                .smalltitile{
                    display: block;
                    // margin-left: 0;
                }
            }
        }
    }
    .dropdown-chevron{
        left: 15px;
        position: relative;
        width: 1515px;
        margin-bottom: 30px;
        margin-left: 4px;
        background-size: 639px; 
    }
}
.dropdown-chevron{
    position: absolute;
    width: 1514px;
    height: 358px;
    // display:none;
    z-index: 9;
    padding: 24px 30px 30px 30px;
    left:30px;
    right:0;
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    
    &:before{
        content: "";
        position: absolute;
        background: linear-gradient(90deg, #000000 0%, #000000 65.15%, rgba(0,0,0,0) 100%);
        top:0;
        bottom: 0;
        right: 0;
        left:0;
        z-index: -1;
    }
    .dropdown-button{
        margin:0 -10px;
        .btn{
            margin:0 10px;

            &.check-sign{
                padding: 0;
                display: none;
            }

            &.cc-btn{
                height: 30px!important;
                padding: 0px 10px!important;
                width: 48px;
                font-size: 18px;
                line-height: 28px;
                letter-spacing: 0.2px;
                &.active{
                    background-color:#fff;
                    color: #424242!important;
                }
            }
        }
        .btn-large{
            // width: 220px;
            // height: 40px;
        }
    }
}
.myListCatelogue{
    margin:-14px -15px 0;
    display: flex;
    height: 720px;
    overflow-y: scroll;
    position: relative;
    padding: 33px 0px 15px 0;
    flex-flow: row wrap;
    align-content: start;
    .placeholder__box{
        margin: 0 0 0 30px; 
        border-radius: 2px;
    }
    .mylist-box{
        position: initial;
        &.showResultsActive{
            padding-bottom: 387px;
        }
        
    }
    .card{
        width: 356px !important;
        height: 200px;
        position:initial;
        // margin:0 15px;
        margin: 0 0 0 30px;
        margin-bottom: 30px;
        border-radius: 2px;
        position: relative;
        border: none;

        &::before{
            content: "";
            position: absolute;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: 0;
        }

        .video-caption{
            position: absolute;
            text-align: center;
            bottom: 10px;
            left:0;
            right: 0;
            margin-bottom: 0;
            text-transform: uppercase;
        }
        .video_downarrow{
            width: 26px;
            position: absolute;
            bottom: -12px;
            left: 50%;
            margin-left: -13px;
            opacity: 0;
        }
        .category_title{
            position: absolute;
            bottom: 5px;
            left: 15px;
            right: 15px;
            // -webkit-transform: translateX(-50%);
            // -ms-transform: translateX(-50%);
            // transform: translateX(-50%);
            margin-bottom: 0;
            text-transform: uppercase;
            font-size: 15px;
            text-align: center;
            span{
                text-transform: none;
            }
        }
        .subTitle{
            color: white;
            left: -3px;
            right: 0;
            bottom: 13px;
            font-size: 12px;
            position: absolute;
            padding-right: 54px;
            padding-left: 15px;
            padding-bottom: 15px;
            text-align: left;
            display: none;
            .check-sign{
                height: 30px;
                width: 30px;
                position: absolute;
                right: 10px;
                bottom: 6px;
                // display: none;
            }
            .video_icon{
                width: 60px;
                height: 35px;
                border-radius: 2px;
                border: 1px solid #ffffff;
                background-color: rgba(66, 66, 66, 0.5);
                text-align: center;
                padding: 9px 0;
                margin: 0 auto;
                margin-bottom: 12px;
                margin-left: -1px;
                // display: none;
                cursor: pointer;
                img{
                    width: 16px;
                    height: auto;
                }

            }

            .title{
                margin-bottom: -11px;
                font-size: 15px;
                // line-height: 22px;
                // text-transform: uppercase;
                // padding-left: 54px;
            }
            .smalltitile{
                margin-bottom: 0;
                font-size: 15px;
                line-height: 18px;
                text-transform: capitalize;
                margin-left: 0;
                max-width: 252px;
                // display: none;
            }
        }

        video{
            width: 100%;
            height: 100%;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &:hover{
            transform:translateY(-10px) scale(1.12);
            transition: 0.3s ease-in-out;
            background: linear-gradient(315deg, #FFB438 0%, #FF9F00 100%);
            padding: 2px;
            .category_title{
                display: none;
            }
            .video_downarrow{
                opacity: 1;
            }
            .subTitle{
                text-align: left;
                display: block;
                .check-sign{
                    // display: block;
                }
                .video_icon{
                    // display: block;
                    // margin-left: -1px;
                }
                .title{
                    // font-size: 12px;
                    // text-transform: capitalize;
                    // padding-left: 0;
                }
                .smalltitile{
                    display: block;
                    // margin-left: 0;
                }
            }
        }
    }

}

.MuiDrawer-paperAnchorLeft {
    backdrop-filter: blur(10px) !important;
    background-color: rgba(0,0,0,0.26) !important;
    justify-content: center;
}
.second-sidebar ~ div{
    backdrop-filter: blur(10px);
    color: #fff !important;
    background-color: rgba(0,0,0,0.26) !important;
    justify-content: center;
}
.second-sidebar{
    width: 345px;

    > div{
        width: 100%;
    }
    ul{
        div{
            svg{

                path{
                    fill:rgba(255,255,255,0.6) ;
                }
            }
            span{
                font-size: 20px;
                color: rgba(255,255,255,0.6);
            }
            
            &:hover{

                svg{

                    path{
                        fill:#ffa819;
                    }
                }

                span{
                    color: rgba(255,255,255,1);
                }
            }
        }


    }
    .collapse{

        .sidebartwo{
            list-style: none;
            margin:0;
            padding: 0;
            width: 228px;

            li{
                font-size: 15px;
                color: rgba(255,255,255,0.6);
                padding: 7px 5px;

                &:hover{
                    color: rgba(255,255,255,1);
                    font-weight: 400;
                }
            }
        }
    }

    .makeStyles-list-11{
        margin-top: 123px;

        .MuiList-root{

            .MuiButtonBase-root{

                .MuiListItemIcon-root{
                    margin-right: -7px;
                }
                .MuiListItemText-root{
                    margin-top: 5px;
                }
            }
            .MuiListItem-gutters{
                padding-left: 29px; 
            }

            &.MuiList-padding{
                padding-top: 4px;
                padding-bottom: 0px;
                margin-bottom: 0px;
            }
        }
        .sidebartwo{
            margin-left: 96px !important;
        }
    }

    .sidebar__btn{

        button{
            position: absolute;
            left:30px;
            bottom: 40px;
            max-width: 285px;
            height: 40px;
        }
    }
    
    .second-sidebar-menu{
        margin-top: 123px;

        .MuiList-root{

            .MuiButtonBase-root{

                .MuiListItemIcon-root{
                    margin-right: -7px;
                }
            }
        }
    }
}

.second-sidebar .lookingvideo{

    color: #fff;
    padding-left: 16px;
    padding-right: 16px;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    position: absolute;
    top: 80px;

    span{
        display: block;
        color: #ff9f00;
    }
}
.sidebardrawer{
    z-index: 992 !important;
}

.screenshot-section{
    display: flex;
    margin:-14px -15px 0 -30px;
    flex-flow: row wrap;
    overflow-y: scroll;
    padding-top: 35px;
    &__header{
        color: $white;
        margin-top: 25px;
    }
   &__prvbtn , &__nextbtn{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 99;
   }
   &__prvbtn{
       left: -50px;
   }
   &__nextbtn{
       right: -50px;
   }
    .screens-img{
        width: 356px;
        height: 200px;
        // margin: 0 15px;
        margin-left: 30px;
        margin-bottom: 30px;

        .card{
            width: 100%;
            height: 100%;
            position: relative;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .title{
                text-align: center;
                position: absolute;
                bottom: 10px;
                left: 0;
                right: 0;
                font-size: 15px;
                text-transform: uppercase;
                line-height: 22px;
                margin-bottom: 0;
            }
            .overlay-text{
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 50px;
                font-weight: 600;
                display: none;
            }

            &::before{
                content: "";
                position: absolute;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                z-index: 0;
            }

            &:hover{
                transform:translateY(-13px) scale(1.13);
                transition: 0.3s ease-in-out;
                background: linear-gradient(315deg, #FFB438 0%, #FF9F00 100%);
                padding: 1px;

                .overlay-text{
                    display: flex;
                }
            }
        }
        
       
    }
}

.screenshot-image-modal{

    .modal-content{
        max-width: 1372px;
        margin: 0 auto;

        .screenshot-section__prvbtn,
        .screenshot-section__nextbtn{
            top:53%;
        }
        .screenshot-image-preview{
            height: 773px;
            position: relative;
            
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
            .screenshot-img-disclaimer{
                height: 68px;
                background-color: rgba(0,0,0,0.16);
                padding: 12px;
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                text-align: center;
                font-family: 'Roboto',sans-serif;
                font-size: 15px;
                font-weight: 500;
                line-height: 22px;
                color: rgba(255,255,255,0.6);

                p{
                    margin-bottom: 0;
                }
            }
        }
    }
}
.new_releases_section{
    margin-top: 32px;
    margin-left: -16px;
}

.my_list_page{
        margin-top: 31px;
        margin-left: -15px;
}

.specialization-page{
    margin-top: -13px;
    margin-left: -16px;
    .myListCatelogue{
        margin: -35px -15px 0;
    }
}

.screenshot-page{
    margin-top: 8px;
}
.screenshotSentsuccess{
    // height: 773px;
  width: 340px;
  background-color: rgba(255,255,255,0.08);
  color: $white;
  padding: 4rem;
  box-shadow: inset  0 0 0 0.2rem #35A85A;
  h4{
      font-size: 2rem;
      color: $white;
  }
  p{
      font-size: 1.5rem;
      color: $white;
      font-weight: 300;
      font-family:  $body-font;
  }
}

.placeholder__box{
    width: 356px ;
    height: 200px;
    background: #3F3F3F;
    border-radius: 1px;
    margin-left: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}

@media (max-width:1700px){
    .screenshot-section{
        gap: 20px;
        padding: 35px 20px 0 30px;
        max-height: 80vh;
    }
    .screenshot-section .screens-img{
        width: 23%;
        height: auto;
        margin-left: 0;
        margin-bottom: 0;
    }
    .screenshot-section .screens-img .card:hover{
        transform: translateY(-10px) scale(1.1);
    }
    .screenshot-section .screens-img .card .overlay-text{
        font-size: 2rem;
    }
}

@media (max-width:1280px){
    .screenshot-section .screens-img{
        width: 31%;
    }
}