@import "../../scss/utils/variables";

.main {
  &__screen {
    background: linear-gradient(
      315deg,
      rgba(40, 40, 40, 0.93) 0%,
      rgba(0, 0, 0, 0.8835) 100%
    );
    .waiting_room_channel__main__screen,.operator_channel__main__screen{
      background: transparent;
      font-size: 1.5rem;
    }
    .channels__item:last-child {
      position: relative;
      margin-left: 60px;

      &::after {
        content: "";
        position: absolute;
        left: -40px;
        top: 70px;
        bottom: 0;
        width: 1px;
        height: 107px;
        background-color: rgba(255, 255, 255, 0.6);
      }
    }
  }
}
.channels {
  &__page {
  }
  &__item {
    &__divider {
      background-color: rgba(255, 255, 255, 0.6);
      height: 107px;
      width: 1px;
      top: 54px;
      position: relative;
    }
  }
  &__box {
    min-height: 200px;
    max-width: 270px;
    width: 100%;
    border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.08);
    aspect-ratio: 1/1;
    cursor: pointer;
    .channels__icon {
      img {
        // max-width: 180px;
        max-width: 67%;
        display: block;
        margin: 0 auto;
        // height: 180px;
      }
    }
  }
  &__name {
    text-transform: uppercase;
  }
}



@media (max-width:1700px){
  .channels__box{
    min-height: 200px;
    max-width: 200px;
  }
}