@import "../../scss/utils/variables";

.form-group{
    margin-bottom: 1.3rem;
    &.disable{
        opacity: 0.4;
    }
    &.notverifyed{
        input{
            box-shadow: inset  0 0 0 0.2rem #d47074!important;
        }
    }
    &.inputerror{
        .form-control{
            box-shadow: inset  0 0 0 0.2rem #d47074!important;
        }
    }
    &.icon-card input{
        padding-right: 50px;
    }
    &.icon-card .icon-img{
        height: 25px;
        width: 35px;
        position: absolute;
        top: 34px;
        right: 20px;
    }
    &.cvv-img {
        label{
            position: relative;
            // padding-right: 40px;
            // display: block;
            img{
                width:33px;
                height: 25px;
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
        input{
            padding-right: 50px;
        }
        .what-cvv{
            height: 25px;
            width: 23px;
            border-radius: 2px;
            background: linear-gradient(359.98deg, #7287C5 0%, #6287FF 53.71%, #2EA8FF 100%);
            position: absolute;
            text-align: center;
            line-height: 25px;
            right: 20px;
            top: 34px;
            cursor: pointer;
            user-select: none;
            color: $white;
            &:hover span{
                visibility: visible;
                opacity: 1;
                transform: scale(1);
            }
            span{
                height: 90px;
                width: 215px;
                border-radius: 2px;
                background: linear-gradient(359.98deg, #7287C5 0%, #6287FF 53.71%, #2EA8FF 100%);
                position: absolute;
                bottom: 0;
                right: 0;
                overflow: hidden;
                padding: 8px 10px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                transform: scale(0);
                transform-origin: bottom right;
                visibility: hidden;
                opacity: 0;
                transition: all 0.2s ease-in-out;

                img{
                    height:44px;
                    width:60px;
                    margin-right: 8px;
                }
                p{
                    font-size: 12px;
                    line-height: 18px;
                    margin:0px;
                    text-align: left;
                }
            }
        }

    }
}
.form-row > .col, .form-row > [class*=col-]{
    padding-left: 1rem;
    padding-right: 1rem;
}
input::-webkit-input-placeholder {
    color: rgba(255,255,255,0.4)!important;
}
    
input:-moz-placeholder { /* Firefox 18- */
    color: rgba(255,255,255,0.4)!important;  
}
    
input::-moz-placeholder {  /* Firefox 19+ */
    color:  rgba(255,255,255,0.4) !important;  
}
    
input:-ms-input-placeholder {  
    color:  rgba(255,255,255,0.4) !important;  
}

.transparent__form--dark{
    border-radius: 10px;
    background: linear-gradient(315deg, #2E2E2E 0%, rgba(5,5,5,0.80) 100%);
    padding: 3.5rem 4rem;
    backdrop-filter: blur(15px);
    color: $white;
    h1,h2,h3,h4,h5,h6{
        color: $white;
    }
}
.transparent__form--white{
    border-radius: 7px;
    background-color: rgba(255,255,255,0.08);
    padding: 3rem;
    color: $white;
    backdrop-filter: blur(15px);
    &.lg{
        width: 100%;
        max-width: 1624px;
        min-height: 740px;
        margin-top: -70px;
    }
    h1,h2,h3,h4,h5,h6{
        color: $white;
    }
    &.bg_red{
        background: linear-gradient(135deg, #D0393E 0%, #D54A53 100%);
    }
    
}
.bg_red{
    background: linear-gradient(135deg, #D0393E 0%, #D54A53 100%);
}
.darkFields{
    textarea{
        overflow: hidden;
        min-height: 140px;
    }
    .form-control , 
    .react-tel-input .form-control{
        font-size: 1.5rem;
        font-weight: 400;
        color: $white;
        height: 40px;
        width: 100%;
        min-width: 350px;
        max-width: 350px;
        width: 100%;
        border-radius: 2px;
        background-color: rgba(216,216,216,0.4);
        border: 0;
        padding: 1rem 1rem;
        &:focus{
            box-shadow: none;
            background-color: rgba(216,216,216,0.2);
            box-shadow: inset  0 0 0 0.2rem $primary-color;
            color: $white;
        }
        &.invalid-field{
            box-shadow: inset  0 0 0 0.2rem #d47074;
        }
        &.custom-datepicker{
            background-image: url('../../img/ic_callendar.svg');
            background-repeat: no-repeat;
            background-position-x:97%;
            background-position-Y:center;
        }
        &.mw_auto{
            min-width: auto;
        }
        &.w-100{
            max-width: 100%;
        }
        &.bg__yellow{
            background-color: rgba(255,159,0,0.15);
            &::-webkit-input-placeholder {
                color: rgba(66,66,66,0.6)!important;
            }
                
            &:-moz-placeholder { /* Firefox 18- */
                color: rgba(66,66,66,0.6)!important;
            }
                
            &::-moz-placeholder {  /* Firefox 19+ */
                color: rgba(66,66,66,0.6)!important;
            }
                
            &:-ms-input-placeholder {  
                color: rgba(66,66,66,0.6)!important;
            }
        }
       
    }
}
.app{
    

    
    .verifyed{
        .form-control{
            // background: linear-gradient(135deg, #35A85A 0%, #4DA166 100%);
            // color: $main-font-color!important;
            background-image: url(../../img/teenyicons_tick-circle-solid.svg);
            background-repeat: no-repeat;
            background-position: right 10px center;
        }
    }

    .inner_white_box{
        background-color: $white;
        padding: 4rem;
        color: $main-font-color;
        border-radius: 5px;
        h1,h2,h3,h4,h5,h6{
            color: $main-font-color;
        }
        &.mh-335{
            max-height: 335px;
            overflow-y: auto;
        }
        .form-control, 
        .react-tel-input .form-control,
        .react-select-container .react-select__single-value,.react-select-container .react-select__placeholder,
        .react-select-container .react-select__value-container .react-select__input{
            color: $main-font-color ;
            &:focus{
                color: $main-font-color ;
            }
        }
    }

    .purchase_card{
        .form-control, 
        .react-tel-input .form-control,
        .react-select-container .react-select__single-value{
            color: $main-font-color ;
            &:focus{
                color: $main-font-color ;
            }
        }
    }

    .form-control , 
    .react-tel-input .form-control{
        font-size: 1.5rem;
        font-weight: 400;
        color: $white;
        height: 40px;
        width: 100%;
        min-width: 350px;
        max-width: 350px;
        width: 100%;
        border-radius: 2px;
        background-color: rgba(216,216,216,0.20);
        border: 0;
        padding: 1rem 1rem;
        &:focus{
            box-shadow: none;
            background-color: rgba(216,216,216,0.2);
            box-shadow: inset  0 0 0 0.2rem $primary-color;
            color: $white;
        }
        &.invalid-field{
            box-shadow: inset  0 0 0 0.2rem #d47074;
        }
        &.custom-datepicker{
            background-image: url('../../img/ic_callendar.svg');
            background-repeat: no-repeat;
            background-position-x:97%;
            background-position-Y:center;
        }
        &.mw_auto{
            min-width: auto;
        }
        &.bg__yellow{
            background-color: rgba(255,159,0,0.15);
            &:disabled{
                opacity: 0.5;
            }
            &::-webkit-input-placeholder {
                color: rgba(66,66,66,0.6)!important;
            }
                
            &:-moz-placeholder { /* Firefox 18- */
                color: rgba(66,66,66,0.6)!important;
            }
                
            &::-moz-placeholder {  /* Firefox 19+ */
                color: rgba(66,66,66,0.6)!important;
            }
                
            &:-ms-input-placeholder {  
                color: rgba(66,66,66,0.6)!important;
            }
        }
    }

    .styles_react-code-input-container__tpiKG{
        width: 100%!important;
    }

    .styles_react-code-input__CRulA , .react-code-input{
        display: flex!important;
        width: 100%;
        justify-content: space-between;
        max-width: 350px;
        &.bg_white {
            > input{
                background-color: $white;
                color:#424242;
            }
        }
        > input{
            background-color: rgba(216,216,216,0.2);
            width: 65px;
            height: 80px;
            border: 0!important;
            font-size: 5.3rem;
            font-weight: 400;
            color: $white;
            text-align: center;
            &:last-child,
            &:first-child{
                border-radius: 0;
            }
            &:focus{
                box-shadow: inset  0 0 0 0.2rem $primary-color;
                caret-color:$primary-color;
            }
            &.invalid-field{
                box-shadow: inset  0 0 0 0.2rem #d47074;
            }
        }
    }

    .react-datepicker{
        font-size: 1.5rem;
        line-height: 1.5;
        border-radius: 2px;
        border:0;
        background-color: rgba(46,46,46,1);
        
        box-shadow: 0px 4px 7px rgba(52,52,52,0.25);
        &-popper[data-placement^="bottom"]{
            margin-top: 0;
        }
        &__triangle{
            display: none;
        }
        &__day,&__time-name{
            width: 4.4rem;
            line-height: 4.4rem;
            color:  $white;
            margin: 0;
    
            &--selected, &--in-selecting-range, &--in-range, 
            &--selected, &--in-selecting-range, 
            &-text--in-range, &-text--selected, 
            &-text--in-selecting-range, &-text--in-range{
                color: $white;
            }
        }
        &__day, 
        &__month-text
        &__quarter-text, 
        &__year-text{
            &:hover{
                background-color: $primary-color;
                color: $white;
                border-radius: 0rem;
            }
        }
        &__day--disabled, 
        &__month-text--disabled, 
        &__quarter-text--disabled{
            cursor: default;
            color: $white;
        }
        &__week{
            .react-datepicker__day{
                &:last-child{
                    border-right:none
                }
                
            }
        }
        &__day{
            border-bottom: 1px solid rgba(216,216,216,0.2);;
            border-right: 1px solid rgba(216,216,216,0.2);;
            &--outside-month{
                color: $white;
            }
        }
        &__day--keyboard-selected, 
        &__month-text--keyboard-selected, 
        &__quarter-text--keyboard-selected{
            border-radius: 0rem;
            background-color: $primary-color;
            color: $white;
            &:hover{
                background-color: $primary-color;
                color: $white;
                border-radius: 0rem;
            }
        }
        &__day-names{
            margin: 0 1.2rem 0rem 1.2rem;
            border: 1px solid rgba(216,216,216,0.2);;
            border-bottom: none;
        }
        &__day-name{
            width: 4.4rem;
            padding: 0.5rem;
            color: $white;
            border-right: 1px solid rgba(216,216,216,0.2);;
            margin: 0;
            &:last-child{
                border-right: none;
            }
        }
        &__header{
            background:rgba(46,46,46,1);
            border-bottom:none;
            
        }
        &__month{
            margin:0 1.2rem 1.2rem 1.2rem;
            border: 1px solid rgba(216,216,216,0.2);;
        }
        &__current-month, 
        &-time__header, 
        &-year-header{
            font-size: 1.8rem;
            color: $white;
        }
        &__navigation{
            &--next{
                border-left-color: $primary-color;
            }
            &--previous{
                border-right-color: $primary-color;
            }
            &--years-upcoming{
                border-bottom-color: #424242;
            }
            &--years-previous{
                border-top-color: #424242;
            }
        }
        &__year-read-view,&__month-read-view{
            color: $white;
        }
        &__year-dropdown{
            height: 200px;
        }
        &__year-dropdown, &__month-dropdown{
            background-color: $primary-color;
            border-color: $primary-color;
           
        }
       &__year-option:hover, 
       &__month-option:hover, 
       &__month-year-option:hover{
           background-color: #424242;
           color: $white;
       }
    }

    .react-tel-input {
        // &::after{
        //     content: '';
        //     position: absolute;
        //     top: 1rem;
        //     bottom: 1rem;
        //     right: 0;
        //     width: 1px;
        //     background-color: $white;
        // }
       .special-label{
           display: none;
       }
        .form-control{
            padding-left: 5.8rem;
            z-index: 3;
            position: relative;
            // width: 6rem;
        }
        .flag-dropdown{
            background-color: transparent;
            border: none;
            color: $main-font-color;
            width: 100%;
            &.open{
                background: transparent;
                border: none;
                z-index: 5;
                .selected-flag{
                    background: transparent;
                }
            }
            ::-webkit-scrollbar {
                width: 10px!important;  /* Remove scrollbar space */
                // background: transparent;  /* Optional: just make scrollbar invisible */
            }
            /* Optional: show position indicator in red */
            ::-webkit-scrollbar-thumb {
                background: #FF9F00!important;
            }
            ::-webkit-scrollbar-thumb:hover {
                background: #FF9F00;
            }
            .country-list{
                width: 100%;
                margin-top: 0;
                max-width: 350px;
                border-radius: 2px;
                // background-color: rgba(216,216,216,0.2);
                
            }
        }
        .selected-flag{
            width: auto;
            &:hover, &:focus{
                background-color: transparent;
                .arrow{
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid $primary-color;
                }
            }
            .flag{
                z-index: 4;
            }
            .arrow{
                // height:20px;
                // border: none;
                // background-color: #fff;
                // width: 1px;
                // margin: 0;
                // top: -5px;
                // left: 30px;
                // bottom: 0;
                position: relative;
                top: 50%;
                margin-top: -0.5px;
                left: 3rem;
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid $primary-color;
                // display: none;
                &.up{
                    border-top: none;
                    border-bottom: 5px solid $primary-color;
                }
            }
        }
    }

    textarea.form-control{
        max-width: 100%;
        min-height: 300px;
        border-radius: 2px;
        background-color: rgba(255,255,255,0.96);
        color: $main-font-color;
        padding: 2rem;
        margin-bottom: 22px;
        &:focus{
            box-shadow: none;
            background-color: rgba(255,255,255,0.96);
            color: $main-font-color;
        }
    }
    

    

    .custom-redio{
        .custom-control-input{
            height: 2rem;
            width: 2rem;
            &:focus ~ .custom-control-label::before {
                box-shadow:none!important;
                border-color: #424242;
            }
            &:not(:checked) + .custom-control-label:after {
                opacity: 0;
                -webkit-transform: scale(0);
                transform: scale(0);
            }
            &:checked ~ .custom-control-label::before{
                opacity: 1;
                -webkit-transform: scale(1);
                transform: scale(1);
                background-color: transparent;
                border-color: #424242;
            }
        }
        &.white{
            .custom-control-input{
                &:focus ~ .custom-control-label::before {
                    box-shadow:none!important;
                    border-color: $white;
                }
                &:not(:checked) + .custom-control-label:after {
                    opacity: 0;
                    -webkit-transform: scale(0);
                    transform: scale(0);
                }
                &:checked ~ .custom-control-label::before{
                    opacity: 1;
                    -webkit-transform: scale(1);
                    transform: scale(1);
                    background-color: transparent;
                    border-color:$white;
                }

            }
            
            .custom-control-label{
                &:before {
                    border-color: $white;
                    background-color: transparent;
                }
                &:after {
                    background-color: $white;
                }
            }
        }
        .custom-control-label{
            padding-left: 3rem;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 2rem;
                height: 2rem;
                border: solid 2px #424242;
                border-radius: 1.5rem !important;
                background-color: #fff;
            }
            &:after {
                content: '';
                width: 12px;
                height: 12px;
                background-color: #424242;
                position: absolute;
                top: 4px;
                left: 4px;
                border-radius: 1.5rem !important;
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }
        }
    }

    .custom-checkbox{
        position: relative;
        padding-left: 0;
        &.sm{
            .custom-control-label:before{
                height: 14px;
                width: 14px;
                top: 50%;
                margin-top: -7px;
            }
            input:checked + .custom-control-label:after{
                height: 8px;
                width: 8px;
                left: 3px;
                top: 50%;
                margin-top: -4px;
            }
            .custom-control-label{
                padding-left: 20px;
            }
        }
        .custom-control-input{
            position: relative;
            height: 0px;
            width:0px;
        }
        .custom-control-label{
            font-size: 15px;
            margin: 0px;
            cursor:pointer;
            position: relative;
            padding-left: 40px;
        }
        .custom_check_toggle{
            display: inline-block;
            cursor:pointer;
            img{
                max-width: 18px;
                margin-left: 10px;
            }
        }
        .custom-control-label:before{
            content:"";
            height: 20px;
            width: 20px;
            position: absolute;
            left: 0;
            top:0;
            background-color: transparent;
            border-radius:20px;
            border: 2px solid #FF9F00;
        }
        input:checked + .custom-control-label:before{
            // background: linear-gradient(359.98deg, #7287C5 0%, #6287FF 53.71%, #2EA8FF 100%);
            // border-color: #6287FF;
        }
        .custom-control-input:focus ~ .custom-control-label::before{
            box-shadow:none
        }
        .custom-control-input:focus:not(:checked) ~ .custom-control-label::before{
            box-shadow:none;
            // border-color: #424242;
            
        }
        input:checked + .custom-control-label:after{
            content:"";
            background-image: none;
            height: 10px;
            width: 10px;
            position: absolute;
            left: 5px;
            top: 5px;
            background-size: contain;
            background-repeat: no-repeat;
            background-color: #FF9F00;
            border-radius:12px;
        }
    }

    .custom__error{
        background-color: #D0393E;
        font-size: 1.5rem;
        position: fixed;
        bottom: 0;
        width: 100%;
        text-align: center;
        color: #fff;
        padding: 0.9rem 1.5rem;
        left: 0;
        min-height: 5.8rem;
        z-index: 9;
        .custom-error-icon{
            width:30.18px;
            height:24.96px;
            margin-right: 20px;
        }
    }

    fieldset:disabled{
        opacity: 0.4;
    }

    
}

.tabs{
    width: 374px;
    ul{
        font-size: 20px;
        margin-bottom: 10px;
        border-bottom:1px solid rgba(#D8D8D8 , 20%);
        padding-top: 3px;
        label{
            cursor: pointer;
            margin: 0;
        }
        li{
            position: relative;

            &::after{
                content: "";
                position: absolute;
                left:0;
                right:0;
                bottom: -2px;
                width: 0;
                height: 2px;
                background-color: $white;
                transition: 0.2s ease-in;
            }
            &.activetab{
                &::after{
                    width: 100%;
                }
            }
        }
    }
}

html .custom__multi__step__form {
    padding: 0!important;
    width: 100%;
    max-width: 101rem;
    &--progressbar{
        margin: 1rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
    }
    
    &--step{
        height: 3rem;
        width: 2rem;
        list-style: none;
        position: relative;
        text-align: center;
        position: relative;
        border:0;
        &:first-child{
            &::before{
                content: none;
            }
        }
        &.active{
            &::before{
                background-position: left bottom;
            }
            span{
                background-color: #FFB438;
                animation:none;
                &:hover{
                    background-color: #FFB438;
                }
            }
        }
        &.active{
            span{
                background-color: #FFB438;
                &:hover{
                    background-color: #FFB438;
                }
            }
        }
        span{
            height: 1.3rem;
            width: 1.3rem;
            line-height: 1.3rem;
            border-radius: 50%;
            background-color:#4F4F4F;
            font-size: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-50% , -50%);
            transform: translate(-50%, -50%);
            svg{
                display: none;
            }
        }
    }
    &--label{
        top: -100%;
        font-size: 18px;
        white-space: nowrap;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    &--content{
        padding: 0;
        .transparent__form--white{
            width: 100%;
            max-width: 82rem;
            margin:0 auto;
        }
    }
}

.invalid-field{
    box-shadow: inset  0 0 0 0.2rem #d47074;
}
.signin__box  input[type=number]   { padding-left: 0px; }
// .edit_profile__main__screen input[type=number] { padding-left: 18px; }
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.owners_profile_creation__main__screen .react-select-container .react-select__single-value { white-space: pre-wrap !important; overflow: visible !important; text-overflow: ellipsis !important; line-height: 1.2; }
.owners_profile_creation__main__screen .react-select__option { white-space: pre-wrap !important; overflow: visible !important; text-overflow: ellipsis !important; line-height: 1.1 !important; }
.ediImage__left_side__screen input      { padding: 5px 10px !important; font-size: 15px; line-height: 22px; color: #fff; }
.MuiBox-root .MuiTypography-root .MuiGrid-container .MuiGrid-grid-lg-9 .pintura-editor { background: rgba(0,0,0,0.1); }
.placeholder-textarea           { position: relative; }
.placeholder-textarea textarea  { color: rgba(255, 255, 255, 0.5); position: relative; z-index: 1; resize: none; }
// .placeholder-textarea:after     { position: absolute; top: 50%; left: 0;  width: 390px; height: auto; content: 'Sent by your doctor at “Smith Dental”.'; font-size: 15px; line-height: 22px; padding: 0px 10px; z-index: -1; }
.PinturaRadioGroupOptions li label { padding: 0px 30px !important; }
.PinturaRadioGroupOptions li label div { margin-left: 7px; padding-left: 5px;}
.PinturaRadioGroupOptions li    { border-width: 1px !important; }
.ediImage__right_side__screen h5 { font-size: 20px !important; line-height: 30px !important; }

.new-opratory-sec-left  h6           { margin-bottom: 25px; font-size: 20px; line-height: 26px; }
.channel-name-opratory          { margin-bottom: 25px; }
.new-opratory-sec-left .Operatory__show_header { margin-bottom: 25px;}
.Operatory__main__screen--slide--footer h3 { background-color: #b42f2e !important; }
.Operatory__main__screen--slide--footer--text p { color: #000 !important; font-size: 23px !important; line-height: 30px; font-weight: 500; }
.new-opratory-sec-right .footer.Operatory__main__screen--slide--footer--text { padding: 5px !important; }
.new-opratory-sec-right .Operatory__main__screen--slide--footer label { color: #000 !important; font-size: 20px !important; line-height: 26px; font-weight: 500; }
// .app .form-control:focus, .app .react-tel-input .form-control:focus { box-shadow: none; border: 3px solid #ff9f00 }
.react-select__menu                     { position: absolute !important; top: 100% !important; left: 0; width: 100% !important; height: auto;z-index: 9999 !important; }
.react-select-container .react-select__menu-list { max-height: 150px !important; }
.slide-cat-dropdown .react-select__menu-list { max-height: 100px !important; }