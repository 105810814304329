@import "../../scss/utils/variables";

.upgradeplan{
    &__main{
        &__screen{
            // background-image: url('../../img/Background_imageTHALAMUS_Subscription.jpg');
            background: linear-gradient(315deg, rgba(40, 40, 40, 0.93) 0%, rgba(0, 0, 0, 0.8835) 100%);
            background-size: 100% 100%;
            padding-top: 6rem;
            width: 100%;
            color: $white;
           
            .transparent__form--white{
                padding: 2rem;
                // min-height: 808px;
            }
            .mw-1270{
                max-width: 1270px;
                width: 100%;;
            }
            .mw-849{
                width: 100%;
                max-width: 849px;
            }
            .mw-1082 {
                max-width: 1082px;
                width: 100%;
            }
            .padd-l-20{
                padding-left: 20px;
            }
            .padd-r-20{
                padding-right: 20px;
            }
            .bg_gray{
                border-radius: 2px;
                background-color: rgba(216,216,216,0.2);
                
            }
            .list-group{
                .list-group-item{
                    min-height: 58px;
                    border: 1px solid rgba(216,216,216,0.2);
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
            .list-group-flush {
                > .list-group-item{
                    border-width: 1px 0 0;
                }
            }
        }
    }
    &__inner{
        &__screen{
            width: 100%;
            
            &__heading{
                background-color: #fff;
                border-radius: 2px;
                justify-content: space-between;
                display: flex;
                padding: 15px 40px;
                align-items: center;

                h5{
                    text-transform: capitalize;
                    font-size: 20px;
                    margin-bottom: 0;
                    font-weight: 700;
                }
            }

            &__planbox{
                display: flex;
                margin: 0 -7.5px;
                margin-top: 15px;
            }

            &__plandetail{
                flex: 1;
                background-color: #fff;
                color: #000;
                padding: 30px;
                min-height: 500px;
                border-radius: 2px;
                position: relative;
                margin: 0 7.5px;
                h5{
                    font-size: 20px;
                    text-transform: capitalize;
                    margin-bottom: 30px;
                }
                p{
                    &.plan-desc{
                        height: 103px;
                        margin-bottom: 30px;
                        font-size: 15px;
                    }
                   
                }
                ul{

                    li{
                        margin: 5px 0;
                    }
                }
            }
            &__footer{
                position: absolute;
                bottom: 30px;
                max-width: 100%;
                right: 30px;
                left: 30px;

                p{
                    font-size: 20px;
                    font-weight: 600;

                    span{
                        font-size: 14px;
                        font-weight: 400;
                        color: #a59595;
                    }
                }
                button{
                    max-width: 100% !important;
                }
            }

        }
        &__text{
            color: $white;
            font-size: 2rem;
            line-height: 3rem;
            max-width: 67rem;
            h1{
                margin-top: 2rem;
            }
            h3{
                font-size: 2.7rem;
                font-weight: 500;
                letter-spacing: 7px;
                margin-bottom: 3rem;
            }
            h1,h2,h3,h4,h5,h6{
                color: $white;
            }
            
        }
    }
}