.drag {
    margin-right: 19px;
    cursor: move;
  }
.currentdrag{
    background: red; 
}
.drag__items{
  flex: 1;
}
.drag__items{
  margin-bottom: 1rem;

  &--box{
    box-sizing: border-box;
    height: 41px;
    max-width: 350px;
    min-width: 350px;
    border: 1px solid rgba(255,255,255,0.4);
    border-radius: 2px;
    background-color: rgba(216,216,216,0.05);
    padding: 0.5rem 1rem;
    font-size: 13px;
    letter-spacing: 0.6px;
    text-transform: capitalize;

    &.bg_red{
      border-color: #D0393E;
    }
  }

  .btn-close{
    height: 30px;
    width: 30px;
    border: 1px solid #FFFFFF;
    border-radius: 2px;
  }
}

.messages_inner_box_section{
  max-height: 450px;
  overflow-y: auto;
}